import { useEffect, useMemo, useState } from 'react';
import calculateMovementsPerStage from './calculateMovementsPerStage';
import headerIPR from './headerIPR';
import useTeethChartDataTransform from './useTeethChartDataTransform';

const arrOfKeys = [...new Array(32).keys()];
const TEETH_HEADER = Object.freeze(
  arrOfKeys.splice(0, 16).concat(arrOfKeys.reverse()),
);

function floatAggregatedValue(aggregatedValue) {
  return (
    aggregatedValue &&
    aggregatedValue.toFixed(1) >= 0.1 &&
    aggregatedValue.toFixed(1)
  );
}

function useIprsCalculations({
  setIprs,
  otpFiles,
  redesignCountObj,
  activeStage,
}) {
  const [isTotal, setIsTotal] = useState(true);
  const [orderStages, setOrderStages] = useState([]);

  useEffect(() => {
    if (otpFiles) {
      const otpFileForCurrentRedesign = otpFiles.find(
        (item) => item.redesignCount === redesignCountObj,
      );
      setOrderStages(
        otpFileForCurrentRedesign ? otpFileForCurrentRedesign.stages : [],
      );
    }
  }, [otpFiles, redesignCountObj]);

  useEffect(() => {
    if (activeStage && activeStage !== 1) {
      setIsTotal(false);
    }
  }, [activeStage]);

  const recalculatedStage = useMemo(
    () => calculateMovementsPerStage(orderStages),
    [orderStages],
  );

  const total = useMemo(
    () =>
      orderStages.length > 0
        ? {
            ...orderStages[orderStages.length - 1],
            Teeth: orderStages[orderStages.length - 1].Teeth.filter(
              (tooth) =>
                tooth.Movement.Value.MovementValues?.Value &&
                Object.keys(tooth.Movement.Value.MovementValues?.Value).length >
                  0,
            ),
          }
        : {},
    [orderStages],
  );

  const { headerTableData, bodyTeethData } = useTeethChartDataTransform(
    recalculatedStage,
    activeStage,
    isTotal,
    total,
  );

  const calculateIprs = useMemo(() => {
    if (!headerTableData) {
      return {};
    }

    return TEETH_HEADER.reduce((obj, toothUnn) => {
      const { style, aggregatedValue } = headerIPR(toothUnn, headerTableData);
      const showIPR = aggregatedValue && style && Object.keys(style).length > 0;

      obj[toothUnn + 1] = {
        showIPR: !!(showIPR && style?.borderRight),
        aggregatedValue: floatAggregatedValue(aggregatedValue),
      };
      return obj;
    }, {});
  }, [headerTableData]);

  useEffect(() => {
    setIprs(calculateIprs);
  }, [calculateIprs, setIprs]);

  return {
    orderStages,
    isTotal,
    setIsTotal,
    total,
    recalculatedStage,
    bodyTeethData,
    headerTableData,
  };
}

export default useIprsCalculations;
