import { useAmplify } from '@fullcontour/auth';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { PasswordInput } from '../../../shared';
import { ServerErrorsAmplify } from '../../../shared/Errors';
import UpdatePasswordSuccess from './UpdatePasswordSuccess';

function UpdatePasswordForm() {
  const { updateProfilePassword, error } = useAmplify();
  const [{ isFormSuccess, serverErrors }, setState] = useState({
    isFormSuccess: false,
    serverErrors: null,
  });
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: { password: '', currentPassword: '' },
    validationSchema: Yup.object({
      password: Yup.string().min(8, 'Too Short!').required('Required'),
    }),
    onSubmit: async ({ password, currentPassword }, { resetForm }) => {
      updateProfilePassword({
        newPassword: password,
        oldPassword: currentPassword,
      })
        .then(() => {
          setState({ isFormSuccess: true, serverErrors: null });
        })
        .catch((err) => {
          setState({ isFormSuccess: false, serverErrors: err.message });
        })
        .finally(() => {
          resetForm();
        });
    },
  });
  const { t } = useTranslation('formfields');
  return (
    <form onSubmit={handleSubmit} className="mt-2">
      <div className="columns">
        <div className="column is-8">
          <div className="block">
            <h4 className="title is-4">Update Password</h4>
          </div>
          <div className="block">
            <div className="field">
              <div className="control">
                <label htmlFor="currentPassword" className="title is-size-6">
                  Current Password <span className="has-text-danger">*</span>
                </label>
                <PasswordInput
                  onChange={handleChange}
                  value={values.currentPassword}
                  name="currentPassword"
                  label="Current Password"
                  formField
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
          </div>
          <div className="block">
            <div className="field">
              <div className="control">
                <label htmlFor="password" className="title is-size-6">
                  New Password <span className="has-text-danger">*</span>
                </label>
                <PasswordInput
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  label="New Password"
                  formField
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <span className="title is-size-5 has-text-danger mt-5 ml-5">
              {errors.password}
            </span>
          </div>
          <div className="block">
            <p className="is-size-7 has-text-centered">
              {t(`Passwords must be a minimum of 8 characters in length, contain an
              upper and lower case letter, a numeral, and a special character.`)}
            </p>

            <UpdatePasswordSuccess success={isFormSuccess} />
            <ServerErrorsAmplify
              errors={error || serverErrors}
              title="There was a problem updating your Password"
            />
          </div>
          <div className="block">
            <input
              type="submit"
              value="Submit"
              className={`button has-background-primary has-text-white is-pulled-right ${
                isSubmitting ? 'is-loading' : ''
              }`}
            />
          </div>
        </div>
        <div className="column is-4" />
      </div>
    </form>
  );
}

export default UpdatePasswordForm;
