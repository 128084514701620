import PropTypes from 'prop-types';

function priceListRenderer({ value }) {
  if (!value) {
    return null;
  }

  const { priceList, priceListIsCustom } = value;

  if (!priceList?.name) {
    return null;
  }

  return (
    <span>
      {priceList.name}
      {priceListIsCustom && (
        <div
          className="tag is-warning"
          style={{
            marginLeft: '5px',
            verticalAlign: 'top',
            marginTop: '4%',
          }}
        >
          custom
        </div>
      )}
    </span>
  );
}

priceListRenderer.propTypes = {
  value: PropTypes.shape({
    priceList: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    priceListIsCustom: PropTypes.bool,
  }).isRequired,
};

export default priceListRenderer;
