import PropTypes from 'prop-types';
import { AccordionSingle } from '../../../Components';
import TreatmentCard from './components/TreatmentCard';
import './TreatmentHistory.css';

function TreatmentHistory({
  otpFiles,
  feedbacks,
  setRedesignCountObj,
  currentRedesign,
  resetHistory,
  designerMessages,
  orderRedesignCount,
  designerMessagesCurrent,
  treatmentReviewFiles,
  objFiles,
  dataFromUrl = null,
  redesignCountObj = null,
}) {
  const helperArray = [...new Array(currentRedesign || 0).keys()];

  const arrayWithDetails = helperArray.map((item) => {
    const otpFileOfCurrentRedesign = otpFiles.find(
      (otpFile) => otpFile.redesignCount === item,
    );
    if (otpFileOfCurrentRedesign) {
      return otpFileOfCurrentRedesign;
    }
    let randomObjFileFromCurrentRedesign = [];
    if (objFiles?.length > 0) {
      randomObjFileFromCurrentRedesign = objFiles.find(
        (file) => file.redesignCount === item,
      );
    } else if (treatmentReviewFiles.length > 0) {
      randomObjFileFromCurrentRedesign = treatmentReviewFiles.find(
        (file) => file.redesignCount === item && file.fileType === 'obj_files',
      );
    }
    return {
      id: item,
      redesignCount: item,
      createdAt: randomObjFileFromCurrentRedesign?.createdAt,
    };
  });

  const filesArrayToDisplay = dataFromUrl ? otpFiles : arrayWithDetails;

  const handleTitleClickVersion = (v) => {
    setRedesignCountObj(v);
  };

  return (
    <div className="treatment__wrapper">
      {arrayWithDetails.length > 0 && (
        <AccordionSingle
          title="Treatment Plan"
          index={0}
          parentName="treatment_accordion"
          isOpen={dataFromUrl}
        >
          {filesArrayToDisplay.map((item) => (
            <TreatmentCard
              key={item.redesignCount}
              item={item}
              idx={item.redesignCount}
              currentRedesign={redesignCountObj}
              feedbacks={feedbacks}
              designerMessages={designerMessages}
              handleTitleClickVersion={handleTitleClickVersion}
            />
          ))}
        </AccordionSingle>
      )}

      {!dataFromUrl && (
        <TreatmentCard
          item={otpFiles.find(
            (item) => redesignCountObj === item.redesignCount,
          )}
          idx={orderRedesignCount}
          currentRedesign={redesignCountObj}
          feedbacks={feedbacks}
          designerMessages={designerMessagesCurrent}
          last
          resetHistory={() => {
            resetHistory();
          }}
        />
      )}
    </div>
  );
}

TreatmentHistory.propTypes = {
  otpFiles: PropTypes.array.isRequired,
  feedbacks: PropTypes.array.isRequired,
  setRedesignCountObj: PropTypes.func.isRequired,
  resetHistory: PropTypes.func.isRequired,
  currentRedesign: PropTypes.number.isRequired,
  orderRedesignCount: PropTypes.number.isRequired,
  designerMessages: PropTypes.array.isRequired,
  designerMessagesCurrent: PropTypes.array.isRequired,
  treatmentReviewFiles: PropTypes.array.isRequired,
  objFiles: PropTypes.array.isRequired,
  dataFromUrl: PropTypes.bool,
  redesignCountObj: PropTypes.number,
};

export default TreatmentHistory;
