import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import BoxIcon from '../../Icons/BoxIcon';
import './Drawer.scss';
import getDirectionStyle from './utils/getDirectionStyle';

function Drawer({
  open = false,
  onClose = () => ({}),
  children = null,
  style = {},
  enableOverlay = true,
  overlayColor = '#000',
  overlayOpacity = 0.4,
  direction = 'left',
  zIndex = 100,
  duration = 500,
  size = 250,
  className = '',
  customIdSuffix = '',
  lockBackgroundScroll = false,
}) {
  const bodyRef = useRef(null);

  useEffect(() => {
    const updatePageScroll = () => {
      bodyRef.current = window.document.querySelector('body');
      if (bodyRef.current && lockBackgroundScroll) {
        if (open) {
          bodyRef.current.style.overflow = 'hidden';
        } else {
          bodyRef.current.style.overflow = '';
        }
      }
    };
    updatePageScroll();
  }, [lockBackgroundScroll, open]);

  const idSuffix = useMemo(
    () => customIdSuffix || (Math.random() + 1).toString(36).substring(7),
    [customIdSuffix],
  );

  const overlayStyles = {
    backgroundColor: `${overlayColor}`,
    opacity: `${overlayOpacity}`,
    zIndex,
  };

  const drawerStyles = {
    zIndex: zIndex + 1,
    transitionDuration: `${duration}ms`,
    ...getDirectionStyle(direction, size),
    ...style,
  };

  return (
    <div id={`Drawer${idSuffix}`} className="Drawer">
      <input
        type="checkbox"
        id={`Drawer__checkbox${idSuffix}`}
        className="Drawer__checkbox"
        onChange={onClose}
        checked={open}
      />

      <nav
        id={`Drawer__container${idSuffix}`}
        style={drawerStyles}
        className={`Drawer__container ${className}`}
      >
        <div
          className="is-flex is-justify-content-space-between has-background-primary p-3"
          style={{ position: 'sticky', top: -12, zIndex: 1 }}
        >
          <h4 className="title is-4 has-text-white mb-0">Info</h4>
          <button className="delete" type="button" onClick={onClose}>
            <BoxIcon name="bx-x" />
          </button>
        </div>
        <div className="p-3">{children}</div>
      </nav>
      {enableOverlay && (
        <label
          htmlFor={`Drawer__checkbox${idSuffix}`}
          id={`Drawer__overlay${idSuffix}`}
          className="Drawer__overlay"
          style={overlayStyles}
        />
      )}
    </div>
  );
}

Drawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  enableOverlay: PropTypes.bool,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.number,
  zIndex: PropTypes.number,
  duration: PropTypes.number,
  direction: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  size: PropTypes.number,
  className: PropTypes.string,
  customIdSuffix: PropTypes.string,
  lockBackgroundScroll: PropTypes.bool,
};

export default Drawer;
