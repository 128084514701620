import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '../../../Components';
import { SearchResults } from '../../../SearchResults';
import GuideBodyCodeCard from './GuideBodyCodeCard';
import useFindCurrentDesignType from './hooks/useFindCurrentDesignType';

import './styles/guideAccordion.css';

function GuideBodyCategoryGroups({
  data: guideCategoryGroups = [],
  setActiveTabs,
  activeTabs,
  designType,
  filteValue = '',
  scrollCategory = null,
  scrollToCategory = null,
}) {
  const itemsRef = useRef([]);

  const { t } = useTranslation('designProfile');

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, guideCategoryGroups.length);
  }, []);

  useEffect(() => {
    if (
      (scrollCategory || scrollCategory === 0) &&
      activeTabs &&
      activeTabs[scrollCategory]
    ) {
      itemsRef.current[scrollCategory]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [scrollCategory, itemsRef, activeTabs?.[scrollCategory]]);

  useEffect(() => scrollToCategory?.(null), []);

  const currentDesignType = useFindCurrentDesignType();

  const designCategoriesData = useMemo(
    () => ({
      children: guideCategoryGroups.map((item, index) => {
        const cardElements = item.guideCategories.map((guideCategory) => (
          <GuideBodyCodeCard
            key={guideCategory.id}
            designType={designType}
            guideCategory={guideCategory}
            guideCategoryGroupId={item.id}
            filteValue={filteValue}
            hideCreateOtherButton
            currentDesignType={currentDesignType}
          />
        ));

        const cardBody = (
          <div>
            <div className="card__element-group">{cardElements}</div>
          </div>
        );

        return {
          cardHeader: item.name,
          ref: (el) => (itemsRef.current[index] = el),
          cardId: item.id,
          cardIndex: index,
          callback: setActiveTabs,
          isActive: activeTabs[index],
          cardBody,
          cardHeaderText:
            cardElements.length === 0 ? (
              <span className="tag ml-3">{t('Empty')}</span>
            ) : (
              `${cardElements.length} ${t('codes')}`
            ),
        };
      }),
    }),

    [guideCategoryGroups, scrollCategory, activeTabs, filteValue],
  );

  return (
    <div>
      <h6 className="title is-6">{t('Categories')}</h6>
      <SearchResults
        isNoValue={designCategoriesData?.children.length === 0}
        searchValue={filteValue}
        apologiesText="Sorry, we could not find any categories / codes with this name"
      >
        <Accordion data={designCategoriesData} />
      </SearchResults>
    </div>
  );
}

GuideBodyCategoryGroups.propTypes = {
  data: PropTypes.array.isRequired,
  setActiveTabs: PropTypes.func.isRequired,
  scrollToCategory: PropTypes.func,
  activeTabs: PropTypes.object.isRequired,
  designType: PropTypes.object.isRequired,
  filteValue: PropTypes.string,
  scrollCategory: PropTypes.number,
};

export default GuideBodyCategoryGroups;
