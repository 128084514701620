import { useQuery } from '@apollo/client';
import {
  DESTROY_INTEGRATION,
  GET_CUSTOMER_LOC_ID,
  GET_INTEGRATION_TYPES_DROPDOWN,
  GET_MANUF_ORG_ID,
} from '@fullcontour/shared-api';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import {
  CheckboxField,
  DynamicDropdown,
  FieldContainer,
  FormGridWrap,
  FormSection,
  LoadingOrError,
  PasswordField,
} from '../../../shared';

function IntegrationFormInner({
  action = null,
  root = null,
  slug = null,
  t = () => ({}),
  setFieldValue = () => ({}),
  setSelectedType = () => ({}),
  selectedType = null,
  match = {},
}) {
  let qLoading;
  let qError;

  const { error: lError, loading: lLoading } = useQuery(GET_CUSTOMER_LOC_ID, {
    variables: { slug },
    skip: action !== 'new' || root !== 'locations',
    onCompleted: (d) => {
      if (d?.customerLocation) {
        setFieldValue('labLocationId', d.customerLocation.id);
      }
    },
  });

  const { error: oError, loading: oLoading } = useQuery(GET_MANUF_ORG_ID, {
    variables: { slug },
    skip: action !== 'new' || root !== 'organizations',
    onCompleted: (d) => {
      if (d?.manufacturerOrganization) {
        setFieldValue(
          'manufacturerOrganizationId',
          d.manufacturerOrganization.id,
        );
      }
    },
  });

  if (root === 'locations') {
    qLoading = lLoading;
    qError = lError;
  }

  if (root === 'organizations') {
    qLoading = oLoading;
    qError = oError;
  }
  if (qLoading || qError) {
    return (
      <LoadingOrError error={qError} loading={qLoading} title="Integration" />
    );
  }
  return (
    <FormGridWrap
      match={match}
      customMessage="integration"
      mutationType={DESTROY_INTEGRATION}
      redirectPath={`/locations/customer/${slug}/integrations`}
    >
      <FormSection title="General Info">
        <Field
          name="integrationTypeId"
          required
          component={DynamicDropdown}
          placeholder={t('Select an Integration Type')}
          dynamicQuery={GET_INTEGRATION_TYPES_DROPDOWN}
          disabled={action === 'edit'}
          dataName="integrationTypes"
          labelText="Integration Type"
          selectionFullData={setSelectedType}
        />
        <Field
          name="active"
          required
          component={CheckboxField}
          labelText="Active?"
          headerText="Select to make this integration active"
        />
      </FormSection>
      {selectedType && (
        <FormSection title={`${selectedType.name} Options`}>
          {selectedType.symbol === 'communicate' && (
            <FieldContainer
              name="credentials.token"
              label="Communicate ID"
              required
            >
              <Field
                id="credentials.token"
                name="credentials.token"
                required
                placeholder="Communicate ID"
                className="input"
              />
            </FieldContainer>
          )}
          {selectedType.symbol === 'argen_clear_aligners' && (
            <FieldContainer name="credentials.token" label="Argen ID" required>
              <Field
                id="credentials.token"
                name="credentials.token"
                required
                placeholder="Argen ID"
                className="input"
              />
            </FieldContainer>
          )}
          {selectedType.symbol === 'argen' && (
            <FieldContainer
              name="credentials.token"
              label="Customer ID"
              required
            >
              <Field
                id="credentials.token"
                name="credentials.token"
                required
                placeholder="Customer ID"
                className="input"
              />
            </FieldContainer>
          )}
          {selectedType.symbol === 'ftp' && (
            <div>
              <FieldContainer
                name="credentials.username"
                label="Login"
                required
              >
                <Field
                  id="credentials.username"
                  name="credentials.username"
                  required
                  placeholder="Login"
                  className="input"
                />
              </FieldContainer>
              <PasswordField
                name="credentials.password"
                label="Password"
                required
              />
              <FieldContainer
                name="credentials.serverAddress"
                label="Server Address"
                required
              >
                <Field
                  id="credentials.serverAddress"
                  name="credentials.serverAddress"
                  required
                  placeholder="credentials.serverAddress"
                  className="input"
                />
              </FieldContainer>
              <FieldContainer
                name="credentials.initialPath"
                label="Initial Path"
              >
                <Field
                  id="credentials.initialPath"
                  name="credentials.initialPath"
                  placeholder="credentials.initialPath"
                  className="input"
                />
              </FieldContainer>
              <Field
                name="credentials.sftp"
                required
                component={CheckboxField}
                labelText="Use SFTP?"
                headerText="Turn SFTP mode on/off"
                className="input"
              />
              <Field
                name="credentials.passiveMode"
                required
                component={CheckboxField}
                labelText="Passive Mode?"
                className="input"
                headerText="Turn passive mode on/off"
              />
            </div>
          )}
        </FormSection>
      )}
    </FormGridWrap>
  );
}
export default IntegrationFormInner;

IntegrationFormInner.propTypes = {
  action: PropTypes.string,
  root: PropTypes.string,
  slug: PropTypes.string,
  t: PropTypes.func,
  setFieldValue: PropTypes.func,
  setSelectedType: PropTypes.func,
  match: PropTypes.object,
  selectedType: PropTypes.string,
};
