function PrivacyPolicy() {
  return (
    <>
      <p>Last Modified: September 12th, 2024</p>
      <br />
      <h4>1. General Information</h4>
      <br />
      <p>
        Your privacy is very important to all of us at 3Shape Design Services,
        LLC, f/k/a FullContur, LLC (the &quot;Company&quot;). We have
        established this privacy policy (&quot;Privacy Policy&quot;) to explain
        to you how your personal information is protected.
        <br />
        <br />
        The Company may at any time change, modify, or otherwise update this
        Privacy Policy without prior notification. The latest applicable version
        can be accessed at all times at
        <a
          href="https://design.3shape.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;design.3shape.com
        </a>
        .
        <br />
        <br />
        The privacy practices of this Privacy Policy apply to our services
        available through websites owned and operated by the Company, such as
        <a
          href="https://3shape.com/designservices"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;3shape.com/designservices
        </a>
        , and including any Company platforms accessible through such websites
        or other applications, such as the
        <a
          href="https://design.3shape.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;design.3shape.com&nbsp;
        </a>
        (collectively, the “Site”).
      </p>
      <br />
      <h4>2. Data Controller</h4>
      <br />
      <p>
        Data controller of your personal information is: 3Shape Design Services,
        LLC, 17505 N. 79th Ave #412, Glendale, AZ 85308, phone no. 00 1 602 688
        4133.
        <br />
        <br />
        If you have any questions about this privacy policy or requests
        regarding processing of your personal information, please contact us at
        <a href="mailto:designservices@3shape.com">
          &nbsp;designservices@3shape.com
        </a>
        .
      </p>
      <br />
      <h4>3. Personal information collected and processed by the Company</h4>
      <br />
      Personal information is information that allows to identify a specific
      person (human), e.g., name, surname, e-mail address, phone number, IP
      address. Sometimes only one piece of information is enough, but usually it
      is necessary to combine at least two of them.
      <br />
      <br />
      Your use of the Site may include providing Protected Health Information
      (“PHI”), as such term is defined by the Health Insurance Portability and
      Accountability Act of 1996 and the regulations promulgated thereunder. The
      Company&apos;s Business Associate Agreement governs the use and disclosure
      of PHI.
      <br />
      <br />
      Please check in details how the Company handle with your personal
      information:
      <br />
      <br />
      a) When you browse Site without login
      <br />
      <u>Personal data collected:</u>
      <br />
      &middot; Your IP address, your computer browser information, the URL that
      you just came from, which URL you go to next.
      <br />
      <br />
      <u>Purpose of data processing:</u>
      <br />
      &middot; To track certain information based upon your behavior on the
      Site. We use this information to do internal research on our users&apos;
      demographics, interests, and behavior to better understand our users.
      <br />
      <br />
      Please read more about log files and cookies in the following part of the
      Privacy Policy.
      <br />
      <br />
      b) When you create a user account, including user ID and password to
      access certain services offered by the Company:
      <br />
      <u>Personal data collected:</u>
      <br />
      &middot; First name and last name,
      <br />
      &middot; Name of your Dental Lab,
      <br />
      &middot; E-mail address,
      <br />
      &middot; Phone number,
      <br />
      &middot; Your IP address, your computer browser information, the URL that
      you just came from, which URL you go to next.
      <br />
      <br />
      <u>Purpose of data processing:</u>
      <br />
      &middot; To facilitate your registration,
      <br />
      &middot; to enable you to access certain services offered by the Company
      via Site,
      <br />
      &middot; to provide access to services that most likely meet your needs
      and preferences,
      <br />
      &middot; to track certain information based upon your behavior on the
      Site. We use this information to do internal research on our users&apos;
      demographics, interests, and behavior to better understand our users,
      <br />
      &middot; to detect and protect us against error, fraud and other criminal
      activity,
      <br />
      &middot; to enforce our Terms of Service or other contractual agreements,
      <br />
      &middot; to send you strictly service-related announcements on rare
      occasions when it is necessary to do so. For instance, if access to our
      services is temporarily suspended for maintenance, we might send you an
      email.
      <br />
      <br />
      c) When you use the Company&apos;s Site to upload your cases, store them,
      send cases for design or/and manufacturing:
      <br />
      <u>Personal data collected:</u>
      <br />
      &middot; First name and last name,
      <br />
      &middot; Name of your Dental Lab,
      <br />
      &middot; E-mail address,
      <br />
      &middot; Phone number,
      <br />
      &middot; Credit card info,
      <br />
      &middot; Name and surname of the patient whose case is processed (no other
      PHI is ever transmitted).
      <br />
      <br />
      <u>Purpose of data processing:</u>
      <br />
      &middot; To verify the accuracy of your name, address and other
      information,
      <br />
      &middot; to bill you for your use of our services, if applicable,
      <br />
      &middot; to contact you per e-mail or phone if there are any issues
      related to services ordered by you,
      <br />
      &middot; to upload and distribute your cases correctly and provide you the
      high-quality services.
      <br />
      <br />
      d) When you participate in discussion boards, and post messages on this
      Site or leave feedback or communications for other users:
      <br />
      <u>Personal data collected:</u>
      <br />
      &middot; User ID, information you provided to us in the discussion, posts,
      feedback and communications.
      <br />
      <br />
      <u>Purpose of data processing:</u>
      <br />
      &middot; To resolve disputes,
      <br />
      &middot; to provide user support,
      <br />
      &middot; to troubleshoot problems as permitted by law.
      <br />
      <br />
      Please notice: If you use a discussion board, forum or comment section on
      the Site, you should be aware that any personally identifiable information
      that you submit, including your name and email address, can be read,
      collected, or used by other users of the Site, and could be used to send
      you unsolicited messages. We are not responsible for the personally
      identifiable information you choose to submit on this Site.
      <br />
      <br />
      e) When you send us personal correspondence, such as e-mails or letter, or
      if other users or third parties send us correspondence about your
      activities or postings on the Site:
      <br />
      <u>Personal data collected:</u>
      <br />
      &middot; Name, surname or user ID, e-mail address, information provided to
      us in the correspondence.
      <br />
      <br />
      <u>Purpose of data processing:</u>
      <br />
      &middot; To provide user support,
      <br />
      &middot; to collect and retain such information in a file specific to you.
      <br />
      <br />
      f) When you accepted the Terms of Service, including the BAA or other
      documents referenced therein, and you use software, which includes a
      functionality implying that data within the software is automatically sent
      to the Company&apos;s cloud platform:
      <br />
      <u>Personal data collected:</u>
      <br />
      &middot; Protected Health Information (PHI) in anonymized form,
      <br />
      &middot; Patient name and surname (and other personal data if any).*
      <br />
      <br />
      *Please notice: Pursuant to the BAA you are obliged to send PHI in
      anonymized form, because the Company does not need any personal data of
      your patients. If you do not anonymize your cases, the Company may receive
      name and surname of your patients (and other personal data if any) and
      process it not intentionally. In such a case the Company has right to
      anonymize the cases before using for the purpose mentioned below and it is
      your obligation to have consent of your patient for such a processing.
      <br />
      <br />
      <u>Purpose of data processing:</u>
      <br />
      &middot; To facilitate the functionality and use of the software,
      <br />
      &middot; to store data,
      <br />
      &middot; to use any such transferred data for development/improvements of
      the Company&apos;s products.
      <br />
      <br />
      g) When you gave your consent to receive marketing communications from the
      Site:
      <br />
      <u>Personal data collected:</u>
      <br />
      &middot; E-mail address,
      <br />
      <br />
      <u>Purpose of data processing:</u>
      <br />
      &middot; To send you information on services, special deals and
      promotions,
      <br />
      &middot; to improve our marketing and promotional efforts,
      <br />
      &middot; to analyze site usage,
      <br />
      &middot; to improve our content and product offerings,
      <br />
      &middot; to customize the Site&apos;s content, layout and services
      (profiling).
      <br />
      <br />
      <h4>4. Retention and deletion of your personal information</h4>
      <br />
      The Company will store your personal data as long as you have an account
      with the Company.
      <br />
      <br />
      In general, you can browse certain areas of the Site and “opt-out” from
      providing us with any personal information. You can always ask for data
      submitted by you to the Company to be deleted contacting us by
      <a href="mailto:designservices@3shape.com">
        &nbsp;designservices@3shape.com
      </a>
      . We will delete your personal data when we no longer need to process them
      in relation to one or more of the purposes set out above in section 3.
      <br />
      <br />
      Please notice that cases uploaded by you for design or/and manufacturing
      are deleted on a regular (weekly) basis at the local design centers. The
      items are stored indefinitely on our platform for HIPAA purposes.
      <br />
      <br />
      Marketing content will be delivered to you as long as your consent to
      receive it is active. You can withdraw your consent at any time. To revoke
      your consent, you may opt-out of receiving them by following the
      instructions included in each such communication or by contacting us by
      <a href="mailto:designservices@3shape.com">
        &nbsp;designservices@3shape.com
      </a>
      .
      <br />
      <br />
      <h4>5. Information Sharing and Disclosure</h4>
      <br />
      <p>
        As a matter of policy, we do not sell, rent or share any of your
        Personal Information, including your email address, to third parties for
        their marketing purposes without your explicit consent. However, the
        following describes some of the ways that your Personal Information may
        be disclosed in the normal scope of business to provide our services to
        customers:
        <br />
        <br />
        <u>External Service Providers</u>: There may be separate services
        offered by third parties that we refer to as external service providers
        that may be complementary to your use of the Site. If you choose to use
        these separate services, disclose information to the external service
        providers, and/or grant them permission to collect information about
        you, then their use of your information is governed by their privacy
        policy. We do not disclose your Personal Information, including your
        email address, to external service providers unless you provide your
        explicit consent. With your consent we may provide some of your Personal
        Information to the external service provider offering such services, for
        your convenience. To prevent our disclosure of your Personal Information
        to an external service provider, you can decline such consent or simply
        not use their services or products. Because we do not control the
        privacy practices of these third parties, you should evaluate their
        practices before deciding to use their services.
        <br />
        <br />
        <u>Internal Service Providers</u>: We may use third parties that we
        refer to as internal service providers to facilitate or outsource one or
        more aspects of the business, product and service operations that we
        provide to you and therefore we may provide some of your personal
        information, including your email address, directly to these internal
        service providers. These internal service providers are subject to
        confidentiality agreements with us and other legal restrictions that
        prohibit their use of the information we provide them for any other
        purpose except to facilitate the specific outsourced Company related
        operation, unless you have explicitly agreed or given your prior
        permission to them for additional uses. If you provide additional
        information to an internal service provider directly, then their use of
        your personal information is governed by their applicable privacy
        policy. Our internal service providers processing your personal
        information may be located in following countries: China, Costa Rica,
        Ukraine and within the European Union (Denmark).
        <br />
        <br />
        <u>Legal Requests</u>: The Company cooperates with law enforcement
        inquiries, as well as other third parties to enforce laws, such as:
        intellectual property rights, fraud and other rights, to help protect
        you, other users, and the Company from bad actors. Therefore, in
        response to a verified request by law enforcement or other government
        officials relating to a criminal investigation or alleged illegal
        activity, we can (and you authorize us to) disclose your name, city,
        state, telephone number, email address, username history, and fraud
        complaints without a subpoena. Without limiting the above, in an effort
        to respect your privacy, we will not otherwise disclose your personal
        information to law enforcement or other government officials without a
        subpoena, court order or substantially similar legal procedure, except
        when we believe in good faith that the disclosure of information is
        necessary to prevent imminent physical harm or financial loss, or report
        suspected illegal activity.
        <br />
        <br />
        Due to the existing regulatory environment, we cannot ensure that all of
        your private communications and other personal information will never be
        disclosed in ways not otherwise described in this Privacy Policy. By way
        of example (without limiting the foregoing), we may be forced to
        disclose personal information, including your email address, to the
        government or third parties under certain circumstances, third parties
        may unlawfully intercept or access transmissions or private
        communications, or users may abuse or misuse your personal information
        that they collect from the Site. Therefore, although we use industry
        standard practices to protect your privacy, we do not promise, and you
        should neither rely upon nor expect, that your personal information or
        private communications will always remain private.
      </p>
      <br />
      <h4>6. Log Files</h4>
      <br />
      <p>
        As is true of most websites, we gather certain information automatically
        and store it in log files. This information includes internet protocol
        (IP) addresses, browser type, internet service provider (ISP),
        referring/exit pages, operating system, date/time stamp, and clickstream
        data. We use this information, which does not identify individual users,
        to analyze trends, to administer the site, to track users&apost;
        movements around the site and to gather demographic information about
        our user base as a whole. We do not link this automatically-collected
        data to personally identifiable information. We track trends in
        users&apost; usage and volume statistics to create a more efficient and
        usable site and offerings, and to determine areas of the site or our
        services that could be improved to enhance the user and customer
        experience. Log files are used on the Site, and in any link to the Site
        from an email.
      </p>
      <br />
      <h4>7. Cookies and Related Technologies</h4>
      <br />
      <p>
        When you use this Site, we collect certain information by automated or
        electronic means, using technologies such as cookies, browser analysis
        tools, and web server logs. As you use this Site, or our applications,
        your browser and other electronic devices communicate with servers
        operated by us and our services providers to coordinate and record the
        interactivity and fill your requests for services and information.
        <br />
        <br />
        The information from cookies and related technology is stored in web
        server logs and also in web cookies kept on your computers or mobile
        devices, which are then transmitted back to this Site by your computers
        or mobile devices. These servers are operated, and the cookies managed
        by us or our service providers.
        <br />
        <br />
        For example, when you visit this Site, the Company and our service
        providers may place cookies on your computers or mobile devices. Cookies
        allow us to recognize you when you return, and track and target your
        interests in order to provide a customized experience. They also help us
        provide a customized experience and help us to detect certain kinds of
        fraud. A “cookie” is a small amount of information that a web server
        sends to your browser that stores information about your account and
        preferences.
        <br />
        <br />
        Some cookies are temporary, whereas others may be configured to last
        longer. &quote;Session&quote; cookies are temporary cookies used for
        various reasons, such as to manage page views. Your browser usually
        erases session cookies once you exit your browser.
        &quote;Persistent&quote; cookies are more permanent cookies that are
        stored on your computers or mobile devices even beyond when you exit
        your browser. We use persistent cookies for a number of purposes, such
        as retrieving certain information you have previously provided, and
        storing your preferences.
      </p>
      <br />
      <h4>8. Manage Your Security Settings</h4>
      <br />
      <p>
        You may manage how your browser handles cookies and related technologies
        by adjusting its privacy and security settings. Browsers are different,
        so refer to instructions related to your browser to learn about
        cookie-related and other privacy and security settings that may be
        available. You can opt-out of being targeted by certain third-party
        advertising companies online at:
        <br />
        <br />
        <a
          href="http://www.networkadvertising.org/consumer/opt_out.asp"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.networkadvertising.org/consumer/opt_out.asp&nbsp;
        </a>
        or
        <a
          href="http://preferences.truste.com/truste/"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;http://preferences.truste.com/truste/
        </a>
        <br />
        <br />
        You may manage how your mobile browser handles cookies and related
        technologies by adjusting your mobile device privacy and security
        settings. Please refer to instructions provided by your mobile service
        provider or the manufacturer of your device to learn how to adjust your
        settings.
      </p>
      <br />
      <h4>9. Links to Other Sites</h4>
      <br />
      <p>
        This Site may contain links to other sites that are not owned or
        controlled by the Company. Please be aware that we are not responsible
        for the privacy practices of such other sites. We encourage you to be
        aware when you leave our site and to read the privacy statements of each
        and every website that collects personally identifiable information.
        This Privacy Policy applies only to information collected by this Site.
      </p>
      <br />
      <h4>10. Social Media</h4>
      <br />
      <p>
        You can find the Company also in social media. Please check below what
        information about your activities in our social media platforms the
        Company may collect. This information is used for statistical purposes
        and for improving content of our social media pages.
        <br />
        <br />
        <u>Facebook</u>:
        <br />
        Followers as total number by: country/language/age range/gender,
        interactions by profile name/post. Please read
        <a
          href="https://www.facebook.com/about/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Privacy Policy&nbsp;
        </a>
        of Facebook to receive more information.
        <br />
        <br />
        <u>LinkedIn</u>:
        <br />
        Followers as total number by: location/job function/seniority/company
        size/industry, Interactions by profile name/post. Please read
        <a
          href="https://www.linkedin.com/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Privacy Policy&nbsp;
        </a>
        of LinkedIn to receive more information.
        <br />
        <br />
        <u>Twitter</u>:
        <br />
        Followers by profile, retweets by profile, total numbers by:
        interests/consumer buying styles/wireless carrier/household income
        categories/net worth/marital status/home ownership/TV genres/premium
        brands/consumer goods purchases, Demographics – region/country/language.
        Please read
        <a
          href="https://twitter.com/en/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Privacy Policy&nbsp;
        </a>
        of Twitter to receive more information.
        <br />
        <br />
        <u>YouTube</u>:
        <br />
        Subscribers by profile, total numbers by region/demographics/age
        range/country/gender, comments – by profile name, Likes as anonymous.
        Please read
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Privacy Policy&nbsp;
        </a>
        of YouTube to receive more information.
        <br />
        <br />
        <u>Instagram</u>:
        <br />
        Followers by profile, Likes and comments by profile, total numbers by
        location/age range/gender. Please read
        <a
          href="https://help.instagram.com/155833707900388"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Privacy Policy&nbsp;
        </a>
        of Instagram to receive more information.
        <br />
        <br />
        <b>Please notice</b>: You have the opportunity to participate in
        discussions on social media platforms administered by the Company. The
        goal of these social media platforms is to enable you to share content.
        The Company is not responsible if you share data and personal
        information on social media platforms, and then these data will be used
        or misappropriated by other users or will be misused.
      </p>
      <br />
      <h4>11. Your rights concerning processing of personal information</h4>
      <br />
      <p>
        You are at any time entitled to be informed of the personal information
        about you that we process, with certain legislative exceptions. You also
        have the right to object to the collection and further processing of
        your personal data. Furthermore, you have the right to have your
        personal data rectified, erased or blocked according to applicable laws,
        but please be informed that we cannot always delete records of past
        interactions and transactions. For example, it may be required to retain
        records relating to previous purchases for financial reporting and
        compliance reasons. Moreover, you have the right to have the data that
        you have provided to the Company issued to you or transmitted to another
        data controllers, if possible. If you want us to update, amend or delete
        the personal data that we have recorded about you, if you wish to get
        access to the data being processed about you, or if you have any
        questions concerning the above guidelines, or if you wish to appeal
        against the processing of your personal data you may contact us at
        <a href="mailto:designservices@3shape.com">
          &nbsp;designservices@3shape.com
        </a>
        . You may also write to us at the following address:
        <br /> 3Shape Design Services, LLC f/k/a FullContour 17505 N. 79thAve
        #412 Glendale, AZ 85308.
        <br />
        <br />
        Please notice that you have the right to lodge a complaint regarding
        processing of your personal information to data protection authority
        within your jurisdiction.
      </p>
      <br />
      <h4>12. Transmission of the personal data to other countries</h4>
      <br />
      <p>
        The Company is headquartered in the United States of America. Personal
        information may be accessed by us or transferred to us in the United
        States or to our affiliates, business partners, or service providers
        located in: China, Costa Rica, Ukraine and within the European Union
        (Denmark). We will protect the privacy and security of personal
        information according to this Privacy Policy, regardless of where it is
        processed or stored.
      </p>
      <br />
      <h4>13. Children Under the Age of 13</h4>
      <br />
      <p>
        The Site is not intended for children under 13 years of age. No one
        under age 13 may provide any information to or on the Site. We do not
        knowingly collect personal information from children under 13. If you
        are under 13, do not use or provide any information on this Site or on
        or through any of its features/register on the Site, make any purchases
        through the Site, use any of the interactive or public comment features
        of this Site or provide any information about yourself to us, including
        your name, address, telephone number, email address, or any screen name
        or user name you may use. If we learn we have collected or received
        personal information from a child under 13 without verification of
        parental consent, we will delete that information. If you believe we
        might have any information from or about a child under 13, please
        contact us at
        <a href="mailto:designservices@3shape.com">
          &nbsp;designservices@3shape.com
        </a>
        .
      </p>
      <br />
      <h4>14. Your State Privacy Rights</h4>
      <br />
      <p>
        State consumer privacy laws may provide their residents with additional
        rights regarding our use of their personal information. To learn more
        about consumer privacy rights in other states and how to exercise them,
        please contact us at
        <a href="mailto:designservices@3shape.com">
          &nbsp;designservices@3shape.com
        </a>
        .
        <br />
        <br />
        California Civil Code Section § 1798.83 permits users of our Site that
        are California residents to request certain information regarding our
        disclosure of personal information to third parties for their direct
        marketing purposes. To make such a request, please send an email to
        <a href="mailto:designservices@3shape.com">
          &nbsp;designservices@3shape.com&nbsp;
        </a>
        or 3Shape Design Services, 17505 N. 79thAve #412, Glendale, AZ 85308.
      </p>
      <br />
      <h4>15. Data Security</h4>
      <br />
      <p>
        We follow generally accepted industry standards to protect personal
        information both during transmission and once we receive it. We have
        implemented measures designed to secure your personal information from
        accidental loss and from unauthorized access, use, alteration, and
        disclosure. All information you provide to us is stored on our secure
        servers behind firewalls.
        <br />
        <br />
        The safety and security of your information also depends on you. Where
        we have given you (or where you have chosen) a password for access to
        certain parts of our Site, you are responsible for keeping this password
        confidential. We ask you not to share your password with anyone. We urge
        you to be careful about giving out information in public areas of the
        Site. The information you share in public areas may be viewed by other
        users of the Site.
        <br />
        <br />
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Site. Any transmission of information is
        at your own risk. We are not responsible for circumvention of any
        privacy settings or security measures contained on the Site.
      </p>
      <br />
      <h4>16. SMS Texting Policy</h4>
      <br />
      <p>
        By agreeing to the 3Shape Design Services Privacy Policy you agree to
        receive a message to the provided phone number. Standard message and
        Data rates apply. Reply STOP to unsubscribe from all future messages.
      </p>
    </>
  );
}

export default PrivacyPolicy;
