import PropTypes from 'prop-types';
import { BoxIcon } from '../../../../../../../../shared';
import './Tooth.css';

function Tooth({
  type,
  toothNumber = null,
  label = null,
  toothData = {},
  isLegend = false,
}) {
  return (
    <>
      <div className={`restrictionToothWrapper ${isLegend ? 'legend' : ''}`}>
        <div
          className={`restrictionTooth ${type}${
            toothData.MayBeMoved === false ? 'immovable' : ''
          }${toothData.IsPresent === false ? 'missing' : ''}`}
        >
          {toothNumber}
        </div>
        {toothData.MayPerformIpr === true && <div className="allowIpr" />}
        {toothData.MayPlaceAttachment === true && (
          <div className="attachment" />
        )}
        {toothData.MayBeExtracted === true && (
          <div className="extract">
            <BoxIcon name="bx-x bx-md" />
          </div>
        )}
      </div>

      {label && (
        <p style={{ display: 'inline' }}>
          <small>{label}</small>
        </p>
      )}
    </>
  );
}

Tooth.propTypes = {
  toothNumber: PropTypes.number,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  toothData: PropTypes.object,
  isLegend: PropTypes.bool,
};

export default Tooth;
