import PropTypes from 'prop-types';
import { LoadingOrError } from '../../../../shared';
import ReviewModalBodyContent from './ReviewModalBodyContent';
import ReviewModalBodyDrApproval from './ReviewModalBodyDrApproval';
import ReviewModalBodyPatientLink from './ReviewModalBodyPatientLink';
import ReviewModalBodyRedesign from './ReviewModalBodyRedesign';

function ReviewModalBody({
  error = null,
  loading = false,
  orderFiles,
  order,
  redesign,
  docApproval,
  patientApproval,
  changeMessage,
  redesignOff,
  message,
  closeAndReset,
  docApprovalOff,
  patientApprovalOff,
}) {
  if (error || loading) {
    return <LoadingOrError error={error} loading={loading} title="Files" />;
  }

  return (
    <div>
      <ReviewModalBodyContent
        orderFiles={orderFiles}
        order={order}
        redesign={redesign}
        docApproval={docApproval}
        patientApproval={patientApproval}
      />
      <ReviewModalBodyRedesign
        changeMessage={changeMessage}
        redesign={redesign}
        redesignOff={redesignOff}
        docApproval={docApproval}
        patientApproval={patientApproval}
        order={order}
        message={message}
        closeAndReset={closeAndReset}
      />
      <ReviewModalBodyDrApproval
        docApproval={docApproval}
        docApprovalOff={docApprovalOff}
        patientApproval={patientApproval}
        redesign={redesign}
        order={order}
        closeAndReset={closeAndReset}
      />
      <ReviewModalBodyPatientLink
        docApproval={docApproval}
        patientApproval={patientApproval}
        patientApprovalOff={patientApprovalOff}
        redesign={redesign}
        order={order}
      />
    </div>
  );
}

ReviewModalBody.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
};

export default ReviewModalBody;
