import { useQuery } from '@apollo/client';
import { GET_ORDER_ACTIVITY } from '@fullcontour/shared-api';
import { useParams } from 'react-router';
import { timestampFormatter } from '../../../../../transforms';
import { LoadingOrError } from '../../../../shared';

function OrderShowActivity() {
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_ORDER_ACTIVITY, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const appName = import.meta.env.VITE_APP_NAME;
  const heightReduction =
    appName === 'customer_portal' || appName === 'designer_portal'
      ? '190'
      : '140';

  const orderActivity = data?.orderActivity;

  return (
    <div className="columns">
      <div className="column is-half">
        <div
          className="panel"
          style={{ minHeight: `calc(100vh - ${heightReduction}px - 2rem)` }}
        >
          <p className="panel-heading">Case Activity</p>

          {loading || error ? (
            <div
              className="panel-block"
              style={{ position: 'relative', minHeight: '70vh' }}
            >
              <LoadingOrError
                error={error}
                loading={loading}
                title="Case Activity"
              />
            </div>
          ) : (
            <>
              {[...orderActivity]
                .sort(
                  (a, b) => new Date(b.timeOfAction) - new Date(a.timeOfAction),
                )
                .map((activity) => (
                  <a href="#pblock" className="panel-block" key={activity.id}>
                    <figure className="image is-48x48 mr-2">
                      <img
                        src={activity.userLogo}
                        className="is-rounded"
                        alt={activity.userResponsible}
                      />
                    </figure>
                    <span className="has-text-primary has-text-weight-semibold mr-2">
                      {activity.userResponsible}
                    </span>
                    <span className="has-text-black-bis mr-2">
                      {activity.eventVerb} {activity.eventSubject}
                    </span>
                    <span className="is-italic has-text-grey-dark is-size-7 is-pulled-right">
                      {timestampFormatter(activity.timeOfAction)}
                    </span>
                  </a>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderShowActivity;
