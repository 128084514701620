import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConfirmationModal } from '../../..';
import { gridDateComparator, timestampFormatter } from '../../../transforms';
import { AgGridLoader } from '../Loader';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';

import './ag-grid-css-overrides.css';
import './ag-theme-balham-overrides.css';

function AgGridContainer({
  gridOptions,
  containerHeight = import.meta.env.VITE_APP_NAME === 'customer_portal' ||
  import.meta.env.VITE_APP_NAME === 'designer_portal'
    ? 'calc(100vh - 190px - 3em)'
    : 'calc(100vh - 129px - 3em)',
  shortContainer = false,
  deleteModal = false,
  onRowClicked = () => ({}),
}) {
  const gridRef = useRef(null);
  const containerRef = useRef(null);

  const { components, rowData, context, defaultColDef, ...gridRest } =
    gridOptions;
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);

  function onGridSizeChanged() {
    if (gridRef.current?.api && containerRef.current?.offsetWidth > 0) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }

  function onFirstDataRendered() {
    if (gridRef.current?.api && containerRef.current?.offsetWidth > 0) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }

  function reviewCase(data) {
    setModal(true);
    setSelectedCase(data);
  }

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect.width > 0 && gridRef.current?.api) {
            gridRef.current.api.sizeColumnsToFit();
          }
        }
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className="ag-theme-balham mainGridContainer"
      style={{
        height: shortContainer ? 300 : containerHeight,
      }}
    >
      <AgGridReact
        ref={gridRef}
        gridOptions={gridRest}
        onRowClicked={onRowClicked}
        rowData={rowData}
        headerHeight={40}
        rowHeight={40}
        animateRows
        maintainColumnOrder
        reactNext
        context={{
          navigate,
          reviewCase,
          ...context,
        }}
        modules={[
          ClientSideRowModelModule,
          InfiniteRowModelModule,
          CsvExportModule,
          ClipboardModule,
          ColumnsToolPanelModule,
          ExcelExportModule,
          FiltersToolPanelModule,
          MasterDetailModule,
          MenuModule,
          RangeSelectionModule,
          RichSelectModule,
          RowGroupingModule,
          SetFilterModule,
          MultiFilterModule,
          AdvancedFilterModule,
          SideBarModule,
          StatusBarModule,
          ViewportRowModelModule,
        ]}
        components={{
          customLoadingOverlay: AgGridLoader,
          ...components,
        }}
        loadingOverlayComponent="customLoadingOverlay"
        floatingFiltersHeight={40}
        onGridSizeChanged={onGridSizeChanged}
        onFirstDataRendered={onFirstDataRendered}
        defaultColDef={{
          resizable: true,
          editable: false,
          suppressHeaderMenuButton: false,
          sortable: true,
          ...defaultColDef,
        }}
        columnTypes={{
          dateColumn: {
            width: 185,
            minWidth: 185,
            filter: 'agDateColumnFilter',
            filterParams: {
              newRowsAction: 'keep',
              comparator: (filterLocalDateAtMidnight, cellValue) =>
                gridDateComparator(filterLocalDateAtMidnight, cellValue),
            },
            valueFormatter: (params) => {
              if (params.value) {
                return timestampFormatter(params.value);
              }

              return params.value;
            },
          },
        }}
      />
      {deleteModal && selectedCase && (
        <ConfirmationModal
          open={modal}
          setModal={setModal}
          text={
            selectedCase.bodyText ||
            `Are you sure you want to delete "${selectedCase?.name}"?`
          }
          data={selectedCase}
        />
      )}
    </div>
  );
}

AgGridContainer.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  containerHeight: PropTypes.any,
  shortContainer: PropTypes.bool,
  deleteModal: PropTypes.bool,
  onRowClicked: PropTypes.func,
};

export default AgGridContainer;
