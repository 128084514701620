import Proptypes from 'prop-types';
import { memo } from 'react';

function Dropdown(props) {
  const { field, data } = props;
  return (
    <div className="select">
      <select {...field}>
        <option value="">Please Select</option>
        {data?.map(({ name, value }, key) => (
          <option key={key.toString()} value={value}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
}
Dropdown.propTypes = {
  field: Proptypes.object.isRequired,
  data: Proptypes.object.isRequired,
};
export default memo(Dropdown);
