import { useFormikContext } from 'formik';
import { useContext } from 'react';
import {
  displayTeethBasedOnNotation,
  transformNestedData,
} from '../../../../..';
import { DesignGuideOrderContext } from '../../../Context';
import { useGuideCodeCardContext } from './context/GuideCodeCardContext';

function GuideCodeCardNestedLabels() {
  const {
    data: {
      id: guideCodeId = '',
      category: { id: categoryId },
      numberingSystem,
    },
  } = useGuideCodeCardContext();

  const { values, setFieldValue } = useFormikContext();
  const { orderItemIndex } = useContext(DesignGuideOrderContext);

  const { nestedTeeth = {}, nestedArches = {} } =
    values.orderItems[orderItemIndex];

  const transformedTeeth = transformNestedData(nestedTeeth, true, (key) => key);
  const transformedArches = transformNestedData(
    nestedArches,
    true,
    (key) => key,
  );

  function removeNestedItem(nestedObj, itemKey) {
    const updatedNestedObj = {
      ...nestedObj,
      [itemKey]: nestedObj[itemKey].filter((id) => id !== guideCodeId),
    };

    if (updatedNestedObj[itemKey].length === 0) {
      delete updatedNestedObj[itemKey];
    }

    return updatedNestedObj;
  }

  function handleRemoveNestedItem(e, itemKey, removeFunc, nestedObj, label) {
    e.stopPropagation();
    const updatedNestedObj = removeFunc(nestedObj, itemKey);
    const fieldPath = `orderItems[${orderItemIndex}].${
      label === 'Teeth' ? 'nestedTeeth' : 'nestedArches'
    }`;
    setFieldValue(fieldPath, updatedNestedObj);
  }

  function renderNestedLabels(transformedObj, label, removeFunc, nestedObj) {
    const identificator =
      guideCodeId === 'other' ? `other-${categoryId}` : guideCodeId;
    return Object.keys(transformedObj).includes(identificator) ? (
      <div className="px-2 py-3">
        <span className="mr-2">{label}:</span>
        {transformedObj[identificator].map((item) => (
          <span key={item} className="tag is-primary mr-1">
            {label === 'Tooth'
              ? displayTeethBasedOnNotation(numberingSystem, item)
              : item}
            <button
              type="button"
              onClick={(e) =>
                handleRemoveNestedItem(e, item, removeFunc, nestedObj, label)
              }
              className="delete is-small"
              aria-label="Remove item"
            />
          </span>
        ))}
      </div>
    ) : null;
  }

  return (
    <>
      {renderNestedLabels(
        transformedTeeth,
        'Teeth',
        removeNestedItem,
        nestedTeeth,
      )}
      {renderNestedLabels(
        transformedArches,
        'Arches',
        removeNestedItem,
        nestedArches,
      )}
    </>
  );
}

export default GuideCodeCardNestedLabels;
