import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import GuideBodyCodeCard from './GuideBodyCodeCard';
import useFindCurrentDesignType from './hooks/useFindCurrentDesignType';

function GuideBodyFavourites({
  designType,
  setDisplayedComponent = () => ({}),
  favoriteData = [],
}) {
  const { t } = useTranslation('designProfile');

  const currentDesignType = useFindCurrentDesignType();

  return (
    <div className="favorites__wrapper">
      <h6 className="title is-6">{t('Favorites')}</h6>
      {favoriteData?.length > 0 ? (
        <div className="guide__accrordion-card">
          {favoriteData.map((guideCategory) => (
            <GuideBodyCodeCard
              key={guideCategory.id}
              designType={designType}
              guideCategory={guideCategory}
              guideCategoryGroupId={guideCategory.guideCategoryGroupId}
              hideCreateOtherButton
              currentDesignType={currentDesignType}
            />
          ))}
        </div>
      ) : (
        <div style={{ marginBottom: 30 }}>
          {t('Please add first code card to your favorites! Select')}
          <button
            title="Go to categories"
            type="button"
            onClick={() => setDisplayedComponent('categories')}
            style={{
              color: '#1b77c8',
              cursor: 'pointer',
              background: 'none',
              border: 'none',
            }}
          >
            {t('Categories')}
          </button>
          {t('to add your first category to favorites')}
        </div>
      )}
    </div>
  );
}

GuideBodyFavourites.propTypes = {
  setDisplayedComponent: PropTypes.func,
  favoriteData: PropTypes.array,
  designType: PropTypes.object.isRequired,
};

export default GuideBodyFavourites;
