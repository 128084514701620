import PropTypes from 'prop-types';
import Styles from './AdditionalControls.module.scss';

function GridControl(props) {
  const { toggle, visible } = props;

  return (
    <button
      type="button"
      onClick={toggle}
      className={`has-tooltip-arrow has-tooltip-right ${Styles.additionalControlsButton}`}
      data-tooltip={`${visible ? 'hide' : 'show'} grid`}
    >
      <svg
        width="43"
        height="43"
        viewBox="0 0 43 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
      <title>svg</title>
      <path
          d="M42.5 21.5C42.5 33.098 33.098 42.5 21.5 42.5C9.90202 42.5 0.5 33.098 0.5 21.5C0.5 9.90202 9.90202 0.5 21.5 0.5C33.098 0.5 42.5 9.90202 42.5 21.5Z"
          fill={visible ? '#444' : 'none'}
          stroke="#CFD5DB"
        />
        <rect
          x="13.5449"
          y="9.91016"
          width="1.27836"
          height="22.3714"
          rx="0.639181"
          fill="#CFD5DB"
        />
        <rect
          x="18.0195"
          y="9.91016"
          width="1.27836"
          height="22.3714"
          rx="0.639181"
          fill="#CFD5DB"
        />
        <rect
          x="22.4932"
          y="9.91016"
          width="1.27836"
          height="22.3714"
          rx="0.639181"
          fill="#CFD5DB"
        />
        <rect
          x="26.9678"
          y="9.91016"
          width="1.27836"
          height="22.3714"
          rx="0.639181"
          fill="#CFD5DB"
        />
        <rect
          x="32.0811"
          y="13.7454"
          width="1.27836"
          height="22.3714"
          rx="0.639181"
          transform="rotate(90 32.0811 13.7454)"
          fill="#CFD5DB"
        />
        <rect
          x="32.0811"
          y="18.2195"
          width="1.27836"
          height="22.3714"
          rx="0.639181"
          transform="rotate(90 32.0811 18.2195)"
          fill="#CFD5DB"
        />
        <rect
          x="32.0811"
          y="22.6938"
          width="1.27836"
          height="22.3714"
          rx="0.639181"
          transform="rotate(90 32.0811 22.6938)"
          fill="#CFD5DB"
        />
        <rect
          x="32.0811"
          y="27.1682"
          width="1.27836"
          height="22.3714"
          rx="0.639181"
          transform="rotate(90 32.0811 27.1682)"
          fill="#CFD5DB"
        />
      </svg>
    </button>
  );
}

GridControl.propTypes = {
  toggle: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default GridControl;
