import { useMemo } from 'react';

const useFilteredCategories = (searchValue, orderItems) =>
  useMemo(() => {
    if (!searchValue) {
      return orderItems;
    }
    const filteredData = [];

    orderItems.map((item) => {
      const designType = {
        id: item.designType.id,
        name: item.designType.name,
      };
      const feedbackCategoriesfiltered = [];
      item.designType.feedbackCategories.map((feedbackCategory) => {
        if (feedbackCategory.name.toLowerCase().includes(searchValue)) {
          feedbackCategoriesfiltered.push({
            ...feedbackCategory,
          });
        } else {
          const options = [];
          feedbackCategory.options.map((option) => {
            if (option.name.toLowerCase().includes(searchValue)) {
              options.push(option);
            }

            if (options.length > 0) {
              const ifExists = feedbackCategoriesfiltered.findIndex(
                (i) => i?.id === feedbackCategory.id,
              );

              if (ifExists > -1) {
                feedbackCategoriesfiltered[ifExists].options = options;
              } else {
                feedbackCategoriesfiltered.push({
                  ...feedbackCategory,
                  options,
                });
              }
            }
          });
        }
      });

      filteredData.push({
        designType: {
          ...designType,
          feedbackCategories: feedbackCategoriesfiltered,
        },
      });
    });

    return filteredData;
  }, [searchValue, orderItems]);

export default useFilteredCategories;
