const useAvaliableManufactures = ({
  designTypes,
  item,
  assignedManufacturers,
}) => {
  const initialDesignType = designTypes.find(
    (type) => type.id === item.designTypeId,
  );

  let locationManufIds = [];

  if (initialDesignType && initialDesignType.disableInHouse === true) {
    locationManufIds = assignedManufacturers
      .filter((m) => m.manufacturerOrganization.isInHouse === false)
      .map((assignedManuf) => assignedManuf.manufacturerOrganization.id);
  }

  if (initialDesignType && initialDesignType.disableInHouse === false) {
    locationManufIds = assignedManufacturers.map(
      (assignedManuf) => assignedManuf.manufacturerOrganization.id,
    );
  }

  return initialDesignType?.manufacturers
    ? initialDesignType.manufacturers.filter((manuf) =>
        locationManufIds.some((locManuf) => locManuf === manuf.id),
      )
    : [];
};

export default useAvaliableManufactures;
