import PropTypes from 'prop-types';

function SingleArchSVG({ isEmpty = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 62 60"
      width="28"
      height="28"
      fill="none"
    >
      <title>svg</title>
      <path
        stroke={isEmpty ? '#4A4A4A' : 'rgba(61, 172, 209, .9)'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.8}
        strokeWidth={3}
        d="M42.631 42.02c-1.75 0-3.228-1.464-3.352-3.38-.365-5.549-1.8-11.337-3.918-13.806-2.117-2.47-5.825-3.313-8.724 0-1.331.976-3.435 6.314-3.916 13.804-.13 2.002-1.743 3.512-3.59 3.374-1.853-.14-3.252-1.876-3.123-3.878.161-2.507 2.862-14.45 8.939-18.325 2.956-1.884 7.282-2.685 11.421-.475 6.81 3.637 9.46 16.293 9.624 18.8.13 2.002-1.27 3.739-3.123 3.878a3.193 3.193 0 0 1-.238.008v0Z"
      />
      <path
        stroke={isEmpty ? '#4A4A4A' : 'rgba(61, 172, 209, .9)'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.8}
        strokeWidth={2}
        d="M31 23.008V19M37 24.625 38.83 21M37.97 31.992l4.998-2M39.723 38.073l5.743-1.25"
      />
      <path
        stroke={isEmpty ? '#4A4A4A' : 'rgba(61, 172, 209, .9)'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.8}
        strokeWidth={3}
        d="M20 42s10.573-4.5 24 0"
      />
      <path
        stroke={isEmpty ? '#4A4A4A' : 'rgba(61, 172, 209, .9)'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.8}
        strokeWidth={2}
        d="M24.83 24.562 23 21M23.644 31.826l-4.663-2M21.957 38.089l-5.465-1.019"
      />
    </svg>
  );
}

SingleArchSVG.propTypes = {
  isEmpty: PropTypes.bool,
};

export default SingleArchSVG;
