import PropTypes from 'prop-types';

function FeedbackLabels(props) {
  const { labels, setSelectedOptions } = props;
  const isTruncated = (str) => str.length > 30;
  return labels.length > 0 ? (
    <div
      className="is-flex is-justify-content-space-between mt-2"
      style={{ lineHeight: '25px' }}
    >
      <div>
        {labels.map((item) => (
          <span
            style={{ borderBottom: 'none' }}
            key={item}
            className="tag is-dark has-tooltip-arrow has-tooltip-multiline mr-1"
            {...(isTruncated(item) ? { 'data-tooltip': item } : {})}
          >
            {isTruncated(item) ? `${item.slice(0, 30)}...` : item}
          </span>
        ))}
      </div>
      <div>
        <button
          type="button"
          className="button is-danger is-outlined is-small"
          onClick={() => setSelectedOptions({})}
        >
          clear
        </button>
      </div>
    </div>
  ) : null;
}

FeedbackLabels.propTypes = {
  labels: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
};

export default FeedbackLabels;
