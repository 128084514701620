import { NavMenuLink } from '@fullcontour/common';

function OrganizationShowNavMenu() {
  return (
    <>
      <NavMenuLink to="/organizations" name="details" />
      <NavMenuLink to="/organizations/integrations" name="integrations" />
      <NavMenuLink to="/organizations/api-settings" name="api-settings" />
    </>
  );
}

export default OrganizationShowNavMenu;
