import PropTypes from 'prop-types';
import { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';

function ImgLightBox({ data }) {
  const [open, setIsOpen] = useState(false);
  function showLightBox() {
    setIsOpen(!open);
  }

  return (
    <>
      <Lightbox
        open={open}
        slides={[{ src: data.imageUrl }]}
        close={showLightBox}
        carousel={{ finite: true }}
        render={{
          buttonPrev: () => null,
          buttonNext: () => null,
        }}
      />
      <button type='button'
        className="has-text-centered is-clickable"
        onClick={showLightBox}
      >
        <figure className="image is-16x16 is-inline-block ">
          <img src={data.imageUrl} alt={` ${data.name}`} />
        </figure>
      </button>
    </>
  );
}
export default ImgLightBox;
ImgLightBox.propTypes = {
  data: PropTypes.object.isRequired,
};
