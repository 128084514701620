import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function Message({
  title = null,
  children = '',
  headerType = '',
  dismissable = false,
  type = 'primary',
  size = null,
  onDismiss = () => null,
  style = {},
  error = null,
}) {
  const [canShow, setCanShow] = useState(true);
  useEffect(() => {
    if (!canShow) {
      toggle();
    }
  }, [error]);
  function toggle() {
    setCanShow(!canShow);
  }
  function dismiss() {
    onDismiss();
    setCanShow(false);
  }
  return (
    canShow && (
      <article className={`message is-${type}${size || ''}`} style={style}>
        {title && (
          <div className={`message-header ${headerType}`}>
            <p>{title}</p>
            {dismissable && (
              <button
                type="button"
                className="delete"
                aria-label="delete"
                onClick={dismiss}
              />
            )}
          </div>
        )}
        <div className="message-body">{children}</div>
      </article>
    )
  );
}

Message.propTypes = {
  title: PropTypes.string,
  headerType: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dismissable: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
    'link',
    'info',
    'success',
    'warning',
    'danger',
    'dark',
  ]),
  size: PropTypes.oneOf(['is-small', 'is-medium', 'is-large']),
  onDismiss: PropTypes.func,
  style: PropTypes.object,
  error: PropTypes.string,
};

export default Message;
