import { graphql } from '@apollo/client/react/hoc';
import { REQUEST_S3_SIGN } from '@fullcontour/shared-api';
import axios from 'axios';
import format from 'date-fns/format';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { CreateNotification } from '../../../HelperFunctions';
import { FieldContainer } from '../../FieldContainer';
import {
  activeStyle,
  baseStyle,
  rejectedStyle,
} from './FileDropzoneCadFileStyle';

function FileDropzoneNoImage({
  field,
  form,
  signS3,
  keyPrefix,
  t,
  updateUploadProgress,
  setUploading,
  setUploadingOff,
  labelText = null,
  accept = {
    'application/*': ['.dme'],
  },
}) {
  const [{ file, formattedFilename }, setState] = useState({
    file: null,
    formattedFilename: null,
  });
  const { name } = field;
  const { setValues, values } = form;
  useEffect(() => {
    if (file && formattedFilename) {
      signAndSubmit();
    }
  }, [file, formattedFilename]);
  function formatFilename(filename) {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  }
  async function onDrop(accepted, rejected) {
    if (rejected.length > 0) {
      return CreateNotification.createNotification({
        type: 'error',
        message: rejected[0]?.errors[0]?.message,
      });
    }
    const file = accepted[0];
    setState((state) => ({
      ...state,
      file,
      formattedFilename: formatFilename(file.name),
    }));
    return null;
  }

  async function uploadToS3(signedRequest) {
    const options = {
      headers: {
        'Content-Type': 'binary/octet-stream',
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (percent >= 100) {
          updateUploadProgress(100);
          setUploadingOff();
        } else {
          updateUploadProgress(percent);
        }
      },
    };

    setUploading();
    await axios.put(signedRequest, file, options);
  }

  async function signAndSubmit() {
    const response = await signS3({
      variables: {
        input: {
          input: {
            originalFilename: file.name,
            filename: `${keyPrefix}${formattedFilename}`,
            acl: 'private',
            filetype: 'binary/octet-stream',
          },
          clientMutationId: uuidv4(),
        },
      },
    });

    const { signedRequest, url } = response.data.signS3.s3Response;

    await uploadToS3(signedRequest);

    setValues({
      ...values,
      fileUrl: url,
      filename: formattedFilename,
      s3Key: `${keyPrefix}${formattedFilename}`,
    });
  }

  return (
    <FieldContainer name={name} label={labelText || name}>
      <Dropzone
        accept={accept}
        multiple={false}
        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
          let styles = { ...baseStyle };
          styles = isDragActive ? { ...styles, ...activeStyle } : styles;
          styles = isDragReject ? { ...styles, ...rejectedStyle } : styles;

          return (
            <div style={styles} {...getRootProps()}>
              <input {...getInputProps()} />
              <div>
                <span>
                  {t(`Drop a DME file here, or click/tap to select a DME to
                    upload`)}
                </span>
              </div>
            </div>
          );
        }}
      </Dropzone>
    </FieldContainer>
  );
}

FileDropzoneNoImage.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  labelText: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  signS3: PropTypes.func.isRequired,
  keyPrefix: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  updateUploadProgress: PropTypes.func.isRequired,
  setUploading: PropTypes.func.isRequired,
  setUploadingOff: PropTypes.func.isRequired,
};

export default flowRight(
  graphql(REQUEST_S3_SIGN, { name: 'signS3' }),
  withTranslation('formfields'),
)(memo(FileDropzoneNoImage));
