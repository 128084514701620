import * as Yup from 'yup';

function schema() {
  return Yup.object({
    active: Yup.boolean().required('Active flag Required'),
    termsAccepted: Yup.boolean().required('Terms Required'),
    fcClientId: Yup.string(),
    fcClientSecret: Yup.string(),
    clientId: Yup.string(),
    clientSecret: Yup.string(),
    authServer: Yup.string(),
    username: Yup.string(),
    password: Yup.string(),
    callbackUrl: Yup.string(),
    authorizationType: Yup.string(),
    grantType: Yup.string(),
    tokenResponseKey: Yup.string(),
    labLocationId: Yup.string(),
    manufacturerOrganizationId: Yup.string(),
  });
}

export default schema;
