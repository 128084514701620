import Proptypes from 'prop-types';
import { memo } from 'react';

function TextArea(props) {
  const { field } = props;
  return <textarea className="textarea" {...field} />;
}
TextArea.propTypes = {
  field: Proptypes.object.isRequired,
};
export default memo(TextArea);
