import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Placeholder } from '../../Placeholder';
import Styles from './Search.module.css';
import SearchResultsItem from './SearchResultsItem';

function SearchResults({
  loading,
  handleResult,
  setFocused,
  focused,
  results = [],
  resultRenderer = null,
}) {
  const { t } = useTranslation('common');

  function handleSelect(result) {
    handleResult(result);
  }

  const groupedSearch = results.reduce((obj, item) => {
    if (obj[item.klass]) {
      obj[item.klass].push(item);
    } else {
      obj[item.klass] = [item];
    }
    return obj;
  }, {});

  const formatedGroupedSearch = Object.fromEntries(
    Object.entries(groupedSearch).map((obj) => {
      obj[0] = `${obj[0].split(/(?=[A-Z])/).join(' ')}s`;
      return obj;
    }),
  );

  return (
    // biome-ignore lint/nursery/noStaticElementInteractions: <explanation>
<div
      className={Styles.results}
      style={{
        ...(focused
          ? {
              display: 'flex',
            }
          : { display: 'none' }),
      }}
      onMouseOver={() => setFocused(true)}
      onFocus={() => setFocused(true)}
    >
      {loading ? (
        <div style={{ padding: 10 }}>
          <Placeholder className="mb-2" width="280px" height="20px" />
          <Placeholder className="mb-2" width="280px" height="20px" />
          <Placeholder width="280px" height="20px" />
        </div>
      ) : Object.keys(formatedGroupedSearch).length > 0 ? (
        <table className={Styles.category}>
          {Object.keys(formatedGroupedSearch).map((key) => (
            <tbody key={key}>
              <tr>
                <th
                  className={Styles.name}
                  rowSpan={formatedGroupedSearch[key].length}
                  scope="rowgroup"
                  style={{ background: '#f3f4f5' }}
                >
                  {key}
                </th>
                <td className={Styles.resultsItem}>
                  {formatedGroupedSearch[key].map((item) => (
                    <a
                      key={item.id}
                      onClick={() => handleSelect(item)}
                      className={Styles.result}
                    >
                      <SearchResultsItem
                        result={item}
                        resultRenderer={resultRenderer}
                      />
                    </a>
                  ))}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      ) : (
        <p className="p-4">{t('Case ID not found')}</p>
      )}
    </div>
  );
}

SearchResults.propTypes = {
  results: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  focused: PropTypes.bool.isRequired,
  handleResult: PropTypes.func.isRequired,
  resultRenderer: PropTypes.any,
  setFocused: PropTypes.func.isRequired,
};

export default SearchResults;
