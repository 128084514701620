import { useApolloClient } from '@apollo/client';
import { useAmplify } from '@fullcontour/auth';
import { GET_ADMIN_MESSAGE_ACTIVE } from '@fullcontour/shared-api';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import {
  addIgnoredMessageId,
  getIgnoredMessageIds,
  toggleDismissedMessages,
} from './helpers';

function AdminMessageModal() {
  const client = useApolloClient();
  const { currentUser } = useAmplify();
  const [adminMessage, setAdminMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [ignoreMessage, setIgnoreMessage] = useState(false);
  const ignoredMessageIds = getIgnoredMessageIds(currentUser.email);

  useEffect(() => {
    async function displayAdminMessage() {
      const { data } = await client.query({
        query: GET_ADMIN_MESSAGE_ACTIVE,
        variables: { showApp: true },
        fetchPolicy: 'network-only',
      });
      if (
        data?.adminMessage &&
        !ignoredMessageIds.ids.includes(data?.adminMessage.id)
      ) {
        setAdminMessage(data?.adminMessage);
        setModalOpen(true);
      }
    }

    displayAdminMessage();
  }, [client]);

  const handleClose = () => {
    setModalOpen(false);
    addIgnoredMessageId(adminMessage.id, currentUser.email);
  };
  function ignoreChangeHandler() {
    setIgnoreMessage(!ignoreMessage);
    toggleDismissedMessages(currentUser.email, !ignoreMessage);
  }
  return adminMessage ? (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <h4 className="title is-5 modal-card-title mb-3">
            {adminMessage.name}
          </h4>
        </header>
        <section className="modal-card-body">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(adminMessage.content, {
                ADD_ATTR: ['target', 'href', 'style'],
                ADD_TAG: ['a'],
              }),
            }}
          />
        </section>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <label htmlFor="ignoreMessage">Don&apos;t show again</label>
          <input
            className="mr-6 ml-2"
            name="ignoreMessage"
            type="checkbox"
            checked={ignoreMessage}
            onChange={ignoreChangeHandler}
          />
          <button
            className="button is-dark"
            type="button"
            onClick={handleClose}
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  ) : null;
}

export default AdminMessageModal;
