import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import BasicDropdown from '../../../shared/FormFields/Dropdowns/BasicDropdown';
import BasicDropdownItem from '../../../shared/FormFields/Dropdowns/BasicDropdownItem';
import BillingInvoiceAttachCreditForm from './BillingInvoiceAttachCreditForm';
import BillingInvoiceMemoForm from './BillingInvoiceMemoForm/BillingInvoiceMemoForm';
import BillingInvoicePostPaymentForm from './BillingInvoicePostPaymentForm';

function BillingInvoicesHeader({
  statusUpdate,
  refetch,
  getCsv,
  resendInvoice,
  resendInvoiceTransaction,
  modalOpen,
  openModal,
  closeModal,
  downloadPDF = null,
  selectedItem = null,
  customer = null,
  type = null,
}) {
  const [actionsOpen, setActionsOpen] = useState(false);
  const { hexInvoiceNumber } = useParams();

  const app = import.meta.env.VITE_APP_NAME;

  const printInvoice = () => {
    const content = document.getElementById('printableInvoice');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.querySelector('#printableInvoiceInner').style.width = '7in';
    pri.document.querySelector('#invoiceSummary').style.padding = '.25in 0';
    pri.document.querySelector('.invoiceAddressRow').style.width = '7in';
    pri.document.querySelector('.invoiceAddressRow').style.boxSizing =
      'border-box';
    const addressCol = pri.document.querySelectorAll('.invoiceAddressCol');
    const tables = pri.document.querySelectorAll('table');
    for (const table of tables) {
      table.style.width = '7in';
      table.style.textAlign = 'left';
    }
    for (const col of addressCol) {
      col.style.boxSizing = 'border-box';
      col.style.width = '2.1in';
      col.style.display = 'inline-block';
      col.style.verticalAlign = 'top';
    }

    pri.document.close();
    pri.focus();
    pri.print();
  };

  const handleActionsOpen = (val) => {
    if (val !== null) {
      setActionsOpen(val);
    } else {
      setActionsOpen((prev) => !prev);
    }
  };

  const { t } = useTranslation('common');

  return (
    <div className="is-flex is-flex-direction-row is-justify-content-flex-end">
      <BasicDropdown
        contentClasses="is-pulled-right has-text-left is-align-content-center"
        hideIcon
        dropdownClasses="is-right"
        disabled={!selectedItem}
        aria-haspopup="true"
        buttonText="Actions"
        activeOverride={actionsOpen}
        setActiveOverride={setActionsOpen}
      >
        <p className="is-size-6 has-text-weight-bold mb-3 ml-5">
          {t('Actions')}
        </p>
        <BasicDropdownItem
          itemText="Download CSV"
          tabIndex={0}
          clickHandler={getCsv}
          setDropdownOpen={handleActionsOpen}
        />
        <BasicDropdownItem
          itemText="Download PDF"
          clickHandler={downloadPDF}
          setDropdownOpen={handleActionsOpen}
        />
        {hexInvoiceNumber && (
          <BasicDropdownItem
            itemText="Print..."
            clickHandler={printInvoice}
            setDropdownOpen={handleActionsOpen}
          />
        )}
        <BasicDropdownItem
          itemText="Resend Invoice"
          clickHandler={() => resendInvoice(selectedItem, refetch)}
          setDropdownOpen={handleActionsOpen}
        />
        <BasicDropdownItem
          itemText="Resend Transactions"
          clickHandler={() => resendInvoiceTransaction(selectedItem, refetch)}
          setDropdownOpen={handleActionsOpen}
        />
        {app === 'admin_portal' && (
          <>
            <hr className="dropdown-divider" />
            <p className="is-size-6 has-text-weight-bold mb-3 ml-5">
              {t('Status Updates')}
            </p>
            {selectedItem?.state !== 'paid' && (
              <BasicDropdownItem
                itemText="Mark as Paid"
                clickHandler={() =>
                  statusUpdate('mark_as_paid', selectedItem, refetch)
                }
                setDropdownOpen={handleActionsOpen}
              />
            )}
            {selectedItem?.state !== 'unpaid' && (
              <BasicDropdownItem
                itemText="Mark as Unpaid"
                clickHandler={() =>
                  statusUpdate('mark_as_unpaid', selectedItem, refetch)
                }
                setDropdownOpen={handleActionsOpen}
              />
            )}
            {selectedItem?.state !== 'past_due' &&
              selectedItem?.state !== 'paid' && (
                <BasicDropdownItem
                  itemText="Mark as Past Due"
                  clickHandler={() =>
                    statusUpdate('mark_as_past_due', selectedItem, refetch)
                  }
                  setDropdownOpen={handleActionsOpen}
                />
              )}
            {selectedItem?.state !== 'paid' && (
              <BasicDropdownItem
                itemText="Void Invoice"
                clickHandler={() =>
                  statusUpdate('mark_as_voided', selectedItem, refetch)
                }
                setDropdownOpen={handleActionsOpen}
              />
            )}
            <hr className="dropdown-divider" />
            <p className="is-size-6 has-text-weight-bold mb-3 ml-5">
              {t('Edit Invoice')}
            </p>
            {customer && type && selectedItem?.balanceDue > 0 && (
              <BillingInvoiceAttachCreditForm
                selectedItem={selectedItem}
                refetch={refetch}
                customer={customer}
                type={type}
                handleActionsOpen={handleActionsOpen}
                disabled
              />
            )}
            <BillingInvoiceMemoForm
              modalOpen={modalOpen}
              openModal={openModal}
              closeModal={closeModal}
              selectedItem={selectedItem}
              refetch={refetch}
            />
            {selectedItem?.state !== 'paid' && (
              <BillingInvoicePostPaymentForm
                selectedItem={selectedItem}
                refetch={refetch}
              />
            )}
          </>
        )}
      </BasicDropdown>
      <iframe
        id="ifmcontentstoprint"
        title="invoicePrint"
        style={{
          height: 0,
          width: 0,
          position: 'absolute',
        }}
      />
    </div>
  );
}

BillingInvoicesHeader.propTypes = {
  selectedItem: PropTypes.object,
  statusUpdate: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  getCsv: PropTypes.func.isRequired,
  resendInvoice: PropTypes.func.isRequired,
  downloadPDF: PropTypes.func,
  resendInvoiceTransaction: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  customer: PropTypes.object,
  type: PropTypes.string,
};

export default BillingInvoicesHeader;
