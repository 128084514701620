function initialValues(props) {
  const { order = {} } = props;

  return {
    id: order.id,
    name: order.name,
    instructions: order.instructions,
    requiresDesignApproval: order.requiresDesignApproval,
    turnaroundTimeId: order.turnaroundTimeId || '',
    orderItems: !(
      Array.isArray(order.orderItems) && order.orderItems.length > 0
    )
      ? []
      : order.orderItems.map((item) => {
          const { __typename, manufacturerOrganization, price, ...rest } = item;

          return { ...rest, _destroy: false };
        }),
  };
}

export default initialValues;
