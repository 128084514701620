import PropTypes from 'prop-types';
import { memo } from 'react';

function ServerErrors({ errorMessages = [] }) {
  return errorMessages?.length > 0 ? (
    <article className="message is-danger">
      <div className="message-header">
        <p>There were problems submitting your request</p>
      </div>
      <div className="message-body">
        <ul>
          {errorMessages.map((err, i) => (
            <li key={i}>
              <b style={{ textTransform: 'capitalize' }}>{err.path}:</b>
              {err.message}
            </li>
          ))}
        </ul>
      </div>
    </article>
  ) : null;
}

ServerErrors.propTypes = {
  errorMessages: PropTypes.array,
};

export default memo(ServerErrors);
