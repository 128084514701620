import { useLazyQuery } from '@apollo/client';
import { GET_ORDER_ITEMS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLoader, ReactPortal, handleTryCatch } from '../../../../shared';
import Feedback from '../../../../shared/Feedback/Feedback';
import MessageModalForm from './MessageModalForm';
import MessageModalFormHoldReasons from './MessageModalFormHoldReasons';

function MessageModal({
  modalOpen,
  closeModal,
  openModal,
  messageTitle,
  actionTitle,
  changeMessage,
  label = '',
  order = null,
  placeholder = '',
  message = '',
  redesignFeedback = false,
  statusAction = null,
  selectedCases = [],
  updateStatus = () => ({}),
}) {
  const { t } = useTranslation('orderactions');

  const [orderItems, setOrderItems] = useState(null);

  const [triggerFeedbackSend, setTriggerFeedbackSend] = useState(false);
  const [sendEnable, setSendEnable] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const singleData = useMemo(
    () =>
      order ? { data: order } : selectedCases.length === 1 && selectedCases[0],
    [order, selectedCases],
  );

  const [fetchOrderItems] = useLazyQuery(GET_ORDER_ITEMS);

  const isAdmin = import.meta.env.VITE_APP_NAME === 'admin_portal';

  useEffect(() => {
    const fetchOrderItemsHandler = handleTryCatch(async () => {
      const response = await fetchOrderItems({
        variables: {
          id: singleData?.data?.hexOrderCode,
        },
      });

      setOrderItems(response.data.order.orderItems);
    });

    if (
      !orderItems &&
      isAdmin &&
      singleData &&
      modalOpen &&
      selectedCases.length === 1 &&
      !order
    ) {
      fetchOrderItemsHandler();
    }

    if (!modalOpen && orderItems) {
      setOrderItems(null);
    }
  }, [modalOpen]);

  return (
    <>
      <a className="dropdown-item" onClick={openModal}>
        {t(actionTitle)}
      </a>
      {modalOpen && (
        <ReactPortal wrapperId="message-modal" show={modalOpen}>
          <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">{t(messageTitle)}</header>
              <section className="modal-card-body">
                {!orderItems &&
                isAdmin &&
                selectedCases.length === 1 &&
                !order ? (
                  <div style={{ height: '50vh' }}>
                    <BaseLoader />
                  </div>
                ) : statusAction === 'place_a_design_hold' ? (
                  <MessageModalFormHoldReasons
                    label={t(label)}
                    placeholder={t(placeholder)}
                    changeMessage={changeMessage}
                    message={message}
                    order={singleData.data}
                    selectedCases={selectedCases}
                  />
                ) : (
                  <>
                    <Feedback
                      showFeedback={{
                        condition: redesignFeedback && singleData,
                        app: import.meta.env.VITE_APP_NAME,
                      }}
                      data={{
                        ...singleData.data,
                        ...(isAdmin && !order ? { orderItems } : {}),
                      }}
                      bodyOnly
                      setSendEnable={setSendEnable}
                      triggerFeedbackSend={triggerFeedbackSend}
                      setTriggerFeedbackSend={setTriggerFeedbackSend}
                      message={message}
                      setSendLoading={setSendLoading}
                      callback={closeModal}
                    />
                    <MessageModalForm
                      label={
                        redesignFeedback
                          ? 'Additional Redesign Reason'
                          : t(label)
                      }
                      placeholder={
                        redesignFeedback ? 'Redesign Reason' : t(placeholder)
                      }
                      changeMessage={changeMessage}
                      message={message}
                      required={!redesignFeedback}
                    />
                  </>
                )}
              </section>
              <footer className="modal-card-foot is-flex is-justify-content-flex-end">
                <button
                  type="button"
                  className="is-dark button"
                  onClick={closeModal}
                >
                  {t('Close')}
                </button>
                <button
                  type="button"
                  className={`button is-primary ${
                    sendLoading ? 'is-loading' : ''
                  }`}
                  onClick={() =>
                    redesignFeedback
                      ? redesignFeedback &&
                        singleData &&
                        setTriggerFeedbackSend(true)
                      : updateStatus()
                  }
                  disabled={redesignFeedback ? !sendEnable : !message}
                >
                  {t('Send')}
                </button>
              </footer>
            </div>
          </div>
        </ReactPortal>
      )}
    </>
  );
}

MessageModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  updateStatus: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  messageTitle: PropTypes.string.isRequired,
  actionTitle: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  changeMessage: PropTypes.func.isRequired,
  message: PropTypes.string,
  statusAction: PropTypes.string,
  order: PropTypes.object,
  redesignFeedback: PropTypes.bool,
  selectedCases: PropTypes.array,
};

export default memo(MessageModal);
