import PropTypes from 'prop-types';
import { useState } from 'react';
import Tab from './Tab';
import TabList from './TabList';
import TabPanel from './TabPanel';
import TabsContext from './TabsContext';

import './Tabs.css';

function Tabs({ children = null, defaultTabId = null }) {
  const [selectedTabId, setSelectedTabId] = useState(defaultTabId);

  return (
    <TabsContext.Provider
      value={{
        selectedTabId,
        setSelectedTabId: (tabId) => setSelectedTabId(tabId),
      }}
    >
      {children}
    </TabsContext.Provider>
  );
}

Tabs.Tab = Tab;
Tabs.TabList = TabList;
Tabs.TabPanel = TabPanel;

Tabs.displayName = 'Tabs';

Tabs.propTypes = {
  defaultTabId: PropTypes.string,
  children: PropTypes.node,
};

export default Tabs;
