import { ProfileListNavMenu } from '@fullcontour/common';

const routes = [
  {
    path: [
      '/:root(profile)',
      '/:root(profile)/notifications',
      '/:root(profile)/update-password',
    ],
    exact: true,
    component: ProfileListNavMenu,
    so: true,
    sl: true,
  },
];

export default routes;
