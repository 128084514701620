import PropTypes from 'prop-types';

import Styles from './Info.module.scss';

function Info({
  dataTooltip = null,
  className = null,
  children = null,
  infoCallback = () => ({}),
}) {
  return (
    <div className="is-inline-flex is-align-items-flex-start">
      {children}
      <span
        className={`ml-1 ${className} ${Styles.questionIcon}`}
        data-tooltip={dataTooltip}
        onClick={infoCallback}
      >
        ?
      </span>
    </div>
  );
}

Info.propTypes = {
  children: PropTypes.node,
  dataTooltip: PropTypes.string,
  className: PropTypes.string,
  infoCallback: PropTypes.func,
};

export default Info;
