import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function FeedbackActionButtons({
  setBodyShow,
  disabledSend,
  sendFeedback,
  deleteFeedback,
  isUpdate,
  loading,
  deleteLoading,
  isModifiedByAdmin = false,
}) {
  const { t } = useTranslation('common');

  return (
    <div className="mt-5">
      {isModifiedByAdmin && (
        <div className="p-2">
          <small style={{ fontSize: 12 }}>
            {t('Feedback was modified by Admin. You cannot change it.')}
          </small>
        </div>
      )}
      <div
        className="is-flex is-justify-content-space-between pt-5"
        style={{ borderTop: '1px solid #ddd' }}
      >
        <button
          type="button"
          className="button is-dark"
          onClick={() => setBodyShow(false)}
        >
          {t('Close')}
        </button>
        <div className="buttons">
          {isUpdate ? (
            <button
              type="button"
              onClick={deleteFeedback}
              disabled={isModifiedByAdmin}
              className={`button is-danger is-outlined ${
                deleteLoading ? 'is-loading' : ''
              }`}
            >
              {t('Delete Feedback')}
            </button>
          ) : null}

          <button
            type="button"
            disabled={disabledSend || isModifiedByAdmin}
            onClick={() => sendFeedback({ isPositive: false })}
            className={`button is-primary ${loading ? 'is-loading' : ''}`}
          >
            {t(isUpdate ? 'Update' : 'Send')}
          </button>
        </div>
      </div>
    </div>
  );
}

FeedbackActionButtons.propTypes = {
  setBodyShow: PropTypes.func.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  deleteFeedback: PropTypes.func.isRequired,
  disabledSend: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  deleteLoading: PropTypes.bool.isRequired,
  isModifiedByAdmin: PropTypes.bool,
};

export default FeedbackActionButtons;
