import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { CurrentUserContext } from '../../../../../shared';
import {
  HeaderOrderActions,
  HeaderOrderInfo,
  HeaderOrderState,
  HeaderOrderTime,
} from './components';
import './HeaderInfo.css';

import { findUserClass } from './utils';

function HeaderInfo({ order = null, ...rest }) {
  const props = { order, ...rest };
  const app = import.meta.env.VITE_APP_NAME;

  const { currentUser } = useContext(CurrentUserContext);

  const isSubscribed = useMemo(
    () =>
      order?.orderSubscriptions.some(
        (sub) =>
          sub.orderSubscriptionableType === findUserClass(app) &&
          sub.orderSubscriptionableId === currentUser.username,
      ),
    [order, currentUser],
  );

  return (
    <div className="columns m-0" style={{ minHeight: 74 }}>
      <div className="detailsHeaderColumn column is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
        <HeaderOrderInfo isSubscribed={isSubscribed} {...props} />
        <HeaderOrderState {...props} />
      </div>
      <div className="detailsHeaderColumn column is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
        <HeaderOrderTime app={app} {...props} />
        <HeaderOrderActions app={app} {...props} />
      </div>
    </div>
  );
}

HeaderInfo.propTypes = {
  order: PropTypes.object,
};

export default HeaderInfo;
