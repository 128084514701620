import integrationRoutes from './integrationRoutes';
import orderRoutes from './orderRoutes';
import organizationRoutes from './organizationRoutes';
import userRoutes from './userRoutes';

const combinedRoutes = [
  ...organizationRoutes,
  ...userRoutes,
  ...orderRoutes,
  ...integrationRoutes,
];

export default combinedRoutes;
