import { useApolloClient, useQuery } from '@apollo/client';
import { useApp } from '@fullcontour/hooks';
import { GET_ORDER_FILES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { timestampFormatter } from '../../../../../transforms';
import createSignedLink from '../../../../shared/HelperFunctions/createSignedLink';
import { FilesGrid, FilesGridActions, fileCellRenderer } from './FilesGrid';
import { FilesShow } from './FilesShow';
import FilesUpload from './FilesUpload/FilesUpload';
import './OrderShowFiles.css';

function OrderShowFiles({ order }) {
  const client = useApolloClient();
  const { t } = useTranslation('aggrid');
  const { isAdmin, isCustomer, isDesigner } = useApp();
  const gridApiRef = useRef();
  const gridColumnApiRef = useRef();

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState(null);
  const [loadingFileView, setLoadingFileView] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [toggleVersions, setToggleVersions] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  const { data, refetch } = useQuery(GET_ORDER_FILES, {
    variables: { orderId: order.id },
    fetchPolicy: 'network-only',
  });

  const gridOptions = {
    columnDefs: [
      {
        field: 'createdAt',
        headerName: t('Created At'),
        minWidth: 150,
        maxWidth: 175,
        sort: 'desc',
        comparator(d1, d2) {
          return new Date(d1).getTime() < new Date(d2).getTime() ? -1 : 1;
        },
      },
    ],
    components: { fileCellRenderer: fileCellRenderer() },
    groupDefaultExpanded: -1,
    getDataPath(data) {
      return data.filePath;
    },
    getRowId(params) {
      return params.data.id.toString();
    },
    rowSelection: 'single',
    autoGroupColumnDef: {
      headerName: t('Files'),
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: 'fileCellRenderer',
      },
    },
  };

  const checkFor3dFiles = useCallback(
    (file) =>
      file.fileType === 'stl_files' ||
      file.fileType === 'visualization_output' ||
      file.fileType === 'obj_files' ||
      file.fileType === 'converted_treatment_review_files',
    [],
  );

  const fileTypes = useCallback(() => {
    const { orderItems } = order;
    const { orderFiles = [] } = data || {};

    const requiresAttachments = orderItems.some(
      (item) => item.designType.attachmentFiles === true,
    );

    const hasObjs = orderFiles.some((file) => file.fileType === 'obj_files');
    const hasTreatmentReviewFiles = orderFiles.some(
      (file) => file.fileType === 'treatment_review_files',
    );
    const hasConvertedFiles = orderFiles.some(
      (file) => file.fileType === 'converted_treatment_review_files',
    );
    const hasFinishedPackage = orderFiles.some(
      (file) => file.fileType === 'designer_finished_package',
    );
    const hasVisualizationOutput = orderFiles.some(
      (file) => file.fileType === 'visualization_output',
    );

    let fileTypes = [
      'initial_scans',
      'finished_scans',
      'manufacturer_scans',
      'stl_files',
      'quality_control_images',
    ];

    if (requiresAttachments || isAdmin || isCustomer || isDesigner) {
      fileTypes = [...fileTypes, 'attachments'];
    }

    if (hasVisualizationOutput || isAdmin || isCustomer || isDesigner) {
      fileTypes = [...fileTypes, 'visualization_output'];
    }

    if (hasObjs || isAdmin || isDesigner) {
      fileTypes = [...fileTypes, 'obj_files'];
    }

    if (hasFinishedPackage || isAdmin || isDesigner) {
      fileTypes = [...fileTypes, 'designer_finished_package'];
    }

    if (hasTreatmentReviewFiles || isAdmin) {
      fileTypes = [...fileTypes, 'treatment_review_files'];
    }

    if (hasConvertedFiles || isAdmin || isDesigner) {
      fileTypes = [...fileTypes, 'converted_treatment_review_files'];
    }

    return fileTypes;
  }, [order, data, isAdmin, isCustomer, isDesigner]);

  const fileRowData = useCallback(
    (showVersions = false) => {
      const { orderFiles = [] } = data || {};
      const { redesignCount } = order;

      let filteredFiles;

      if (!showVersions) {
        filteredFiles = orderFiles.filter((file) => {
          if (checkFor3dFiles(file)) {
            return (
              file.ver === file.latestVer &&
              file.redesignCount === redesignCount
            );
          }
          return file.ver === file.latestVer;
        });
      } else {
        filteredFiles = [...orderFiles]
          .sort((a, b) => a.redesignCount - b.redesignCount)
          .filter((item) => {
            if (checkFor3dFiles(item)) {
              return item.ver === item.latestVer;
            }
            return true;
          });
      }

      return filteredFiles.map((file) => {
        const fileName = !showVersions
          ? file.originalFileName
          : `v${
              checkFor3dFiles(file) ? file.redesignCount + 1 : file.ver
            }:${'  '} ${file.originalFileName}`;
        return {
          id: file.id,
          createdAt: timestampFormatter(file.createdAt),
          filePath: ['files', file.fileType, fileName],
        };
      });
    },
    [data, order, checkFor3dFiles],
  );

  const setUpGridData = useCallback(() => {
    const types = fileTypes();
    const rootTypes = types.map((type, index) => ({
      id: index + 1,
      filePath: ['files', type],
      createdAt: null,
    }));

    const files = fileRowData(toggleVersions);
    const newRowData = [...rootTypes, ...files];

    setRowData(newRowData);
    setSelectedFileError(null);
  }, [toggleVersions, fileTypes, fileRowData]);

  useEffect(() => {
    if (data?.orderFiles) {
      setUpGridData();
    }
  }, [data?.orderFiles, setUpGridData]);

  const onGridReady = useCallback((params) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;
  }, []);

  const onRowSelected = useCallback(
    async (event) => {
      if (!event.node.isSelected()) {
        return;
      }

      setLoadingFileView(true);
      setSelectedNode(event.node);

      if (event?.data?.createdAt) {
        const foundFile = data.orderFiles.find(
          (file) => file.id === event.data.id,
        );
        const signedFile = await createSignedLink({ client, foundFile });

        setLoadingFileView(false);
        setSelectedFile(signedFile);
        setSelectedFolder(null);
      } else {
        const foundType = data.orderFiles.filter((file) => {
          if (toggleVersions) {
            return file.fileType === event.data.filePath[1];
          }
          return (
            file.fileType === event.data.filePath[1] &&
            file.ver === file.latestVer
          );
        });

        const selectedFolderItems = await Promise.all(
          foundType.map((foundFile) =>
            createSignedLink({
              client,
              foundFile,
            }),
          ),
        );

        setLoadingFileView(false);
        setSelectedFolder({
          fileType: event.data.filePath[1],
          selectedItems: selectedFolderItems,
        });
        setSelectedFile(null);
      }
    },
    [toggleVersions, client, data?.orderFiles],
  );

  const onRowDataChanged = useCallback(
    (event) => {
      if (selectedNode) {
        event.api.deselectAll();
        event.api.forEachNode((newNode) => {
          if (newNode.data && newNode.data.id === selectedNode.data.id) {
            newNode.setSelected(true);
          } else {
            newNode.setSelected(false);
          }
        });
      }
    },
    [selectedNode],
  );

  const toggleAllVersions = useCallback(() => {
    setToggleVersions((prev) => !prev);
    setSelectedFile(null);
    setSelectedFolder(null);
    setSelectedFileError(null);
    setLoadingFileView(false);
    setUpGridData();
  }, [setUpGridData]);

  const setSelectedToDefault = useCallback(() => {
    setSelectedFile(null);
    setSelectedFolder(null);
    setSelectedFileError(null);
  }, []);

  if (!rowData) {
    return null;
  }

  return (
    <div className="columns">
      <div className="column is-half">
        <FilesGridActions
          toggleAllVersions={toggleAllVersions}
          toggleVersions={toggleVersions}
        />
        <FilesGrid
          gridOptions={gridOptions}
          rowData={rowData}
          onGridReady={onGridReady}
          onGridSizeChanged={(params) => params.api.sizeColumnsToFit()}
          onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
          onRowSelected={onRowSelected}
          onRowDataChanged={onRowDataChanged}
        />
      </div>
      <div className="column is-half is-relative is-flex is-flex-direction-column">
        <FilesShow
          selectedFile={selectedFile}
          client={client}
          refetch={refetch}
          selectedFolder={selectedFolder}
          selectedFileError={selectedFileError}
          loadingFileView={loadingFileView}
          toggleVersions={toggleVersions}
          setSelectedToDefault={setSelectedToDefault}
          order={order}
          orderFiles={data?.orderFiles}
        />
        {(selectedFile || selectedFolder) && (
          <FilesUpload
            order={order}
            selectedFile={selectedFile}
            selectedFolder={selectedFolder}
            refetch={refetch}
          />
        )}
      </div>
    </div>
  );
}

OrderShowFiles.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    orderItems: PropTypes.arrayOf(
      PropTypes.shape({
        designType: PropTypes.shape({
          attachmentFiles: PropTypes.bool,
        }),
      }),
    ).isRequired,
    redesignCount: PropTypes.number,
  }).isRequired,
};

export default OrderShowFiles;
