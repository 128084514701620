import { match } from 'path-to-regexp';
import { BreadCrumbContainer } from '../components/layouts';

function convertPathBack(path) {
  const regex = /\{\/(:\w+)\}/g;
  return path.replace(regex, '/$1');
}
function removeParenthesesContent(path) {
  const regex = /\(([^)]+)\)/g;
  return path.replace(regex, '');
}
function findMatchedRoute(routes, pathname) {
  for (const route of routes) {
    const matcher = match(route.path, { decode: decodeURIComponent });
    const matched = matcher(pathname);
    if (matched) {
      return {
        ...route,
        path: removeParenthesesContent(convertPathBack(route.path)),
      };
    }
  }
  return null;
}
export function createRoutesArray(routes, isBreadCrumbs, currentPath) {
  const newRoutes = [];
  for (const route of routes) {
    if (Array.isArray(route.path)) {
      for (const path of route.path) {
        newRoutes.push({
          ...route,
          path,
          Component: isBreadCrumbs ? BreadCrumbContainer : route.component,
          component: null,
        });
      }
    } else {
      newRoutes.push({
        ...route,
        Component: isBreadCrumbs ? BreadCrumbContainer : route.component,
        component: null,
      });
    }
  }
  return [findMatchedRoute(newRoutes, currentPath)].filter((route) => route);
}

export function getRoot() {
  const splitPath = window.location.pathname.split('/');
  if (splitPath.length > 1) {
    return splitPath[1];
  }
  return null;
}

export function getPathParam(position) {
  const splitPath = window.location.pathname.split('/');
  if (splitPath.length >= position) {
    return splitPath[position];
  }
  return null;
}
export function generatePathParams(options = []) {
  const pathSegments = window.location.pathname.split('/');
  const params = {};

  for (const [position, name] of options) {
    if (position < pathSegments.length) {
      params[name] = pathSegments[position];
    }
  }
  return params;
}
