import { ACTION_DESIGN_USER_SET_PASS } from '@fullcontour/shared-api';
import { CreateNotification } from '../../..';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  setModalOpen,
}) {
  const result = await client.mutate({
    mutation: ACTION_DESIGN_USER_SET_PASS,
    variables,
  });
  const { actionDesignUserSetPassword } = result.data;
  if (actionDesignUserSetPassword.errorMessages.length > 0) {
    setErrorMessages(actionDesignUserSetPassword.errorMessages);
  } else {
    clearErrorMessages();
    setModalOpen(false);
    CreateNotification.createNotification({
      type: 'success',
      message: 'Password has been successfully updated',
    });
  }
}

export default update;
