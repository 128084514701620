import IntegrationForm from '@fullcontour/common/src/components/views/Integrations/IntegrationForm/IntegrationForm';
import IntegrationsListQueryWrapper from '@fullcontour/common/src/components/views/Integrations/IntegrationsList/IntegrationsListQueryWrapper';

const routes = [
  {
    path: ['/:root(organizations)/integrations'],
    component: IntegrationsListQueryWrapper,
    sl: false,
    so: true,
  },
  {
    path: [
      '/:root(organizations)/integrations/:action(new)',
      '/:root(organizations)/integrations/:id/:action(edit)',
    ],
    component: IntegrationForm,
    sl: false,
    so: true,
  },
];

export default routes;
