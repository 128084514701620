function newOrderToCache(order) {
  return {
    createdAt: order.createdAt,
    hexOrderCode: order.hexOrderCode,
    id: order.id,
    intOrderId: order.intOrderId,
    isRush: order.isRush,
    itemsMetadata: order.itemsMetadata,
    location: order.location,
    name: order.name,
    percentComplete: order.percentComplete,
    requiresDesignApproval: order.requiresDesignApproval,
    state: order.state,
    turnaroundTimeName: order.turnaroundTime.name,

    __typename: order.__typename,
  };
}

export default newOrderToCache;
