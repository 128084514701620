import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { BoxIcon } from '../../shared';
import './OrderDropzoneDesigner.css';
import {
  activeStyle,
  baseStyle,
  rejectedStyle,
} from './OrderDropzoneDesignerStyle';

function OrderDropzoneDesigner({
  pushFiles,
  children,

  style = null,
}) {
  async function onDrop(accepted) {
    const acceptedFiles = accepted
      .filter((acc) => !acc.name.endsWith('.csv'))
      .map((file) => ({
        file,
        isChecked: false,
        id: uuidv4(),
      }));
    pushFiles(acceptedFiles);
  }

  return (
    <Dropzone multiple onDrop={(accepted) => onDrop(accepted)}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
        let styles = { ...baseStyle };
        styles = style ? { ...styles, ...style } : styles;
        styles = isDragActive ? { ...styles, ...activeStyle } : styles;
        styles = isDragReject ? { ...styles, ...rejectedStyle } : styles;

        return (
          <div
            style={styles}
            {...getRootProps({ onClick: (evt) => evt.stopPropagation() })}
            className="dropzoneContainer card"
          >
            <input {...getInputProps()} />
            {children}
            {isDragActive && (
              <div className="fullScreenDrag">
                <div
                  className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                  style={{ height: '100%' }}
                >
                  <div className="has-text-centered">
                    <BoxIcon name="bx-cloud-upload bx-lg has-text-primary" />
                    <h6 className="title is-6 m-0 my-2">
                      Drop your files here
                    </h6>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Dropzone>
  );
}

OrderDropzoneDesigner.propTypes = {
  children: PropTypes.node.isRequired,
  files: PropTypes.array,
  pushFiles: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default OrderDropzoneDesigner;
