import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClick from '../../HelperFunctions/OutsideClick';
import SearchResults from './SearchResults';

function Search({
  id,
  onSearchChange,
  onResultSelect,
  value: val,
  placeholder = 'Search...',
  results = [],
  labelStyle = {},
  loading = false,
  minCharacters = 3,
  resultRenderer = null,
  label = 'Search',
}) {
  const [searchVal, setSearchVal] = useState(val);
  const [focused, setFocused] = useState(false);
  const [searchResults, setSearchResults] = useState(
    results.sort((a, b) => a.name?.localeCompare(b.name)),
  );

  const { t } = useTranslation('formfields');

  function handleSearchChange(e) {
    const {
      target: { value },
    } = e;

    setSearchVal(value);

    if (value?.length < minCharacters || searchVal < minCharacters) {
      setSearchResults([]);
    }

    if (value?.length >= minCharacters) {
      onSearchChange(value);
    }
  }

  const handleResult = useCallback(
    (result) => {
      setSearchVal('');
      setSearchResults([]);
      onResultSelect(result);
    },
    [onResultSelect],
  );

  useEffect(() => {
    setSearchResults(results?.sort((a, b) => a?.name?.localeCompare(b?.name)));
  }, [results]);

  return (
    <OutsideClick
      className="is-flex is-align-items-center"
      fn={() => setFocused(false)}
    >
      {/* biome-ignore lint/nursery/noStaticElementInteractions: <explanation> */}
      <div onFocus={() => setFocused(true)}>
        <label className="label" htmlFor={id} style={labelStyle}>
          {label}
        </label>
        <div
          className="field"
          style={{
            position: 'relative',
          }}
        >
          <div className="control has-icons-right">
            <input
              id={id}
              name={id}
              className="input is-rounded"
              type="text"
              placeholder={t(placeholder)}
              onChange={handleSearchChange}
              style={{
                width: 200,
              }}
              value={searchVal}
            />
            <span className="icon is-right" style={{ color: 'inherit' }}>
              <i className="bx bx-search" />
            </span>
          </div>
          <SearchResults
            results={searchResults}
            loading={loading}
            handleResult={handleResult}
            resultRenderer={resultRenderer}
            focused={focused}
            setFocused={setFocused}
          />
        </div>
      </div>
    </OutsideClick>
  );
}

Search.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  results: PropTypes.array,
  labelStyle: PropTypes.object,
  loading: PropTypes.bool,
  onSearchChange: PropTypes.func.isRequired,
  onResultSelect: PropTypes.func.isRequired,
  minCharacters: PropTypes.number,
  value: PropTypes.any.isRequired,
  resultRenderer: PropTypes.any,
  label: PropTypes.string,
};

export default Search;
