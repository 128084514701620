import { GET_PRICE_LIST_OVERPANEL } from '@fullcontour/shared-api';
import { FieldArray, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FormGridWrap, LoadingOrError, withFormWrap } from '../../../../shared';
import { statusUpdate } from '../../OrderActions/helpers';
import './OrderShowInfo.css';
import { useQuery } from '@apollo/client';
import OrderShowInfoCase from './OrderShowInfoCase';
import { initialValues, schema, update } from './OrderShowInfoForm/helpers';
import { OrderShowInfoItems } from './OrderShowInfoItems';
import OrderShowInfoTotals from './OrderShowInfoTotals';

function OrderShowInfo({
  order,
  client,
  initialValues = () => ({}),
  schema = () => ({}),
  getVariables = () => ({}),
  skipQuery = () => ({}),
  match = {},
  ...rest
}) {
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_PRICE_LIST_OVERPANEL, {
    skip:
      import.meta.env.VITE_APP_NAME === 'designer_portal' ||
      import.meta.env.VITE_APP_NAME === 'manuf_portal',
    variables: { id: order.location.id },
    fetchPolicy: 'cache-and-network',
  });
  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Case" />;
  }
  const {
    customerLocation: {
      priceList: { turnaroundTimes = null, designTypes = [] } = {},
      assignedManufacturers,
    } = {},
  } = data;

  function handleSubmit(values, { setSubmitting }) {
    const rushTime = turnaroundTimes.find(
      (item) => item.value === values.turnaroundTimeId,
    );
    const { orderItems, ...restValues } = values;
    const scrubbedOrderItems = orderItems.map((item) => {
      const { designType, modifiers, ...restItem } = item;
      return restItem;
    });

    const newValues = { ...restValues, orderItems: scrubbedOrderItems };

    const variables = {
      variables: { input: { input: newValues, clientMutationId: uuidv4() } },
    };

    if (rushTime?.text.toLowerCase().includes('rush')) {
      statusUpdate(client, 'rush_design', values.id);
    }
    update({ variables, client, ...rest });
    setSubmitting(false);
  }
  return (
    <Formik
      initialValues={initialValues({ order })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <FormGridWrap match={match} hideHeader width="is-12">
          <div
            style={{ marginTop: '2em', position: 'relative' }}
            className="orderShowInfoGrid box p-4"
          >
            <div className="columns m-0">
              <OrderShowInfoCase
                order={order}
                turnaroundTimes={turnaroundTimes}
              />
              <FieldArray
                name="orderItems"
                render={(arrayHelpers) => (
                  <OrderShowInfoItems
                    order={order}
                    arrayHelpers={arrayHelpers}
                    designTypes={designTypes}
                    assignedManufacturers={assignedManufacturers}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                )}
              />
            </div>
          </div>
          <OrderShowInfoTotals order={order} />
        </FormGridWrap>
      )}
    </Formik>
  );
}

OrderShowInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  OrderShowInfo,
);
