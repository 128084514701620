import { useQuery } from '@apollo/client';
import { GET_DEBITS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { LoadingOrError } from '../../../shared';
import DebitsListDataTable from './DebitsListDataTable';

function DebitsListQueryWrapper({
  customer = null,
  type = null,
  openModal = () => null,
  ...rest
}) {
  const props = { customer, type, openModal, ...rest };
  let variables = {};

  if (customer && type && type === 'location') {
    variables = { labLocationId: customer.id };
  }

  if (customer && type && type === 'organization') {
    variables = { labOrganizationId: customer.id };
  }

  const { error, loading, data, refetch } = useQuery(GET_DEBITS, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Debits" />;
  }

  return (
    <DebitsListDataTable
      debits={data.debits}
      refetch={refetch}
      openModal={openModal}
      {...props}
    />
  );
}

DebitsListQueryWrapper.propTypes = {
  customer: PropTypes.object,
  type: PropTypes.string,
  openModal: PropTypes.func,
};

export default DebitsListQueryWrapper;
