import { withFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

const form = withFormik({
  mapPropsToValues: (props) => props.initialValues(props),
  validationSchema: (props) => props.schema(),
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };

    if (props.separateId && props.match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }

    if (props.separateId) {
      variables.variables.input.input.id = undefined;
    }

    if (props.match.params.action && props.match.params.action === 'new') {
      props.create(variables);
    } else {
      props.update(variables);
    }
  },
});

export default form;
