import PropTypes from 'prop-types';
import { timestampFormatter } from '../../../../../../transforms';

function HeaderInfoSubmitted(props) {
  const { createdAt } = props;

  return (
    <>
      <span className="has-text-weight-bold">Submitted:</span>
      &nbsp;
      {timestampFormatter(createdAt)}
    </>
  );
}

HeaderInfoSubmitted.propTypes = {
  createdAt: PropTypes.string.isRequired,
};

export default HeaderInfoSubmitted;
