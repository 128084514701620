import { CREATE_CREDIT } from '@fullcontour/shared-api';

async function create({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  refetch,
  closeModal,
}) {
  try {
    const result = await client.mutate({
      mutation: CREATE_CREDIT,
      variables,
    });

    const { data } = result || {};
    const { createCredit } = data || {};

    if (createCredit?.errorMessages?.length > 0) {
      setErrorMessages(createCredit.errorMessages);
    } else {
      clearErrorMessages();
      refetch();
      closeModal();
    }
  } catch (error) {
    setErrorMessages([error.message]);
  }
}

export default create;
