import OrdersNavMenu from '@fullcontour/common/src/components/shared/NavMenus/Orders/OrdersList/OrdersList';

const routes = [
  {
    path: ['/orders', '/orders/:status'],
    component: OrdersNavMenu,
    so: true,
    sl: true,
  },
];

export default routes;
