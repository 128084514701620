import PropTypes from 'prop-types';

function Tooth24({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 24)
    : lowerArch;
  const variable = designType.toothChart ? 24 : 'lower';
  const handleClick = designType.toothChart ? toggleTooth : toggleArch;

  const isSelected = nestedTeeth[24]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <g
      onClick={() => handleClick(variable)}
      role="button"
      tabIndex={0}
      aria-pressed={isActive}
      onKeyDown={(e) => e.key === 'Enter' && handleClick(variable)}
    >
      <path
        className={className}
        d="M1999.4 4733.3c13 28.5 22.2 60.1 45.9 80.1 10.6 10.7 19.8 24.3 8 80.2 -12.3 23.3-33.3 36.9-66.3 37.2 -26.6-5-49.5-12.1-91.4-8.4 -20.2-4.9-38.5 1.7-63-30.9 -8.1-15.4-20.7-25.1-0.2-75.9l62.7-83.8c17-12.7 18.9-32.2 58.5-34.6C1984.6 4698.1 1990.4 4716.8 1999.4 4733.3L1999.4 4733.3z"
      />
      <path
        className="st1"
        d="M2040.3 4885.7c-17.1 2.9-33.3 8.5-52.6 2.7l-31 2.7c-21.8 3.2-40.6 0.3-60.3-0.7 -29.9-1.6-60.7-2.7-52.6-22.3"
      />
    </g>
  );
}

Tooth24.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth24;
