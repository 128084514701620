import { UPDATE_CREDIT_CARD } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  refetch,
  closeModal,
  refetchCustomerLocation,
}) {
  const result = await client.mutate({
    mutation: UPDATE_CREDIT_CARD,
    variables,
  });
  const { updateCreditCard } = result.data;
  if (updateCreditCard.errorMessages.length > 0) {
    setErrorMessages(updateCreditCard.errorMessages);
  } else {
    clearErrorMessages();
    refetch();
    refetchCustomerLocation();
    closeModal();
  }
}

export default update;
