import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_ORDER,
  GET_ORDER_FILES,
  GET_ORDER_FILE_TYPES,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { downloadModal } from '../../../../../config/apollo';
import generateSimpleOrdersZips from '../../../../../utils/folderZipper';
import { LoadingOrError } from '../../../../shared';
import {
  checkPermissions,
  exportSelectedCsv,
  filesDownload,
  orderTypesHandler,
} from '../helpers';
import DownloadModal from './DownloadModal';

function ContainerActionDownload({
  actionTitle,
  selectedCases,
  updateDownloadProgress,
  setDownloading,
  setDownloadingOff,
  statusAction = null,
  order = null,
  gridApi = null,
  params = null,
  ...rest
}) {
  const props = {
    actionTitle,
    selectedCases,
    updateDownloadProgress,
    setDownloading,
    setDownloadingOff,
    statusAction,
    order,
    gridApi,
    params,
    ...rest,
  };
  const isDownloadModalData = useReactiveVar(downloadModal);
  const client = useApolloClient();
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [orderFileTypes, setOrderFileTypes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [checkedTypes, setCheckedTypes] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setCheckedTypes(() =>
      selectedTypes
        .filter((type) => type.selected === true)
        .map((type) => type.klass),
    );
  }, [selectedTypes]);

  const { error, loading, data } = useQuery(GET_ORDER_FILE_TYPES, {
    fetchPolicy: 'cache-and-network',
  });

  function updateSelectedTypes(selected) {
    setSelectedTypes(selected);
  }

  async function downloadFile(orderId, ids) {
    const { data: d } = await client.query({
      query: GET_ORDER_FILES,
      variables: { orderId },
      fetchPolicy: 'no-cache',
    });

    const filteredTypes = checkedTypes.filter((type) => type !== 'csv_file');

    const calcHighestCount = (type) =>
      d.orderFiles.reduce(
        (count, { redesignCount, fileType }) =>
          redesignCount > count && fileType === type ? redesignCount : count,
        0,
      );

    const highestObjRedesignCount = calcHighestCount('obj_files');
    const highestStlRedesignCount = calcHighestCount('stl_files');

    const orderFiles = d.orderFiles.filter((item) => {
      if (item.fileType === 'obj_files') {
        return item.redesignCount === highestObjRedesignCount;
      }

      if (item.fileType === 'stl_files') {
        return item.redesignCount === highestStlRedesignCount;
      }

      return !(ids.includes(orderId) && item.fileType === 'initial_scans');
    });

    filesDownload(
      [orderFiles],
      filteredTypes,
      client,
      statusAction,
      updateDownloadProgress,
      setDownloading,
      setDownloadingOff,
      order?.state || params?.status,
      order?.hexOrderCode,
      selectedCases.length,
    );
  }

  function closeModal() {
    downloadModal(false);
    setModalOpen(false);
  }

  async function downloadSelectedFiles(e) {
    e.preventDefault();
    setLoadingData(true);

    const fetchedOrders = await Promise.all(
      selectedCases.map(async ({ data: { hexOrderCode } }) => {
        const {
          data: { order },
        } = await client.query({
          query: GET_ORDER,
          variables: { id: hexOrderCode },
          fetchPolicy: 'no-cache',
        });
        return order;
      }),
    );

    const foundCsv = checkedTypes.includes('csv_file');
    const foundInitialScans = checkedTypes.includes('initial_scans');

    const { simple, ids } = fetchedOrders.reduce(
      (filtered, caseOrder) => {
        if (caseOrder.source === 'simple') {
          filtered.simple.push({ ...caseOrder });
          filtered.ids.push(caseOrder.id);
        }

        return filtered;
      },
      { simple: [], ids: [] },
    );

    if (foundInitialScans) {
      await generateSimpleOrdersZips(simple, client, actionTitle);
    }

    if (foundCsv) {
      exportSelectedCsv(gridApi);
    }

    if (fetchedOrders && fetchedOrders.length > 0) {
      await Promise.all(
        fetchedOrders.map(async (caseData) => {
          await downloadFile(caseData.id, ids);
        }),
      );
    }

    if ((order && fetchedOrders.length === 0) || isDownloadModalData) {
      await downloadFile(order ? order.id : isDownloadModalData.id, ids);
    }

    setLoadingData(false);
    closeModal();
  }

  const orderData = useMemo(
    () => order || isDownloadModalData,
    [order, isDownloadModalData],
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    if (orderData) {
      setOrderFileTypes(
        orderTypesHandler(orderData)
          ? data.orderFileTypes.filter((item) => item.klass !== 'stl_files')
          : data.orderFileTypes,
      );
    }

    if (selectedCases?.length > 0) {
      for (const singleCase of selectedCases) {
        if (orderTypesHandler(singleCase.data)) {
          setOrderFileTypes(
            data.orderFileTypes.filter((item) => item.klass !== 'stl_files'),
          );
          return;
        }
      }

      setOrderFileTypes(data.orderFileTypes);
    }
  }, [selectedCases, data, orderData]);

  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Case File Types" />
    );
  }

  return checkPermissions(props) ? (
    <DownloadModal
      closeModal={closeModal}
      modalOpen={!!(modalOpen || (isDownloadModalData && !statusAction))}
      openModal={() => setModalOpen(true)}
      orderFileTypes={
        orderData
          ? orderFileTypes
          : [
              {
                id: '123',
                name: 'CSV File',
                klass: 'csv_file',
                __typename: 'OrderFileType',
              },
              ...orderFileTypes,
            ]
      }
      selectedTypes={selectedTypes}
      updateSelectedTypes={updateSelectedTypes}
      downloadSelectedFiles={downloadSelectedFiles}
      actionTitle={actionTitle}
      loadingData={loadingData}
    />
  ) : null;
}

ContainerActionDownload.propTypes = {
  selectedCases: PropTypes.array.isRequired,
  statusAction: PropTypes.string,
  actionTitle: PropTypes.string.isRequired,
  order: PropTypes.object,
  updateDownloadProgress: PropTypes.func.isRequired,
  setDownloading: PropTypes.func.isRequired,
  setDownloadingOff: PropTypes.func.isRequired,
  gridApi: PropTypes.object,
  params: PropTypes.object,
};

export default ContainerActionDownload;
