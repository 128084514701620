import { gql } from '@apollo/client';

export default gql`
  query viewerLink($linkHash: String!) {
    viewerLink(linkHash: $linkHash) {
      id
      linkHash
      createdAt
      order {
        id
        redesignCount
        convertedTreatmentReviewFiles {
          category
          createdAt
          fileType
          downloadUrl
          fileUrl
          id
          latestVer
          orderId
          originalFileName
          redesignCount
          s3Key
          sortOrder
          updatedAt
          ver
        }
        treatmentReviewFiles {
          category
          createdAt
          fileType
          fileUrl
          downloadUrl
          id
          latestVer
          orderId
          originalFileName
          redesignCount
          s3Key
          sortOrder
          updatedAt
          ver
        }
        location {
          id
          name
          website
          logoUrl
          viewerSetting {
            id
            theme
          }
        }
        objFiles {
          id
          fileUrl
          downloadUrl
          s3Key
          ver
          latestVer
          sortOrder
          originalFileName
          category
          redesignCount
        }
      }
    }
  }
`;
