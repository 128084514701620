import { UPDATE_NOTIFICATION_TYPE_USER } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
}) {
  const result = await client.mutate({
    mutation: UPDATE_NOTIFICATION_TYPE_USER,
    variables,
  });
  const { updateNotificationTypeUser } = result.data;
  if (updateNotificationTypeUser.errorMessages.length > 0) {
    setErrorMessages(updateNotificationTypeUser.errorMessages);
  } else {
    clearErrorMessages();
  }
}

export default update;
