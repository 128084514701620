import accountMenuRoutes from './accountMenuRoutes';
import orderRoutes from './orderRoutes';
import organizationRoutes from './organizationRoutes';
import profileRoutes from './profileRoutes';
import userRoutes from './userRoutes';

const combinedRoutes = [
  ...accountMenuRoutes,
  ...organizationRoutes,
  ...userRoutes,
  ...profileRoutes,
  ...orderRoutes,
];

export default combinedRoutes;
