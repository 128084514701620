import PropTypes from 'prop-types';

function ThemeSelectionField({ handleThemeChange, value }) {
  return (
    <>
      <h6 className="title is-6 mt-5 mb-3">Select Your Theme</h6>
      <div className="field is-grouped">
        <div className="control">
          <div className="is-flex is-align-items-center">
            <input
              id="dark"
              name="dark"
              type="radio"
              className="is-checkradio is-primary is-small"
              checked={value === 'dark'}
              onChange={handleThemeChange}
            />
            <label className="checkbox is-size-6 m-0" htmlFor="dark">
              Dark Theme
            </label>
          </div>
        </div>
        <div className="control ml-4">
          <div className="is-flex is-align-items-center">
            <input
              id="light"
              name="light"
              type="radio"
              className="is-checkradio is-primary is-small"
              checked={value === 'light'}
              onChange={handleThemeChange}
            />
            <label className="checkbox is-size-6 m-0" htmlFor="light">
              Light Theme
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

ThemeSelectionField.propTypes = {
  handleThemeChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ThemeSelectionField;
