import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function TeethChartActions({
  stages,
  isTotal,
  setIsTotal,
  selectedStages,
  setSelectedStages,
}) {
  const { t } = useTranslation('orderactions');

  const selectedStagesHandler = useCallback(
    (isIncrement) => {
      if (isTotal) {
        setIsTotal(false);
      }
      if (
        (selectedStages === stages && isIncrement) ||
        (!isIncrement && selectedStages === 1)
      ) {
        return;
      }
      setSelectedStages((prev) => (isIncrement ? prev + 1 : prev - 1));
    },
    [stages, selectedStages, isTotal, setIsTotal, setSelectedStages],
  );

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;

      if (keyCode === 37) {
        selectedStagesHandler(false);
      }

      if (keyCode === 39) {
        selectedStagesHandler(true);
      }
    },

    [selectedStagesHandler],
  );

  useEffect(() => {
    if (isTotal) {
      setSelectedStages(1);
    }
  }, [isTotal, setSelectedStages]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <div className="is-flex is-align-items-center mb-3">
      <div className="teeth-chart__btns-wrapper">
        <button
          type="button"
          className={`button mr-2 ${isTotal && 'is-dark'}`}
          onClick={() => setIsTotal(true)}
        >
          {t('Total movements')}
        </button>
        <button
          type="button"
          className={`button ${!isTotal && 'is-dark'}`}
          onClick={() => setIsTotal(false)}
        >
          {t('Movements per stage')}
        </button>
      </div>
      <div className="teeth-chart__stage-btns-wrapper">
        <button
          className="teeth-chart__stage-btns"
          type="button"
          onClick={() => selectedStagesHandler(false)}
        >
          <i className="bx bx-left-arrow-alt is-size-4 has-text-grey" />
        </button>
        <span>
          {t('Stage')}
          {isTotal
            ? ` 0-${stages - 1}`
            : selectedStages - 1
              ? ` ${selectedStages - 2}-${selectedStages - 1}`
              : ' 0'}
        </span>
        <button
          className="teeth-chart__stage-btns"
          type="button"
          onClick={() => selectedStagesHandler(true)}
        >
          <i className="bx bx-right-arrow-alt is-size-4 has-text-grey" />
        </button>
      </div>
    </div>
  );
}

TeethChartActions.propTypes = {
  stages: PropTypes.number.isRequired,
  isTotal: PropTypes.bool.isRequired,
  setIsTotal: PropTypes.func.isRequired,
  setSelectedStages: PropTypes.func.isRequired,
  selectedStages: PropTypes.number.isRequired,
};

export default TeethChartActions;
