import { memo } from 'react';
import BreadCrumbPaths from './BreadCrumbPaths';

function BreadCrumbContainer() {
  return (
    <div
      style={{
        borderBottom: '1px solid #ddd',
        borderTop: '1px solid #ddd',
      }}
    >
      <nav className="breadcrumb has-succeeds-separator is-size-7">
        <ul className="m-0 py-2 pl-4">
          <BreadCrumbPaths />
        </ul>
      </nav>
    </div>
  );
}

export default memo(BreadCrumbContainer);
