function transformNestedData(
  nestedData,
  allowNested = true,
  transformFn = (key) => key,
) {
  const transformedData = {};
  if (nestedData && allowNested) {
    for (const [key, value] of Object.entries(nestedData)) {
      for (const id of value) {
        if (!transformedData[id]) {
          transformedData[id] = [];
        }
        transformedData[id].push(transformFn(key));
      }
    }
  }
  return transformedData;
}

export default transformNestedData;
