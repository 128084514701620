import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DesignGuideOrderContext } from '../../..';
import { transformNestedData } from '../../../../../transforms';
import BasicDropdown from '../../../FormFields/Dropdowns/BasicDropdown';

function GuideCodesSelected({ index = null, removeAllButton = false }) {
  const { setFieldValue, values } = useFormikContext();
  const { orderItems } = values;
  const { orderItemIndex, allowNested } = useContext(DesignGuideOrderContext);

  const { t } = useTranslation('designProfile');

  const currentItem = orderItems[index ?? orderItemIndex];

  const currentGuideCodes = currentItem.guideCodes;
  const { nestedTeeth } = currentItem;
  const { nestedArches } = currentItem;

  const numberOfSelectedCodes = currentGuideCodes?.reduce(
    (num, currentGuideCode) => num + (currentGuideCode?.codes?.length || 0),
    0,
  );
  const deleteGuideCode = (idxGuide) => {
    const updatedCurrentGuideCodes = [...currentGuideCodes];
    updatedCurrentGuideCodes.splice(idxGuide, 1);
    setFieldValue(
      `orderItems[${index || index === 0 ? index : orderItemIndex}].guideCodes`,
      updatedCurrentGuideCodes,
    );
  };

  const removeHandler = () => {
    setFieldValue(
      `orderItems[${index || index === 0 ? index : orderItemIndex}].guideCodes`,
      [],
    );
  };

  const transformedTeeth = transformNestedData(
    nestedTeeth,
    allowNested,
    Number.parseInt,
  );
  const transformedArches = transformNestedData(
    nestedArches,
    allowNested,
    (key) => key,
  );

  return currentGuideCodes ? (
    <div className="is-flex">
      <BasicDropdown
        buttonClasses="is-flex hover-primary-button"
        contentClasses="py-0"
        hideIcon
        noStyles
        buttonTextStyle={{ fontSize: '14px' }}
        disabled={!currentGuideCodes}
        buttonText={`${t('Codes Selected ({{count}})', {
          count: numberOfSelectedCodes || 0,
        })}`}
      >
        <table className="is-bordered is-striped is-narrow is-hoverable is-fullwidth table">
          <thead>
            <tr>
              <th>{t('Guide Category')}</th>
              <th>{t('Guide Code')}</th>
              {allowNested && (
                <>
                  <th>{t('Teeth')}</th>
                  <th>{t('Arches')}</th>
                </>
              )}
              <th>{t('Remove')}</th>
            </tr>
          </thead>
          <tbody>
            {currentGuideCodes?.map((item, idxGuide) =>
              item?.codes?.map((code) => (
                <tr key={code + Math.random()}>
                  <td
                    style={{
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      width: '33%',
                    }}
                  >
                    {item.showDropdown ? item.categoryName : code}
                  </td>
                  <td
                    style={{
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      width: '33%',
                    }}
                  >
                    {item.showDropdown ? code : '-'}
                  </td>
                  {allowNested && (
                    <>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'center',
                          width: '33%',
                        }}
                      >
                        {transformedTeeth[item.codeId]
                          ? transformedTeeth[item.codeId].join(', ')
                          : '-'}
                      </td>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'center',
                          width: '33%',
                        }}
                      >
                        {transformedArches[item.codeId]
                          ? transformedArches[item.codeId].join(', ')
                          : '-'}
                      </td>
                    </>
                  )}
                  <td
                    style={{
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      width: '33%',
                    }}
                  >
                    <button
                      type="button"
                      className="button is-small"
                      onClick={() => deleteGuideCode(idxGuide)}
                    >
                      <span className="icon">
                        <i className="bx bx-x has-text-weight-bold is-size-4 has-text-grey" />
                      </span>
                    </button>
                  </td>
                </tr>
              )),
            )}
          </tbody>
        </table>
      </BasicDropdown>
      {removeAllButton && (
        <button
          type="button"
          className="button is-primary ml-3"
          onClick={removeHandler}
        >
          Remove all
        </button>
      )}
    </div>
  ) : null;
}

GuideCodesSelected.propTypes = {
  index: PropTypes.number,
  removeAllButton: PropTypes.bool,
};

export default GuideCodesSelected;
