import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BillingCreditCard from './BillingCreditCard';
import BillingCreditCardForm from './BillingCreditCardForm/BillingCreditCardForm';

function BillingCreditCards(props) {
  const {
    creditCards,
    customer: { paymentTerm },
    customer,
    type,
    refetch,
  } = props;

  const { t } = useTranslation('billing');

  const [action, setAction] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [creditCard, setCreditCard] = useState({});

  const closeModal = () => {
    setAction(null);
    setModalOpen(false);
  };

  const openModal = (act, card) => {
    setAction(act);
    setCreditCard(card);
    setModalOpen(true);
  };

  function sortCreditCards(creditCardsArray) {
    const defaultCard = creditCardsArray.find((card) => card.defaultCard);

    if (!defaultCard) {
      return creditCardsArray;
    }
    return [
      defaultCard,
      ...creditCardsArray.filter((card) => !card.defaultCard),
    ];
  }

  return (
    <div
      className="box is-flex is-flex-direction-column is-justify-content-space-between"
      style={{ minHeight: '100%' }}
    >
      <div style={{ minHeight: '100%' }}>
        <h5 className="title is-5">{t('Payment Method')}</h5>
        {import.meta.env.VITE_APP_NAME === 'admin_portal' && (
          <p className="is-size-6 pb-4">
            <b>{t('Terms')}:</b> {paymentTerm.name}
            <small className="ml-2">
              (
              {t(
                `${
                  customer.locationBilling ? 'Location' : 'Organization'
                } is responsible for payment`,
              )}
              )
            </small>
          </p>
        )}
        {creditCards.length === 0 ? (
          <p className="mb-6">{t('No Credit Cards Available')}</p>
        ) : (
          <div style={{ maxHeight: '170px', overflowY: 'auto', width: '100%' }}>
            {sortCreditCards(creditCards).map((card) => (
              <BillingCreditCard
                creditCard={card}
                key={card.id}
                openModal={openModal}
                numberOfCards={creditCards.length}
                refetch={refetch}
              />
            ))}
          </div>
        )}
      </div>
      <div className="is-flex is-align-self-flex-end mt-4">
        {type === 'organization' ||
        (type === 'location' && customer.locationBilling) ? (
          <BillingCreditCardForm
            {...props}
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            action={action}
            creditCard={creditCard}
            numberOfCards={creditCards.length}
          />
        ) : null}
      </div>
    </div>
  );
}

BillingCreditCards.propTypes = {
  creditCards: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default BillingCreditCards;
