import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsVolumeOrderCustomer(props) {
  const { reportDetails } = props;

  function percentageAvg(v) {
    // This math is wrong.
    const filteredVals = v.values.filter((val) => val !== 0);

    if (filteredVals.length === 0) {
      return 0;
    }

    const add = (a, b) => a + b;

    const filteredSum = filteredVals.reduce(add);

    return filteredSum / filteredVals.length;
  }

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      headerName: 'Organization',
      width: 200,
      sort: 'asc',
    },
    columnDefs: [
      {
        field: 'organization',
        headerName: 'Organization',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Location',
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 120,
        filter: 'agTextColumnFilter',
        hide: reportDetails.reportType === 'Reporting::VolumeOrderDesigner',
      },
      {
        field: 'orders',
        headerName: 'Designed Orders',
        minWidth: 120,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
        aggFunc: 'sum',
      },
      {
        field: 'nonDesignedOrders',
        headerName: 'Non-Designed Orders',
        minWidth: 120,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
        aggFunc: 'sum',
      },
      {
        field: 'totalOrders',
        headerName: 'Total Orders',
        minWidth: 120,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
        aggFunc: 'sum',
      },
      {
        field: 'percentageDesigned',
        headerName: '% Designed',
        minWidth: 120,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
        aggFunc: percentageAvg,
        valueFormatter: (p) => {
          if (
            typeof p.value !== 'number' &&
            typeof p.value.value === 'number'
          ) {
            return `${p.value.value.toFixed(2)}%`;
          }

          return `${typeof p.value === 'number' ? p.value.toFixed(2) : 0}%`;
        },
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsVolumeOrderCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsVolumeOrderCustomer;
