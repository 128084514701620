import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Info } from '../../Info';
import FormErrors from './FormErrors';
import testForErrors from './testForErrors';

function FieldContainer({
  children,
  name,
  label = null,
  style = {},
  hideLabel = false,
  icon = false,
  iconName = null,
  isGroup = false,
  hasAddons = false,
  addOnLeft = null,
  addOnLabel = null,
  addOnRight = null,
  required = false,
  flex = false,
  classNames = '',
  size = '',
  infoCallback = null,
}) {
  const { t } = useTranslation('formfields');

  const { errors, touched } = useFormikContext();

  const isSignUp = import.meta.env.VITE_APP_NAME === 'signup_portal';

  return (
    <div
      className={`field ${classNames}${size}${isGroup ? 'is-grouped' : ''}${
        hasAddons ? 'has-addons' : ''
      }${testForErrors(errors, touched, name) ? 'error' : ''}`}
      style={style}
      {...(isSignUp
        ? {
            'data-required': required,
            'data-name': name,
          }
        : {})}
    >
      <div
        className={`columns is-gapless ${
          size === 'is-small' ? 'mb-0' : 'mb-2'
        }`}
        style={{ width: '100%' }}
      >
        {!hideLabel && (
          <div className="column is-flex">
            <label
              className={`label ${size}`}
              htmlFor={name}
              style={{ textTransform: 'capitalize' }}
            >
              {t(label || name)}
              {required && <span className="has-text-danger">*</span>}
            </label>
            {infoCallback && (
              <Info
                dataTooltip="Click to learn more"
                className="has-tooltip-arrow has-tooltip-bottom"
                infoCallback={infoCallback}
              />
            )}
          </div>
        )}
        {addOnLabel && <div className="column">{addOnLabel}</div>}
      </div>
      {addOnLeft}
      <div
        className={`control is-expanded ${flex ? 'is-flex' : ''}${
          icon && iconName ? 'has-icons-left' : ''
        }`}
      >
        {children}
        {icon && iconName && (
          <span className="icon is-small is-left">
            <i className={`${iconName}`} />
          </span>
        )}
      </div>
      {addOnRight}

      <FormErrors name={name} />
    </div>
  );
}

FieldContainer.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  hideLabel: PropTypes.bool,
  icon: PropTypes.bool,
  iconName: PropTypes.string,
  isGroup: PropTypes.bool,
  required: PropTypes.bool,
  flex: PropTypes.bool,
  hasAddons: PropTypes.bool,
  addOnLeft: PropTypes.node,
  addOnLabel: PropTypes.node,
  addOnRight: PropTypes.node,
  classNames: PropTypes.string,
  size: PropTypes.string,
  infoCallback: PropTypes.func,
};

export default FieldContainer;
