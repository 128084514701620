import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { classesGenerator } from '../../../../../../transforms';
import {
  FieldContainer,
  FormGridWrap,
  withFormWrap,
} from '../../../../../shared';
import { Steps } from '../../../../../shared/Components';
import { LocationDesignGuideSelect } from '../../components';
import { create, initialValues, schema } from './helpers';
import { useApolloClient } from '@apollo/client';

function LocationGuideGroupModal({
  modal,
  setModal,
  initialValues = () => ({}),
  schema = () => ({}),
  getVariables = () => ({}),
  skipQuery = () => ({}),
  match = {},
  ...rest
}) {
  const client = useApolloClient();
  const modalClasses = useMemo(
    () =>
      classesGenerator({
        'is-active': modal,
        modal: true,
      }),
    [modal],
  );

  const onChange = (data, category, values, setFieldValue) => {
    const { guideCodeIds } = values;
    if (category.showDropdown) {
      category.guideCodes.map((item) => {
        guideCodeIds[item.id] = false;
        return null;
      });
    }

    guideCodeIds[data.id] = !guideCodeIds[data.id];

    setFieldValue('guideCodeIds', guideCodeIds);
  };
  function handleSubmit(values, { setSubmitting }) {
    const { guideCodeIds } = values;
    const codeIds = [];
    for (const key of Object.keys(guideCodeIds)) {
      if (guideCodeIds[key]) {
        codeIds.push(key);
      }
    }

    const variables = {
      variables: {
        input: {
          input: {
            locationSlug: match.params.slug,
            name: values.name,
            guideCodeIds: codeIds,
          },
          clientMutationId: uuidv4(),
        },
      },
    };

    create({ variables, client, ...rest, setModal });
    setSubmitting(false);
  }
  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, submitForm, setFieldValue }) => (
        <FormGridWrap match={match} hideHeader>
          <div className={modalClasses}>
            <div
              className="modal-background"
              onClick={() => setModal(false)}
              onKeyDown={() => setModal(false)}
              role="button"
              tabIndex={0}
              aria-label="close"
            />
            <div
              className="modal-card"
              style={{ maxWidth: 1100, width: '100%', padding: '100px 0' }}
            >
              <section className="modal-card-body pt-0 pb-0">
                <Steps
                  stepsBorder
                  lastButtonText="Create"
                  callback={submitForm}
                  modal={modal}
                  setModal={setModal}
                >
                  <Steps.Step title="Create new group" validate={['name']}>
                    <FieldContainer required name="Group name">
                      <Field
                        name="name"
                        className="input"
                        type="string"
                        placeholder="Name..."
                      />
                    </FieldContainer>
                  </Steps.Step>
                  <Steps.Step title="Add codes to the group">
                    <LocationDesignGuideSelect
                      onChange={(data, category) => {
                        onChange(data, category, values, setFieldValue);
                      }}
                      selectedCodes={values.guideCodeIds}
                      noOther
                    />
                  </Steps.Step>
                </Steps>
              </section>
            </div>
          </div>
        </FormGridWrap>
      )}
    </Formik>
  );
}

LocationGuideGroupModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.shape({
    guideCodeIds: PropTypes.object,
  }).isRequired,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  LocationGuideGroupModal,
);
