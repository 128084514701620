import { gql } from '@apollo/client';
import { ErrorMessageInfo, FeedbackCategoryInfo } from '../fragments';

export default gql`
  mutation updateFeedbackCategory($input: FeedbackCategoryUpdateInput!) {
    updateFeedbackCategory(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      feedbackCategory {
        ...FeedbackCategoryInfo
      }
    }
  }
  ${FeedbackCategoryInfo}
  ${ErrorMessageInfo}
`;
