import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { checkPermissions, statusUpdate } from '../helpers';
import MessageModal from './MessageModal';

function ActionWithMessage({
  selectedCases,
  messageTitle,
  actionTitle,
  statusAction = null,
  messagePrefix = null,
  order = null,
  label = '',
  redesignFeedback = false,
  placeholder = '',
  ...rest
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const client = useApolloClient();
  const props = {
    selectedCases,
    messageTitle,
    actionTitle,
    statusAction,
    messagePrefix,
    order,
    label,
    redesignFeedback,
    placeholder,
    ...rest,
  };
  function changeMessage(e) {
    setMessage(e.target.value);
  }

  function updateStatus() {
    let completeMessage = message;
    if (messagePrefix) {
      completeMessage = `${messagePrefix} ${completeMessage}`;
    }
    if (selectedCases && selectedCases.length > 0) {
      for (const selectedOrder of selectedCases) {
        statusUpdate(
          client,
          statusAction,
          selectedOrder.data.id,
          completeMessage,
          null,
          selectedCases.length,
          selectedOrder.data.state,
        );
      }
    }
    if (order && selectedCases.length === 0) {
      statusUpdate(client, statusAction, order.id, completeMessage);
    }
    setModalOpen(false);
  }

  return checkPermissions(props) ? (
    <MessageModal
      modalOpen={modalOpen}
      redesignFeedback={redesignFeedback}
      openModal={() => setModalOpen(true)}
      closeModal={() => setModalOpen(false)}
      updateStatus={updateStatus}
      messageTitle={messageTitle}
      actionTitle={actionTitle}
      selectedCases={selectedCases}
      label={label}
      placeholder={placeholder}
      changeMessage={changeMessage}
      statusAction={statusAction}
      message={message}
      autoFocus={false}
      order={order}
    />
  ) : null;
}

ActionWithMessage.propTypes = {
  selectedCases: PropTypes.array.isRequired,
  statusAction: PropTypes.string,
  actionTitle: PropTypes.string.isRequired,
  messageTitle: PropTypes.string.isRequired,
  redesignFeedback: PropTypes.bool,
  messagePrefix: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  order: PropTypes.object,
};

export default ActionWithMessage;
