import { CREATE_CREDIT_TRANSACTION } from '@fullcontour/shared-api';

async function create({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  refetch,
  closeModal,
}) {
  const result = await client.mutate({
    mutation: CREATE_CREDIT_TRANSACTION,
    variables,
  });
  const { createCreditTransaction } = result.data;
  if (createCreditTransaction.errorMessages.length > 0) {
    setErrorMessages(createCreditTransaction.errorMessages);
  } else {
    clearErrorMessages();
    refetch();
    closeModal();
  }
}
export default create;
