import { useApp } from '@fullcontour/hooks';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FileDropzoneOrderFile } from '../../../../../shared';

function FilesUpload({
  selectedFile = null,
  selectedFolder = null,
  order,
  refetch,
}) {
  const { isCustomer, isDesigner, isAdmin, isManuf } = useApp();

  const getFileType = useCallback(() => {
    if (selectedFile) {
      return selectedFile.fileType;
    }

    return selectedFolder.fileType;
  }, [selectedFile, selectedFolder]);

  const [fileType, setFileType] = useState(getFileType());
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  useEffect(() => {
    const updatedFileType = getFileType();
    setFileType(updatedFileType);
  }, [getFileType]);

  const setAcceptTypes = () => {
    if (fileType === 'quality_control_images') {
      return {
        'image/*': ['.jpeg', '.png', '.jpg'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
        'image/png': ['.png'],
      };
    }

    if (fileType === 'stl_files' || fileType === 'visualization_output') {
      return {
        'model/stl': ['.stl'],
      };
    }

    if (fileType === 'attachments') {
      return {
        'application/pdf': ['.pdf'],
        'video/quicktime': ['.mov'],
        'video/mp4': ['.mp4'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
        'image/png': ['.png'],
        'application/zip': ['.zip'],
        'application/cam5zip': ['.cam5zip'],
        'application/json': ['.json'],
      };
    }

    if (fileType === 'converted_treatment_review_files') {
      return {
        'model/obj': ['.obj'],
      };
    }

    if (fileType === 'obj_files') {
      return {
        'model/obj': ['.obj'],
        'model/mtl': ['.mtl'],
      };
    }

    if (fileType === 'treatment_review_files') {
      return {
        'text/plain': ['.diff'],
        'application/dicom': ['.dcm'],
      };
    }

    return {
      'application/zip': ['.zip'],
      'application/order': ['.order'],
      'application/lab': ['.lab'],
    };
  };

  return (isCustomer &&
    fileType !== 'stl_files' &&
    fileType !== 'visualization_output') ||
    isDesigner ||
    isAdmin ||
    isManuf ? (
    <div style={{ height: '100%' }}>
      <FileDropzoneOrderFile
        updateUploadProgress={setUploadPercentage}
        setUploading={() => setUploading(true)}
        setUploadingOff={() => setUploading(false)}
        uploading={uploading}
        orderFileType={fileType}
        accept={setAcceptTypes()}
        order={order}
        multiple={
          fileType === 'quality_control_images' ||
          fileType === 'stl_files' ||
          fileType === 'attachments' ||
          fileType === 'obj_files' ||
          fileType === 'converted_treatment_review_files'
        }
        refetch={refetch}
      />
      {uploading && (
        <progress
          value={uploadPercentage}
          max="100"
          className="progress is-small is-success"
        >
          Uploading
        </progress>
      )}
    </div>
  ) : null;
}

FilesUpload.propTypes = {
  selectedFile: PropTypes.object,
  selectedFolder: PropTypes.object,
  order: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default FilesUpload;
