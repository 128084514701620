import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { timestampFormatter } from '../../../../transforms';

function InvoiceSummary({ invoice, toDownload = false }) {
  const { invoiceItems } = invoice;
  const [totalOrders] = useState(invoiceItems.length);
  const [totalUnitsCount] = useState(() => {
    if (invoiceItems && invoiceItems.length > 0) {
      return invoiceItems
        .flatMap((item) =>
          item.invoiceItemCases.map((itemCase) =>
            Number.parseInt(itemCase.totalUnits, 10),
          ),
        )
        .reduce((a, b) => a + b);
    }

    return 0;
  });

  const jsx = (
    <>
      <div className="columns" id="invoiceSummary">
        <div className="column" style={{ paddingLeft: '1em' }}>
          <b>
            <span className="is-uppercase">#{invoice.hexInvoiceNumber}</span>
            <span> Summary</span>
          </b>
        </div>
      </div>
      <div className="columns">
        <div className="column" style={{ paddingLeft: '1em' }}>
          <table
            className="is-bordered is-striped is-fullwidth table"
            style={{
              fontSize: toDownload ? '10px' : '.75em',
              ...(toDownload ? { width: '660px' } : {}),
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    paddingLeft: '1em',
                    width: '18%',
                  }}
                >
                  Description
                </th>
                <th style={{ width: '42%' }}>Details</th>
                <th style={{ width: '25%' }}>Date</th>
                <th
                  style={{
                    width: '15%',
                    textAlign: 'right',
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingLeft: '1em' }}>
                  Invoice #
                  <span style={{ textTransform: 'uppercase' }}>
                    {invoice.hexInvoiceNumber}
                  </span>
                </td>
                <td>
                  {totalOrders} Orders, {totalUnitsCount} Units
                </td>
                <td>{timestampFormatter(invoice.invoicingDate, false)}</td>
                <td style={{ textAlign: 'right' }}>${invoice.totalPrice}</td>
              </tr>
              {invoice.totalDebits > 0 && (
                <tr>
                  <td style={{ paddingLeft: '1em' }}>Total Charges</td>
                  <td>-</td>
                  <td>-</td>
                  <td style={{ textAlign: 'right' }}>${invoice.totalDebits}</td>
                </tr>
              )}
              {invoice.totalCredits > 0 && (
                <tr>
                  <td style={{ paddingLeft: '1em' }}>Total Credits</td>
                  <td>-</td>
                  <td>-</td>
                  <td style={{ textAlign: 'right' }}>
                    (${invoice.totalCredits})
                  </td>
                </tr>
              )}
              {invoice.invoiceTransactions.length > 0 &&
                invoice.invoiceTransactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td style={{ paddingLeft: '1em' }}>Transaction</td>
                    <td>
                      {transaction.message}
                      {transaction.cardBilled
                        ? ` - ${transaction.cardBilled}`
                        : null}
                    </td>
                    <td>{timestampFormatter(transaction.createdAt, false)}</td>
                    <td style={{ textAlign: 'right' }}>
                      {transaction.paymentAmount > 0
                        ? `($${transaction.paymentAmount})`
                        : `$${transaction.paymentAmount}`}
                    </td>
                  </tr>
                ))}
              <tr>
                <td style={{ paddingLeft: '1em' }}>
                  <b>Invoice Balance</b>
                </td>
                <td> </td>
                <td> </td>
                <td style={{ textAlign: 'right' }}>
                  <b>${invoice.balanceDue}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  return toDownload ? (
    <div style={{ width: '210mm', marginRight: '20mm' }}>{jsx}</div>
  ) : (
    jsx
  );
}

InvoiceSummary.propTypes = {
  invoice: PropTypes.object.isRequired,
  toDownload: PropTypes.bool,
};

export default memo(InvoiceSummary);
