import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';

function ElementResizeListener(props) {
  const { onResize } = props;
  const rafRef = useRef(0);
  const objectRef = useRef(null);
  const onResizeRef = useRef(onResize);

  onResizeRef.current = onResize;

  const _onResize = useCallback((e) => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
    rafRef.current = requestAnimationFrame(() => {
      onResizeRef.current(e);
    });
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const onLoad = useCallback(() => {
    const obj = objectRef.current;
    if (obj?.contentDocument?.defaultView) {
      obj.contentDocument.defaultView.addEventListener('resize', _onResize);
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(
    () => () => {
      const obj = objectRef.current;
      if (obj?.contentDocument?.defaultView) {
        obj.contentDocument.defaultView.removeEventListener(
          'resize',
          _onResize,
        );
      }
    },
    [],
  );

  return (
    <object
      aria-label="a"
      onLoad={onLoad}
      ref={objectRef}
      tabIndex={-1}
      type="text/html"
      data="about:blank"
      title=""
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        pointerEvents: 'none',
        zIndex: -1,
        opacity: 0,
      }}
    />
  );
}

ElementResizeListener.propTypes = {
  onResize: PropTypes.func.isRequired,
};

export default ElementResizeListener;
