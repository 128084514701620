import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function ExpireComponent({ delay, children }) {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const time = setTimeout(() => {
      setVisible(false);
    }, delay);
    return () => {
      clearTimeout(time);
      setVisible(true);
    };
  }, [delay, pathname]);

  return visible ? children : null;
}

ExpireComponent.propTypes = {
  delay: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};
