import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { nextBillingDate } from '../../../../transforms';
import { getRoot } from '../../../../utils/functions';
import BasicDropdown from '../../../shared/FormFields/Dropdowns/BasicDropdown';
import BasicDropdownItem from '../../../shared/FormFields/Dropdowns/BasicDropdownItem';
import BillingChargeForm from './BillingChargeForm/BillingChargeForm';

function BillingChargesHeader({
  selectedItem = null,
  destroyDebit,
  refetch,
  openModal,
  statusUpdate,
  toggleApplied,
  toggleAppliedView,
  ...rest
}) {
  const props = {
    selectedItem,
    destroyDebit,
    refetch,
    openModal,
    statusUpdate,
    toggleApplied,
    toggleAppliedView,
    ...rest,
  };
  const app = import.meta.env.VITE_APP_NAME;

  const root = getRoot();

  const { t } = useTranslation('billing');

  return (
    <div className="columns">
      <div className="column">
        <button
          className="button is-small is-primary"
          type="button"
          onClick={toggleAppliedView}
        >
          {t(`View ${toggleApplied ? 'Unapplied' : 'Applied'}`, {
            ns: 'common',
          })}
        </button>
      </div>
      {root !== 'billing' && (
        <div
          className="column is-6 has-text-centered"
          style={{ verticalAlign: 'middle' }}
        >
          <p>
            <small>
              {t(
                `Charges will be applied toward the next invoice, scheduled for ${nextBillingDate()}`,
              )}
            </small>
          </p>
        </div>
      )}
      <div className="column is-flex is-flex-direction-row is-justify-content-flex-end">
        {app === 'admin_portal' && (
          <>
            <BillingChargeForm {...props} />
            <BasicDropdown
              contentClasses="is-pulled-right has-text-left is-align-content-center"
              hideIcon
              dropdownClasses="is-right"
              disabled={!selectedItem}
              buttonText="Actions"
            >
              <BasicDropdownItem
                itemText="Mark as Applied"
                tabIndex={0}
                clickHandler={() =>
                  statusUpdate('applied', selectedItem, refetch)
                }
                disabled={selectedItem && selectedItem.applied === true}
              />
              <BasicDropdownItem
                itemText="Edit Charge"
                clickHandler={() => openModal('Edit', selectedItem)}
                disabled={selectedItem && selectedItem.applied === true}
              />
              <BasicDropdownItem
                itemText="Delete Charge"
                clickHandler={() => destroyDebit(selectedItem, refetch)}
                disabled={selectedItem && selectedItem.applied === true}
              />
            </BasicDropdown>
          </>
        )}
      </div>
    </div>
  );
}

BillingChargesHeader.propTypes = {
  selectedItem: PropTypes.object,
  destroyDebit: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  statusUpdate: PropTypes.func.isRequired,
  toggleApplied: PropTypes.bool.isRequired,
  toggleAppliedView: PropTypes.func.isRequired,
};

export default BillingChargesHeader;
