import PropTypes from 'prop-types';
import { getRoot } from '../../../../../utils/functions';
import BoxIcon from '../../../../shared/Icons/BoxIcon';
import BillingCreditFormModal from './BillingCreditFormModal';

function BillingCreditForm({
  modalOpen,
  openModal,
  closeModal,
  action = null,
  billingType = 'Credit',
  ...rest
}) {
  const props = {
    modalOpen,
    openModal,
    closeModal,
    action,
    billingType,
    ...rest,
  };

  const root = getRoot();

  return (
    <>
      {root !== 'billing' && (
        <button
          type="button"
          className="button mr-3"
          onClick={() => openModal('New')}
        >
          <BoxIcon name="bx-plus-medical" />
          <span>New {billingType}</span>
        </button>
      )}

      <BillingCreditFormModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        {...props}
        action={action}
      />
    </>
  );
}

BillingCreditForm.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  billingType: PropTypes.string,
};

export default BillingCreditForm;
