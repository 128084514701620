import PropTypes from 'prop-types';

function Tooth4({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart ? teeth.includes(4) : upperArch;
  const variable = designType.toothChart ? 4 : 'upper';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[4]?.includes(guideCodeId);
  const className = `st2 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <g
      onClick={handleClick}
      role="button"
      tabIndex={0}
      aria-pressed={isActive}
      onKeyDown={(e) => e.key === 'Enter' && handleClick()}
    >
      <path
        className={className}
        d="M804.2 1228.2c-97.8 13.9-144.1 64.4-114.5 168.7 31.2 38.3 59.5 79.4 112.4 96.1 18.7-0.4 35.2 1.5 46.5 8.6 16.2 4.4 34.8 4.4 54.9 1.6 37.8-20.1 88.7-3.4 110-70.1 17.6-53.4 14.6-100.4-27.8-135.3C901.9 1227.6 855.8 1233.5 804.2 1228.2z"
      />
      <path
        className="st2"
        d="M828.1 1235.3c-12.4 14.4-23.9 27.9-19.3 43.4 -5.4 17.9-5.4 23.2-6.3 29.8 -7.7 14.2-8.2 17.9-8.5 21.1 0.3 24.7 4.4 30.2 8.4 36.6 11.2 32.7 0.9 39.6-5.8 50.7 -6.8 11-16.3 5.6-19.8 36.6"
      />
      <path
        className="st2"
        d="M900.8 1459.4c-47.5-16.4-50.5-41.7-42.1-69.1 -5-44.2 13-69.9 25.6-99.9"
      />
      <path className="st2" d="M927.9 1310.2c-19.7 2.4-39.4 3-59.2 12.5" />
      <path className="st2" d="M836.4 1281.8c21.5 18.5 28.2 33.2 35.1 48" />
      <path className="st2" d="M805 1455c9.1-9.3 25.8-23.1 53.6-43.5" />
      <path className="st2" d="M880.1 1492c37.3-25.4 87.2-4.1 103.7-80.8 2.9-24.4 19.6-53.3-18.7-109.5" />
    </g>
  );
}

Tooth4.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.number).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth4;
