import { gql } from '@apollo/client';

export default gql`
  query designTypesByPriceListsDropdown($labLocationIds: [ID!]!) {
    designTypesByPriceLists(labLocationIds: $labLocationIds) {
      id
      name
      value
    }
  }
`;
