import { Mutation } from '@apollo/client/react/components';
import { CREATE_SIGNED_LINK } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { timestampFormatter } from '../../../../transforms';
import CadFileDestroy from './CadFileDestroy';

function CadFilesListDataTable({ cadFiles = [] }) {
  const app = import.meta.env.VITE_APP_NAME;
  const { slug } = useParams();

  const { t } = useTranslation('common');

  return cadFiles?.length > 0 ? (
    <table className="is-bordered is-striped is-fullwidth is-hoverable table">
      <thead>
        <tr>
          <th>{t('File')}</th>
          <th>{t('Uploaded')}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {cadFiles.map((cadFile) => (
          <tr key={cadFile.id}>
            <td>
              <Mutation
                mutation={CREATE_SIGNED_LINK}
                onCompleted={({
                  createSignedS3Link: {
                    signedS3Link: { url },
                  },
                }) => {
                  const a = document.createElement('a');
                  a.href = url;
                  const fileName = cadFile.filename;
                  a.download = fileName;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                  a.remove();
                }}
              >
                {(createKey) => (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      createKey({
                        variables: {
                          input: {
                            input: { s3Key: cadFile.s3Key },
                            clientMutationId: uuidv4(),
                          },
                        },
                      });
                    }}
                  >
                    <button type="submit" className="buttonAsLink">
                      {cadFile.filename}
                    </button>
                  </form>
                )}
              </Mutation>
            </td>
            <td>{timestampFormatter(cadFile.createdAt, false)}</td>
            <td>
              <CadFileDestroy cadFileId={cadFile.id} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <article className="message is-info mt-3">
      <div className="message-body is-flex is-align-items-center p-4">
        <span className="icon is-large mr-4">
          <i className="bx bx-info-circle is-size-1" />
        </span>

        <div>
          <h6 className="title is-6 has-text-link mb-1">
            {t('No DME Files uploaded')}
          </h6>
          <p>
            {t('Please add a')}
            <Link
              to={
                app === 'admin_portal'
                  ? `/locations/customer/${slug}/dme-files/new`
                  : '/locations/dme-files/new'
              }
            >
              {t('New DME File')}
            </Link>
          </p>
        </div>
      </div>
    </article>
  );
}

CadFilesListDataTable.propTypes = {
  cadFiles: PropTypes.array,
};

export default CadFilesListDataTable;
