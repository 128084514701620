import { UserShowNavMenu, UsersListNavMenu } from '@fullcontour/common';

const routes = [
  {
    path: '/users',
    component: UsersListNavMenu,
    so: true,
    sl: true,
  },
  {
    path: '/users/:id',
    component: UserShowNavMenu,
    so: true,
    sl: true,
  },
];

export default routes;
