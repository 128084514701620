import bulmaCollapsible from '@creativebulma/bulma-collapsible';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import BoxIcon from '../../Icons/BoxIcon';

function AccordionCard({
  children,
  index,
  title = '',
  titleNode = null,
  defaultState = undefined,
  titleExtra = null,
  titleExtraClasses = '',
  hideTitle = false,
  headerClasses = '',
  containerClasses = '',
  onToggle = () => null,
  bodyClasses = '',
  hideToggleButton = false,
  titleClasses = '',
}) {
  const openBtn = useRef(null);
  const collapsibles = useRef(null);

  const [open, setOpen] = useState(defaultState);

  useEffect(() => {
    bulmaCollapsible.attach('.is-collapsible', {
      container: collapsibles?.current,
    });
  }, []);

  const toggle = () => {
    if (onToggle) {
      onToggle();
    }
    setOpen((prev) => !prev);
  };

  return (
    <div className={`card ${containerClasses}`} ref={collapsibles}>
      <header className={`card-header ${headerClasses}`}>
        {!hideTitle && title && (
          <p className={`card-header-title mr-5 ${titleClasses}`}>{title}</p>
        )}
        {titleNode && <div className="card-header-title">{titleNode}</div>}
        {titleExtra && (
          <div className={`card-header-icon ${titleExtraClasses}`}>
            {titleExtra}
          </div>
        )}
        <a
          onClick={() => toggle}
          ref={openBtn}
          href={`#collapsible-card-${index}`}
          data-action="collapse"
          className="card-header-icon is-justify-content-flex-end"
          aria-label="more options"
          style={hideToggleButton ? { display: 'none' } : {}}
        >
          <BoxIcon size="large" name={`bxs-${!open ? 'up' : 'down'}-arrow`} />
        </a>
      </header>
      <div
        id={`collapsible-card-${index}`}
        className={`is-collapsible ${open ? 'is-active' : ''}`}
      >
        <div className={`card-content ${bodyClasses}`}>{children}</div>
      </div>
    </div>
  );
}

AccordionCard.propTypes = {
  title: PropTypes.string,
  titleNode: PropTypes.node,
  titleExtra: PropTypes.node,
  titleExtraClasses: PropTypes.string,
  hideTitle: PropTypes.bool,
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  defaultState: PropTypes.bool,
  headerClasses: PropTypes.string,
  containerClasses: PropTypes.string,
  onToggle: PropTypes.func,
  bodyClasses: PropTypes.string,
  hideToggleButton: PropTypes.bool,
  titleClasses: PropTypes.string,
};

export default AccordionCard;
