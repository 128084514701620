import { useQuery } from '@apollo/client';
import { GET_INTEGRATIONS } from '@fullcontour/shared-api';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { CurrentUserContext, LoadingOrError } from '../../../shared';
import IntegrationsListDataTable from './IntegrationsListDataTable';

function IntegrationsListQueryWrapper(props) {
  const { slug, root } = useParams();

  const { currentUser } = useContext(CurrentUserContext);
  const app = import.meta.env.VITE_APP_NAME;
  let variables = {};

  if (
    (currentUser.scopeToLocation && app === 'customer_portal') ||
    app === 'manuf_portal'
  ) {
    variables = { root };
  } else {
    variables = { root, slug };
  }

  const { error, loading, data } = useQuery(GET_INTEGRATIONS, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Integrations" />
    );
  }

  return <IntegrationsListDataTable data={data} {...props} />;
}

export default IntegrationsListQueryWrapper;
