import { graphql } from '@apollo/client/react/hoc';
import { CREATE_GUIDE_CODE_GROUP } from '@fullcontour/shared-api';

const withCreate = graphql(CREATE_GUIDE_CODE_GROUP, {
  name: 'create',
  options: (props) => ({
    onCompleted: (data) => {
      const { createGuideCodeGroup } = data;
      if (createGuideCodeGroup.errorMessages.length > 0) {
        props.setErrorMessages(createGuideCodeGroup.errorMessages);
      } else {
        props.clearErrorMessages(true); // disable notification
        props.setModal(false);
      }
    },
  }),
});

export default withCreate;
