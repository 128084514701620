import PropTypes from 'prop-types';

import ResultsTypes from './ResultTypes';
import ResultsActions from './ResultsActions';

function Results(props) {
  const {
    setResults,
    formikProps: { resetForm },
    newReportValues,
    results,
  } = props;

  return (
    <div>
      <div className="columns gridActionsRow">
        <div className="column has-text-right">
          <ResultsActions
            setResults={setResults}
            resetForm={resetForm}
            newReportValues={newReportValues}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <ResultsTypes results={results} />
        </div>
      </div>
    </div>
  );
}

Results.propTypes = {
  setResults: PropTypes.func.isRequired,
  formikProps: PropTypes.object.isRequired,
  newReportValues: PropTypes.object.isRequired,
  results: PropTypes.object.isRequired,
};

export default Results;
