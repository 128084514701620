const MOVEMENT_SUFFIXES = {
  Angulation: { negative: 'D', positive: 'M' },
  LeftRight: { negative: 'D', positive: 'M' },
  Inclination: { negative: 'L', positive: 'B' },
  ForwardBackward: { negative: 'L', positive: 'B' },
  IntrusionExtrusion: { negative: 'E', positive: 'I' },
  Rotation: { negative: 'M', positive: 'D' },
};

function transformValue(type, value) {
  const absVal = Math.abs(value).toFixed(2);
  const suffixes = MOVEMENT_SUFFIXES[type];
  return suffixes
    ? `${absVal}${value < 0 ? suffixes.negative : suffixes.positive}`
    : absVal;
}

function bodyTableDataGenerate(allTeethData) {
  const initialState = Object.keys(MOVEMENT_SUFFIXES).reduce((acc, key) => {
    acc[key] = new Array(32).fill(null);
    return acc;
  }, {});

  return allTeethData.reduce((obj, item) => {
    if (!item) {
      return obj;
    }

    const movementValue = item.Movement?.Value?.MovementValues?.Value;
    const movementKeys = Object.keys(obj);

    for (const key of movementKeys) {
      obj[key][item.Unn - 1] =
        Object.keys(item).length > 0
          ? transformValue(key, movementValue ? movementValue[key] : 0)
          : null;
    }

    return obj;
  }, initialState);
}

export default bodyTableDataGenerate;
