import { UPDATE_ORDER } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
}) {
  const result = await client.mutate({
    mutation: UPDATE_ORDER,
    variables,
  });
  const { updateOrder } = result.data;
  if (updateOrder.errorMessages.length > 0) {
    setErrorMessages(updateOrder.errorMessages);
  } else {
    clearErrorMessages();
  }
}

export default update;
