import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import {
  Accordion,
  GuideCodeCard,
  Placeholder,
  SearchResults,
} from '../../../../../shared';

import { useDeleteItems } from './hooks';
import { searchHandler } from './utils';

import { LocationDesignGuideSelect } from '../../components';
import LocationGuideGroupsBodyAddModal from './LocationGuideGroupsBodyAddModal';

function LocationGuideGroupsBody({ groupData, editMode, searchValue = null }) {
  const [modal, setModal] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState(null);

  const { deleteCard, deleteGroup } = useDeleteItems();

  const { data, loading } = groupData;

  const { values } = useFormikContext();

  const handleAddItems = useCallback((id, name) => {
    setSelectedGroupId(id);
    setSelectedGroupName(name);
    setModal(true);
  }, []);

  if (loading || !data) {
    return (
      <div className="is-flex is-flex-direction-column">
        {[...new Array(3).keys()].map((item) => (
          <Placeholder height={60} className="mb-2" key={item} />
        ))}
      </div>
    );
  }

  const searchResults = searchValue
    ? searchHandler(data?.labLocationGuideCodeGroups, searchValue)
    : data?.labLocationGuideCodeGroups || [];

  const designGuidesData = {
    children:
      searchResults.length > 0
        ? searchResults
            .filter((item) => values?.selectedCodes[item.id])
            .map((item, key) => {
              const cardElements = item.guideCodes
                .filter(
                  (card) =>
                    values?.selectedCodes[item.id][card.guideCategory.id],
                )
                .map((guideCode) => {
                  const guideCodeData = {
                    ...guideCode,
                    category: guideCode.guideCategory,
                    guideCategoryGroupId:
                      guideCode.guideCategory.guideCategoryGroupId,
                  };

                  return (
                    <GuideCodeCard
                      key={guideCode.id}
                      image={<GuideCodeCard.Image />}
                      cardDropdown={<GuideCodeCard.Dropdown disabled />}
                      footer={
                        <GuideCodeCard.CardFooter
                          editMode={editMode}
                          removeCard={deleteCard}
                          groupdId={item.id}
                        />
                      }
                      data={guideCodeData}
                    />
                  );
                });

              const cardBody = editMode ? (
                <div key={`codes${key.toString()}`}>
                  <div
                    className="is-inline-flex is-align-items-center is-clickable is-primary button mb-5"
                    onClick={() => handleAddItems(item.id, item.name)}
                    onKeyDown={() => handleAddItems(item.id.item.name)}
                    role="button"
                    tabIndex={0}
                  >
                    Add codes <i className="bx bx-plus-circle is-size-4 ml-1" />
                  </div>

                  <div className="card__element-group">{cardElements}</div>
                </div>
              ) : (
                <div
                  key={`celement${key.toString()}`}
                  className="card__element-group"
                >
                  {cardElements}
                </div>
              );

              const accordionData = {
                cardHeader: item.name,
                cardId: item.id,
                editMode,
                cardBody,
                cardHeaderText:
                  cardElements.length === 0 ? (
                    <span className="tag ml-3">Empty</span>
                  ) : (
                    `${cardElements.length} codes`
                  ),
                callback: deleteGroup,
              };

              if (searchValue || editMode) {
                accordionData.isActive = true;
              }

              return accordionData;
            })
        : [],
  };

  return (
    <>
      <section>
        <SearchResults
          isNoValue={designGuidesData?.children.length === 0}
          searchValue={searchValue}
          apologiesText="Sorry, we could not find any groups / codes with this name"
        >
          <Accordion data={designGuidesData} searchValue={searchValue} />
        </SearchResults>
      </section>

      {modal && (
        <LocationGuideGroupsBodyAddModal
          modal={modal}
          setModal={setModal}
          groupId={selectedGroupId}
          groups={data.labLocationGuideCodeGroups}
          groupName={selectedGroupName}
        >
          <LocationDesignGuideSelect
            noOther
            filterSelected
            groupId={selectedGroupId}
          />
        </LocationGuideGroupsBodyAddModal>
      )}
    </>
  );
}

LocationGuideGroupsBody.propTypes = {
  groupData: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  searchValue: PropTypes.string,
};

export default LocationGuideGroupsBody;
