import PropTypes from 'prop-types';

function Tooth19({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 19)
    : lowerArch;
  const variable = designType.toothChart ? 19 : 'lower';
  const handleClick = designType.toothChart ? toggleTooth : toggleArch;

  const isSelected = nestedTeeth[19]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <g
      onClick={() => handleClick(variable)}
      role="button"
      tabIndex={0}
      aria-pressed={isActive}
      onKeyDown={(e) => e.key === 'Enter' && handleClick(variable)}
    >
      <path
        className={className}
        d="M2826.6 3621.6c-20.1-6.3-42.3 5.9-64.6 20l-30.1 34.6c-21.2 20.2-30.1 40.3-28.3 60.4 1.9 35.1-7.4 70.1-30.8 105.3 -14.9 43.1-24.7 84.7 1.2 116.4 15.8 28.7 35 56.1 73.5 75.7 40.2 16.6 75.7 39.2 124.3 45.4 31.7 2.5 57.5 12.9 108.6-10.6l69.7-46.7c11.7-8.6 22.9-13.4 41.2-66.5 6-49.3 29-96.6 1.3-150l-44.2-101.6c-20.9-26.1-34.8-54.8-79.5-72.2 -26.1-8.4-43.5-21.5-93.1-17L2826.6 3621.6z"
      />
      <path
        className="st1"
        d="M2756.1 3670.1c-10.6 22.2-27.2 44.3-27.4 66.5 -5.4 44.7-19.8 75.8-34.2 107 -8.4 21.8-21.4 37.9-10.1 83.6 8 28.7 15.5 57.6 57.1 70.5l71.6 31.7c20.4 9.6 48.1 8.2 77.6 4.1 28.7-10.3 57.4-9.9 86.1-50.2 6.2-12.1 9.8-29.2 6.7-59.5"
      />
      <path
        className="st1"
        d="M2997.2 3901.3c13.8 0.2 24.5-4.5 22.3-29.4 -3.2-47.2 3.3-95.4-21.1-140.5 0-26.2 1.7-53.5-18.3-66.3 -26.4-19.3-39-52.4-94.9-42 -27.6 6.1-45.9 6.4-55.2 1"
      />
      <path
        className="st1"
        d="M2831.9 3675c10.3 25.9 16.6 50.3 41.6 81.8 -10.2 33.7-24.8 45.8-39.5 57 -3.7 9.5-9.6 4.9-6.8 55.2 -3.6 48.3-35.1 64.9-53.2 96.7"
      />
      <path
        className="st1"
        d="M2937.7 3890.3c-36.3-17.7-72.5-41.1-108.7-32.5"
      />
      <path
        className="st1"
        d="M2738.3 3789.9c26.6 41.1 59 38.3 89.8 47.2"
      />
      <path
        className="st1"
        d="M2791.6 3733.8c21.4 24.1 42.6 48.3 41.6 79.2"
      />
      <path
        className="st1"
        d="M2979.5 3715.2c-24.6 29.1-58.3 45.5-105.9 42.5"
      />
    </g>
  );
}

Tooth19.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth19;
