import { DESTROY_ORDER_FILE } from '@fullcontour/shared-api';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../../../../../config/log';
import { orderTypesHandler } from '../../../../../OrderActions/helpers';
import './ElementActions.css';
import ElementActionsModal from './ElementActionsModal';

function ElementActions({
  client,
  selectedFolder = null,
  selectedFile = null,
  refetch,
  setSelectedToDefault,
  order,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const { t } = useTranslation('caseFiles');

  function modalOpen() {
    setModalVisible(true);
  }

  function modalClose() {
    setModalVisible(false);
  }

  function confirmOpen() {
    setConfirmVisible(true);
  }

  function confirmClose() {
    setConfirmVisible(false);
  }

  async function deleteFile() {
    await client.mutate({
      mutation: DESTROY_ORDER_FILE,
      variables: {
        input: {
          id: selectedFile.id,
          clientMutationId: uuidv4(),
        },
      },
    });

    setSelectedToDefault();
    confirmClose();
    refetch();
  }

  function downloadFile(file) {
    if (file.fileType !== 'quality_control_images') {
      axios
        .get(file.signedUrl, {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'binary/octet-stream',
          },
        })
        .then((response) => {
          const u = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = u;
          link.setAttribute('download', file.originalFileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(file.signedUrl);
          link.remove();
        })
        .catch((error) => Log.error(error));
    } else {
      const link = document.createElement('a');
      link.href = file.signedUrl;
      link.setAttribute('download', file.originalFileName);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(file.signedUrl);
      link.remove();
    }
  }

  function triggerDownload(e) {
    e.preventDefault();

    if (selectedFile) {
      downloadFile(selectedFile);
    }

    if (selectedFolder) {
      for (const file of selectedFolder.selectedItems) {
        downloadFile(file);
      }
    }
  }

  const hideDownloadStl = useMemo(
    () =>
      (import.meta.env.VITE_APP_NAME !== 'admin_portal' &&
        orderTypesHandler(order) &&
        selectedFile?.originalFileName?.endsWith('.stl')) ||
      (import.meta.env.VITE_APP_NAME !== 'admin_portal' &&
        orderTypesHandler(order) &&
        selectedFolder?.fileType === 'stl_files'),
    [order, selectedFile, selectedFolder],
  );

  return selectedFile || selectedFolder ? (
    <div className="mb-3">
      {!hideDownloadStl && (
        <button
          type="button"
          className="button is-small is-primary has-tooltip-arrow has-tooltip-left"
          onClick={triggerDownload}
          data-tooltip={
            selectedFile
              ? `${t('Download')} ${selectedFile.originalFileName}`
              : `${t('Download all')} ${selectedFolder.fileType.replace(
                  /_/g,
                  ' ',
                )}`
          }
        >
          <span className="icon is-small">
            <i className="bx bxs-download" />
          </span>
        </button>
      )}

      {selectedFolder && selectedFolder.fileType === 'obj_files' && (
        <button
          type="button"
          className="button is-small is-primary has-tooltip-arrow has-tooltip-left ml-2"
          onClick={modalOpen}
          data-tooltip="Smile Summary"
        >
          <span className="icon is-small">
            <i className="bx bx-expand" />
          </span>
        </button>
      )}

      {selectedFolder &&
        selectedFolder.fileType === 'quality_control_images' && (
          <button
            type="button"
            className="button is-small is-primary has-tooltip-arrow has-tooltip-left ml-2"
            onClick={modalOpen}
            data-tooltip="Expand fullscreen"
          >
            <span className="icon is-small">
              <i className="bx bx-expand" />
            </span>
          </button>
        )}
      {selectedFile &&
        (selectedFile.fileType === 'quality_control_images' ||
          selectedFile.fileType === 'stl_files' ||
          selectedFile.fileType === 'visualization_output') && (
          <button
            type="button"
            className="button is-small is-primary has-tooltip-arrow has-tooltip-left ml-2"
            onClick={modalOpen}
            data-tooltip="Expand fullscreen"
          >
            <span className="icon is-small">
              <i className="bx bx-expand" />
            </span>
          </button>
        )}
      {selectedFile &&
        (import.meta.env.VITE_APP_NAME === 'admin_portal' ||
          import.meta.env.VITE_APP_NAME === 'designer_portal') &&
        (selectedFile.fileType === 'quality_control_images' ||
          selectedFile.fileType === 'stl_files' ||
          selectedFile.fileType === 'visualization_output' ||
          selectedFile.fileType === 'attachments' ||
          selectedFile.fileType === 'obj_files' ||
          selectedFile.fileType === 'manufacturer_scans' ||
          selectedFile.fileType === 'finished_scans' ||
          (import.meta.env.VITE_APP_NAME !== 'designer_portal' &&
            selectedFile.fileType === 'designer_finished_package')) && (
          <button
            type="button"
            className="button is-small is-danger is-outlined has-tooltip-arrow has-tooltip-left ml-2"
            onClick={confirmOpen}
            data-tooltip="Delete File"
          >
            <span className="icon is-small">
              <i className="bx bx-trash" />
            </span>
          </button>
        )}

      <div className={`modal ${confirmVisible ? 'is-active' : ''}`}>
        <div className="modal-background" />

        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">File Delete</p>
            <button type="button" className="delete" aria-label="close" />
          </header>
          <div className="modal-card-body">
            Are you sure you want to delete
            {selectedFile ? selectedFile.originalFileName : null}
          </div>
          <footer className="modal-card-foot">
            <button
              type="button"
              className="button is-danger is-outlined"
              onClick={deleteFile}
            >
              Delete File
            </button>
            <button
              type="button"
              className="button is-danger is-outlined"
              onClick={confirmClose}
            >
              Cancel
            </button>
          </footer>
        </div>
      </div>
      <ElementActionsModal
        order={order}
        modalClose={modalClose}
        modalVisible={modalVisible}
        selectedFile={selectedFile}
        selectedFolder={selectedFolder}
      />
    </div>
  ) : null;
}

ElementActions.propTypes = {
  selectedFolder: PropTypes.object,
  selectedFile: PropTypes.object,
  client: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  setSelectedToDefault: PropTypes.func.isRequired,
};

export default ElementActions;
