import { CREATE_GUIDE_CODE_GROUP } from '@fullcontour/shared-api';

async function create({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  refetch,
  setModal
}) {
  const result = await client.mutate({
    mutation: CREATE_GUIDE_CODE_GROUP,
    variables,
  });
  const { createGuideCodeGroup } = result.data;
  if (createGuideCodeGroup.errorMessages.length > 0) {
    setErrorMessages(createGuideCodeGroup.errorMessages);
  } else {
    clearErrorMessages(true); // disable notification
    refetch();
    setModal(false);
  }
}
export default create;
