import PropTypes from 'prop-types';
import { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import { OrthoStlViewer, OutsideClick } from '../../../../../../../shared';
import ElementImage from '../ElementImage/ElementImage';
import './ElementActions.css';

function ElementActionsModal({
  selectedFolder = null,
  selectedFile = null,
  order: { files, name, hexOrderCode },
  modalClose,
  modalVisible,
}) {
  const [photoIndex] = useState(0);

  if (selectedFolder && selectedFolder.fileType === 'quality_control_images') {
    return (
      <Lightbox
        styles={{ container: { backgroundColor: 'rgba(80, 80, 80, 0.87)' } }}
        open={modalVisible}
        close={modalClose}
        slides={[
          ...selectedFolder.selectedItems.map((img) => ({
            src: img.signedUrl,
          })),
        ]}
        index={photoIndex}
      />
    );
  }

  return selectedFile || selectedFolder ? (
    <div className={`modal ${modalVisible ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <OutsideClick fn={modalClose}>
        <div
          className="modal-content"
          style={{ width: '94vw', height: 'calc(100vh - 40px)' }}
        >
          {selectedFile &&
            selectedFile.fileType === 'quality_control_images' && (
              <>
                <ElementImage image={selectedFile} />
                <p style={{ marginTop: '1em' }}>
                  {selectedFile.originalFileName}
                </p>
              </>
            )}

          {selectedFile && selectedFile.fileType === 'stl_files' && (
            <>
              <OrthoStlViewer
                file={selectedFile}
                allFiles={files}
                containerStyle={{ height: '90vh' }}
                orderName={name}
                hexOrderCode={hexOrderCode}
              />
              <p style={{ marginTop: '1em' }}>
                {selectedFile.originalFileName}
              </p>
            </>
          )}
        </div>
      </OutsideClick>
      <button
        className="modal-close is-large"
        aria-label="close"
        type="button"
        onClick={modalClose}
      />
    </div>
  ) : null;
}

ElementActionsModal.propTypes = {
  selectedFolder: PropTypes.object,
  selectedFile: PropTypes.object,
  order: PropTypes.object.isRequired,
  modalClose: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
};

export default ElementActionsModal;
