import { useAmplify } from '@fullcontour/auth';
import { AppHOC, CurrentUserProvider } from '@fullcontour/common';
import NavElements from './components/layouts/NavElements';
import { mainRoutes, rootPaths, sidebarRoutes } from './components/routing';
import navMenuList from './components/shared/OrdersNavMenu/navMenuList';

const routing = {
  mainRoutes,
  redirects: [],
  rootPaths,
  sidebarRoutes,
  navMenuList,
};

function App() {
  const values = useAmplify();
  return (
    <CurrentUserProvider
      value={{
        logOut: values.logOut,
        setCurrentUser: values.updateCurrentUser,
        currentUser: values.currentUser,
      }}
    >
      <AppHOC
        app={import.meta.env.VITE_APP_NAME}
        routing={routing}
        values={values}
      >
        <NavElements />
      </AppHOC>
    </CurrentUserProvider>
  );
}

export default App;
