import { flowRight } from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { BaseLoader, DropdownSelect } from '../../../shared';
import OrderActions from '../OrderActions';
import OrdersListGrid from './OrdersListGrid';
import './OrdersListGridContainer.css';
import { getRoot } from '../../../../utils/functions';
import DateRangeSelect from '../../../shared/FormFields/DateInputs/DateRangeSelect/index';
import OrdersListGridStateDropdown from './OrdersListGridStateDropdown';

class OrdersListGridContainer extends Component {
  state = {
    selectedCases: [],
    downloadPercentage: 0,
    downloading: false,
  };

  componentDidUpdate(prevProps) {
    const { orders, location } = this.props;
    if (orders === null && this.gridApi) {
      this.gridApi.setGridOption('loading', true);
    }

    if (orders !== null && orders.length === 0 && this.gridApi) {
      this.gridApi.showNoRowsOverlay();
    }

    if (orders !== null && orders.length > 0 && this.gridApi) {
      this.gridApi.setGridOption('loading', false);
    }

    if (location !== prevProps.location) {
      this.setSelectedCases([]);
    }
  }

  componentWillUnmount() {
    this.gridApi = undefined;
  }

  setDownloading = () => {
    this.setState({
      downloading: true,
    });
  };

  setDownloadingOff = () => {
    this.setState({
      downloading: false,
    });
  };

  updateDownloadProgress = (percentage) => {
    this.setState({ downloadPercentage: percentage });
  };

  getColumnStateFromLocalStorage = () => {
    const columnState = localStorage.getItem('agGridColumnState');
    return columnState ? JSON.parse(columnState) : null;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;

    this.setColumnsFromStorage();
  };

  setColumnsFromStorage = () => {
    const columnState = this.getColumnStateFromLocalStorage();

    if (columnState) {
      this.gridApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  };

  onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  onColumnVisible = ({ api }) => {
    localStorage.setItem(
      'agGridColumnState',
      JSON.stringify(api.getColumnState()),
    );
  };

  onDisplayedColumnsChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  onSelectionChanged = (event) => {
    this.setSelectedCases(event.api.getSelectedNodes());
  };

  setSelectedCases = (selectedCases) => {
    this.setState({
      selectedCases,
    });
  };

  onColumnMoved = ({ api }) => {
    localStorage.setItem(
      'agGridColumnState',
      JSON.stringify(api.getColumnState()),
    );
  };

  render() {
    const {
      gridOptions,
      orders,
      match: { params },
      selectedRange,
      t,
      focusedInput,
      startDate,
      endDate,
      onCalendarFocusChange,
      onCalendarDatesChange,
      selectedState,
      setSelectedState,
      loading,
      ...rest
    } = this.props;

    const { selectedCases, downloadPercentage, downloading } = this.state;

    return (
      <>
        <div className="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center mb-4">
          <h6
            className="title is-6 mb-0"
            style={{
              textTransform: 'capitalize',
            }}
          >
            {!params.status && (
              <span>
                {t(
                  `${selectedRange}${
                    selectedRange !== 'custom' ? "'s " : ' Range '
                  } Cases`,
                )}
              </span>
            )}

            {params.status && (
              <span>{t(params.status.replace(/_/g, ' '))}</span>
            )}
          </h6>
          <div style={{ flex: 1 }} />
          {downloadPercentage > 0 && downloading && (
            <progress
              className={` filesDownloadProgressprogress is-small mr-3 ${
                downloadPercentage === 100 ? 'is-success' : 'is-primary'
              }`}
              value={downloadPercentage}
              max="100"
            />
          )}
          {params.root === 'orders' && !params.status && (
            <Suspense fallback={<BaseLoader loading />}>
              <OrdersListGridStateDropdown
                setSelectedState={setSelectedState}
                selectedState={selectedState}
              />
              <DateRangeSelect
                focusedInput={focusedInput}
                startDate={startDate}
                endDate={endDate}
                onCalendarDatesChange={onCalendarDatesChange}
                onCalendarFocusChange={onCalendarFocusChange}
              />
              <div className="buttons has-addons mx-4 mb-0">
                <button
                  type="button"
                  className={`button is-small mb-0 ${
                    selectedRange === 'today' ? 'is-active' : ''
                  }`}
                  onClick={() =>
                    onCalendarDatesChange({
                      startDate: moment(),
                      endDate: moment(),
                      selectedRange: 'today',
                    })
                  }
                >
                  {t('Today')}
                </button>
                <button
                  type="button"
                  className={`button is-small mb-0 ${
                    selectedRange === 'yesterday' ? 'is-active' : ''
                  }`}
                  onClick={() =>
                    onCalendarDatesChange({
                      startDate: moment().subtract(1, 'day'),
                      endDate: moment().subtract(1, 'day'),
                      selectedRange: 'yesterday',
                    })
                  }
                >
                  {t('Yesterday')}
                </button>
                <button
                  type="button"
                  className={`button is-small mb-0 ${
                    selectedRange === 'last week' ? 'is-active' : ''
                  }`}
                  onClick={() =>
                    onCalendarDatesChange({
                      startDate: moment()
                        .subtract(1, 'week')
                        .startOf('isoWeek'),
                      endDate: moment().subtract(1, 'week').endOf('isoWeek'),
                      selectedRange: 'last week',
                    })
                  }
                >
                  {t('Last Week')}
                </button>
              </div>
            </Suspense>
          )}

          <DropdownSelect
            className="is-right"
            customOptions
            disabled={selectedCases.length === 0}
            trigger={
              <button type="button" className="button is-outlined is-small">
                <span>
                  {t('caseSelected', { count: selectedCases.length })}
                </span>
                <span className="icon is-small">
                  <i className="bx bx-chevron-down" />
                </span>
              </button>
            }
          >
            <OrderActions
              selectedCases={selectedCases}
              gridApi={this.gridApi}
              params={params}
              list
              details={false}
              downloadPercentage={downloadPercentage}
              updateDownloadProgress={this.updateDownloadProgress}
              setDownloading={this.setDownloading}
              setDownloadingOff={this.setDownloadingOff}
              {...rest}
            />
          </DropdownSelect>
        </div>

        <OrdersListGrid
          gridOptions={gridOptions}
          rowData={orders}
          loading={loading}
          onColumnMoved={this.onColumnMoved}
          onGridReady={this.onGridReady}
          onGridSizeChanged={this.onGridSizeChanged}
          onFirstDataRendered={this.onFirstDataRendered}
          onColumnVisible={this.onColumnVisible}
          onSelectionChanged={this.onSelectionChanged}
          onDisplayedColumnsChanged={this.onDisplayedColumnsChanged}
        />
      </>
    );
  }
}
const withParams =
  (WrappedComponent) =>
  ({ ...props }) => {
    const params = useParams();
    const root = getRoot();

    const location = useLocation();
    return (
      <WrappedComponent
        match={{ params: { ...params, root } }}
        location={location}
        {...props}
      />
    );
  };
OrdersListGridContainer.propTypes = {
  orders: PropTypes.array,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  gridOptions: PropTypes.object.isRequired,
  selectedRange: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  focusedInput: PropTypes.string,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object,
  onCalendarDatesChange: PropTypes.func.isRequired,
  onCalendarFocusChange: PropTypes.func.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  selectedState: PropTypes.number.isRequired,
};

OrdersListGridContainer.defaultProps = {
  selectedRange: 'today',
  orders: null,
  focusedInput: null,
  endDate: null,
};

export default flowRight(
  withTranslation('manual'),
  withParams,
)(OrdersListGridContainer);
