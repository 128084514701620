import PropTypes from 'prop-types';

function Tooth29({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart ? teeth.includes(29) : lowerArch;
  const variable = designType.toothChart ? 29 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));

  const isSelected = nestedTeeth[29]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <g
      onClick={handleClick}
      role="button"
      tabIndex={0}
      aria-pressed={isActive}
      onKeyDown={(e) => e.key === 'Enter' && handleClick()}
    >
      <path
        className={className}
        d="M953.5 4065.5c84.6 42.1 83.4 64.7 86 96.8 -6.4 38.4-4.7 52.7-4.5 71.5 1.1 39.5-23.5 49.9-39 70.6 -49.7 49.8-76.2 33.7-111.3 42 -32.3-5-51.9-9.9-70.7-14.8 -52.6-12.8-48-32.5-68-49.3 -25.6-34.3-27.8-78.2-14.3-128.5 11.1-29.9 33.6-52.3 57.1-74l57.8-29.2C891 4032.2 941.9 4057.4 953.5 4065.5z"
      />
      <path
        className="st1"
        d="M874.2 4062.7c-18.4 3.7-40.8 6.5-48.4 47.3 0.2 17.8-5.5 31.6-12.2 44.8 -8 42.6 12.9 70.8 31.6 100.1 14.5 24.5 32.5 27 49.9 32.9 19 10 33.3 3.3 43.2-18.9 6.1-12.6 7.3-31.3 21.6-33.6 6.5-11.4 7.3-21 7-30.2 -8.2-15.3-1.5-36.5 1-56 -33.5-1.7-41.7-28.5-45.5-59.6C906.8 4083.7 891.9 4081.6 874.2 4062.7z"
      />
      <path
        className="st1"
        d="M914.2 4250.8c19.8-18 19.5-49.5 19.2-81 -12.2-52.8-34.3-78-69.7-66.6"
      />
      <path
        className="st1"
        d="M952.2 4217.2c-1.6-27.1-9.8-39.2-20.6-45.7"
      />
      <path
        className="st1"
        d="M902.6 4087.8l2.5 24.1"
      />
    </g>
  );
}

Tooth29.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.number).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth29;
