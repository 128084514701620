import { useQuery } from '@apollo/client';
import { GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS } from '@fullcontour/shared-api';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Accordion, SearchField, SearchResults } from '../../../../shared';
import NonFormDropdown from '../../../../shared/FormFields/Dropdowns/NonFormDropdown';
import LocationCodeCard from './LocationCodeCard';
import searchHandler from './helpers/searchHandler';
import useDesignTypeSelection from './helpers/useDesignTypeSelection';
import useGetGuideCategoryGroups from './helpers/useGetGuideCategoryGroups';

function LocationDesignGuideSelect({
  filterSelected = false,
  noOther = false,
  groupId = null,
  onChange = undefined,
  selectedCodes = undefined,
}) {
  const { values } = useFormikContext() || {};

  const [guideCategoryGroups, setGuideCategoryGroups] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const { slug } = useParams();

  const { data, error } = useQuery(GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS, {
    fetchPolicy: 'network-only',
    variables: { slug },
  });

  const { t } = useTranslation('designProfile');

  const {
    designType,
    errorP,
    designTypeOptions,
    selectedDesignType,
    handleDesignType,
  } = useDesignTypeSelection({ data, setGuideCategoryGroups });

  useGetGuideCategoryGroups({
    selectedDesignType,
    filterSelected,
    values,
    data,
    groupId,
    setGuideCategoryGroups,
  });

  const searchResults = searchValue
    ? searchHandler(guideCategoryGroups, searchValue)
    : guideCategoryGroups || [];

  const designGuidesData = {
    children:
      searchResults?.length > 0
        ? searchResults.map((item) => {
            const cardBody = item.guideCategories.map((guideCategory) => (
              <LocationCodeCard
                key={guideCategory.id}
                guideCategory={guideCategory}
                guideCategoryGroupId={item.id}
                designType={designType}
                onChange={onChange}
                selectedCodes={selectedCodes}
                searchValue={searchValue}
                noOther={noOther}
              />
            ));

            const accordionData = {
              cardHeader: item.name,
              searchValue,
              cardHeaderText:
                item.guideCategories.length === 0 ? (
                  <span className="tag ml-3">Empty</span>
                ) : (
                  `${item.guideCategories.length} codes`
                ),
              cardBody: <div className="card__element-group">{cardBody}</div>,
            };

            if (searchValue) {
              accordionData.isActive = true;
            }

            return accordionData;
          })
        : [],
  };

  return (
    <>
      <div className="is-inline-block mb-6">
        <NonFormDropdown
          error={error || errorP}
          loading={!designTypeOptions}
          onChange={(e) => handleDesignType(e.target.value)}
          name="designType"
          value={selectedDesignType}
          options={designTypeOptions || []}
          placeholder="Design Type"
          label={t('Select Design Type')}
          selectClasses="mt-2"
        />
      </div>
      {selectedDesignType && (
        <SearchField
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      )}

      <div>
        {designType && (
          <SearchResults
            isNoValue={designGuidesData?.children.length === 0}
            searchValue={searchValue}
            apologiesText="Sorry, we could not find any categories / codes with this name"
          >
            <Accordion data={designGuidesData} />
          </SearchResults>
        )}
      </div>
    </>
  );
}

LocationDesignGuideSelect.propTypes = {
  filterSelected: PropTypes.bool,
  noOther: PropTypes.bool,
  groupId: PropTypes.string,
  onChange: PropTypes.func,
  selectedCodes: PropTypes.object,
};

export default LocationDesignGuideSelect;
