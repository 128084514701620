function useApp() {
  const app = import.meta.env.VITE_APP_NAME;

  return {
    app,
    isApproval: app.includes('doctor'),
    isApprovalLite: app === 'approval-doctor-lite',
    isPatient: app.includes('patient'),
    isPatientFull: app === 'approval-patient-full',
    isAdmin: app === 'admin_portal',
    isCustomer: app === 'customer_portal',
    isDesigner: app === 'designer_portal',
    isManuf: app === 'manufacturer_portal',
    isSignup: app === 'customer_signup',
  };
}

export default useApp;
