// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
function aggregateInterproximalReductions({
  correctItemCount,
  distalReductionValue,
  mesialReductionValue,
  distalReductionValueNext,
  mesialReductionValueNext,
  mesialReductionValueVisible,
  mesialReductionValueVisibleNext,
  isNextMissing,
  distalReductionValuePrev,
  mesialReductionValuePrev,
  isCurrentMissing,
}) {
  const borderStyle = '2px solid red';
  const style = {};
  let aggregatedValue;

  const setBorder = () => {
    style.borderRight = borderStyle;
  };
  const clearBorder = () => {
    style.borderRight = null;
  };

  const calculateAggregatedValue = (value1, value2) =>
    value1 && value2 ? value1 + value2 : value1 || value2;

  const applyAggregatedValue = (value) => {
    aggregatedValue = value ? Number.parseFloat(value.toFixed(2)) : null;
    return {
      style: aggregatedValue >= 0.05 ? style : null,
      aggregatedValue,
    };
  };

  const isUpperOrLowerLeftOrRight = (count) =>
    (count >= 1 && count <= 8) || (count >= 25 && count <= 32);

  // Main Logic
  if (
    isCurrentMissing &&
    isUpperOrLowerLeftOrRight(correctItemCount) &&
    mesialReductionValuePrev &&
    distalReductionValueNext
  ) {
    aggregatedValue = calculateAggregatedValue(
      mesialReductionValuePrev,
      distalReductionValueNext,
    );
    setBorder();
    return applyAggregatedValue(aggregatedValue);
  }

  if (isUpperOrLowerLeftOrRight(correctItemCount) && !isNextMissing) {
    if (distalReductionValueNext && !distalReductionValue) {
      if (!mesialReductionValueVisibleNext) {
        setBorder();
      }
      aggregatedValue = calculateAggregatedValue(
        mesialReductionValue,
        distalReductionValueNext,
      );
    } else if (mesialReductionValue && mesialReductionValueVisible !== false) {
      aggregatedValue = calculateAggregatedValue(
        mesialReductionValue,
        distalReductionValueNext,
      );
      setBorder();
    }

    if (correctItemCount === 8 || correctItemCount === 25) {
      if (
        mesialReductionValue &&
        mesialReductionValueVisible !== false &&
        mesialReductionValueNext
      ) {
        aggregatedValue =
          mesialReductionValue +
          (mesialReductionValueVisibleNext ||
          mesialReductionValueVisibleNext === undefined
            ? mesialReductionValueNext
            : 0);
        setBorder();
      } else {
        clearBorder();
        aggregatedValue = 0;
      }
    }
  } else if (!isNextMissing) {
    if (
      mesialReductionValueNext &&
      !mesialReductionValue &&
      mesialReductionValueVisibleNext &&
      !isCurrentMissing
    ) {
      setBorder();
      aggregatedValue = calculateAggregatedValue(
        distalReductionValue,
        mesialReductionValueNext,
      );
    } else if (distalReductionValue) {
      aggregatedValue = calculateAggregatedValue(
        distalReductionValue,
        mesialReductionValueNext,
      );
      if (!mesialReductionValueVisible) {
        setBorder();
      }
    }
  } else {
    if (
      distalReductionValuePrev &&
      mesialReductionValueNext &&
      correctItemCount !== 26
    ) {
      aggregatedValue = calculateAggregatedValue(
        distalReductionValuePrev,
        mesialReductionValueNext,
      );
      setBorder();
      return applyAggregatedValue(aggregatedValue);
    }
    if (distalReductionValue && mesialReductionValueNext && isNextMissing) {
      aggregatedValue = calculateAggregatedValue(
        distalReductionValue,
        mesialReductionValueNext,
      );
      setBorder();
      return applyAggregatedValue(aggregatedValue);
    }
  }

  return applyAggregatedValue(aggregatedValue);
}

export default aggregateInterproximalReductions;
