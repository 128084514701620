import { useEffect } from 'react';
import { useLocation } from 'react-router';

function ScrollToTop({ children }) {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
}

export default ScrollToTop;
