import { useQuery } from '@apollo/client';
import {
  GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
  GET_ORDER,
} from '@fullcontour/shared-api';
import { memo, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { SidebarMainRoutes } from '../../routing';
import { OrderDetailsProvider } from '../../shared';
import { OrderShowHeader } from '../../views/Orders/OrderShow/OrderShowHeader';
import SidebarArea from './SidebarArea';
import './SidebarLayout.css';

function OrderDetailsSidebarLayout() {
  const { pathname } = useLocation();

  const [changeableLayout, setChangeableLayout] = useState(false);
  const [operationName, setOperationName] = useState(null);

  const [headerHeight, setHeaderHeight] = useState(0);
  const ref = useRef(null);

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { id: pathname.split('/')[3] },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const updateWindowDimensions = () => {
      setHeaderHeight(ref.current.clientHeight);
    };

    if (ref.current) {
      updateWindowDimensions();
    }

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  function operationNameHandler(name) {
    setOperationName(name);
    setChangeableLayout(false);
  }

  return (
    <OrderDetailsProvider
      value={{
        operationName,
        changeableLayout,
        setOperationName,
        headerHeight,
        numberingSystem: data?.order.location.numberingSystem,
      }}
    >
      <div className="sidebarDesktopContainer layoutWithCommonHeader">
        <div
          ref={ref}
          style={{
            gridArea: 'header',
            minHeight: 70,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            zIndex: 5,
            background: '#fafafa',
            position: 'fixed',
          }}
        >
          <OrderShowHeader
            order={data?.order}
            setChangeableLayout={setChangeableLayout}
            changeableLayout={changeableLayout}
            operationNameHandler={operationNameHandler}
            isLoading={loading || error}
          />
        </div>
        <SidebarArea />
        <main className="sidebarMain" style={{ paddingTop: headerHeight + 10 }}>
          <SidebarMainRoutes />
        </main>
      </div>
    </OrderDetailsProvider>
  );
}

export default memo(OrderDetailsSidebarLayout);
