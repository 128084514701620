import { Message } from '@fullcontour/common';
import UpdatePasswordSuccess from '@fullcontour/common/src/components/views/Profile/UpdatePassword/UpdatePasswordSuccess';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

export default function ResetPassword({
  resetPassword,
  tempCredentials,
  forgotPassword,
  cleanError,
  error = null,
  login,
}) {
  const { t } = useTranslation('signin');
  const [revealPassword, setRevealPassword] = useState(false);
  const navigate = useNavigate();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    'We sent you an email with your verification code, please enter it below, along with your new password.',
  );
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: { recoveryCode: '', password: '' },
    onSubmit: async (values) => {
      try {
        await resetPassword({
          username: tempCredentials.username.trim().toLowerCase(),
          confirmationCode: values.recoveryCode.trim(),
          newPassword: values.password.trim(),
        });
        setIsSuccessful(true);
      } catch {
        resetForm();
      }
    },
    validate: (values) => {
      const errors = {};
      if (values.recoveryCode.trim() === '') {
        errors.recoveryCode =
          'Please enter the verification code sent to your email.';
      }
      if (values.password === '') {
        errors.password = 'Please enter your new password. Required';
      }
      return errors;
    },
  });

  useEffect(
    () => () => {
      cleanError();
    },
    [],
  );

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(() => {
        (async () => {
          await login({
            username: tempCredentials.username,
            password: values.password,
          });
          navigate('/');
        })();
      }, 3000);
    }
  }, [isSuccessful]);

  const resendRecoveryCodeHandler = () => {
    if (tempCredentials?.username) {
      forgotPassword(tempCredentials);
      setSuccessMessage(
        'A new verification code has been emailed to you, please enter it below, along with your new password.',
      );
    }
  };

  return (
    <>
      <div className="mb-4">
        {!(error || isSuccessful) && (
          <Message type="success" title="Password Update">
            <p>{t(successMessage)}</p>
          </Message>
        )}
      </div>
      {error &&
        error !== 'User needs to be authenticated to call this API.' && (
          <Translation ns={['signin']}>
            {(t) => (
              <Message
                type="danger"
                title="Sign In"
                dismissable
                error={error}
                onDismiss={cleanError}
              >
                <p>{t(error)}</p>
              </Message>
            )}
          </Translation>
        )}
      <UpdatePasswordSuccess success={isSuccessful} isNewUser />
      <form onSubmit={handleSubmit}>
        <div className="field">
          <p className="control">
            <input
              className="input"
              type="text"
              value={values.recoveryCode}
              onChange={handleChange}
              name="recoveryCode"
              placeholder={t('Verification Code Goes Here...')}
            />
          </p>
        </div>
        <p className="help is-danger mb-3">{errors.recoveryCode}</p>
        <div className="field">
          <p className="control has-icons-left has-icons-right">
            <input
              className="input"
              type={revealPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              name="password"
              placeholder={t('New Password')}
            />
            <span className="icon is-small is-left">
              <i className="bx bxs-lock" />
            </span>
            <span className="icon is-small is-right is-clickable">
              {revealPassword ? (
                <i
                  className="bx bxs-show"
                  onClick={() => setRevealPassword(!revealPassword)}
                  onKeyDown={null}
                />
              ) : (
                <i
                  className="bx bxs-hide"
                  onClick={() => setRevealPassword(!revealPassword)}
                  onKeyDown={null}
                />
              )}
            </span>
          </p>
        </div>
        <p className="help is-danger mb-3">{errors.password}</p>
        <span className="is-size-7">
          * Minimum 8 characters. Must contain an uppercase, lowercase, number
          and special character.
        </span>
        <div className="field is-grouped is-grouped-centered mt-5">
          <p className="control">
            <button
              type="submit"
              className={`button is-primary ${
                isSubmitting ? 'is-loading' : ''
              }`}
            >
              {t('Update Password')}
            </button>
          </p>
          <p className="control">
            <Link
              to="/signin"
              className="button has-background-grey has-text-white"
            >
              {t('Cancel and Sign In')}
            </Link>
          </p>
        </div>
        <button
          type="button"
          className="button"
          onClick={resendRecoveryCodeHandler}
        >
          {t('Resend Code')}
        </button>
      </form>
    </>
  );
}
ResetPassword.propTypes = {
  tempCredentials: PropTypes.object,
  forgotPassword: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  cleanError: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};
