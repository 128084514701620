import { Html, useProgress } from '@react-three/drei';
import PropTypes from 'prop-types';

function ThreeLoader({ loading = false }) {
  const { progress } = useProgress();

  return loading ? (
    <Html zIndexRange={-1} prepend fullscreen>
      <div
        className="loader-wrapper is-active inverted"
        style={{ zIndex: '-1 !important' }}
      >
        <div className="loader is-loading" />
        <div>
          <p className="loader-text has-text-centered">
            Generating Viewer
            <br />
            <small style={{ fontSize: '65%' }}>
              {Number.parseInt(progress, 10)}% loaded
            </small>
          </p>
        </div>
      </div>
    </Html>
  ) : null;
}

ThreeLoader.propTypes = {
  loading: PropTypes.bool,
};

export default ThreeLoader;
