import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Styles from './ErrorBoundry.module.css';

class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch() {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service like Sentry/LogRocket
    // logErrorToMyService(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return (
        <div className={Styles.errorContainer}>
          <h4 className="title is-4">{t('Sorry, something went wrong!')}</h4>
          <p>
            {t('Try to refresh this page or load')}
            &nbsp;
            <a href="/" onClick={() => window.location('/')}>
              Home
            </a>
          </p>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('errors')(ErrorBoundary);
