import PropTypes from 'prop-types';
import FeedbackSelectionListItem from './FeedbackSelectionListItem';

import { useFilteredCategories } from './hooks';

function FeedbackSelectionList(props) {
  const { orderItems, searchValue, handleOptionSelection, selectedOptions } =
    props;

  const feedbackCategories = useFilteredCategories(searchValue, orderItems);

  const lastOption = (feedbackCategoryId) => ({
    id: `${feedbackCategoryId}-other`,
    name: 'Other',
    isOther: true,
  });

  return (
    <div
      className="py-3"
      style={{
        border: '1px solid #ddd',
        borderRadius: 5,
        maxHeight: 200,
        overflowY: 'auto',
      }}
    >
      {feedbackCategories.map((item) => (
        <div key={item.designType.id}>
          <h5 className="mb-2 px-3">{item.designType.name}</h5>
          {item.designType.feedbackCategories.map((feedbackCategory) =>
            [...feedbackCategory.options, lastOption(feedbackCategory.id)].map(
              (option) => {
                const optionSelected =
                  selectedOptions?.[item.designType.id] &&
                  selectedOptions[item.designType.id][option.id];
                return (
                  <FeedbackSelectionListItem
                    key={option.id}
                    feedbackCategory={feedbackCategory}
                    option={option}
                    searchValue={searchValue}
                    setSelectedOptions={handleOptionSelection}
                    isSelected={!!optionSelected}
                    currentOption={optionSelected}
                    designTypeId={item.designType.id}
                  />
                );
              },
            ),
          )}
        </div>
      ))}
    </div>
  );
}

FeedbackSelectionList.propTypes = {
  orderItems: PropTypes.array.isRequired,
  handleOptionSelection: PropTypes.func.isRequired,
  selectedOptions: PropTypes.object.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default FeedbackSelectionList;
