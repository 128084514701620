import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BoxIcon, Message } from '../../../../shared';
import NonFormCheckboxField from '../../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';

function ResendModal(props) {
  const {
    modalOpen,
    closeModal,
    openModal,
    resendToFtp,
    messageTitle,
    actionTitle,
    order,
    updateEntities,
    success,
    failure,
  } = props;

  const { t } = useTranslation('orderactions');

  return (
    <>
      <a  className="dropdown-item" onClick={openModal}>
        {t(actionTitle)}
      </a>
      <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <BoxIcon name="bx-redo bx-md is-size-3" />
            <span>
              <h4 className="title is-5 modal-card-title mb-0 ml-4">
                {t(`${messageTitle}`)}
              </h4>
            </span>
          </header>
          <section className="modal-card-body" style={{ minHeight: 375 }}>
            {success && (
              <Message type="success" title={t('Case Resent via FTP')}>
                <p>
                  {t(
                    'We are now attempting to resend this case to the selected hosts.',
                  )}
                </p>
              </Message>
            )}
            {failure && (
              <Message type="danger" title={t('Resent Request Error')}>
                <p>
                  {t(
                    'There was a problem processing your request. Please try again later.',
                  )}
                </p>
              </Message>
            )}
            <div>
              <div className="columns">
                <div className="column">
                  <NonFormCheckboxField
                    name="locationAutoReassign"
                    labelText={`Resend to ${order.location.name}`}
                    onChange={(e) => updateEntities(e, order.location.id, true)}
                  />
                </div>
                <div className="column">
                  {order.orderItems.map((item) => {
                    if (!item.manufacturerOrganization.isInHouse) {
                      return (
                        <NonFormCheckboxField
                          name="manufAutoReassign"
                          labelText={`Resend to ${item.manufacturerOrganization.name}`}
                          onChange={(e) =>
                            updateEntities(
                              e,
                              item.manufacturerOrganizationId,
                              false,
                            )
                          }
                          key={item.id}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
            <button
              type="button"
              className="is-dark button"
              onClick={closeModal}
            >
              {t('Close')}
            </button>
            <button
              type="button"
              className="button is-primary"
              onClick={resendToFtp}
              disabled={success}
            >
              {t('Send')}
            </button>
          </footer>
        </div>
      </div>
    </>
  );
}

ResendModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  resendToFtp: PropTypes.func.isRequired,
  messageTitle: PropTypes.string.isRequired,
  actionTitle: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  updateEntities: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
};

export default ResendModal;
