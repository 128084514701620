import ApiSettings from '@fullcontour/common/src/components/views/ApiSettings/ApiSettingsForm/ApiSettingsForm';
import OrganizationFormManufacturer from '../../views/Organizations/OrganizationForms/OrganizationFormManufacturer';
import OrganizationShowInner from '../../views/Organizations/OrganizationShow/OrganizationShowInner';

const routes = [
  {
    path: '/:root(organizations)/:action(edit)',
    component: OrganizationFormManufacturer,
    sl: false,
    so: true,
  },
  {
    path: '/:root(organizations)/api-settings',
    component: ApiSettings,
    sl: false,
    so: true,
  },
  {
    path: '/:root(organizations)',
    component: OrganizationShowInner,
    sl: false,
    so: true,
  },
];

export default routes;
