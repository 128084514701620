import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../../shared';
import { checkPermissions, statusUpdate } from '../helpers';

function ActionUpdateStatus({
  statusAction,
  actionTitle,
  selectedCases = [],
  message = null,
  order = null,
  ...rest
}) {
  const props = {
    statusAction,
    actionTitle,
    selectedCases,
    message,
    order,
    ...rest,
  };
  const [modal, setModal] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const isConfrim = ['cancel_order', 'complete_order'].includes(statusAction);

  const selectedCasesNames = useMemo(() => {
    if (order) {
      return order.intOrderId;
    }
    return selectedCases.length > 0
      ? selectedCases.map((item) => item.data.intOrderId).join(', ')
      : null;
  }, [selectedCases, order]);

  const { t } = useTranslation('orderactions');
  const client = useApolloClient();

  function updateStatus() {
    if (selectedCases && selectedCases.length > 0) {
      selectedCases.forEach((selectedOrder) => {
        statusUpdate(
          client,
          statusAction,
          selectedOrder.data.id,
          message,
          null,
          selectedCases.length,
          selectedOrder.data.state,
        );
      });
    }

    if (order && selectedCases.length === 0) {
      statusUpdate(client, statusAction, order.id, message);
    }
  }

  useEffect(() => {
    if (confirm && isConfrim) {
      updateStatus();
      setConfirm(false);
    }
  }, [confirm]);

  const openModal = () => setModal(true);

  return checkPermissions(props) ? (
    <>
      <a
        className="dropdown-item"
        onClick={isConfrim ? openModal : updateStatus}
      >
        {t(actionTitle)}
      </a>

      {isConfrim && (
        <ConfirmationModal
          open={modal}
          setModal={setModal}
          text={`Are you sure you want to ${
            statusAction === 'cancel_order' ? 'cancel' : 'complete'
          } order${
            order || (selectedCases && selectedCases.length === 1) ? '' : 's'
          }: ${selectedCasesNames}?`}
          clientOnly
          callbacks={[() => setConfirm(true)]}
        />
      )}
    </>
  ) : null;
}

ActionUpdateStatus.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  statusAction: PropTypes.string.isRequired,
  selectedCases: PropTypes.array,
  message: PropTypes.string,
  order: PropTypes.object,
};

export default ActionUpdateStatus;
