import { useQuery } from '@apollo/client';
import { GET_CREDIT_CARDS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { BaseLoader } from '../../../shared';
import BillingCreditCards from '../BillingCreditCards/BillingCreditCards';

function BillingDetailsCreditCardsInner(props) {
  const { customer, type } = props;

  const variables = {
    labLocationId: type === 'location' ? customer.id : null,
    labOrganizationId:
      type === 'location' ? customer.organization.id : customer.id,
  };

  const { error, loading, data, refetch } = useQuery(GET_CREDIT_CARDS, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (error) {
    return <p>Error...</p>;
  }
  if (loading) {
    return <BaseLoader title="Payment Methods" loading />;
  }

  const { creditCards } = data;

  return (
    <BillingCreditCards
      creditCards={creditCards}
      type={type}
      refetch={refetch}
      customer={customer}
    />
  );
}

BillingDetailsCreditCardsInner.propTypes = {
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default BillingDetailsCreditCardsInner;
