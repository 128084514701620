import PropTypes from 'prop-types';
import { useState } from 'react';
import { Highlighted } from '../Components';

function FeedbackSelectionListItem({
  feedbackCategory,
  option,
  searchValue,
  isSelected,
  setSelectedOptions,
  designTypeId,
  currentOption = '',
}) {
  const [otherValue, setOtherValue] = useState(
    currentOption ? currentOption.split('> ')[1] : '',
  );

  const data = {
    optionName: `${feedbackCategory.name} > ${option.name}`,
    designTypeId,
    name: option.id,
    isOther: option.isOther,
  };

  const onSelectHandler = (e) => {
    setSelectedOptions({
      ...data,
      checked: e.target.checked,
    });
  };

  const onChangeHandler = (e) => {
    setOtherValue(e.target.value);
    setSelectedOptions({
      ...data,
      checked: true,
      optionName: `${feedbackCategory.name} > ${e.target.value || option.name}`,
    });
  };

  return (
    <p className="is-relative m-0 px-3">
      <label className="feedback__list-item">
        <span className="has-text-grey-light">
          <Highlighted text={feedbackCategory.name} highlight={searchValue} />
          &gt;
        </span>

        <span className="ml-1">
          <Highlighted text={option.name} highlight={searchValue} />
          {option.isOther && '&gt;'}
        </span>
        <input
          type="checkbox"
          name={option.id}
          onChange={onSelectHandler}
          checked={!!isSelected}
        />
        <span className="checkmark" />
        {isSelected && option.isOther && (
          <input
            type="text"
            onChange={onChangeHandler}
            value={otherValue}
            name={`${option.id}-other`}
            placeholder="Type custom option..."
            className="input is-small is-fullwidth ml-1"
          />
        )}
      </label>
    </p>
  );
}

FeedbackSelectionListItem.propTypes = {
  feedbackCategory: PropTypes.object.isRequired,
  option: PropTypes.object.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  designTypeId: PropTypes.string.isRequired,
  currentOption: PropTypes.string,
};

export default FeedbackSelectionListItem;
