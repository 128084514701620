import PropTypes from 'prop-types';

function Tooth8({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart ? teeth.includes(8) : upperArch;
  const variable = designType.toothChart ? 8 : 'upper';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[8]?.includes(guideCodeId);
  const className = `st2 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <g
      onClick={handleClick}
      role="button"
      tabIndex={0}
      aria-pressed={isActive}
      onKeyDown={(e) => e.key === 'Enter' && handleClick()}
    >
      <path
        className={className}
        d="M1458.4 554.8c0.7 6 35.7 67.9 44.6 95.8 12 30.2 31 57.4 81.7 71.8 41.4-5.1 83.4-6.2 118.2-55.7l85.7-129.9 15.6-41.3c11.3-29.8-13.2-57.3-36-69.1l-62-16.5c-36.2-2.3-60.3-12.9-131.1 8.3l-66.4 14.5c-24.2 10.7-56.4 6.5-57.1 60.2C1452.9 513.2 1444.9 532.1 1458.4 554.8L1458.4 554.8z"
      />
      <path
        className="st2"
        d="M1522 634.2l-15.2-126.8c8.2 0-9.3-25.6 21.6-37 112.9-44 178.1-11.7 196.6-2.1 18.4 9.7 34.4 36.1 30.9 63.9s-11.3 34.4-16.5 48.2"
      />
    </g>
  );
}

Tooth8.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.number).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth8;
