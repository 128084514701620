import { useMemo } from 'react';
import bodyTableDataGenerate from './bodyTableDataGenerate';
import headerTableDataGenerate from './headerTableDataGenerate';

function useTeethChartDataTransform(
  stages,
  selectedStages,
  isTotal,
  total = {},
) {
  const allTeeth = Object.freeze(new Array(32).fill(null));
  const allTeethData = useMemo(() => [...allTeeth], [allTeeth]);

  const currentStageTeeth = useMemo(() => {
    if (!(stages || total)) {
      return [];
    }
    return isTotal ? total.Teeth : stages[selectedStages - 1]?.Teeth;
  }, [isTotal, total, stages, selectedStages]);

  useMemo(() => {
    for (const item of currentStageTeeth ?? []) {
      if (item) {
        allTeethData[item.Unn - 1] = item;
      }
    }
  }, [currentStageTeeth, allTeethData]);

  const headerTableData = useMemo(() => {
    if (currentStageTeeth) {
      return headerTableDataGenerate(currentStageTeeth, isTotal);
    }

    return null;
  }, [currentStageTeeth, isTotal]);

  const bodyTeethData = useMemo(
    () => bodyTableDataGenerate(allTeethData),
    [allTeethData],
  );

  return { headerTableData, bodyTeethData };
}

export default useTeethChartDataTransform;
