import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseLoader from './BaseLoader';

function LoadingOrError({
  loading,
  title = '',
  error = null,
  hideText = false,
  inverted = false,
}) {
  const { t } = useTranslation('navigation');

  if (loading) {
    return <BaseLoader title={title} hideText={hideText} inverted={inverted} />;
  }
  if (error) {
    return (
      <article className="message is-danger">
        <div className="message-header">
          <p>{t('We encountered a problem')}</p>
        </div>
        <div className="message-body">
          <ul>
            {error.graphQLErrors.map((err, i) => (
              <li key={i}>{t(err.message)}</li>
            ))}
          </ul>
        </div>
      </article>
    );
  }
}

LoadingOrError.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  hideText: PropTypes.bool,
  inverted: PropTypes.bool,
};

export default LoadingOrError;
