import PropTypes from 'prop-types';
import Styles from './BeforeAfterControl.module.css';

function BeforeAfterControl({
  updateStage,
  totalStages,
  currentStage,
  disabled = false,
  playing,
  setPlaying,
  isDarkTheme,
}) {
  const lightThemeButtonsClass = !isDarkTheme ? Styles.lightThemeButton : '';

  return (
    <div className={Styles.beforeAfterWrap}>
      <button
        type="button"
        onClick={() => updateStage(0)}
        style={{ width: '38%', marginRight: '2%' }}
        disabled={disabled}
        className={`button ${
          Styles.beforeAfterButton
        } ${lightThemeButtonsClass}${
          currentStage === 0 ? Styles.active : null
        }`}
      >
        Before
      </button>
      <div
        style={{
          width: '16%',
          marginRight: '2%',
          marginLeft: '2%',
          display: 'inline-block',
        }}
      >
        {totalStages > 2 && (
          <button
            type="button"
            onClick={() => setPlaying(!playing)}
            disabled={disabled}
            style={{ width: '100%' }}
            className={`${lightThemeButtonsClass} ${Styles.beforeAfterButton} ${
              playing ? Styles.active : null
            } button `}
          >
            <span className="icon">
              <i className={`bx bx-md ${playing ? 'bx-pause' : 'bx-play'}`} />
            </span>
          </button>
        )}
      </div>
      <button
        type="button"
        onClick={() => updateStage(totalStages - 1)}
        style={{ width: '38%', marginLeft: '2%', marginRight: 0 }}
        disabled={disabled}
        className={`button ${
          Styles.beforeAfterButton
        } ${lightThemeButtonsClass}${
          currentStage === totalStages - 1 ? Styles.active : null
        }`}
      >
        After
      </button>
    </div>
  );
}

BeforeAfterControl.propTypes = {
  disabled: PropTypes.bool,
  updateStage: PropTypes.func.isRequired,
  totalStages: PropTypes.number.isRequired,
  currentStage: PropTypes.number.isRequired,
  playing: PropTypes.bool.isRequired,
  setPlaying: PropTypes.func.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
};

export default BeforeAfterControl;
