import PropTypes from 'prop-types';
import ResultsAgingCustomer from './ResultsAgingCustomer';
import ResultsAssignmentDesignSilo from './ResultsAssignmentDesignSilo';
import ResultsAssignmentDesigner from './ResultsAssignmentDesigner';
import ResultsAvgDesignTimeCustomerLoc from './ResultsAvgDesignTimeCustomerLoc';
import ResultsAvgDesignTimeCustomerOrg from './ResultsAvgDesignTimeCustomerOrg';
import ResultsDentalSystemCustomer from './ResultsDentalSystemCustomer';
import ResultsDesignCategoryCustomer from './ResultsDesignCategoryCustomer';
import ResultsDesignerRedesign from './ResultsDesignerRedesign';
import ResultsFeedbackAnswer from './ResultsFeedbackAnswer';
import ResultsFeedbackOrder from './ResultsFeedbackOrder';
import ResultsInvoicesCustomer from './ResultsInvoicesCustomer';
import ResultsMaterialCustomer from './ResultsMaterialCustomer';
import ResultsMaterialManufacturer from './ResultsMaterialManufacturer';
import ResultsOrderCustomer from './ResultsOrderCustomer';
import ResultsSubmissionTimeCustomer from './ResultsSubmissionTimeCustomer';
import ResultsTransactionCustomer from './ResultsTransactionCustomer';
import ResultsVolumeCustomer from './ResultsVolumeCustomer';
import ResultsVolumeManufacturer from './ResultsVolumeManufacturer';
import ResultsVolumeOrderCustomer from './ResultsVolumeOrderCustomer';
import ResultsVolumeOrderManufacturer from './ResultsVolumeOrderManufacturer';

function ResultTypes(props) {
  const { results } = props;

  if (
    results.reportType === 'Reporting::VolumeCustomer' ||
    results.reportType === 'Reporting::VolumeDesigner'
  ) {
    return <ResultsVolumeCustomer reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::TransactionCustomer') {
    return <ResultsTransactionCustomer reportDetails={results.reportDetails} />;
  }

  if (
    results.reportType === 'Reporting::VolumeOrderCustomer' ||
    results.reportType === 'Reporting::VolumeOrderDesigner'
  ) {
    return <ResultsVolumeOrderCustomer reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::VolumeManufacturer') {
    return <ResultsVolumeManufacturer reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::VolumeOrderManufacturer') {
    return (
      <ResultsVolumeOrderManufacturer reportDetails={results.reportDetails} />
    );
  }

  if (results.reportType === 'Reporting::AssignmentDesignSilo') {
    return (
      <ResultsAssignmentDesignSilo reportDetails={results.reportDetails} />
    );
  }

  if (results.reportType === 'Reporting::AssignmentDesigner') {
    return <ResultsAssignmentDesigner reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::DesignCategoryCustomer') {
    return (
      <ResultsDesignCategoryCustomer reportDetails={results.reportDetails} />
    );
  }

  if (results.reportType === 'Reporting::MaterialCustomer') {
    return <ResultsMaterialCustomer reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::MaterialManufacturer') {
    return (
      <ResultsMaterialManufacturer reportDetails={results.reportDetails} />
    );
  }

  if (results.reportType === 'Reporting::SubmissionTimeCustomer') {
    return (
      <ResultsSubmissionTimeCustomer reportDetails={results.reportDetails} />
    );
  }

  if (results.reportType === 'Reporting::AgingCustomer') {
    return <ResultsAgingCustomer reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::InvoicesCustomer') {
    return <ResultsInvoicesCustomer reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::OrderCustomer') {
    return <ResultsOrderCustomer reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::AvgDesignTimeCustomerLoc') {
    return (
      <ResultsAvgDesignTimeCustomerLoc reportDetails={results.reportDetails} />
    );
  }

  if (results.reportType === 'Reporting::AvgDesignTimeCustomerOrg') {
    return (
      <ResultsAvgDesignTimeCustomerOrg reportDetails={results.reportDetails} />
    );
  }

  if (results.reportType === 'Reporting::SourceDentalSystemCustomer') {
    return (
      <ResultsDentalSystemCustomer reportDetails={results.reportDetails} />
    );
  }

  if (results.reportType === 'Reporting::RedesignDesignUser') {
    return <ResultsDesignerRedesign reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::FeedbackOrder') {
    return <ResultsFeedbackOrder reportDetails={results.reportDetails} />;
  }

  if (results.reportType === 'Reporting::FeedbackAnswerOrder') {
    return <ResultsFeedbackAnswer reportDetails={results.reportDetails} />;
  }

  return null;
}

ResultTypes.propTypes = {
  results: PropTypes.object.isRequired,
};

export default ResultTypes;
