import { CREATE_CAD_FILE } from '@fullcontour/shared-api';

async function create({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  navigate,
  currentUser
}) {
  const result = await client.mutate({
    mutation: CREATE_CAD_FILE,
    variables,
  });
  const { createCadFile } = result.data;
  if (createCadFile.errorMessages.length > 0) {
    setErrorMessages(createCadFile.errorMessages);
  } else {
    clearErrorMessages();
    const app = import.meta.env.VITE_APP_NAME;
    let url;

    if (app === 'admin_portal') {
      url = `/locations/customer/${createCadFile.cadFile.location.slug}`;
    }

    if (app === 'customer_portal' && currentUser.scopeToLocation) {
      url = '/locations';
    }

    if (app === 'customer_portal' && !currentUser.scopeToLocation) {
      url = `/locations/${createCadFile.cadFile.location.slug}`;
    }
    navigate(url);
  }
}
export default create;
