import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Styles from './ErrorPages.module.css';

function errorPage(code) {
  const errorCode = code.substr(0, 3);
  const errorFamily = code.substr(0, 2);
  const errorObj = {
    statusCode: errorCode,
    content: 'Something went wrong',
  };
  switch (errorFamily) {
    case '40':
      if (errorCode === '403') {
        errorObj.content = 'The requested resource is Forbidden';
      } else {
        errorObj.content =
          'The requested resource could not be found but may be available again in the future.';
      }
      break;
    case '50':
      errorObj.content =
        'An unexpected condition was encountered. Our service team has been dispatched to bring it back online.';
      break;
    default:
      break;
  }
  return errorObj;
}

function Page() {
  const { error = '404' } = useParams();
  const { statusCode, content } = errorPage(error);

  return (
    <div className={Styles.cover}>
      <h2 className="title is-2">
        We got a problem <small>Error {statusCode}</small>
      </h2>
      <p className={Styles.lead}>{content}</p>
      <p>
        <Link href="/" to="/">
          Home
        </Link>
      </p>
    </div>
  );
}

export default Page;
