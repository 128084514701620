import { useMutation } from '@apollo/client';
import {
  CREATE_ORDER_APPROVAL_LOG_FEEDBACK,
  CREATE_ORDER_FEEDBACK,
  UPDATE_ORDER_FEEDBACK,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../Context';
import FeedbackBody from './FeedbackBody';
import FeedbackRedesignButton from './FeedbackRedesignButton';
import FeedbackStatusButtons from './FeedbackStatusButtons';

import { createFeedback } from './helpers';
import {
  checkIfOptionSelected,
  findCurrentFeedback,
  updateOrderCache,
} from './utils';

import './Feedback.scss';

function FeedbackInner({
  data,
  bodyOnly = false,
  redesign = false,
  setSendEnable = () => ({}),
  triggerFeedbackSend = false,
  withoutRedesign = false,
  message = null,
  editUserId = null,
  locationId = null,
  approvalDoctorId = null,
  feedbackToEdit = null,
  setTriggerFeedbackSend = () => ({}),
  setSendLoading = () => ({}),
  callback = () => ({}),
}) {
  const user = useContext(CurrentUserContext);

  const [bodyShow, setBodyShow] = useState(false);
  const [feeedbackUser, setFeedbackUser] = useState(editUserId);

  const { orderItems, hexOrderCode, feedbacks, redesignCount } = data;

  const { feedbackToShow, currentFeedback } = findCurrentFeedback(
    feedbacks,
    redesignCount,
    user,
    approvalDoctorId,
    feedbackToEdit,
  );

  const [selectedOptions, setSelectedOptions] = useState(feedbackToShow || {});

  const handleOptionSelection = useCallback(
    ({ designTypeId, optionName, name, isOther, checked }) => {
      setSelectedOptions((prev) => ({
        ...prev,
        [designTypeId]: {
          ...(prev[designTypeId] || {}),
          [name]: checked && ((isOther && optionName) || optionName),
        },
      }));
    },
    [],
  );

  const isfeedbackToShow =
    feedbackToShow && Object.keys(feedbackToShow).length > 0;

  useEffect(() => {
    if (setSendEnable) {
      setSendEnable(checkIfOptionSelected(redesign, selectedOptions));
    }
  }, [selectedOptions]);

  const [createFeedbackMutation, { loading }] = useMutation(
    currentFeedback
      ? UPDATE_ORDER_FEEDBACK
      : approvalDoctorId
        ? CREATE_ORDER_APPROVAL_LOG_FEEDBACK
        : CREATE_ORDER_FEEDBACK,
    {
      update(cache, { data: response }) {
        updateOrderCache({
          order: data,
          cache,
          data: response,
          orderId: hexOrderCode,
          isfeedbackToShow,
        });
      },
    },
  );

  useEffect(() => {
    setSendLoading(loading);
  }, [loading, setSendLoading]);

  const sendFeedback = ({ isPositive, doRedesign }) =>
    createFeedback({
      feedbackId: currentFeedback?.id || null,
      selectedOptions,
      orderId: hexOrderCode,
      createFeedbackMutation,
      isPositive,
      doRedesign: !!doRedesign,
      message,
      closeModal: () => setBodyShow(false),
      callback,
      approvalDoctorId,
      feeedbackUser,
      isUpdate: !!currentFeedback,
    });

  useEffect(() => {
    if (triggerFeedbackSend) {
      setTriggerFeedbackSend(false);
      sendFeedback({ isPositive: false, doRedesign: !withoutRedesign });
    }
  }, [triggerFeedbackSend]);

  const sharedBodyProps = {
    setBodyShow,
    orderItems,
    orderId: hexOrderCode,
    feedbackToShow,
    currentFeedback,
    createFeedbackMutation,
    loading,
    isfeedbackToShow: !!isfeedbackToShow,
    selectedOptions,
    setSelectedOptions,
    handleOptionSelection,
    approvalDoctorId,
    setFeedbackUser,
    feeedbackUser,
    locationId: data.location?.id || locationId,
    editUserId,
  };

  return bodyOnly ? (
    <FeedbackBody
      {...sharedBodyProps}
      sendFeedback={sendFeedback}
      bodyShow
      bodyOnly
    />
  ) : (
    <div className="is-relative is-flex is-align-items-center">
      <FeedbackStatusButtons
        setBodyShow={setBodyShow}
        currentFeedback={currentFeedback}
        sendFeedback={sendFeedback}
      />
      {redesign && <FeedbackRedesignButton data={sharedBodyProps} />}

      {bodyShow && (
        <FeedbackBody
          {...sharedBodyProps}
          sendFeedback={sendFeedback}
          bodyShow={bodyShow}
        />
      )}
    </div>
  );
}

FeedbackInner.propTypes = {
  data: PropTypes.object.isRequired,
  bodyOnly: PropTypes.bool,
  sendFeedback: PropTypes.bool,
  redesign: PropTypes.bool,
  setSendEnable: PropTypes.func,
  triggerFeedbackSend: PropTypes.bool,
  withoutRedesign: PropTypes.bool,
  setTriggerFeedbackSend: PropTypes.func,
  setSendLoading: PropTypes.func,
  callback: PropTypes.func,
  message: PropTypes.string,
  approvalDoctorId: PropTypes.string,
  locationId: PropTypes.string,
  editUserId: PropTypes.string,
  feedbackToEdit: PropTypes.object,
};

export default FeedbackInner;
