import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Tabs } from '../../../../shared';
import LocationDesignFavoritesGuideCode from './LocationDesignFavoritesGuideCode';

function LocationDesignGuideFavorites(props) {
  const { t } = useTranslation('designProfile');

  const { data } = props;
  const accordionData = useMemo(
    () => ({
      children: data?.labLocationGuideCategoryGroups
        .reduce((arr, item) => {
          const filteredData = item.guideCategoryGroups.reduce(
            (arrf, group) => {
              const updatedGuideCategories = group.guideCategories.filter(
                (guideCat) => guideCat.favorite,
              );
              if (updatedGuideCategories.length > 0) {
                arrf.push({
                  ...group,
                  guideCategories: updatedGuideCategories,
                });
              }

              return arrf;
            },
            [],
          );

          if (filteredData.length > 0) {
            arr.push({
              ...item,
              guideCategoryGroups: filteredData,
            });
          }
          return arr;
        }, [])
        .map((group) => ({
          title: group.designTypeName,
          children: group.guideCategoryGroups.map((item) => {
            const cardBody = item.guideCategories.map((guideCategory) => (
              <LocationDesignFavoritesGuideCode
                key={guideCategory.id}
                guideCategory={guideCategory}
                item={item}
                group={group}
              />
            ));

            return {
              cardHeader: item.name,
              cardBody: <div className="card__element-group">{cardBody}</div>,
            };
          }),
        })),
    }),
    [data],
  );
  return (
    <Tabs.TabPanel tabId="favorites">
      <h5 className="title is-5 mb-5">{t('Favorite codes')}</h5>
      <div className="column is-6 mb-6">
        <p className="mb-2">
          {t(`You also have the option of choosing favorites by clicking on the
          heart. This will allow for you to only seeing a limited list of Design
          Guide Codes when choosing a Design Guide Code for a case, as some of
          the codes you might not ever use.`)}
        </p>
        <p>
          {t(`Through the ‘Case Form’ you can then choose to apply one of your
          favorite Design Guide Codes, this will then overrule your Design
          Preferences.`)}
        </p>
      </div>

      <Accordion data={accordionData} />
    </Tabs.TabPanel>
  );
}

LocationDesignGuideFavorites.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LocationDesignGuideFavorites;
