import { match } from 'path-to-regexp';
import { useEffect } from 'react';
//  this hook is meant to be used in the APPHOC or the component where the first set of Routes are coded ONLY. Should be used once only.
import { useLocation, useNavigate } from 'react-router';

function useRedirect(redirects = []) {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const redirect = redirects.find(({ from }) => {
      const isMatch = match(from, { decode: decodeURIComponent });
      return isMatch(location.pathname);
    });
    if (redirect) {
      navigate(redirect.to);
    }
  }, [location.pathname]);
}

export default useRedirect;
