import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function BasicDropdownItem({
  itemText = '',
  clickHandler = null,
  setDropdownOpen = null,
  disabled = false,
}) {
  const { t } = useTranslation('common');

  const handleClick = () => {
    if (clickHandler) {
      clickHandler();
    }
    if (setDropdownOpen) {
      setDropdownOpen(false);
    }
  };

  return (
    <button
      type="button"
      className="dropdown-item button is-borderless"
      onClick={handleClick}
      disabled={disabled}
    >
      {itemText !== '' && <p>{t(itemText)}</p>}
    </button>
  );
}

BasicDropdownItem.propTypes = {
  itemText: PropTypes.string,
  clickHandler: PropTypes.func,
  setDropdownOpen: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BasicDropdownItem;
