import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { OrthoContext } from '../../../../../shared/Context/OrthoContext';

function ObjElementToothOverlayInner({
  index,
  obj,
  name,
  isUpper = false,
  isLower = false,
  currentTooth = null,
}) {
  const { toothMaterialOverlay, visibility, objRefsOverlay } =
    useContext(OrthoContext);

  const currentMatrix = useMemo(() => {
    let matrix = [];

    if (currentTooth) {
      const m = currentTooth.Movement.Value.Transformation;
      matrix = [
        m[0][0],
        m[0][1],
        m[0][2],
        m[0][3],
        m[1][0],
        m[1][1],
        m[1][2],
        m[1][3],
        m[2][0],
        m[2][1],
        m[2][2],
        m[2][3],
        m[3][0],
        m[3][1],
        m[3][2],
        m[3][3],
      ];
    }

    return matrix;
  }, [currentTooth]);

  return obj?.children[0]?.geometry ? (
    <group
      onUpdate={(self) => {
        if (currentMatrix?.length > 0) {
          self.matrix.set(
            currentMatrix[0],
            currentMatrix[1],
            currentMatrix[2],
            currentMatrix[3],
            currentMatrix[4],
            currentMatrix[5],
            currentMatrix[6],
            currentMatrix[7],
            currentMatrix[8],
            currentMatrix[9],
            currentMatrix[10],
            currentMatrix[11],
            currentMatrix[12],
            currentMatrix[13],
            currentMatrix[14],
            currentMatrix[15],
          );
        }
      }}
      matrixAutoUpdate={false}
    >
      <mesh
        geometry={obj.children[0].geometry}
        name={name}
        receiveShadow
        castShadow
        visible={(isUpper && visibility.upper) || (isLower && visibility.lower)}
        ref={(ref) => {objRefsOverlay.current[index] = ref}}
        material={toothMaterialOverlay}
      />
    </group>
  ) : null;
}

ObjElementToothOverlayInner.propTypes = {
  index: PropTypes.number.isRequired,
  obj: PropTypes.object.isRequired,
  isUpper: PropTypes.bool,
  isLower: PropTypes.bool,
  currentTooth: PropTypes.object,
  name: PropTypes.string.isRequired,
};

export default ObjElementToothOverlayInner;
