import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { CountryDropdown, RegionDropdown } from '../Dropdowns';
import FieldContainer from '../FieldContainer/FieldContainer';
import { FormSection } from '../FormContainer';

function AddressFields({ toggleParentAddress = null, selectedOrg = null }) {
  const { values } = useFormikContext();

  const { action, type } = useParams();

  useEffect(() => {
    const parentAddressToggle = document.querySelector('#parentAddressToggle');
    if (
      parentAddressToggle?.checked &&
      selectedOrg &&
      values.address.address !== selectedOrg.address.address
    ) {
      toggleParentAddress(true);
    }
  }, [selectedOrg, values.address.address]);

  return (
    <FormSection title="Address">
      {toggleParentAddress && action === 'new' && (
        <div className="is-flex is-align-items-center">
          <input
            id="parentAddressToggle"
            name="in"
            type="checkbox"
            className="is-checkradio is-primary"
            disabled={selectedOrg === null}
            onChange={(e) => toggleParentAddress(e.target.checked)}
          />
          <label className="checkbox ml-2" htmlFor="parentAddressToggle">
            {`Copy address from parent ${
              type === 'design' ? 'center' : 'organization'
            }`}
          </label>
        </div>
      )}
      <FieldContainer name="address.address" label="Address">
        <Field
          id="address.address"
          className="input"
          name="address.address"
          autoComplete="address-line-1"
        />
      </FieldContainer>
      <FieldContainer name="address.address2" label="Address 2">
        <Field
          id="address.address2"
          className="input"
          name="address.address2"
          autoComplete="address-line-2"
        />
      </FieldContainer>
      <div className="columns">
        <div className="column">
          <Field
            id="address.country"
            name="address.country"
            required
            component={CountryDropdown}
          />
        </div>
        <div className="column">
          <Field
            id="address.region"
            name="address.region"
            styles={{ paddingBottom: 10 }}
            component={RegionDropdown}
            country={values.address.country}
            autoComplete="address-level-1"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <FieldContainer name="address.city" label="City">
            <Field
              className="input"
              id="address.city"
              name="address.city"
              required
              autoComplete="address-level-2"
            />
          </FieldContainer>
        </div>
        <div className="column">
          <FieldContainer name="address.postalCode" label="Postal Code">
            <Field
              className="input"
              id="address.postalCode"
              name="address.postalCode"
              required
              autoComplete="postal-code"
              placeholder="999999"
            />
          </FieldContainer>
        </div>
      </div>
    </FormSection>
  );
}

AddressFields.propTypes = {
  toggleParentAddress: PropTypes.func,
  selectedOrg: PropTypes.object,
};

export default AddressFields;
