import { useContext, useEffect, useState } from 'react';
import { OrthoContext } from '../../../../shared/Context/OrthoContext';
import CenterView from '../../CenterView/CenterView';
import ObjGroupInner from './ObjGroupInner';
import calculateTeethElements from './utils/calculateTeethElements';

let cachedResults = {};

function ObjGroup() {
  const {
    files,
    group,
    rotation,
    visibility: { overlay },
    objRefs,
    currentStage,
    iprs,
  } = useContext(OrthoContext);

  const [iprTeeth, setIprTeeth] = useState(null);

  useEffect(() => {
    let worker;
    if (
      iprs &&
      Object.values(iprs).some((item) => item.showIPR) &&
      !cachedResults[currentStage]
    ) {
      worker = new Worker(
        new URL('./worker/closest-teeth-point-calc-worker.js', import.meta.url),
        {
          type: 'module',
        },
      );

      const teethElements = calculateTeethElements(
        objRefs?.current,
        currentStage,
        iprs,
      );

      worker.postMessage(teethElements);
      worker.onmessage = (event) => {
        setIprTeeth(Object.keys(event.data).length > 0 ? event.data : null);
        cachedResults[currentStage] = event.data;
      };
    }

    return () => {
      cachedResults = {};
      if (worker) {
        worker.terminate();
      }
    };
  }, [currentStage, iprs, objRefs]);

  return (
    <CenterView>
      <group ref={group} rotation={rotation}>
        {files.map((file, index) => (
          <ObjGroupInner
            key={file.id}
            file={file}
            index={index}
            files={files}
            iprTeeth={cachedResults[currentStage] || iprTeeth}
          />
        ))}

        {overlay &&
          files.map((file, index) => (
            <ObjGroupInner
              key={file.id}
              file={file}
              index={index}
              files={files}
              overlay
            />
          ))}
      </group>
    </CenterView>
  );
}

export default ObjGroup;
