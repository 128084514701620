import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { classesGenerator } from '../../../../transforms';

function PaginationStep({ pageNum, currentPage, pageHandler }) {
  const modalClasses = useMemo(
    () =>
      classesGenerator({
        'is-current': currentPage === pageNum,
        'pagination-link': true,
      }),
    [pageNum, currentPage],
  );

  return (
    // biome-ignore lint/nursery/noStaticElementInteractions: <explanation>
    <li onClick={pageHandler} onKeyPress={pageHandler} role="presentation">
      <button
        type="button"
        className={modalClasses}
        aria-label="Page 1"
        data-page={pageNum}
      >
        {pageNum + 1}
      </button>
    </li>
  );
}

PaginationStep.propTypes = {
  pageNum: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageHandler: PropTypes.func.isRequired,
};

export default PaginationStep;
