import { Field } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  DateRangeSelect,
  FieldContainer,
  SingleDateSelect,
} from '../../../shared';
import DataPoints from './DataPoints';
import DataPointsSecondary from './DataPointsSecondary';
import { ReportTypeSelect, TimeFrameSelect } from './FormFields';

function ReportFormInputs(props) {
  const {
    formikProps: { handleSubmit, setValues, values, resetForm, isValid, dirty },
  } = props;

  const [focusedInput, setFocusedInput] = useState(null);
  const [customDates, setCustomDates] = useState(false);
  const [hasReportType, setHasReportType] = useState(false);
  const [currentReportType, setCurrentReportType] = useState(null);

  const [calState, setCalState] = useState({
    startDate: moment(),
    endDate: moment(),
  });

  useEffect(() => {
    if (
      values.reportTypeId !== '' &&
      values.reportTypeId !== null &&
      values.reportTypeId !== undefined
    ) {
      setHasReportType(true);
    }

    if (
      values.reportTypeId === '' ||
      values.reportTypeId === null ||
      values.reportTypeId === undefined
    ) {
      setHasReportType(false);
    }
  }, [values.reportTypeId]);

  const onCalendarFocusChange = (focusedI) => {
    setFocusedInput(focusedI);
  };

  const onCalendarDatesChange = ({ startDate: startD, endDate: endD }) => {
    setValues({
      ...values,
      customStart: startD.format(),
      customEnd: endD.format(),
    });

    setCalState({ startDate: startD, endDate: endD });
  };

  const onCalendarDateChange = (date) => {
    setValues({
      ...values,
      singleDate: date.format(),
    });
    setCalState({ startDate: date });
  };

  const updateCustomDates = (isCustom) => {
    if (isCustom) {
      setCustomDates(true);
    }
  };

  const { startDate, endDate } = calState;

  return (
    <form className="form" onSubmit={(e) => handleSubmit(e)} noValidate>
      <div className="block">
        <div className="buttons is-pulled-right">
          <button
            type="button"
            className="button mr-2"
            onClick={resetForm}
            disabled={!dirty}
          >
            Reset
          </button>
          <button type="submit" className="button is-dark" disabled={!isValid}>
            Generate Report
          </button>
        </div>
      </div>

      <div className="block">
        <div className="columns">
          <div className="column">
            <Field
              name="reportTypeId"
              required
              component={ReportTypeSelect}
              setreporttype={setCurrentReportType}
            />
          </div>
          <div className="column">
            {hasReportType && (
              <Field
                name="reportTimeFrameId"
                required
                component={TimeFrameSelect}
                updateCustomDates={updateCustomDates}
                currentReportType={currentReportType}
              />
            )}
          </div>
          <div className="column">
            {customDates && (
              <div className="field">
                <label htmlFor="dateRangePicker">Date Ranges</label>
                <DateRangeSelect
                  name="dateRangePicker"
                  focusedInput={focusedInput}
                  onCalendarFocusChange={onCalendarFocusChange}
                  onCalendarDatesChange={onCalendarDatesChange}
                  showExtraPresets
                  hidePresets
                  anchorDirection="right"
                  startDate={startDate}
                  endDate={endDate}
                  maximumNights={0}
                />
              </div>
            )}
            {currentReportType?.singleDate &&
              values.reportTimeFrameId !== '' && (
                <div className="field">
                  <FieldContainer name="date" label="Date">
                    <Field
                      id="singleDatePicker"
                      name="singleDatePicker"
                      component={SingleDateSelect}
                      onCalendarDateChange={onCalendarDateChange}
                      date={startDate}
                      dateOptionsMode="past"
                    />
                  </FieldContainer>
                </div>
              )}
          </div>
        </div>

        {currentReportType && values.reportTimeFrameId && (
          <>
            <div className="columns">
              <DataPoints
                currentReportType={currentReportType}
                values={values}
              />
            </div>
            <div className="columns">
              <DataPointsSecondary
                currentReportType={currentReportType}
                values={values}
              />
            </div>
          </>
        )}
      </div>
    </form>
  );
}

ReportFormInputs.propTypes = {
  formikProps: PropTypes.object.isRequired,
};

export default ReportFormInputs;
