import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import BoxIcon from '../../Icons/BoxIcon';

function ActionDeleteButton({
  title = null,
  mutationType = null,
  mutationVariables = null,
  handleDelete = null,
  redirectPath = '/',
  customMessage = null,
  className = null,
  style = {},
  showTrashCan = false,
}) {
  const { t } = useTranslation('showelements');
  const client = useApolloClient();
  const navigate = useNavigate();
  const [confirmState, setConfirmState] = useState({ open: false });

  const show = () => setConfirmState({ open: true });
  const handleCancel = () => setConfirmState({ open: false });

  const destroyItem = async () => {
    await client.mutate({
      skip: !mutationType,
      mutation: mutationType,
      variables: { mutationVariables },
    });
    navigate(redirectPath);
  };

  const handleConfirm = () => {
    setConfirmState({ open: false });
    if (mutationType) {
      destroyItem();
    }
    if (handleDelete) {
      handleDelete();
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={show}
        className={`button is-justify-content-left is-borderless p-0 ${className}`}
        style={{ width: '100%', ...style }}
      >
        {showTrashCan && <BoxIcon name="bx-trash" />}
        <span>{title ? t({ title }) : t('Delete')}</span>
      </button>
      <div className={`modal ${confirmState.open ? 'is-active' : ''}`}>
        <div className="modal-background" />
        <div
          className="modal-content"
          style={{ backgroundColor: '#fff', padding: '1em', borderRadius: 12 }}
        >
          <div className="block">
            <h3 className="title is-3">{t('Are you sure?')}</h3>
            <p>{t(`Are you sure you want to delete this ${customMessage}?`)}</p>
          </div>
          <div className="block">
            <div className="buttons is-pulled-right">
              <button
                type="button"
                className="button is-light is-danger is-outlined"
                onClick={handleCancel}
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                className="button is-confirm"
                onClick={handleConfirm}
              >
                {t('Confirm')}
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={handleCancel}
        />
      </div>
    </>
  );
}

ActionDeleteButton.propTypes = {
  title: PropTypes.string,
  mutationType: PropTypes.object,
  mutationVariables: PropTypes.object,
  handleDelete: PropTypes.func,
  redirectPath: PropTypes.string,
  customMessage: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.any,
  showTrashCan: PropTypes.bool,
};

export default memo(ActionDeleteButton);
