function initialValues(props) {
  const {
    data: { notificationTypeUsers = {} } = {},
  } = props;

  return {
    notificationTypeUsers: !(
      Array.isArray(notificationTypeUsers) && notificationTypeUsers.length > 0
    )
      ? []
      : notificationTypeUsers.map((type) => {
          const { __typename, ...rest } = type;

          return { ...rest, _destroy: false };
        }),
  };
}

export default initialValues;
