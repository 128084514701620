import PropTypes from 'prop-types';
import React from 'react';

function Highlighted({ text = '', highlight = '' }) {
  if (!(highlight?.trim() && text)) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.filter(String).map((part, i) =>
        React.createElement(
          regex.test(part) ? 'mark' : 'span',

          { key: i },
          part,
        ),
      )}
    </span>
  );
}

Highlighted.propTypes = {
  text: PropTypes.string,
  highlight: PropTypes.string,
};

export default Highlighted;
