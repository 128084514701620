import { gql } from '@apollo/client';

export default gql`
  query orderOtpFiles($id: ID!) {
    orderOtpFiles(id: $id) {
      stages
      redesignCount
      createdAt
      id
    }
  }
`;
