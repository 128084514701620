import { useLoader } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrthoContext } from '../../../../../shared/Context/OrthoContext';
import ObjElementToothOverlayInner from './ObjElementToothOverlayInner';

function ObjElementToothOverlay({ file, index, name, isUpper, isLower, unn }) {
  const obj = useLoader(OBJLoader, file.signedUrl);
  const { stageZeroOtp } = useContext(OrthoContext);

  const currentTooth = useMemo(() => {
    let cTooth;

    if (stageZeroOtp) {
      cTooth = stageZeroOtp.Teeth.find(
        (tooth) => Number.parseInt(tooth.Unn, 10) === unn,
      );
    }

    return cTooth;
  }, [stageZeroOtp, unn]);

  return currentTooth?.$type !== 'ExtractedTooth' ? (
    <ObjElementToothOverlayInner
      obj={obj}
      name={name}
      isUpper={isUpper}
      isLower={isLower}
      currentTooth={currentTooth}
      index={index}
      unn={unn}
    />
  ) : null;
}

ObjElementToothOverlay.propTypes = {
  file: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isUpper: PropTypes.bool.isRequired,
  isLower: PropTypes.bool.isRequired,
  unn: PropTypes.number.isRequired,
};

export default ObjElementToothOverlay;
