import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Placeholder, createSignedLink } from '../../../../shared';

function OrderMessageImage({
  message,
  multipleFiles,
  setIsOpen = null,
  setMessageImages = null,
  setCurrentMessagePhotoModal = null,
  setPhotoIndex = null,
  messageId = null,
  messageImages = {},
}) {
  const client = useApolloClient();
  const [image, setImage] = useState(null);
  const [imageDownloaded, setImageDownloaded] = useState(false);
  const [photoPosition, setPhotoPosition] = useState(0);

  const updateMessageImages = useCallback(
    (prev, signedUrl) => {
      if (prev[messageId]) {
        const updatedArray = [...prev[messageId], signedUrl];
        return updatedArray;
      }
      return [signedUrl];
    },
    [messageId],
  );

  useEffect(() => {
    if (setMessageImages && messageId) {
      setPhotoPosition((messageImages?.[messageId]?.length || 1) - 1);
    }
  }, [messageId, messageImages, setMessageImages]);

  useEffect(() => {
    async function getFileUrl() {
      const { signedUrl } = await createSignedLink({
        client,
        foundFile: {
          s3Key: multipleFiles
            ? message.imageUrl.split('/').slice(3).join('/')
            : message.s3Id,
        },
      });

      if (setImage) {
        setImage(signedUrl);
      }

      if (setMessageImages && messageId) {
        setMessageImages((prev) => ({
          ...prev,
          [messageId]: updateMessageImages(prev, signedUrl),
        }));
      }
    }

    getFileUrl();
  }, [
    message,
    client,
    multipleFiles,
    messageId,
    setMessageImages,
    updateMessageImages,
  ]);

  const imageClickHandler = () => {
    if (setPhotoIndex) {
      setPhotoIndex(photoPosition);
    }
    if (setCurrentMessagePhotoModal) {
      setCurrentMessagePhotoModal(messageId);
    }
    if (setIsOpen) {
      setIsOpen(true);
    }
  };

  return (
    <div
      style={{
        width: '140px',
        height: '100px',
        overflow: 'hidden',
        marginBottom: '10px',
        cursor: 'pointer',
        ...(multipleFiles ? { marginLeft: '10px' } : {}),
      }}
      onKeyDown={imageClickHandler}
      onClick={imageClickHandler}
      role="button"
      tabIndex={0}
    >
      {!imageDownloaded && <Placeholder height="100px" />}
      <img
        style={{
          width: '100%',
          cursor: 'pointer',
          objectFit: 'cover',
          height: '100%',
        }}
        src={image}
        onLoad={() => setImageDownloaded(true)}
        alt={message.s3Id}
      />
    </div>
  );
}

OrderMessageImage.propTypes = {
  message: PropTypes.object.isRequired,
  setMessageImages: PropTypes.func,
  setIsOpen: PropTypes.func,
  setPhotoIndex: PropTypes.func,
  setCurrentMessagePhotoModal: PropTypes.func,
  multipleFiles: PropTypes.bool.isRequired,
  messageId: PropTypes.string,
  messageImages: PropTypes.object,
};

export default OrderMessageImage;
