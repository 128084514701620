import { useApolloClient, useQuery } from '@apollo/client';
import { GET_ORDER } from '@fullcontour/shared-api';
import { Suspense } from 'react';
import { Route, Routes, useParams } from 'react-router';
import { BaseLoader, LoadingOrError } from '../../../shared';

import OrderShowActivity from './OrderShowActivity/OrderShowActivity';
import OrderShowActors from './OrderShowActors/OrderShowActors';
import OrderShowFiles from './OrderShowFiles/OrderShowFiles';
import OrderShowInfo from './OrderShowInfo/OrderShowInfo';
import OrderShowMessages from './OrderShowMessages/OrderShowMessages';
import OrderShowMessagesCustomer from './OrderShowMessages/OrderShowMessagesCustomer';
import OrderShowPreferencesNew from './OrderShowPreferences/OrderShowPreferencesNew';

function OrderShow() {
  const { id } = useParams();
  const client = useApolloClient();

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { id },
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Case" />;
  }

  const { order } = data;

  const isCustomerPortal = import.meta.env.VITE_APP_NAME === 'customer_portal';
  const showCustomerActivity =
    import.meta.env.VITE_APP_NAME === 'admin_portal' ||
    import.meta.env.VITE_APP_NAME === 'manuf_portal';
  return (
    <Suspense fallback={<BaseLoader loading />}>
      <Routes>
        <Route path="/" element={<OrderShowFiles order={order} />} />

        <Route
          path="/order-info"
          element={<OrderShowInfo order={order} client={client} />}
        />
        <Route
          path="/design-preferences"
          element={
            <OrderShowPreferencesNew
              feedbacks={order.feedbacks}
              jobCode={order.jobCode}
              orderItems={order.orderItems}
              customInstructions={order.location.customInstructions}
              keepExistingCustomInstructions={
                order.location.keepExistingCustomInstructions
              }
              redesignCount={order.redesignCount}
              preferences={order.customerDesignPreferences}
              instructions={order.instructions}
              locationId={order.location.id}
              modifiers={order.itemsMetadata.modifiers}
              orderId={order.id}
              hexOrderCode={order.hexOrderCode}
              designTypeInstructions={order.location.designTypeInstructions}
              allowNested={order.location.allowNested}
              numberingSystem={order.location.numberingSystem}
            />
          }
        />

        {showCustomerActivity && (
          <Route
            path="/customer-activity"
            element={
              <OrderShowMessagesCustomer locationId={order.location.id} />
            }
          />
        )}
        <Route
          path="/activity"
          element={
            isCustomerPortal ? (
              <OrderShowMessagesCustomer />
            ) : (
              <OrderShowActivity />
            )
          }
        />

        {!isCustomerPortal &&
        import.meta.env.VITE_APP_NAME !== 'designer_portal' ? (
          <Route path="/messages" element={<OrderShowMessages />} />
        ) : null}

        <Route path="/actors" element={<OrderShowActors order={order} />} />
      </Routes>
    </Suspense>
  );
}

export default OrderShow;
