import { useQuery } from '@apollo/client';
import { GET_CREDITS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { LoadingOrError } from '../../../shared';
import CreditsListDataTable from './CreditsListDataTable';

function CreditsListQueryWrapper({ customer = null, type = null, ...rest }) {
  const props = { customer, type, ...rest };
  const app = import.meta.env.VITE_APP_NAME;

  let variables = {};

  if (customer && type && type === 'location') {
    variables = { labLocationId: customer.id };
  }

  if (customer && type && type === 'organization') {
    variables = { labOrganizationId: customer.id };
  }

  if (app === 'customer_portal') {
    variables.excludeExpired = true;
  }

  const { error, loading, data, refetch } = useQuery(GET_CREDITS, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Credits" />;
  }

  return (
    <CreditsListDataTable credits={data.credits} refetch={refetch} {...props} />
  );
}

CreditsListQueryWrapper.propTypes = {
  customer: PropTypes.object,
  type: PropTypes.string,
};

export default CreditsListQueryWrapper;
