import PropTypes from 'prop-types';
import { memo } from 'react';
import Styles from './ShowHeaderGeoData.module.css';
import ShowHeaderGeoDataClock from './ShowHeaderGeoDataClock';

function ShowHeaderGeoData(props) {
  const { geoData } = props;

  return (
    <p className={Styles.geoDataContainer}>
      {geoData?.timezone && (
        <span>
          <span>Timezone:</span> {geoData.timezone.replace('_', ' ')}
        </span>
      )}
      {geoData?.utcOffset && (
        <span style={{ marginLeft: '1em' }}>
          <span>UTC Offset:</span> {geoData.utcOffset}
        </span>
      )}
      {geoData?.timezone && (
        <span style={{ marginLeft: '1em' }}>
          <span>Local Time:</span> <ShowHeaderGeoDataClock geoData={geoData} />
        </span>
      )}
    </p>
  );
}

ShowHeaderGeoData.propTypes = {
  geoData: PropTypes.object.isRequired,
};

export default memo(ShowHeaderGeoData);
