import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useApp } from '@fullcontour/hooks';
import { GET_ORDER_MESSAGES, REQUEST_S3_SIGN } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Lightbox from 'yet-another-react-lightbox';
import { LoadingOrError } from '../../../../shared';
import { FilesShow } from '../OrderShowFiles/FilesShow';
import OrderShowMessageFormCustomer from './OrderShowMessageFormCustomer';
import OrderShowMessagesCustomerMessage from './OrderShowMessagesCustomerMessage';

function OrderShowMessagesCustomer({
  locationId = null,
  noWrapper = false,
  showFrom = false,
}) {
  const messagesWrapper = useRef();

  const client = useApolloClient();
  const { id } = useParams();
  const { t } = useTranslation('caseActivity');
  const { isCustomer } = useApp();

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [sendDisable, setSendDisable] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentMessagePhotoModal, setCurrentMessagePhotoModal] = useState('');
  const [messageImages, setMessageImages] = useState({});

  const { loading, error, data, refetch } = useQuery(GET_ORDER_MESSAGES, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const [signS3] = useMutation(REQUEST_S3_SIGN);

  const scrollToMyRef = useCallback(() => {
    if (messagesWrapper.current) {
      messagesWrapper.current.scrollTo(0, messagesWrapper.current.scrollHeight);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollToMyRef();
    }, 1000);
  }, [scrollToMyRef]);

  const innerMessager = (
    <>
      <div>
        <div
          ref={messagesWrapper}
          style={{
            height: '65vh',
            position: 'relative',
            overflowY: 'auto',
            overflowX: 'hidden',
            ...(noWrapper
              ? { border: 'none', marginBottom: 0, boxShadow: 'none' }
              : {}),
          }}
        >
          {loading || error ? (
            <LoadingOrError
              error={error}
              loading={loading}
              title="Case Messages"
            />
          ) : data.orderMessages.length > 0 ? (
            [...data.orderMessages]
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((message, idx, arrayOrigin) => (
                <OrderShowMessagesCustomerMessage
                  message={message}
                  idx={idx}
                  arrayOrigin={arrayOrigin}
                  key={message.id}
                  setPreviewImage={setPreviewImage}
                  setCurrentMessagePhotoModal={setCurrentMessagePhotoModal}
                  setIsOpen={setIsOpen}
                  setPhotoIndex={setPhotoIndex}
                  messageImages={messageImages}
                  setMessageImages={setMessageImages}
                  locationId={locationId}
                />
              ))
          ) : (
            <h6 className="title is-6 has-text-centered">
              {t('No Messages have been posted yet')}
            </h6>
          )}
        </div>
      </div>

      {(isCustomer || showFrom) && data && data.order.id && (
        <div
          className="panel-block"
          style={{
            padding: 0,
            ...(noWrapper
              ? {
                  border: 'none',
                  margin: '0px -25px 0px',
                  borderTop: '1px solid rgb(221, 221, 221)',
                }
              : {}),
          }}
        >
          <OrderShowMessageFormCustomer
            orderId={data?.order.id}
            order={data?.order}
            id={id}
            client={client}
            orderState={data?.order.state}
            signS3={signS3}
            setUploadPercentage={setUploadPercentage}
            uploadPercentage={uploadPercentage}
            setSendDisable={setSendDisable}
            sendDisable={sendDisable}
            refetch={refetch}
          />
        </div>
      )}

      <Lightbox
        styles={{ container: { backgroundColor: 'rgba(80, 80, 80, 0.87)' } }}
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={
          messageImages[currentMessagePhotoModal] && [
            ...messageImages[currentMessagePhotoModal].map((img) => ({
              src: img,
            })),
          ]
        }
        index={photoIndex}
      />
    </>
  );

  return noWrapper ? (
    innerMessager
  ) : (
    <div className="columns">
      <div className="column is-6">
        <div className="panel">
          <p className="panel-heading">{t('Case Activity and Messages')}</p>
          {innerMessager}
        </div>
      </div>
      <div className="column" style={{ verticalAlign: 'top' }}>
        {previewImage && (
          <FilesShow
            selectedFile={previewImage}
            loadingFileView={false}
            order={data.order}
          />
        )}
      </div>
    </div>
  );
}

OrderShowMessagesCustomer.propTypes = {
  locationId: PropTypes.string,
  noWrapper: PropTypes.bool,
  showFrom: PropTypes.bool,
};

export default OrderShowMessagesCustomer;
