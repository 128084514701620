import PropTypes from 'prop-types';
import PreferenceActive from './PreferenceActive';
import PreferenceInactive from './PreferenceInactive';

function PreferenceContainer(props) {
  const { foundIndex, edit, preference } = props;

  const options =
    preference.values?.length > 0
      ? [...preference.values]
          .sort((a, b) => a?.value.localeCompare(b?.value))
          .map((option) => ({
            id: option.id,
            name: option.default ? `${option.value}  (default)` : option?.value,
            value: option?.value,
          }))
      : [];

  if (foundIndex !== -1 && edit) {
    return <PreferenceActive {...props} options={options} />;
  }

  if ((foundIndex !== -1 && !edit) || foundIndex === -1) {
    return <PreferenceInactive {...props} options={options} />;
  }

  return null;
}

PreferenceContainer.propTypes = {
  foundIndex: PropTypes.number.isRequired,
  edit: PropTypes.bool.isRequired,
};

export default PreferenceContainer;
