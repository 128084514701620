import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ButtonAddNewCode } from './components';
import { useGuideCodeCardContext } from './context/GuideCodeCardContext';

function GuideCodeCardOtherInput({
  handleOtherValue,
  showOtherValue = false,
  hideCreateOtherButton = false,
}) {
  const { data } = useGuideCodeCardContext();

  const { otherUnit, otherMin, otherMax } = data.category;

  const [value, setValue] = useState(
    data.id?.includes('other') ? data.name : '',
  );
  const [otherValueError, setOtherValueError] = useState(null);

  useEffect(() => {
    if (!showOtherValue) {
      setValue(null);
    }
  }, [showOtherValue]);

  const otherValueItTag = otherUnit === 'id_tag';

  const handleChangeValue = (e) => {
    const { value: inputValue } = e.target;
    setValue(inputValue);

    const error = inputValue < otherMin || inputValue > otherMax;

    if (!otherValueItTag) {
      setOtherValueError(error);
    }

    handleOtherValue({
      data: { ...data, id: `other-${data.category.id}` },
      value: inputValue,
      error,
    });
  };

  return showOtherValue ? (
    <>
      <div className="field has-addons mt-4 mb-0">
        <p className="control is-small mb-1">
          <input
            className={`input is-small ${otherValueError ? 'is-danger' : ''}`}
            type={otherValueItTag ? 'text' : 'number'}
            placeholder="Type here"
            onChange={handleChangeValue}
            value={value}
            onClick={(e) => e.stopPropagation()}
          />
        </p>

        <p className="control is-small">
          <button type='button' className="button is-static is-small">
            {otherValueItTag ? 'Tag ID' : otherUnit}
          </button>
        </p>
      </div>
      {otherValueError ? (
        <p className="help is-danger mt-0">value is not in the range</p>
      ) : null}

      {!otherValueItTag && (
        <p className="mb-0">
          <small>
            min: <b>{otherMin}</b>, max: <b>{otherMax}</b>
          </small>
        </p>
      )}

      {showOtherValue && value && !hideCreateOtherButton && (
        <ButtonAddNewCode disabled={otherValueError} value={value} />
      )}
    </>
  ) : null;
}

GuideCodeCardOtherInput.propTypes = {
  showOtherValue: PropTypes.bool,
  hideCreateOtherButton: PropTypes.bool,
  handleOtherValue: PropTypes.func.isRequired,
};

export default GuideCodeCardOtherInput;
