import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';
import PropTypes from 'prop-types';
import '../../../../../shared/AgGrid/ag-grid-css-overrides.css';
import '../../../../../shared/AgGrid/ag-theme-balham-overrides.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import { AgGridLoader } from '../../../../../shared/Loader';

function FilesGrid({
  containerHeight = import.meta.env.VITE_APP_NAME === 'customer_portal' ||
  import.meta.env.VITE_APP_NAME === 'designer_portal'
    ? 'calc(100vh - 190px - 4em - 20px)'
    : 'calc(100vh - 165px - 4em - 30px)',
  gridOptions,
  onGridReady,
  onFirstDataRendered,
  onGridSizeChanged,
  onRowSelected,
  onRowDataChanged,
  rowData,
}) {
  const { components, ...gridRest } = gridOptions;

  return (
    <div
      className="ag-theme-balham filesGridContainer"
      style={{
        height: containerHeight,
      }}
    >
      <AgGridReact
        gridOptions={gridRest}
        headerHeight={46}
        rowData={rowData}
        animateRows
        reactNext
        maintainColumnOrder
        treeData
        modules={[
          ClientSideRowModelModule,
          InfiniteRowModelModule,
          CsvExportModule,
          ClipboardModule,
          ExcelExportModule,
          FiltersToolPanelModule,
          MasterDetailModule,
          MenuModule,
          RichSelectModule,
          RowGroupingModule,
          SetFilterModule,
          MultiFilterModule,
          AdvancedFilterModule,
          ViewportRowModelModule,
        ]}
        components={{
          customLoadingOverlay: AgGridLoader,
          ...components,
        }}
        loadingOverlayComponent="customLoadingOverlay"
        floatingFiltersHeight={46}
        onGridReady={onGridReady}
        onGridSizeChanged={onGridSizeChanged}
        onFirstDataRendered={onFirstDataRendered}
        onRowSelected={onRowSelected}
        onRowDataChanged={onRowDataChanged}
        defaultColDef={{
          resizable: true,
          editable: false,
          suppressHeaderMenuButton: true,
          filter: true,
          sortable: true,
        }}
      />
    </div>
  );
}

FilesGrid.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  containerHeight: PropTypes.string,
  onGridReady: PropTypes.func.isRequired,
  onFirstDataRendered: PropTypes.func.isRequired,
  onGridSizeChanged: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  onRowDataChanged: PropTypes.func.isRequired,
  rowData: PropTypes.array.isRequired,
};

export default FilesGrid;
