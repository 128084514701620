import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';

function PendingLabelCount({
  data = {
    ordersCount: 0,
  },
  appActive,
  stopPolling,
  startPolling,
}) {
  const { tempOrdersCount = 0 } = data;
  useEffect(() => {
    if (!appActive) {
      stopPolling();
    } else {
      startPolling(60000);
    }
  }, [appActive, stopPolling, startPolling]);

  return tempOrdersCount > 0 ? (
    <span className="tag is-pulled-right">{tempOrdersCount}</span>
  ) : null;
}

PendingLabelCount.propTypes = {
  data: PropTypes.object,
  appActive: PropTypes.bool.isRequired,
  stopPolling: PropTypes.func.isRequired,
  startPolling: PropTypes.func.isRequired,
};

export default memo(PendingLabelCount);
