import { UPDATE_PROFILE } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
}) {
  const result = await client.mutate({
    mutation: UPDATE_PROFILE,
    variables,
  });
  const { updateProfile } = result.data;
  if (updateProfile.errorMessages.length > 0) {
    setErrorMessages(updateProfile.errorMessages);
  } else {
    clearErrorMessages();
  }
}

export default update;
