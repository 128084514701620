import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BoxIcon,
  CheckboxField,
  FieldContainer,
  Info,
  NoQueryDynamicMultiSelect,
  ServerErrors,
} from '../../../../shared';
import HeaderActions from './HeaderActions';
import ProfileContainer from './ProfileContainer';

function FieldArrayInner(props) {
  const {
    values: {
      customInstructions,
      designProfiles,
      designTypeInstructions,
      selectedDesignTypes,
    },
    values,
    setFieldValue,
    initialValues,
    data: {
      customerLocation: {
        priceList: { designTypes },
      },
      designPreferenceProfiles,
    },
    errorMessages,
    arrayHelpers,
    handleReset,
    edit,
    toggleEdit,
  } = props;

  const [nonPriceListProfiles, setNonPriceListProfiles] = useState([]);
  const [priceListProfiles, setPriceListProfiles] = useState([]);

  useEffect(() => {
    if (initialValues) {
      initializedDefaults();
    }
  }, [initialValues]);

  const initializedDefaults = () => {
    addDefaultPreferences();
    setNonPriceListProfiles(
      designPreferenceProfiles
        .filter(
          (preferenceProfile) =>
            !designTypes.some(
              (designType) =>
                preferenceProfile.id === designType.designPreferenceProfileId,
            ),
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
    );
    setPriceListProfiles(
      designPreferenceProfiles
        .filter((preferenceProfile) =>
          designTypes.some(
            (designType) =>
              preferenceProfile.id === designType.designPreferenceProfileId,
          ),
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
    );
  };

  const addDefaultPreferences = () => {
    const sortedProfiles = [...designPreferenceProfiles].sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    for (const profile of sortedProfiles) {
      const designProfileIndex = designProfiles.findIndex(
        (preference) => preference.id === profile.id,
      );
      if (designProfileIndex === -1) {
        arrayHelpers.push({
          id: profile.id,
          name: profile.name,
          designPreferences: profile.designPreferences.map((preference) => ({
            name: preference.name,
            default: true,
            value: preference.values.find((value) => value?.default === true)
              ? preference.values.find((value) => value?.default === true)
                  ?.value
              : preference.values[0]?.value,
          })),
        });
      }
      if (designProfileIndex !== -1) {
        for (const pref of profile.designPreferences) {
          const prefIndex = designProfiles[
            designProfileIndex
          ].designPreferences.findIndex((p) => p.id === pref.id);
          if (prefIndex === -1) {
            const newPrefs = [
              ...designProfiles[designProfileIndex].designPreferences,
              {
                name: pref.name,
                default: true,
                value: pref.values.find((value) => value?.default === true)
                  ? pref.values.find((value) => value?.default === true)?.value
                  : pref.values[0]?.value,
              },
            ];
            setFieldValue(`designProfiles[${designProfileIndex}]`, {
              ...designProfiles[designProfileIndex],
              designPreferences: newPrefs,
            });
          }
        }
      }
    }
  };

  const toggleEditAndReset = () => {
    if (edit === true) {
      handleReset();
      addDefaultPreferences();
    }
    toggleEdit();
  };

  const addDesignTypes = () => {
    let fullSelectedTypes = designTypes.filter((d) =>
      selectedDesignTypes.find((type) => type === d.id),
    );
    fullSelectedTypes = fullSelectedTypes.map(({ id, name }) => ({
      name,
      value: id,
    }));

    setFieldValue('designTypeInstructions', [
      ...designTypeInstructions,
      ...fullSelectedTypes,
    ]);
    setFieldValue('selectedDesignTypes', []);
  };

  const handleDesignTypes = (value) => {
    setFieldValue('selectedDesignTypes', value);
  };

  const { t } = useTranslation('formfields');

  return (
    <div>
      <ServerErrors errorMessages={errorMessages} />
      <HeaderActions toggleEditAndReset={toggleEditAndReset} {...props} />
      <div className="columns">
        <div className="column">
          {edit ? (
            <>
              <FieldContainer
                name="customInstructions"
                label="Custom Instructions"
              >
                <Field
                  id="customInstruction"
                  name="customInstructions"
                  placeholder="Custom Instructions"
                  component="textarea"
                  className="textarea"
                  rows="2"
                />
              </FieldContainer>
              <div className="m-5">
                <Field
                  name="keepExistingCustomInstructions"
                  required
                  component={CheckboxField}
                  labelText="Keep the existing Custom Instructions"
                />
              </div>
              <FieldContainer name="designTypes" label="Add instructions field">
                <NoQueryDynamicMultiSelect
                  name="selectedDesignTypes"
                  onChange={handleDesignTypes}
                  required
                  options={designTypes}
                  useFullDataValue
                  optionsUsed={designTypeInstructions.map((type) => type.value)}
                  value={selectedDesignTypes}
                />
                <button
                  type="button"
                  className="button is-primary ml-3"
                  onClick={addDesignTypes}
                  disabled={selectedDesignTypes?.length === 0}
                >
                  {t('Add', { ns: 'common' })}
                </button>
              </FieldContainer>
              <FieldArray
                name="designTypeInstructions"
                render={(arrayHelpersInstructions) => (
                  <div>
                    {values.designTypeInstructions?.map((designType, index) => (
                      <FieldContainer
                        flex
                        key={designType.value}
                        name={`designTypeInstructions.${index}.instruction`}
                        label={designType.name}
                        required
                      >
                        <button
                          type="button"
                          className="button is-danger is-outlined is-align-self-center mr-2"
                          onClick={() => arrayHelpersInstructions.remove(index)}
                        >
                          <BoxIcon name="bx-trash" />
                        </button>

                        <Field
                          name={`designTypeInstructions.${index}.instruction`}
                          className="textarea"
                          component="textarea"
                          rows="2"
                          placeholder="Add description"
                          style={{ minWidth: 'auto' }}
                        />
                      </FieldContainer>
                    ))}
                  </div>
                )}
              />
            </>
          ) : (
            <div>
              <h4 className="title is-4 mb-3">{t('Custom instructions')}</h4>
              <p className="mb-5">
                {customInstructions === '' || customInstructions === null
                  ? 'None'
                  : customInstructions}
              </p>
              <Field
                name="keepExistingCustomInstructions"
                required
                component={CheckboxField}
                disabled={!edit}
                labelText="Keep the existing Custom Instructions"
              />

              <div className="mb-5">
                <Info
                  className="is-inline-flex is-clickable has-tooltip-arrow"
                  dataTooltip={t(
                    'Here you can add instructions for specific Design Type',
                    { ns: 'designProfile' },
                  )}
                >
                  <h4 className="title is-4 mb-0">
                    {t('Instructions', { ns: 'common' })}
                  </h4>
                </Info>
                <span className="tag is-success ml-3">
                  {t('New', { ns: 'common' })}
                </span>
              </div>

              {designTypeInstructions?.length > 0
                ? designTypeInstructions.map((item) => (
                    <div className="mb-5" key={item?.value}>
                      <h5 className="title is-5 mb-1">{item?.name}</h5>
                      <p>{item.instruction}</p>
                    </div>
                  ))
                : t('No instructions have been added.', { ns: 'common' })}
            </div>
          )}
        </div>
      </div>
      <ProfileContainer
        nonPriceListProfiles={nonPriceListProfiles}
        priceListProfiles={priceListProfiles}
        {...props}
      />
    </div>
  );
}

FieldArrayInner.propTypes = {
  data: PropTypes.object.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    customInstructions: PropTypes.string.isRequired,
    designProfiles: PropTypes.array.isRequired,
    designTypeInstructions: PropTypes.array.isRequired,
    selectedDesignTypes: PropTypes.array.isRequired,
  }).isRequired,
  errorMessages: PropTypes.array.isRequired,
};

export default FieldArrayInner;
