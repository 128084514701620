import { CREATE_INTEGRATION } from '@fullcontour/shared-api';

async function create({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  navigate,
  match,
}) {
  const result = await client.mutate({
    mutation: CREATE_INTEGRATION,
    variables,
  });
  const app = import.meta.env.VITE_APP_NAME;
  const { createIntegration } = result.data;

  if (createIntegration.errorMessages.length > 0) {
    setErrorMessages(createIntegration.errorMessages);
  } else {
    let link;

    const {
      params: { type, slug },
    } = match;

    if (app === 'admin_portal') {
      link =
        type === 'customer'
          ? `/locations/customer/${slug}/integrations`
          : `/organizations/manufacturer/${slug}/integrations`;
    }

    if (app === 'manuf_portal') {
      link = '/organizations/integrations';
    }

    if (app === 'customer_portal') {
      link = slug
        ? `/locations/${slug}/integrations`
        : '/locations/integrations';
    }

    clearErrorMessages();
    navigate(link);
  }
}
export default create;
