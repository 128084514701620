import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import withFormWrap from '../../FormFields/helpers/withFormWrap';
import DesignUserPasswordForm from './DesignUserPasswordForm';
import { initialValues, schema, update } from './helpers';

function DesignUserModalInner({
  initialValues = () => ({}),
  schema = () => ({}),
  getVariables = () => ({}),
  skipQuery = () => ({}),
  match = {},
  modalOpen,
  setModalOpen,
  ...rest
}) {
  const client = useApolloClient();
  function handleSubmit(values, { setSubmitting }) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };
    update({ variables, client, setModalOpen, ...rest });
    setSubmitting(false);
    setModalOpen(false);
  }
  return (
    <Formik
      initialValues={initialValues({ ...rest })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: onSubmit }) => (
        <form noValidate onSubmit={onSubmit}>
          <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head is-flex is-flex-direction-row is-justify-content-space-between pr-4">
                <h4 className="title is-4 modal-card-title mb-4">
                  Please enter a new password
                </h4>
                <button
                  type="button"
                  className="delete is-align-self-flex-start"
                  aria-label="close"
                  onClick={() => setModalOpen(false)}
                />
              </header>
              <section className="modal-card-body">
                {modalOpen && <DesignUserPasswordForm />}
              </section>
              <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
                <button type="submit" className="button is-success is-outlined">
                  <span className="icon">
                    <i className="bx bx-check" />
                  </span>
                  <span>Set Password</span>
                </button>
              </footer>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

DesignUserModalInner.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  DesignUserModalInner,
);
