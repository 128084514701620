import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadModal } from '../../../../../config/apollo';
import NonFormCheckboxField from '../../../../shared/FormFields/CheckboxInputs/NonFormCheckboxField';

function DownloadSelection({
  orderFileTypes = [],
  updateSelectedTypes,
  selectedTypes,
}) {
  const isDownloadModalData = useReactiveVar(downloadModal);

  const { t } = useTranslation('orderactions');

  const [areTrue, setAreTrue] = useState(
    selectedTypes.filter((type) => type.selected === true).length,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const selected = orderFileTypes.map((type) => ({
      ...type,
      selected: false,
    }));
    updateSelectedTypes(selected);
  }, [...(isDownloadModalData ? [orderFileTypes] : [])]);

  useEffect(() => {
    setAreTrue(selectedTypes.filter((type) => type.selected === true).length);
  }, [selectedTypes]);

  function setTypeToDownload(klass) {
    const selectedType = selectedTypes.findIndex(
      (type) => type.klass === klass,
    );
    const newTypes = [...selectedTypes];
    const newObject = newTypes[selectedType];
    newObject.selected = !newObject.selected;
    updateSelectedTypes(newTypes);
  }

  function toggleTypeToDownload(checked) {
    const newTypes = selectedTypes.map((type) => {
      const { selected, ...rest } = type;
      return { selected: checked, ...rest };
    });
    updateSelectedTypes(newTypes);
  }

  return (
    <>
      <div style={{ width: '100%', marginBottom: '2em' }}>
        <NonFormCheckboxField
          name="selectAll"
          labelText={t('Select All')}
          onChange={toggleTypeToDownload}
          checked={areTrue === selectedTypes.length}
          // indeterminate={areTrue < selectedTypes.length && areTrue > 0}
        />
      </div>
      {selectedTypes.map((type) => (
        <div style={{ width: '100%', marginBottom: '1em' }} key={type.id}>
          <NonFormCheckboxField
            name={type.name}
            labelText={t(`${type.name}`)}
            onChange={() => setTypeToDownload(type.klass)}
            checked={type.selected}
          />
        </div>
      ))}
    </>
  );
}

DownloadSelection.propTypes = {
  orderFileTypes: PropTypes.array,
  updateSelectedTypes: PropTypes.func.isRequired,
  selectedTypes: PropTypes.array.isRequired,
};

export default DownloadSelection;
