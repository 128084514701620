import PropTypes from 'prop-types';

function Tooth14({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 14)
    : upperArch;
  const variable = designType.toothChart ? 14 : 'upper';
  const handleClick = designType.toothChart ? toggleTooth : toggleArch;

  const isSelected = nestedTeeth[14]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <g
      onClick={() => handleClick(variable)}
      role="button"
      tabIndex={0}
      aria-pressed={isActive}
      onKeyDown={(e) => e.key === 'Enter' && handleClick(variable)}
    >
      <path
        className={className}
        d="M3020.8 1525.3c-99-85.9-172-36.3-245 13.3 -19.7 24.5-39 48.9-66 73.4 -82.7 79.5-67.1 133.4-19.2 178.9 29.8 33.1 58.4 65.6 73.5 91.4 17.2 17.8 33.1 31.4 46.6 37.9 63.7 28.6 88.1-11.3 123.9-31.3 128.6-67.8 174.6-162.1 141.5-282.1C3072.3 1560.6 3050.5 1537.8 3020.8 1525.3L3020.8 1525.3z"
      />
      <path
        className="st1"
        d="M3005.5 1629.6c20.8-96.4-29.4-85.8-56.6-109.7 -47.3-11.4-86.9-39.1-166.2 17.3 -59 82.1-41.4 128.2-48.8 186 -3.6 55.3 12.9 88.8 35.5 102.7 5.6 29.8 4.3 58.3 53.7 84.4 42.1 37.5 71.7 5.2 102.8-18.6 46.8-12.6 54.3-43.4 53.3-86.1C3074.5 1764.3 3021.2 1686 3005.5 1629.6L3005.5 1629.6z"
      />
      <path
        className="st2"
        d="M2820.8 1539.9c9.7 11.4 22.6 12.9 25.5 45 -4.4 32.9 13.7 65.7 31.2 98.5 18.2 17.7 12.3 65.6 17.2 100 -17.8 17-35.6 17.2-53.5 15.6l-33.7 29.7"
      />
      <path
        className="st2"
        d="M2987.3 1642.3c-12.1 13.1-21.1 28.2-54.9 26.9 -19.3-2.5-38.5-3.7-57.7 8.6"
      />
      <path
        className="st2"
        d="M2774.6 1654.1c36.9 6.3 91.4 3.8 101.5 23.7"
      />
      <path
        className="st2"
        d="M2966.5 1798.7c-37.6 17-53.5-0.8-73.3-12.5"
      />
      <path
        className="st2"
        d="M2935.7 1850.9l-17-52.1"
      />
    </g>
  );
}

Tooth14.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth14;
