import { useContext } from 'react';

import { useLocation } from 'react-router';
import { SidebarNavRoutes } from '../../routing';
import { OrderDetailsContext } from '../../shared';
import SidebarOrderDetailsMenu from './SidebarOrderDetailsMenu';
import SidebarStandardMenu from './SidebarStandardMenu';

function SidebarArea() {
  const location = useLocation();
  const splitPath = location.pathname.split('/');

  const isOrderDetails =
    splitPath[1] === 'orders' && splitPath[2] === 'details';

  const { headerHeight } = useContext(OrderDetailsContext);
  return (
    <div
      className="sidebarArea"
      style={{
        ...(isOrderDetails
          ? { paddingTop: headerHeight, background: 'white' }
          : {}),
      }}
    >
      {splitPath[1] === 'orders' && splitPath[2] !== 'details' ? (
        <SidebarNavRoutes />
      ) : !isOrderDetails ? (
        <SidebarStandardMenu>
          <SidebarNavRoutes />
        </SidebarStandardMenu>
      ) : (
        <SidebarOrderDetailsMenu>
          <SidebarNavRoutes />
        </SidebarOrderDetailsMenu>
      )}
    </div>
  );
}

export default SidebarArea;
