const awsAuthConfig = {
  development: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:647fd1a4-4d33-491e-93df-913d308e9df4',
        userPoolId: 'us-west-2_rQJBkJaqf',
        userPoolClientId: '2ommk49ba2j3rofc1plgq3qp40',
      },
    },
  },
  staging: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:623a6eda-51ca-4398-8c75-9e792dec73bd',
        userPoolId: 'us-west-2_yOVViXuoH',
        userPoolClientId: 'rgbodsnhee4hiou4qpt443hm4',
      },
    },
  },
  production: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:2108dc0b-230f-42c4-a166-c6cc2ee54b5d',
        userPoolId: 'us-west-2_FttUQhzI4',
        userPoolClientId: '23318bhm8nreu79k63l790ao4i',
      },
    },
  },
};

export default awsAuthConfig;
