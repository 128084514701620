import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { LoadingOrError } from '../../../../../shared';
import './MultiSelect.css';

function DynamicDependentMultiSelect({
  height = 150,
  variables = {},
  field: { name, value },
  form: { setFieldValue },
  label,
  query,
  dataname,
}) {
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const { loading, error, data } = useQuery(query, {
    variables,
    onCompleted: (response) => {
      setFilteredData(response[dataname]);
      setOriginalData(response[dataname]);
    },
  });

  const updateOption = (e) => {
    const opts = e.target.options;

    const v = [];
    for (let i = 0, l = opts.length; i < l; i += 1) {
      if (opts[i].selected) {
        v.push(opts[i].value);
      }
    }

    setFieldValue(name, v);
  };

  const selectAll = () => {
    const ids = data[dataname].map((d) => d.id);
    setFieldValue(name, ids);
  };

  const removeAll = () => {
    setFieldValue(name, []);
  };

  const filterData = (e) => {
    const val = e.target.value;
    const filtered = originalData.filter(
      (d) => d.name.toLowerCase().indexOf(val.toLowerCase()) !== -1,
    );

    setFilteredData(filtered);
  };

  return (
    <>
      <div className="required field">
        <label htmlFor={name}>{label}</label>

        <div className="icon input">
          <input
            className="fcMultiSelectSearch"
            placeholder="Filter..."
            onChange={filterData}
          />
          <i className="search icon" />
        </div>
        {!(loading || error) && (
          <select
            name={name}
            multiple
            className="fcMultiSelect"
            value={value}
            onChange={updateOption}
            style={{ height, minHeight: height }}
          >
            {[...filteredData]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((d) => (
                <option key={d.id} value={d.id}>
                  {d.name}
                </option>
              ))}
          </select>
        )}
        {loading || error ? (
          <div style={{ height }}>
            <LoadingOrError error={error} loading={loading} hideText />
          </div>
        ) : null}
      </div>
      <div style={{ textAlign: 'right' }}>
        <button
          type="button"
          className="button is-small is-danger is-outlined mr-2"
          onClick={removeAll}
        >
          clear
        </button>
        <button
          type="button"
          className="button is-small is-primary"
          onClick={selectAll}
        >
          select all
        </button>
      </div>
    </>
  );
}

DynamicDependentMultiSelect.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  dataname: PropTypes.string.isRequired,
  height: PropTypes.number,
  variables: PropTypes.object,
};

export default DynamicDependentMultiSelect;
