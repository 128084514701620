import PropTypes from 'prop-types';
import { ViewerContainer } from './components';
import './styles.css';

function OrthoObjViewerNoTreatment({
  order,
  redesignCountObj = null,
  iprs = null,
  height = '80vh',
}) {
  return (
    <ViewerContainer
      convertedTreatmentReviewFiles={order.convertedTreatmentReviewFiles}
      treatmentReviewFiles={order.treatmentReviewFiles}
      redesignCountObj={redesignCountObj}
      height={height}
      iprs={iprs}
    />
  );
}

OrthoObjViewerNoTreatment.propTypes = {
  order: PropTypes.object.isRequired,
  redesignCountObj: PropTypes.number,
  setRedesignCountDoc: PropTypes.func,
  height: PropTypes.string,
  iprs: PropTypes.object,
};

export default OrthoObjViewerNoTreatment;
