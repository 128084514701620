import { CREATE_SIGNED_LINK } from '@fullcontour/shared-api';
import { v4 as uuidv4 } from 'uuid';

async function CreateSignedLink({ client, foundFile }) {
  if (foundFile?.s3Key) {
    try {
      const { data } = await client.mutate({
        mutation: CREATE_SIGNED_LINK,
        variables: {
          input: {
            input: {
              s3Key: foundFile.s3Key,
            },
            clientMutationId: uuidv4(),
          },
        },
      });

      const {
        createSignedS3Link: {
          signedS3Link: { url },
          errorMessages,
        },
      } = data;

      if (errorMessages.length > 0) {
        return foundFile;
      }
      return { signedUrl: url, ...foundFile };
    } catch (_e) {
      return foundFile;
    }
  }
}

export default CreateSignedLink;
