import { useApolloClient } from '@apollo/client';
import { DESTROY_CONTACT } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { BoxIcon } from '../../../shared';
import AccordionCard from '../../../shared/Components/Accordion/AccordionCard';
import BillingContact from './BillingContact';
import BillingContactForm from './BillingContactForm/BillingContactForm';

function BillingContacts(props) {
  const { contacts, refetch } = props;

  const { t } = useTranslation('billing');

  const client = useApolloClient();
  const [action, setAction] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [contact, setContact] = useState({});

  const closeModal = () => {
    setAction(null);
    setModalOpen(false);
  };

  const openModal = (act, card) => {
    setAction(act);
    setContact(card || {});
    setModalOpen(true);
  };

  const destroyContact = async (contactToDestroy) => {
    await client.mutate({
      mutation: DESTROY_CONTACT,
      variables: {
        input: {
          id: contactToDestroy.id,
          clientMutationId: uuidv4(),
        },
      },
    });
    closeModal();
    refetch();
  };

  return (
    <div
      className="box is-flex is-flex-direction-column is-justify-content-space-between"
      style={{ minHeight: '100%' }}
    >
      <div>
        <h5 className="title is-5 mb-2">{t('Billing Contacts')}</h5>
        {contacts.length === 0 ? (
          <p>{t('No contacts Available')}</p>
        ) : (
          <div
            style={{
              maxHeight: '225px',
              overflowY: 'auto',
              padding: '2px',
              width: '100%',
            }}
          >
            {contacts.map((item, key) => (
              <AccordionCard
                key={`contact${key.toString()}`}
                card={{
                  cardBody: <BillingContact item={item} />,
                  cardHeader: (
                    <div className="is-flex is-justify-content-space-between is-align-items-center">
                      <span> {`${item.name}, ${item.title}`}</span>

                      <button
                        type="button"
                        className="button is-ghost mr-4"
                        onClick={() => openModal('Edit', item)}
                      >
                        <BoxIcon
                          name="bx-edit"
                          className="is-size-5 has-text-primary"
                        />
                      </button>
                    </div>
                  ),
                }}
              />
            ))}
          </div>
        )}
      </div>
      <div className="is-flex is-align-self-flex-end">
        <BillingContactForm
          {...props}
          modalOpen={modalOpen}
          openModal={openModal}
          closeModal={closeModal}
          action={action}
          contact={contact}
          destroyContact={destroyContact}
        />
      </div>
    </div>
  );
}

BillingContacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default memo(BillingContacts);
