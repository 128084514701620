import { useApolloClient, useQuery } from '@apollo/client';
import {
  AddressFields,
  CommonOrgFields,
  FileDropzone,
  FormGridWrap,
  FormSection,
  LoadingOrError,
  PhoneField,
  WebsiteField,
  withFormWrap,
} from '@fullcontour/common';
import { GET_MANUF_ORG } from '@fullcontour/shared-api';
import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { initialValues, schema, update } from './helpers/Manufacturer';

function OrganizationFormManufacturer({
  initialValues = () => ({}),
  schema = () => ({}),
  getVariables = () => ({}),
  skipQuery = () => ({}),
  match = {},
  separateId = null,
  ...rest
}) {
  const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_MANUF_ORG, {
    skip: skipQuery ? skipQuery() : false,
    variables: getVariables ? getVariables() : {},
    fetchPolicy: 'cache-and-network',
  });
  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Organization" />
    );
  }

  function handleSubmit(values) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };

    if (separateId && match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }

    if (separateId) {
      variables.variables.input.input.id = undefined;
    }

    if (match.params.action && match.params.action !== 'new') {
      update({ variables, client, ...rest });
    }
  }
  return (
    <Formik
      initialValues={initialValues({ data })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      <FormGridWrap match={match}>
        <FormSection title="Basic Info">
          <CommonOrgFields />
          <div className="field is-grouped">
            <div className="control is-expanded">
              <WebsiteField />
            </div>
            <div className="control is-expanded">
              <PhoneField />
            </div>
          </div>
          <Field
            name="logoUrl"
            component={FileDropzone}
            labelText="Upload A Logo"
          />
        </FormSection>
        <AddressFields />
      </FormGridWrap>
    </Formik>
  );
}

OrganizationFormManufacturer.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  getVariables: PropTypes.func,
  skipQuery: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  create: PropTypes.func,
  separateId: PropTypes.string,
};
export default flowRight(withFormWrap({ initialValues, schema }))(
  OrganizationFormManufacturer,
);
