import { ShowDetails } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { memo } from 'react';
import ShowHeader from './ShowHeader';

function ShowContainer({
  title,
  editLink,
  leftContent = null,
  rightContent = null,
  subHeader = null,
  subHeaderLabel = null,
  children = null,
  logoUrl = null,
  code = null,
}) {
  return (
    <>
      <ShowHeader
        title={title}
        code={code}
        logoUrl={logoUrl}
        editLink={editLink}
        leftContent={leftContent}
        rightContent={rightContent}
      />
      <div className="box">
        {subHeaderLabel ||
          (subHeader && (
            <ShowDetails
              subHeaderLabel={subHeaderLabel}
              subHeader={subHeader}
            />
          ))}
        <div className={`${children ? 'mt-2' : ''}`}>{children}</div>
      </div>
    </>
  );
}

ShowContainer.propTypes = {
  title: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  subHeader: PropTypes.node,
  subHeaderLabel: PropTypes.string,
  children: PropTypes.node,
  logoUrl: PropTypes.string,
  code: PropTypes.string,
};

export default memo(ShowContainer);
