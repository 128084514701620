import PropTypes from 'prop-types';

function Tooth16({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 16)
    : upperArch;
  const variable = designType.toothChart ? 16 : 'upper';
  const handleClick = designType.toothChart ? toggleTooth : toggleArch;

  const isSelected = nestedTeeth[16]?.includes(guideCodeId);
  const className = `st0 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <g
      onClick={() => handleClick(variable)}
      role="button"
      tabIndex={0}
      aria-pressed={isActive}
      onKeyDown={(e) => e.key === 'Enter' && handleClick(variable)}
    >
      <path
        className={className}
        d="M3002.6 2303.9c-57.5 5.3-109.3 18.9-144.2 50.4 -49.8 25.8-81.6 52.8-79 82.1 -16 44.8-3.5 77.3 5.8 111.1l26.6 55.6c16.8 32.3 51.5 53.9 105.8 63.6 63.9 10.6 127.8 23.3 191.6-13.1 72.6-27.1 76.1-65.5 83.1-103.4 21.4-38.8 35.8-85.6 27.3-157.9 -7.5-33.1-17.1-75.1-64-94.3S3066.6 2288.7 3002.6 2303.9L3002.6 2303.9z"
      />
      <path
        className="st1"
        d="M3173.7 2363c53.4 75.7 16.8 109.1-5.2 149.3 6 68.2 4.2 120.6-42 114.2 -68.1 48.5-117.7 22.5-171.8 14.5 -7.8-12.3-8.3-28.3-29.7-33.7 0 0-47.7-102.2-53.8-118.2 -6.2-15.9-11.2-45.5 20.9-77.5 0 0 49.6-51.9 81.5-66.4 15.2-6.9 33-4.8 49.3-8.6 33.3-7.7 98.5-28.4 98.5-28.4C3152.6 2301.3 3172.1 2317.5 3173.7 2363L3173.7 2363z"
      />
      <path
        className="st2"
        d="M2954.5 2569.2c-2-1.3 33.9-24.2 59.1-34 12.9-5 20.6-20.5 23.9-33.9 6.7-27.6-20-62.6-11.5-84.5s26.4-22.8 42.2-29.7c10.6-4.6 16.8-0.8 33.8-7.1"
      />
      <path
        className="st3"
        d="M2975.1 2391.7c22.5 6.6 51 12.2 52.2 22.4"
      />
      <path
        className="st2"
        d="M3099.4 2509.6c-9.1-4.5-20.8-10-35.2-5.5 -14.5 4.5-22 5.8-26.8-7"
      />
      <path
        className="st2"
        d="M2976.8 2501.5c21.1-0.9 44.3 11.8 61.9-12.8"
      />
    </g>
  );
}

Tooth16.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth16;
