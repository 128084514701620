const findCurrentFeedback = (
  feedbacks,
  redesignCount,
  user,
  approvalDoctorId,
  feedbackToEdit,
) => {
  const currentFeedback =
    feedbackToEdit ||
    (feedbacks?.length > 0
      ? feedbacks.find(
          (feedback) =>
            (!approvalDoctorId &&
              feedback.redesignCount === redesignCount &&
              feedback.userId === user.currentUser.username) ||
            (approvalDoctorId &&
              feedback.redesignCount === redesignCount &&
              !feedback.userId),
        )
      : null);

  const feedbackToShow = currentFeedback?.answers.reduce((obj, item) => {
    obj[item.orderItem.designTypeId] = item.options.reduce(
      (optionsObj, option, index, arr) => {
        optionsObj[option.id] = `${option.category.name} > ${option.name}`;

        if (index === arr.length - 1) {
          optionsObj[`${option.category.id}-other`] = item.customReason || '';
        }

        return optionsObj;
      },
      {},
    );

    return obj;
  }, {});

  return { feedbackToShow, currentFeedback };
};

export default findCurrentFeedback;
