import { useApolloClient, useQuery } from '@apollo/client';
import { GET_INTEGRATION } from '@fullcontour/shared-api';
import { Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { LoadingOrError, withFormWrap } from '../../../shared';
import IntegrationFormInner from './IntegrationFormInner';
import { create, initialValues, schema, update } from './helpers';
function IntegrationForm({
  initialValues = () => ({}),
  schema = () => ({}),
  match = {},
  setErrorMessages = () => ({}),
  clearErrorMessages = () => ({}),
  navigate = () => ({}),
  separateId = null,
}) {
  const client = useApolloClient();
  const { t } = useTranslation('formfields');
  const [selectedType, setSelectedType] = useState(null);
  const { action, slug, root } = useParams();

  const { data, loading, error } = useQuery(GET_INTEGRATION, {
    skip: match.params.action === 'new',
    variables: { id: match.params.id },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (action === 'edit' && !selectedType && !loading && !error) {
      setSelectedType(data.integration.integrationType);
    }
  }, [action, error, data, loading, selectedType]);

  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Integration" />
    );
  }

  function handleSubmit(values) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };

    if (separateId && match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }

    if (separateId) {
      variables.variables.input.input.id = undefined;
    }

    if (match.params.action && match.params.action === 'new') {
      create({
        variables,
        client,
        setErrorMessages,
        clearErrorMessages,
        navigate,
        match,
      });
    } else {
      update({
        variables,
        client,
        setErrorMessages,
        clearErrorMessages,
        navigate,
        match,
      });
    }
  }
  return (
    <Formik
      initialValues={initialValues(
        action === 'edit' ? { data: { integration: data.integration } } : {},
      )}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <IntegrationFormInner
          action={action}
          root={root}
          slug={slug}
          t={t}
          values={values}
          setFieldValue={setFieldValue}
          setSelectedType={setSelectedType}
          selectedType={selectedType}
          match={match}
        />
      )}
    </Formik>
  );
}

IntegrationForm.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  create: PropTypes.func,
  separateId: PropTypes.string,
  setErrorMessages: PropTypes.func,
  clearErrorMessages: PropTypes.func,
  navigate: PropTypes.func,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  IntegrationForm,
);
