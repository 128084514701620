import BasicProfileForm from '../../views/Profile/BasicProfile/BasicProfileForm';
import NotificationsForm from '../../views/Profile/NotificationSettings/NotificationSettingsForm';
import UpdatePasswordForm from '../../views/Profile/UpdatePassword/UpdatePasswordForm';

const routes = [
  {
    path: '/:root(profile)',
    component: BasicProfileForm,
    exact: true,
  },
  {
    path: '/:root(profile)/notifications',
    component: NotificationsForm,
    exact: true,
  },
  {
    path: '/:root(profile)/update-password',
    component: UpdatePasswordForm,
    exact: true,
  },
];

export default routes;
