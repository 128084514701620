import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function NonFormCheckboxField({
  name,
  type = 'checkbox',
  headerText = null,
  removeLabel = false,
  labelText = null,
  required = false,
  disabled = false,
  customToggle = null,
  onChange = null,
  checked = undefined,
  size = '',
  readOnly = false,
}) {
  const { t } = useTranslation('formfields');
  const [internalChecked, setInternalChecked] = useState(false);

  let label = ' ';
  if (labelText && !removeLabel) {
    label = labelText;
  }
  if (!(labelText || removeLabel)) {
    label = name;
  }

  function toggle(e) {
    if (checked === undefined) {
      setInternalChecked(e.target.checked);
    }
    if (onChange) {
      onChange(e.target.checked);
    }
  }

  return (
    <div
      style={{
        pointerEvents: readOnly ? 'none' : null,
        cursor: readOnly ? 'default' : null,
      }}
    >
      {headerText && <h6 className="title is-6 mb-2">{t(headerText)}</h6>}
      <div className="is-flex is-align-items-center">
        <input
          id={name}
          name={name}
          type={type}
          className={`is-checkradio is-primary ${size}`}
          disabled={disabled}
          onChange={customToggle || toggle}
          checked={checked || internalChecked}
          required={required}
        />
        <label className="checkbox" htmlFor={name}>
          {t(label)} {required && <span className="has-text-danger">*</span>}
        </label>
      </div>
    </div>
  );
}

NonFormCheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  headerText: PropTypes.string,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  removeLabel: PropTypes.bool,
  type: PropTypes.string,
  customToggle: PropTypes.func,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default NonFormCheckboxField;
