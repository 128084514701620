import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_LOCATION_GUIDE_CODE_GROUPS } from '@fullcontour/shared-api';
import { useFormikContext } from 'formik';
import { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Accordion, DesignGuideOrderContext, GuideCodeCard } from '../../..';

import { groupsDataToFormValues } from '../../../../views';

function GuideBodyDesignGroups({ designType }) {
  const { values, setValues, setFieldValue } = useFormikContext();

  const { t } = useTranslation('designProfile');

  const { data, loading } = useQuery(GET_LOCATION_GUIDE_CODE_GROUPS, {
    onCompleted(response) {
      const initialValues = groupsDataToFormValues(
        response.labLocationGuideCodeGroups,
      );
      setValues({ ...values, ...initialValues });
    },
  });
  const { orderItemIndex } = useContext(DesignGuideOrderContext);

  const selectedDesignTypeId = values.orderItems[orderItemIndex].designTypeId;

  const addGroup = (group) => {
    const currentGuideCodes = values.orderItems[orderItemIndex].guideCodes;
    const currentGroup = values.selectedCodes[group.id];

    const updatedGuideCodes = currentGuideCodes.map((item) => {
      if (currentGroup[item.categoryId]) {
        const codeToUpdate = group.guideCodes.find(
          (code) => code.id === currentGroup[item.categoryId],
        );

        return {
          ...item,
          codes: [codeToUpdate.name],
          description:
            codeToUpdate.description ||
            codeToUpdate.guideCategory.shortDescription,
          imageUrl:
            codeToUpdate.imageUrl || codeToUpdate.guideCategory.imageUrl,
        };
      }

      return item;
    });

    const newGuideCodes = group.guideCodes.reduce((arr, guideCode) => {
      if (
        !currentGuideCodes.some(
          (currentGuideCode) =>
            currentGuideCode.categoryId === guideCode.guideCategory.id,
        )
      ) {
        arr.push({
          categoryId: guideCode.guideCategory.id,
          codeId: guideCode.id,
          categoryName: guideCode.guideCategory.name,
          codes: [guideCode.name],
          categoryAdditionalInfo: {
            guideCategoryImages: guideCode.guideCategory.guideCategoryImages,
            fullDescription: guideCode.guideCategory.description,
          },
          description:
            guideCode.description || guideCode.guideCategory.shortDescription,
          fileUrl: guideCode.imageUrl || guideCode.guideCategory.imageUrl,
          showDropdown: guideCode.guideCategory.showDropdown,
        });
      }

      return arr;
    }, []);

    setFieldValue(`orderItems[${orderItemIndex}].guideCodes`, [
      ...updatedGuideCodes,
      ...newGuideCodes,
    ]);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const designGuidesData = useMemo(() => {
    if (loading) {
      return null;
    }
    const filteredCodeGroups = data.labLocationGuideCodeGroups.reduce(
      (arr, group) => {
        const updatedGuideCodes = [];
        group.guideCodes.map((guideCode) => {
          const codeFromCurrentDesignType =
            guideCode.guideCategory.guideCategoryGroup.designTypes.some(
              (designType) => designType.id === selectedDesignTypeId,
            );

          if (codeFromCurrentDesignType) {
            updatedGuideCodes.push(guideCode);
          }
          return null;
        });

        if (updatedGuideCodes.length > 0) {
          arr.push({
            ...group,
            guideCodes: updatedGuideCodes,
          });
        }

        return arr;
      },
      [],
    );

    return {
      children: filteredCodeGroups.map((item, key) => {
        let allElementsSelected = true;
        const cardElements = item.guideCodes.map((guideCode) => {
          const currentGuideCodes =
            values.orderItems[orderItemIndex].guideCodes;

          if (
            allElementsSelected &&
            !currentGuideCodes.some(
              (currentGuideCode) =>
                currentGuideCode.categoryId === guideCode.guideCategory.id &&
                currentGuideCode.codes[0] === guideCode.name,
            )
          ) {
            allElementsSelected = false;
          }

          const guideCodeData = {
            ...guideCode,
            imageUrl: guideCode.imageUrl,
            category: guideCode.guideCategory,
            designType,
          };

          return (
            <GuideCodeCard
              key={guideCodeData.id}
              actionButtons={
                <GuideCodeCard.ActionButtons>
                  <GuideCodeCard.ButtonNestedSelection customNested />
                </GuideCodeCard.ActionButtons>
              }
              image={<GuideCodeCard.Image />}
              cardDropdown={<GuideCodeCard.Dropdown disabled />}
              nestedLabels={<GuideCodeCard.NestedLabels />}
              footer={<GuideCodeCard.CardFooter />}
              data={guideCodeData}
            />
          );
        });

        const cardBody = (
          <div key={`cbody-${key.toString()}`}>
            <div className="card__element-group">{cardElements}</div>
            <div className="is-flex is-justify-content-flex-end mt-1">
              {allElementsSelected ? (
                <div className="is-flex is-align-items-center has-text-success">
                  {t('Added')} <i className="bx bx-check-circle mr-3 ml-1" />
                </div>
              ) : (
                <button
                  onClick={() => addGroup(item)}
                  type="button"
                  className="button is-primary"
                >
                  <span>{t('Add codes')}</span>
                  <span className="icon is-small">
                    <i className="bx bx-plus-circle" />
                  </span>
                </button>
              )}
            </div>
          </div>
        );

        return {
          cardHeader: item.name,
          cardId: item.id,
          cardBody,
          cardHeaderText:
            cardElements.length === 0 ? (
              <span className="tag ml-3">{t('Empty')}</span>
            ) : (
              `${cardElements.length} ${t('codes')}`
            ),
        };
      }),
    };
  }, [
    selectedDesignTypeId,
    data,
    values,
    designType,
    loading,
    t,
    orderItemIndex,
  ]);

  return (
    <section>
      <h6 className="title is-6">{t('Design Guide Groups')}</h6>
      <p className="mb-5">
        {t(
          'Only codes that belong to the selected design type are displayed in the groups',
        )}
      </p>
      {designGuidesData && <Accordion data={designGuidesData} />}
    </section>
  );
}
GuideBodyDesignGroups.propTypes = {
  designType: PropTypes.object.isRequired,
};

export default GuideBodyDesignGroups;
