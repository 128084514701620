import PropTypes from 'prop-types';

function Tooth28({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart ? teeth.includes(28) : lowerArch;
  const variable = designType.toothChart ? 28 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));

  const isSelected = nestedTeeth[28]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <g
      onClick={handleClick}
      role="button"
      tabIndex={0}
      aria-pressed={isActive}
      onKeyDown={(e) => e.key === 'Enter' && handleClick()}
    >
      <path
        className={className}
        d="M938.9 4364.6c-33.6 46.3-41.6 79.5-38.2 106.8 10 47.5 15.2 98.6 65.2 117.4 79.8 26.6 96.9 4.5 129.3-5.7 37.1-18 66.2-40.6 87.2-67.9 16.2-11.2 15-22.4 15.6-33.6 -2.5-20.8 2-34.9 3.6-51.7 2.4-32.7-11.7-55.5-19.6-81.9 -23.5-19.1-40.7-45.4-79.2-47.6l-66.4-1.9C995.6 4296.3 967.7 4331.8 938.9 4364.6L938.9 4364.6z"
      />
      <path
        className="st1"
        d="M956.1 4374.1c11.9 16.7 26.6 36.3 34.4 46.6 4.2 7.4 0.7 12.6 1.7 24.1 -5 30 4.3 28.1 12.8 34.5 8.7 6.7 17.6 13 24.1 24.2 7.4 22.4 17.9 17.4 27.5 20.8l39.6 7c31.4 9.5 36.4-5 53.5-5.9"
      />
      <path
        className="st1"
        d="M1140.4 4475.5c2.1 11.4-5.4 24.2 11.1 33.6"
      />
      <path
        className="st1"
        d="M1055 4488.2l73.5-87.7c15.4-18.7 27.3-23.2 28.4 13.9"
      />
      <path
        className="st1"
        d="M1043.1 4409.7c7.2-22.1 27.5-44.2 62.2-66.2 -13.5 4.7-26.9 9.5-44.8 0.7 -21.2-10.4-31.4 1.6-44 8.5"
      />
    </g>
  );
}

Tooth28.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.number).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth28;
