import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

function CloneModal({
  valueToClone,
  options,
  cloneName,
  refetch,
  setParentFieldValue,
  fieldName,
  loading,
  cloneMutation,
  mutationDataName,
  dataName,
  showClone = false,
  showDescriptionField = false,
  isPriceList = false,
}) {
  const client = useApolloClient();
  const [confirmState, setConfirmState] = useState({ open: false });
  const [newItemName, setNewItemName] = useState('');
  const [newItemDescription, setNewItemDescription] = useState('');
  const [cloneLoading, setCloneLoading] = useState(false);
  const selected = options.find((item) => item.id === valueToClone);

  const show = () => setConfirmState({ open: true });

  const handleCancel = () => setConfirmState({ open: false });

  const handleCloneItem = async () => {
    let dynamicInputs = {
      name: newItemName,
    };
    if (!isPriceList) {
      dynamicInputs = {
        ...dynamicInputs,
        ...{ id: valueToClone },
      };
    }
    if (showDescriptionField) {
      dynamicInputs = {
        ...dynamicInputs,
        ...{ description: newItemDescription },
      };
    }
    if (isPriceList) {
      dynamicInputs = {
        ...dynamicInputs,
        ...{ currency: 'USD', parentListId: valueToClone },
      };
    }
    await client
      .mutate({
        mutation: cloneMutation,
        variables: {
          input: {
            input: dynamicInputs,
            clientMutationId: uuidv4(),
          },
        },
      })
      .then((d) => {
        refetch();
        setParentFieldValue(fieldName, d.data[mutationDataName][dataName].id);
        setCloneLoading(false);
        setConfirmState({ open: false });
      });
  };

  const CloneItem = () => {
    setCloneLoading(true);
    handleCloneItem();
  };

  return (
    <>
      {showClone && (
        <div className="control is-align-self-flex-end mr-0">
          <button type="button" onClick={show} className="button">
            Clone
          </button>
        </div>
      )}
      <div className={`modal ${confirmState.open ? 'is-active' : ''}`}>
        <div
          className="modal-background"
          onClick={handleCancel}
          aria-hidden="true"
        />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Clone: {cloneName}</p>
          </header>
          <section className="modal-card-body">
            <h6 className="title is-6 my-5">Clone: {selected?.name}</h6>
            <div className="field">
              <label className="label" htmlFor="newName">
                Name
              </label>
              <div className="control">
                <input
                  id="newName"
                  name="newName"
                  className="input"
                  type="text"
                  placeholder={`${cloneName} Name`}
                  required
                  minLength="2"
                  onChange={(e) => setNewItemName(e.target.value)}
                />
              </div>
            </div>
            {showDescriptionField && (
              <div className="field">
                <label className="label" htmlFor="newDescription">
                  Description
                </label>
                <div className="control">
                  <textarea
                    id="newDescription"
                    name="newDescription"
                    className="textarea is-small"
                    placeholder={`${cloneName} Description`}
                    required
                    onChange={(e) => setNewItemDescription(e.target.value)}
                  />
                </div>
              </div>
            )}
          </section>
          <footer className="modal-card-foot">
            <div className="buttons has-text-right">
              <button
                type="button"
                className="is-danger is-outlined button"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`button is-primary ${
                  (loading || cloneLoading) && 'is-loading'
                }`}
                onClick={CloneItem}
                disabled={loading || newItemName.length === 0}
              >
                Clone
              </button>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

CloneModal.propTypes = {
  valueToClone: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  cloneName: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  setParentFieldValue: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  showClone: PropTypes.bool,
  cloneMutation: PropTypes.object.isRequired,
  showDescriptionField: PropTypes.bool,
  isPriceList: PropTypes.bool,
  mutationDataName: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
};

export default memo(CloneModal);
