import PropTypes from 'prop-types';
import { DropdownSelect } from '../../../../Components';

function ButtonInformattionShared({
  guideCategoryImages = null,
  description = null,
}) {
  return (
    <DropdownSelect
      customOptions
      item
      noPadding
      style={{ padding: 0 }}
      pointing="left"
      trigger={
        <button
          type="button"
          style={{ height: 28, width: 28 }}
          className="button is-rounded has-background-white mr-1 p-0"
        >
          i
        </button>
      }
    >
      <div className="p-2">
        {!(guideCategoryImages?.length > 0 || description) ? (
          <p>No additional information</p>
        ) : (
          <>
            <p>{description}</p>
            <div>
              {guideCategoryImages?.map((item) => (
                <figure
                  className="image"
                  key={`${item.url}-${Math.random() + 1}`}
                >
                  <img src={item.imageUrl} alt="Guide Code" />
                </figure>
              ))}
            </div>
          </>
        )}
      </div>
    </DropdownSelect>
  );
}

ButtonInformattionShared.propTypes = {
  guideCategoryImages: PropTypes.array,
  description: PropTypes.string,
};

export default ButtonInformattionShared;
