import { transformNestedData } from '../../../../../../transforms';

const orderCodesSplit = ({ orderItems, isSpecific, allowNested }) =>
  orderItems.reduce(
    (arr, orderItem) => {
      const transformedTeeth = transformNestedData(
        orderItem.nestedTeeth,
        allowNested,
        Number.parseInt,
      );
      const transformedArches = transformNestedData(
        orderItem.nestedArches,
        allowNested,
        (key) => key,
      );

      orderItem.guideCodes.map((guideCode) => {
        const codeWithTransformedData = allowNested
          ? {
              ...guideCode,
              nestedTeeth: transformedTeeth[guideCode.codeId],
              nestedArches: transformedArches[guideCode.codeId],
            }
          : guideCode;
        if (isSpecific) {
          if (!guideCode.isDefault) {
            arr.push(codeWithTransformedData);
          }
        } else if (guideCode.isDefault) {
          arr.push(codeWithTransformedData);
        }
        return null;
      });

      return arr;
    },

    [],
  );

export default orderCodesSplit;
