import { GET_ORDER, GET_ORDER_APPROVAL_LOG } from '@fullcontour/shared-api';
import { handleTryCatch } from '../../HelperFunctions';

const createFeedback = handleTryCatch(
  async ({
    feedbackId,
    selectedOptions,
    orderId,
    createFeedbackMutation,
    doRedesign,
    closeModal,
    isPositive,
    message,
    callback,
    approvalDoctorId,
    feeedbackUser,
    isUpdate,
  }) => {
    const rating = isPositive ? 5 : 0;
    const id = approvalDoctorId || orderId;
    const query = approvalDoctorId ? GET_ORDER_APPROVAL_LOG : GET_ORDER;

    await createFeedbackMutation({
      variables: {
        input: {
          input: {
            [approvalDoctorId ? 'id' : 'orderId']: id,
            ...(!approvalDoctorId ? { feedbackId } : {}),
            rating,
            doRedesign,
            ...(feeedbackUser && !isUpdate
              ? { customerId: feeedbackUser }
              : {}),
            redesignReason: message,
            answers: Object.keys(selectedOptions).map((item) => ({
              designTypeId: item,
              rating,
              ...(!isPositive
                ? {
                    reasonIds: Object.keys(selectedOptions[item]).filter(
                      (key) =>
                        selectedOptions[item][key] && !key.includes('-other'),
                    ),
                    customReason:
                      selectedOptions[item][
                        Object.keys(selectedOptions[item]).find((key) =>
                          key.includes('-other'),
                        )
                      ] || '',
                  }
                : {}),
            })),
          },
        },
      },
      refetchQueries: [
        {
          query,
          variables: { id },
        },
      ],
    });
    if (closeModal) {
      closeModal();
    }
    if (callback) {
      callback();
    }
  },
  {
    successText: 'Your feedback is very important for us!',
  },
);

export default createFeedback;
