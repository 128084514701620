function initialValues(props) {
  const {
    data: { manufacturerOrganization = {} } = {},
  } = props;
  return {
    id: manufacturerOrganization.id || null,
    name: manufacturerOrganization.name || '',
    hidden:
      manufacturerOrganization.hidden !== null &&
      manufacturerOrganization.hidden !== undefined
        ? manufacturerOrganization.hidden
        : false,
    shortName: manufacturerOrganization.shortName || '',
    administratorLocationId:
      manufacturerOrganization.administratorLocationId || '',
    language: manufacturerOrganization.language || 'en',
    website: manufacturerOrganization.website || '',
    address: manufacturerOrganization.address || {
      address: '',
      address2: '',
      city: '',
      region: '',
      postalCode: '',
      country: 'US',
    },
    phone: manufacturerOrganization.phone || '',
    logoUrl:
      manufacturerOrganization.logoUrl ||
      `https://s3-us-west-2.amazonaws.com/${
        import.meta.env.VITE_ASSET_BUCKET
      }/assets/images/l/default.svg`,
    designTypeIds: manufacturerOrganization.designTypeIds || [],
  };
}

export default initialValues;
