import PropTypes from 'prop-types';
import ScanItem from './ScanItem';

function ElementScan({ selectedFile = null, selectedFolder = null }) {
  return (
    <div className="is-flex is-flex-wrap-wrap">
      {selectedFile && <ScanItem file={selectedFile} />}
      {selectedFolder?.selectedItems.map((item) => (
        <ScanItem file={item} key={item.id} />
      ))}
    </div>
  );
}

ElementScan.propTypes = {
  selectedFile: PropTypes.object,
  selectedFolder: PropTypes.object,
};

export default ElementScan;
