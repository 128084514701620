const groupsDataToFormValues = (labLocationGuideCodeGroups) => {
  if (labLocationGuideCodeGroups) {
    const initialSelectedCodes = labLocationGuideCodeGroups.reduce(
      (obj, group) => {
        const codesData = group.guideCodes.reduce((guideObj, guide) => {
          guideObj[guide.guideCategory.id] = guide.id;
          return guideObj;
        }, {});
        obj[group.id] = codesData;
        return obj;
      },
      {},
    );

    return {
      selectedCodes: initialSelectedCodes,
    };
  }

  return {
    selectedCodes: {},
  };
};

export default groupsDataToFormValues;
