import { MovementTableWithViewerProvider } from '@fullcontour/threed';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import './styles.css';
import { useApp } from '@fullcontour/hooks';
import OrthoObjViewerNoTreatment from './OrthoObjViewerNoTreatment';
import OrthoObjViewerWithTreatment from './OrthoObjViewerWithTreatment';
import useIprsCalculations from './utils/useIprsCalculations';

function OrthoObjViewerInner({
  order,
  dentalNotation = null,
  setRedesignCountDoc = null,
  height = '80vh',
  otpFiles,
  orderRedesignCount,
  redesignCountObj,
  setRedesignCountObj,
}) {
  const { isPatient } = useApp();

  const [activeStage, setActiveStage] = useState(0);
  const [iprs, setIprs] = useState({});

  useEffect(() => {
    if (setRedesignCountDoc) {
      setRedesignCountDoc(redesignCountObj);
    }
  }, [setRedesignCountDoc, redesignCountObj]);

  const calculations = useIprsCalculations({
    setIprs,
    otpFiles,
    redesignCountObj,
    activeStage,
  });

  const providerValue = useMemo(
    () => ({
      activeStage,
      setActiveStage,
      iprs,
      ...calculations,
    }),
    [activeStage, iprs, calculations],
  );

  return redesignCountObj !== null && order ? (
    <MovementTableWithViewerProvider value={providerValue}>
      {isPatient && (
        <OrthoObjViewerNoTreatment
          order={order}
          redesignCountObj={redesignCountObj}
          iprs={iprs}
          height={height}
        />
      )}

      {!isPatient && (
        <OrthoObjViewerWithTreatment
          order={order}
          redesignCountObj={redesignCountObj}
          iprs={iprs}
          height={height}
          dentalNotation={dentalNotation}
          setRedesignCountObj={setRedesignCountObj}
          otpFiles={otpFiles}
          orderRedesignCount={orderRedesignCount}
        />
      )}
    </MovementTableWithViewerProvider>
  ) : null;
}

OrthoObjViewerInner.propTypes = {
  order: PropTypes.object.isRequired,
  dentalNotation: PropTypes.string,
  setRedesignCountDoc: PropTypes.func,
  height: PropTypes.string,
  otpFiles: PropTypes.array.isRequired,
  orderRedesignCount: PropTypes.number.isRequired,
  redesignCountObj: PropTypes.number.isRequired,
  setRedesignCountObj: PropTypes.func.isRequired,
};

export default OrthoObjViewerInner;
