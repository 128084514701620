import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { OrthoContext } from '../../../../shared/Context/OrthoContext';
import Styles from './AdditionalControls.module.scss';
import AttachmentControl from './AttachmentControl';
import GingivaControl from './GingivaControl';
import GridControl from './GridControl';
import IprControl from './IprControl';
import OverlayControl from './OverlayControl';
import ThemeControl from './ThemeControl';

function AdditionalControls(props) {
  const { setCurrentTheme, theme } = props;

  const { visibility, setVisibility, iprs, showAttachmentsButton } =
    useContext(OrthoContext);

  const showIpr = useMemo(
    () => iprs && Object.values(iprs).some((ipr) => ipr.showIPR),

    [],
  );

  const app = import.meta.env.VITE_APP_NAME;

  function toggleTheme() {
    setCurrentTheme(theme === 'light' ? 'dark' : 'light');
  }

  function toggleVisibility() {
    setVisibility({ ...visibility, attachments: !visibility.attachments });
  }

  function toggleGrid() {
    setVisibility({ ...visibility, grid: !visibility.grid });
  }

  function toggleGingiva() {
    setVisibility({ ...visibility, gingiva: !visibility.gingiva });
  }

  function toggleOverlay() {
    setVisibility({ ...visibility, overlay: !visibility.overlay });
  }

  function toggleIpr() {
    setVisibility({ ...visibility, ipr: !visibility.ipr });
  }

  return (
    <div className={Styles.additionalControlsWrap}>
      {app !== 'approval-patient-full' && app !== 'approval-patient' && (
        <GridControl toggle={toggleGrid} visible={visibility.grid} />
      )}
      {showAttachmentsButton && (
        <AttachmentControl
          toggle={toggleVisibility}
          visible={visibility.attachments}
        />
      )}

      <ThemeControl toggle={toggleTheme} theme={theme} />
      {app !== 'approval-patient-full' && app !== 'approval-patient' && (
        <>
          <GingivaControl toggle={toggleGingiva} visible={visibility.gingiva} />
          <OverlayControl toggle={toggleOverlay} visible={visibility.overlay} />
          {showIpr && (
            <IprControl toggle={toggleIpr} visible={visibility.ipr} />
          )}
        </>
      )}
    </div>
  );
}

AdditionalControls.propTypes = {
  setCurrentTheme: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

export default AdditionalControls;
