import { NotificationManager } from 'react-notifications';
import { overpanelCasesData } from '../../../config/apollo';

class CreateNotification {
  successNotifications = 0;

  totalFiles = 0;

  errorNotifications = [];

  createGroupNotification({
    type,
    text,
    total,
    messageSingle,
    messagePlural,
    singleClick,
    errorMessage,
  }) {
    if (type === 'success') {
      this.successNotifications += 1;
    } else {
      this.errorNotifications.push(text);
    }

    if (total > this.totalFiles) {
      this.totalFiles = total;
    }

    if (
      this.successNotifications + this.errorNotifications.length ===
        this.totalFiles ||
      singleClick
    ) {
      if (this.successNotifications) {
        NotificationManager.success(
          `${this.successNotifications} ${
            this.successNotifications === 1 ? messageSingle : messagePlural
          }`,
        );
      }
      if (this.errorNotifications.length > 0) {
        NotificationManager.error(
          <ul style={{ paddingLeft: '20px' }}>
            {this.errorNotifications.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>,
          `${this.errorNotifications.length} ${errorMessage}`,
        );
      }

      this.resetNotifications();
    }
  }

  static createNotification({ type, message, title }) {
    switch (type) {
      case 'info':
        NotificationManager.info(message, title);
        break;
      case 'success':
        NotificationManager.success(message, title);
        break;
      case 'warning':
        NotificationManager.warning(message, title);
        break;
      case 'error':
        NotificationManager.error(message, title);
        break;
      default:
        return null;
    }

    return null;
  }

  resetNotifications() {
    this.successNotifications = 0;
    this.totalFiles = 0;
    this.errorNotifications = [];
    overpanelCasesData({});
  }
}
export default CreateNotification;
