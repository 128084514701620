import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import GuideSelectionPopup from './GuideSelectionPopup';

function GuideSelection({ selectedDesignType = null, index }) {
  const { values } = useFormikContext();
  const { orderItems = [] } = values;

  return selectedDesignType?.designGuideCategories.length > 0 ? (
    <div
      style={{
        display: 'inline-block',
        verticalAlign: 'top',
        width: '25px',
        height: '25px',
        flexBasis: '25px',
        flexShrink: 0,
      }}
    >
      <GuideSelectionPopup
        designGuideCategories={selectedDesignType.designGuideCategories}
        currentOrderItem={orderItems[index]}
        index={index}
      />
    </div>
  ) : null;
}

GuideSelection.propTypes = {
  selectedDesignType: PropTypes.object,
  index: PropTypes.number.isRequired,
};

export default GuideSelection;
