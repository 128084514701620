import { useQuery } from '@apollo/client';
import { GET_ORDER_FORM } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import {
  DesignPreferencesContext,
  LoadingOrError,
} from '../../../../../shared';
import './OrderFormPreferences.css';
import Preferences from './Preferences';
import PreferenceSection from './Preferences/PreferenceTypes/PreferenceSection';

function OrderFormPreferences(props) {
  const { orderId } = props;

  const { setTriggerRerender, setIsEmpty } = useContext(
    DesignPreferencesContext,
  );

  const { error, loading, data } = useQuery(GET_ORDER_FORM, {
    variables: { orderId },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (
        (response?.orderForm?.metadata?.Patient ||
          (data?.orderForm?.metadata &&
            'AdditionalEmail' in data.orderForm.metadata)) &&
        setTriggerRerender
      ) {
        setIsEmpty(false);
        setTriggerRerender((prev) => !prev);
      }
    },
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Case Form" />;
  }
  if (data?.orderForm?.metadata?.Patient) {
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Case Form</p>
            </header>
            <div className="card-content" style={{ minHeight: 300 }}>
              <div className="orderFormPreferences">
                <Preferences
                  prescription={
                    data?.orderForm?.metadata?.TreatmentPrescription
                  }
                  patient={data?.orderForm?.metadata?.Patient}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (
    data?.orderForm?.metadata &&
    'AdditionalEmail' in data.orderForm.metadata
  ) {
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Case Form</p>
            </header>
            <div className="card-content" style={{ minHeight: 300 }}>
              <div className="orderFormPreferences">
                <div className="columns">
                  {data?.orderForm?.metadata?.PreferredImplantLines?.length >
                    0 && (
                    <PreferenceSection title="Preferred Implant Lines">
                      <div>
                        <p style={{ wordBreak: 'break-all' }}>
                          {data?.orderForm?.metadata?.PreferredImplantLines?.map(
                            (line, key) => (
                              <>
                                {line} <br key={`line${key.toString()}`} />
                              </>
                            ),
                          )}
                        </p>
                      </div>
                    </PreferenceSection>
                  )}
                  {data?.orderForm?.metadata?.SleeveType && (
                    <PreferenceSection title="Sleeve Type">
                      <div>
                        <p style={{ wordBreak: 'break-all' }}>
                          {data?.orderForm?.metadata?.SleeveType}
                        </p>
                      </div>
                    </PreferenceSection>
                  )}
                  {data?.orderForm?.metadata?.GuideMachineAndMaterial && (
                    <PreferenceSection title="Guide Machine And Material">
                      <div>
                        <p style={{ wordBreak: 'break-all' }}>
                          {data?.orderForm?.metadata?.GuideMachineAndMaterial}
                        </p>
                      </div>
                    </PreferenceSection>
                  )}
                  {data?.orderForm?.metadata?.Comments && (
                    <PreferenceSection title="Comments">
                      <div>
                        <p style={{ wordBreak: 'break-word' }}>
                          {data.orderForm.metadata.Comments}
                        </p>
                      </div>
                    </PreferenceSection>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

OrderFormPreferences.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default OrderFormPreferences;
