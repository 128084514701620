import { UPDATE_MANUFACTURER_ORG } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  navigate,
}) {
  const result = await client.mutate({
    mutation: UPDATE_MANUFACTURER_ORG,
    variables,
  });
  const { updateManufacturerOrganization } = result.data;
  if (updateManufacturerOrganization.errorMessages.length > 0) {
    setErrorMessages(updateManufacturerOrganization.errorMessages);
  } else {
    clearErrorMessages();
    navigate('/organizations');
  }
}

export default update;
