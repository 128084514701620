import { Suspense, useContext } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { createRoutesArray } from '../../utils/functions';
import { Placeholder } from '../shared';
import { CurrentUserContext, RouteContext } from '../shared/Context';
import legalSidebarRoutes from './sidebar/legalSidebarRoutes';
import orderDetailsSidebarRoutes from './sidebar/orderDetailsSidebarRoutes';

function SidebarNavRoutes() {
  const { pathname } = useLocation();
  const { sidebarRoutes } = useContext(RouteContext);
  const { currentUser } = useContext(CurrentUserContext);

  const scopedRoutes = sidebarRoutes.filter((route) => {
    if (currentUser?.scopeToLocation) {
      return route.sl === true;
    }
    return route.so === true;
  });
  const Routes = useRoutes(
    createRoutesArray(
      [...scopedRoutes, ...orderDetailsSidebarRoutes, ...legalSidebarRoutes],
      false,
      pathname,
    ),
  );
  return (
    <Suspense
      fallback={
        <>
          <Placeholder height="40px" className="mb-1" />
          <Placeholder height="40px" className="mb-1" />
          <Placeholder height="40px" className="mb-1" />
        </>
      }
    >
      {Routes}
    </Suspense>
  );
}

export default SidebarNavRoutes;
