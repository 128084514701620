import { Query } from '@apollo/client/react/components';
import { GET_TEMP_ORDERS_COUNT } from '@fullcontour/shared-api';
import { memo, useEffect, useState } from 'react';
import PendingLabelCount from './PendingLabelCount';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

function PendingLabelQuery() {
  const [{ appActive }, setState] = useState({
    appActive: true,
  });
  useEffect(() => {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, []);

  function handleVisibilityChange() {
    if (document[hidden]) {
      setState((state) => ({ ...state, appActive: false }));
    } else {
      setState((state) => ({ ...state, appActive: true }));
    }
  }

  return (
    <Query
      query={GET_TEMP_ORDERS_COUNT}
      fetchPolicy="cache-and-network"
      pollInterval={60000}
    >
      {({ loading, error, data, startPolling, stopPolling }) => {
        startPolling(60000);

        return (
          <PendingLabelCount
            loading={loading}
            error={error}
            data={data}
            startPolling={startPolling}
            stopPolling={stopPolling}
            appActive={appActive}
          />
        );
      }}
    </Query>
  );
}

export default memo(PendingLabelQuery);
