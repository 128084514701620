import PropTypes from 'prop-types';
import { Component } from 'react';

class AgGridLoader extends Component {
  render() {
    const { title } = this.props;
    return (
      <div className="loader-wrapper is-active">
        <div className="loader is-loading" />
        <div>
          <p className="loader-text" style={{ textTransform: 'capitalize' }}>
            Loading {title}
          </p>
        </div>
      </div>
    );
  }
}

AgGridLoader.propTypes = {
  title: PropTypes.string,
};

AgGridLoader.defaultProps = {
  title: '',
};

export default AgGridLoader;
