import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BoxIcon, DesignGuideOrderContext } from '../../..';

function GuideBodyMenu(props) {
  const {
    menuData: guideCategoryGroups = [],
    setDisplayedComponent,
    filteValue,
    resetData,
    data,
    setActiveTabs,
    displayedComponent,
    activeTabs,
    scrollToCategory,
  } = props;

  const { t } = useTranslation('designProfile');

  const { values } = useFormikContext();
  const { orderItemIndex } = useContext(DesignGuideOrderContext);

  const handleItemClick = (_, { name, idx, scrollToElement }) => {
    if ((idx || idx === 0) && !activeTabs[idx]) {
      setActiveTabs(idx);
    }

    setDisplayedComponent(name);

    if (scrollToElement) {
      scrollToCategory(idx);
    }
  };
  const structuredData = useMemo(
    () =>
      data.reduce((obj, item) => {
        obj[item.id] = item.guideCategories.length;
        return obj;
      }, {}),
    [data],
  );

  return (
    <aside className="menu guide-moda-menu" style={{ width: 200 }}>
      <ul className="menu-list">
        {!filteValue ? (
          <li>
            <a
              onClick={(event) => handleItemClick(event, { name: 'favorites' })}
              className={displayedComponent === 'favorites' ? 'is-active' : ''}
              style={{ borderBottom: '1px solid #ddd' }}
            >
              {t('Favorites')}
            </a>
          </li>
        ) : (
          <li>
            <a
              className={displayedComponent === 'favorites' ? 'is-active' : ''}
            >
              <span>{t('Search results')}:</span>
              <button
                onClick={resetData}
                type="button"
                data-cy="clear-search-results"
                className="button is-small is-rounded"
              >
                <BoxIcon name="bx-x has-text-white" />
              </button>
            </a>
          </li>
        )}
        {!filteValue && (
          <li>
            <a
              style={{ borderBottom: '1px solid #ddd' }}
              onClick={(event) =>
                handleItemClick(event, { name: 'designGuideGroups' })
              }
              className={
                displayedComponent === 'designGuideGroups' ? 'is-active' : ''
              }
            >
              {t('Design Guide Groups')}
            </a>
          </li>
        )}

        <li>
          <a
            onClick={(event) => handleItemClick(event, { name: 'categories' })}
            className={displayedComponent === 'categories' ? 'is-active' : ''}
          >
            {t('Categories')}:
          </a>
        </li>

        {guideCategoryGroups.map((item, idx) => (
          <li key={item.id}>
            <a
              onClick={(event) =>
                handleItemClick(event, {
                  idx,
                  name: 'categories',
                  scrollToElement: true,
                })
              }
              className={`dgModalSubMenu pl-5 ${
                item.guideCategories.find((gc) => {
                  if (values?.orderItems?.[orderItemIndex]?.guideCodes) {
                    return values.orderItems[orderItemIndex].guideCodes.some(
                      (code) => code.categoryId === gc.id,
                    );
                  }

                  return false;
                })
                  ? 'is-active-sub'
                  : ''
              }`}
              disabled={!!filteValue && !structuredData[item.id]}
            >
              {item.name}
              {filteValue && (
                <span style={{ fontWeight: '600' }}>{` (${
                  structuredData[item.id] || 0
                })`}</span>
              )}
            </a>
          </li>
        ))}
      </ul>
    </aside>
  );
}

GuideBodyMenu.propTypes = {
  data: PropTypes.array.isRequired,
  menuData: PropTypes.array.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  filteValue: PropTypes.string.isRequired,
  displayedComponent: PropTypes.string.isRequired,
  resetData: PropTypes.func.isRequired,
  activeTabs: PropTypes.object.isRequired,
  scrollToCategory: PropTypes.func.isRequired,
  setActiveTabs: PropTypes.func.isRequired,
};

export default GuideBodyMenu;
