import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ReviewModal } from '../../shared/ReviewModal';
import { checkPermissions } from '../helpers';

function ActionReviewCase(props) {
  const { t } = useTranslation('orderactions');
  const [modalOpen, setModalOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const redesignsUrl = params.get('modal');
    if (redesignsUrl) {
      setModalOpen(true);
      navigate(pathname, { state: null });
    }
  }, []);

  return checkPermissions(props) ? (
    <>
      <a className="dropdown-item" onClick={() => setModalOpen(true)}>
        {t('Review Case')}...
      </a>
      {modalOpen && (
        <ReviewModal
          closeModal={() => setModalOpen(false)}
          modalOpen={modalOpen}
          {...props}
        />
      )}
    </>
  ) : null;
}

export default ActionReviewCase;
