import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function NavElements() {
  const { t } = useTranslation('navigation');

  return (
    <>
      <NavLink to="/orders" className="navbar-item">
        {t('cases')}
      </NavLink>
      <NavLink to="/organizations" className="navbar-item">
        {t('organization')}
      </NavLink>
      <NavLink to="/users" className="navbar-item">
        {t('users')}
      </NavLink>
    </>
  );
}

export default NavElements;
