import pdfControlImagesNamings from './pdfControlImagesNamings';

const getBase64ImageUrl = (images, callback, mine) => {
  const dataUrls = [];
  const renderUrls = (dataURL, idx) => {
    dataUrls[idx] = dataURL;
    if (dataUrls.length === images.length) {
      callback(dataUrls);
    }
  };

  pdfControlImagesNamings.map((name, idx) => {
    const image = images.find(
      (item) =>
        item.originalFileName === `${name}.jpg` ||
        item.originalFileName === `${name}.png`,
    );

    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(this, 0, 0);

      const dataURL = canvas.toDataURL(mine || 'image/jpeg');
      renderUrls(dataURL, idx);
    };
    img.src = image.downloadUrl;
    return null;
  });
};

export default getBase64ImageUrl;
