import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { gridDateComparator, timestampFormatter } from '../../../../transforms';
import { AgGridLoader } from '../../../shared';
import '../../../shared/AgGrid/ag-grid-css-overrides.css';
import '../../../shared/AgGrid/ag-theme-balham-overrides.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { SparklinesModule } from '@ag-grid-enterprise/sparklines';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import BillingGridFunctions from './BillingGridFunctions';
function BillingGrid({
  gridOptions,
  rowData,
  containerHeight = 400,
  onSelectionChanged = null,
}) {
  const { t } = useTranslation('aggrid');

  const navigate = useNavigate();

  const { components, columnDefs, context, ...gridRest } = gridOptions;

  const translateHeaders = (headers) =>
    headers.map((header) => {
      if (header.children) {
        return {
          ...header,
          headerName: t(header.headerName),
          children: translateHeaders(header.children),
        };
      }
      return {
        ...header,
        headerName: t(header.headerName),
      };
    });

  return (
    <BillingGridFunctions>
      {({
        onGridReady,
        onGridSizeChanged,
        onFirstDataRendered,
        onColumnVisible,
      }) => (
        <div
          className="ag-theme-balham mainGridContainer ordersGridContainer"
          style={{
            height: containerHeight,
          }}
        >
          <AgGridReact
            gridOptions={{
              ...gridRest,
              getLocaleText: ({ defaultValue }) => t(defaultValue),
            }}
            headerHeight={40}
            rowHeight={44}
            rowData={rowData}
            animateRows
            maintainColumnOrder
            columnDefs={translateHeaders(columnDefs)}
            reactNext
            context={{
              navigate,
              ...context,
            }}
            modules={[
              ClientSideRowModelModule,
              InfiniteRowModelModule,
              CsvExportModule,
              SparklinesModule,
              ClipboardModule,
              ColumnsToolPanelModule,
              ExcelExportModule,
              FiltersToolPanelModule,
              MasterDetailModule,
              MenuModule,
              RangeSelectionModule,
              RichSelectModule,
              RowGroupingModule,
              SetFilterModule,
              MultiFilterModule,
              AdvancedFilterModule,
              SideBarModule,
              StatusBarModule,
              ViewportRowModelModule,
            ]}
            components={{
              customLoadingOverlay: AgGridLoader,
              ...components,
            }}
            loadingOverlayComponent="customLoadingOverlay"
            floatingFiltersHeight={46}
            onGridReady={onGridReady}
            onGridSizeChanged={onGridSizeChanged}
            onFirstDataRendered={onFirstDataRendered}
            onColumnVisible={onColumnVisible}
            groupHeaderHeight={30}
            onSelectionChanged={onSelectionChanged}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
              ],
            }}
            defaultColDef={{
              resizable: true,
              editable: false,
              suppressHeaderMenuButton: false,
              sortable: true,
              filterParams: { newRowsAction: 'keep' },
              floatingFilter: true,
            }}
            columnTypes={{
              dateColumn: {
                width: 185,
                minWidth: 185,
                filter: 'agDateColumnFilter',
                filterParams: {
                  newRowsAction: 'keep',
                  comparator: (filterLocalDateAtMidnight, cellValue) =>
                    gridDateComparator(filterLocalDateAtMidnight, cellValue),
                },
                valueFormatter: (params) => {
                  if (params.value) {
                    return timestampFormatter(params.value, false);
                  }

                  return params.value;
                },
              },
            }}
            sideBar={{
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: 'Show / Hide Columns',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                  toolPanelParams: {
                    suppressPivotMode: true,
                    suppressPivots: true,
                    suppressRowGroups: true,
                    suppressValues: true,
                  },
                },
                {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                },
              ],
            }}
          />
        </div>
      )}
    </BillingGridFunctions>
  );
}

BillingGrid.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  rowData: PropTypes.array.isRequired,
  containerHeight: PropTypes.number,
  onSelectionChanged: PropTypes.func,
};

export default BillingGrid;
