import { useQuery } from '@apollo/client';
import { GET_ORDER_OTP_FILES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseLoader from '../Loader/BaseLoader';
import './styles.css';
import { useApp } from '@fullcontour/hooks';
import OrthoObjViewerInner from './OrthoObjViewerInner';

function OrthoObjViewer({
  order,
  dentalNotation = null,
  setRedesignCountDoc = null,
  height = '80vh',
}) {
  const { isDesigner } = useApp();

  const [otpFiles, setOtpFiles] = useState([]);
  const [redesignCountObj, setRedesignCountObj] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const orderRedesignCount = useMemo(
    () =>
      isDesigner && order?.state === 'redesign'
        ? order.redesignCount - 1
        : order.redesignCount,
    [isDesigner, order.redesignCount, order.state],
  );

  const initializeRedesignCount = useCallback(() => {
    const count = order.dataFromUrl
      ? Number.parseInt(order.lastRedesign, 10)
      : orderRedesignCount;
    setRedesignCountObj(count);
    setIsInitialized(true);
  }, [order.dataFromUrl, order.lastRedesign, orderRedesignCount]);

  const { loading, error } = useQuery(GET_ORDER_OTP_FILES, {
    variables: {
      id: order.id,
    },
    onCompleted(data) {
      setOtpFiles(data.orderOtpFiles);
      if (!isInitialized) {
        initializeRedesignCount();
      }
    },
  });

  useEffect(() => {
    if (!(isInitialized || loading)) {
      initializeRedesignCount();
    }
  }, [isInitialized, loading, initializeRedesignCount]);

  if (loading || error || !isInitialized) {
    return <BaseLoader />;
  }

  const shouldRender =
    isInitialized && redesignCountObj !== null && otpFiles.length > 0;

  return shouldRender ? (
    <OrthoObjViewerInner
      key={otpFiles.length}
      order={order}
      orderRedesignCount={orderRedesignCount}
      otpFiles={otpFiles}
      dentalNotation={dentalNotation}
      setRedesignCountDoc={setRedesignCountDoc}
      height={height}
      redesignCountObj={redesignCountObj}
      setRedesignCountObj={setRedesignCountObj}
    />
  ) : null;
}

OrthoObjViewer.propTypes = {
  order: PropTypes.object.isRequired,
  dentalNotation: PropTypes.string,
  setRedesignCountDoc: PropTypes.func,
  height: PropTypes.string,
};

export default OrthoObjViewer;
