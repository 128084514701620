import { useQuery } from '@apollo/client';
import { GET_INVOICES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { LoadingOrError } from '../../../shared';
import InvoicesListDataTable from './InvoicesListDataTable';

function InvoicesListQueryWrapper({
  downloadPDF,
  onSelectionChanged,
  customer = null,
  selectedItem = null,
  type = null,
  ...rest
}) {
  const props = {
    downloadPDF,
    onSelectionChanged,
    customer,
    selectedItem,
    type,
    ...rest,
  };
  let variables = {};

  if (customer && type && type === 'location') {
    variables = { labLocationId: customer.id };
  }

  if (customer && type && type === 'organization') {
    variables = { labOrganizationId: customer.id };
  }

  const { error, loading, data, refetch } = useQuery(GET_INVOICES, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Invoices" />;
  }

  return (
    <InvoicesListDataTable
      invoices={data.invoices}
      refetch={refetch}
      downloadPDF={downloadPDF}
      onSelectionChanged={onSelectionChanged}
      selectedItem={selectedItem}
      {...props}
    />
  );
}

InvoicesListQueryWrapper.propTypes = {
  downloadPDF: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
  customer: PropTypes.object,
  selectedItem: PropTypes.object,
  type: PropTypes.string,
};

export default InvoicesListQueryWrapper;
