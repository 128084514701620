import { useQuery } from '@apollo/client';
import { LoadingOrError, ShowBodySection } from '@fullcontour/common';
import { GET_MANUF_ORG } from '@fullcontour/shared-api';
import {
  ShowContainer,
  ShowHeaderLeftContent,
} from '../../../shared/ShowElements';
import UsersListDataTable from '../../Users/UsersList/UsersListDataTable';

function OrganizationShowInner() {
  const { error, loading, data } = useQuery(GET_MANUF_ORG, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Organization" />
    );
  }

  const { manufacturerOrganization: organization } = data;
  let address = {};
  if (organization) {
    address = organization.address;
  }

  return organization ? (
    <ShowContainer
      logoUrl={organization.logoUrl}
      title={organization.name}
      editLink="/organizations/edit"
      leftContent={
        <ShowHeaderLeftContent
          address={address}
          phone={organization.phone}
          website={organization.website}
          geoData={organization.geoData}
        />
      }
      createdAt={organization.createdAt}
      updatedAt={organization.updatedAt}
    >
      <ShowBodySection title="Users">
        <UsersListDataTable shortContainer organizationId={organization.id} />
      </ShowBodySection>
    </ShowContainer>
  ) : null;
}

export default OrganizationShowInner;
