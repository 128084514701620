import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClick from '../../HelperFunctions/OutsideClick';

function AddItemDropdown(props) {
  const { text, options, onChange } = props;

  const { t } = useTranslation('showelements');
  const [actionsOpen, setActionsOpen] = useState(false);

  const handleActionsOpen = () => {
    setActionsOpen((prev) => !prev);
  };

  const onClick = (value) => {
    onChange(value);
    setActionsOpen(false);
  };

  return (
    <OutsideClick fn={() => setActionsOpen(false)}>
      <div className={`dropdown ${actionsOpen ? 'is-active' : ''}`}>
        <div className="dropdown-trigger">
          <button
            className="button pl-2"
            aria-controls="dropdown-menu"
            type="button"
            onClick={handleActionsOpen}
          >
            <span
              className="icon is-size-6 has-text-grey has-background-grey-lighter"
              style={{
                height: '42px',
                width: '42px',
                borderRadius: '5px 0 0 5px',
              }}
            >
              <i className="bx bx-plus-medical" />
            </span>
            <span>
              <h6 className="title is-6 mr-1 ml-3">{text}</h6>
            </span>
          </button>
        </div>
        <div className="dropdown-menu pt-0" id="dropdown-menu" role="menu">
          <div
            className="dropdown-content is-pulled-right has-text-left is-align-content-center"
            style={{ maxHeight: '300px', overflow: 'scroll', width: '100%' }}
          >
            {options
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((o) => (
                <a
                  className="dropdown-item"
                  id={o.id || o.value}
                  key={o.id || o.value}
                  onClick={() => onClick(o.value)}
                >
                  {t(o.name)}
                </a>
              ))}
          </div>
        </div>
      </div>
    </OutsideClick>
  );
}

AddItemDropdown.propTypes = {
  text: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddItemDropdown;
