import { GET_CUSTOMER_ORGS_MULTI } from '@fullcontour/shared-api';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { CurrentUserContext } from '../../../../shared';
import { MultiSelect } from '../FormFields';
import DataPointWrapper from './DataPointWrapper';

function DataPointsCustomerOrganizations(props) {
  const { inputParams, adminLocationIds } = props;
  const { currentUser } = useContext(CurrentUserContext);
  const app = import.meta.env.VITE_APP_NAME;

  let variables = {};

  if (currentUser.scopeToLocation === false && app === 'admin_portal') {
    variables = { adminLocationIds };
  }

  return (
    inputParams.customerOrganizations &&
    app === 'admin_portal' &&
    ((currentUser.scopeToLocation === false && adminLocationIds.length > 0) ||
      currentUser.scopeToLocation === true) && (
      <DataPointWrapper>
        <Field
          name="customerOrganizationIds"
          required
          component={MultiSelect}
          label="Customer Organizations"
          query={GET_CUSTOMER_ORGS_MULTI}
          dataname="customerOrganizations"
          variables={variables}
        />
      </DataPointWrapper>
    )
  );
}

DataPointsCustomerOrganizations.propTypes = {
  inputParams: PropTypes.object.isRequired,
  adminLocationIds: PropTypes.array.isRequired,
};

export default DataPointsCustomerOrganizations;
