import PropTypes from 'prop-types';
import OrderMessageImage from './OrderMessageImage';

function OrderShowMessagesCustomerImagesSection({
  message,
  setPreviewImage,
  setCurrentMessagePhotoModal,
  setIsOpen,
  setPhotoIndex,
  messageImages,
  setMessageImages,
}) {
  const imagesArray = message.images.map((item) => (
    <OrderMessageImage
      message={item}
      messageId={message.id}
      setPreview={setPreviewImage}
      multipleFiles
      setCurrentMessagePhotoModal={setCurrentMessagePhotoModal}
      setIsOpen={setIsOpen}
      setPhotoIndex={setPhotoIndex}
      messageImages={messageImages}
      setMessageImages={setMessageImages}
      key={item.id}
    />
  ));

  if (message.imageUrl && message.images.length > 0) {
    return (
      <div>
        <OrderMessageImage
          message={message}
          setPreview={setPreviewImage}
          multipleFiles={false}
        />
        {imagesArray}
      </div>
    );
  }

  if (message.imageUrl) {
    return (
      <OrderMessageImage
        message={message}
        setPreview={setPreviewImage}
        multipleFiles={false}
      />
    );
  }

  return imagesArray;
}

OrderShowMessagesCustomerImagesSection.propTypes = {
  message: PropTypes.object,
  setPreviewImage: PropTypes.func,
  setCurrentMessagePhotoModal: PropTypes.func,
  setIsOpen: PropTypes.func,
  setPhotoIndex: PropTypes.func,
  messageImages: PropTypes.object,
  setMessageImages: PropTypes.func,
};

export default OrderShowMessagesCustomerImagesSection;
