function calculateAttachments(tooth, idx, stages, toothIdx) {
  if (!idx) {
    return tooth.Attachments;
  }
  return stages[idx - 1].Teeth[toothIdx].Attachments !== tooth.Attachments;
}

function calculateMovementValues(tooth, idx, stages, toothIdx) {
  const valuesObj = tooth.Movement?.Value.MovementValues?.Value;
  if (!valuesObj || typeof valuesObj !== 'object') {
    return {};
  }

  return Object.keys(valuesObj).reduce(
    (obj, key) => {
      if (stages[idx] && idx) {
        obj[key] =
          valuesObj[key] -
          stages[idx - 1].Teeth[toothIdx].Movement?.Value.MovementValues?.Value[
            key
          ];
      }
      return obj;
    },
    { ...valuesObj },
  );
}

function calculateIPR(tooth, idx, stages, toothIdx) {
  if (!tooth.InterproximalReduction?.Value) {
    return {};
  }

  const keysToCheck = {
    DistalReductionValue: true,
    MesialReductionValue: true,
  };
  const reverseKeys = {
    DistalReductionValue: 'MesialReductionValue',
    MesialReductionValue: 'DistalReductionValue',
  };

  return Object.keys(tooth.InterproximalReduction.Value).reduce((obj, key) => {
    if (!keysToCheck[key]) {
      return obj;
    }

    const prevValue =
      stages[idx - 1]?.Teeth[toothIdx]?.InterproximalReduction?.Value;
    if (prevValue) {
      const newValue = tooth.InterproximalReduction.Value[key] - prevValue[key];
      if (newValue) {
        return {
          Value: {
            ...(obj.Value || tooth.InterproximalReduction.Value),
            [key]: newValue,
            isVisible: true,
            [`${key}Visible`]: true,
            [`${reverseKeys[key]}Visible`]: false,
          },
        };
      }
    }

    return {
      Value: {
        ...(obj.Value || tooth.InterproximalReduction.Value),
        isVisible: !prevValue,
      },
    };
  }, {});
}

function calculateMovementsPerStage(stages) {
  if (stages?.length === 0) {
    return null;
  }

  const updatedStages = stages.map((item, idx) => ({
    ...item,
    Teeth: item.Teeth.map((tooth, toothIdx) => ({
      ...tooth,
      Attachments: calculateAttachments(tooth, idx, stages, toothIdx),
      InterproximalReduction: calculateIPR(tooth, idx, stages, toothIdx),
      Movement: {
        ...tooth.Movement,
        Value: {
          ...tooth?.Movement?.Value,
          MovementValues: {
            Value: calculateMovementValues(tooth, idx, stages, toothIdx),
          },
        },
      },
    })),
  }));

  return updatedStages.map((stage) => ({
    ...stage,
    Teeth: stage.Teeth.filter(
      (tooth) => Object.keys(tooth.Movement.Value.MovementValues.Value).length,
    ),
  }));
}

export default calculateMovementsPerStage;
