import {
  OrdersListQueryWrapper,
  orderStatusRenderer,
} from '@fullcontour/common';

import { GET_MANUF_ORDERS } from '@fullcontour/shared-api';

export const gridOptions = {
  columnDefs: [
    {
      headerName: 'Case Details',
      marryChildren: true,
      children: [
        {
          field: 'hexOrderCode',
          headerName: 'Case ID',
          type: 'orderIdColumn',
        },
        {
          field: 'intOrderId',
          headerName: 'Filename',
          width: 175,
          minWidth: 175,
          filter: 'agTextColumnFilter',
          columnGroupShow: 'open',
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 175,
          minWidth: 175,
          filter: 'agTextColumnFilter',
          columnGroupShow: 'open',
        },
      ],
    },
    {
      headerName: 'Customer',
      marryChildren: true,
      children: [
        {
          field: 'location.name',
          headerName: 'Customer Name',
          width: 150,
          minWidth: 150,
          filter: 'agSetColumnFilter',
        },
        {
          field: 'location.code',
          headerName: 'Customer Code',
          width: 150,
          minWidth: 150,
          columnGroupShow: 'open',
          filter: 'agSetColumnFilter',
        },
      ],
    },
    {
      headerName: 'Case Items',
      marryChildren: true,
      children: [
        {
          field: 'itemsMetadata.units',
          headerName: 'Total Units',
          width: 150,
          minWidth: 150,
          type: 'numericColumn',
          filter: 'agTextColumnFilter',
        },
        {
          field: 'itemsMetadata.design_types',
          headerName: 'Design Types',
          width: 150,
          minWidth: 150,
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
        },
        {
          field: 'itemsMetadata.teeth',
          headerName: 'Teeth',
          width: 150,
          minWidth: 150,
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
        },
        {
          field: 'itemsMetadata.arches',
          headerName: 'Arches',
          width: 150,
          minWidth: 150,
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
        },
        {
          field: 'itemsMetadata.materials',
          headerName: 'Materials',
          width: 150,
          minWidth: 150,
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
        },
      ],
    },
    {
      field: 'state',
      headerName: 'Status',
      width: 175,
      minWidth: 175,
      filter: 'agSetColumnFilter',
      suppressSpanHeaderHeight: true,
      cellRenderer: orderStatusRenderer,
    },
    {
      field: 'turnaroundTimeName',
      headerName: 'Turnaround',
      width: 200,
      minWidth: 200,
      filter: 'agSetColumnFilter',
      suppressSpanHeaderHeight: true,
    },
    {
      field: 'createdAt',
      headerName: 'Submitted',
      suppressSpanHeaderHeight: true,
      type: 'dateColumn',
    },
  ],
};

function OrdersList(props) {
  return (
    <OrdersListQueryWrapper
      gridOptions={gridOptions}
      query={GET_MANUF_ORDERS}
      {...props}
    />
  );
}

export default OrdersList;
