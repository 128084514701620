import { get } from 'lodash-es';
import * as Yup from 'yup';
import { Info } from '../../Info';
import CheckBox from './components/CheckBox/CheckBox';
import DateInput from './components/DateInput/DateInput';
import Dropdown from './components/Dropdown/Dropdown';
import FileInput from './components/FileInput/FileInput';
import Input from './components/Input/Input';
import TextArea from './components/TextArea/TextArea';

export function getFormStructure(fieldsConfig) {
  const structure = {};
  for (const { name, initialValue } of fieldsConfig) {
    if (!name.includes('[')) {
      structure[name] = initialValue;
    }
  }
  return structure;
}

export function getFormSchema(fieldsConfig) {
  const validationSchema = {};
  for (const { name, fieldValidation } of fieldsConfig) {
    if (fieldValidation) {
      validationSchema[name] = fieldValidation;
    }
  }
  return Yup.object().shape({ ...validationSchema });
}
export function mapFieldsToSections(sections, fieldsConfig) {
  const mappedFields = {};
  let _fieldIndex = 0;
  let _sectionIndex = 0;

  for (const [sectionName, headerText] of Object.entries(sections)) {
    mappedFields[sectionName] = { headerText, fields: [], _sectionIndex };
    _sectionIndex += 1;
  }

  for (const fieldConfig of fieldsConfig) {
    if (fieldConfig.name !== 'id') {
      mappedFields[fieldConfig.sectionName].fields.push({
        ...fieldConfig,
        _fieldIndex,
        canChangeField: _fieldIndex < fieldsConfig.length - 1,
      });
      _fieldIndex += 1;
    }
  }

  for (const [sectionName] of Object.entries(sections)) {
    let index = 1;
    for (const fieldConfig of mappedFields[sectionName].fields) {
      fieldConfig.canChangeSection =
        mappedFields[sectionName].fields.length === index;
      index++;
    }
  }

  return Object.values(mappedFields);
}
export function doStep(mappedFields, currentStepIndex, jumpToSteps) {
  const { _fieldIndex, _sectionIndex } = currentStepIndex;
  const fields = Object.values(mappedFields).flatMap(({ fields }) => fields);
  let nextFieldIndex = _fieldIndex;
  let nextSectionIndex = _sectionIndex;

  if (
    jumpToSteps.includes(_fieldIndex) &&
    jumpToSteps.includes(_fieldIndex + 1)
  ) {
    if (fields[_fieldIndex].canChangeField) {
      nextFieldIndex += 1;
    }
    if (fields[_fieldIndex].canChangeSection) {
      nextSectionIndex += 1;
    }
  } else {
    nextFieldIndex = jumpToSteps.find((index) => index > _fieldIndex);
    if (nextFieldIndex === undefined) {
      nextFieldIndex = fields.length - 1;
    }
    for (let i = _fieldIndex; i < nextFieldIndex; i++) {
      if (fields[i].canChangeSection) {
        nextSectionIndex += 1;
      }
    }
  }
  return {
    _fieldIndex: nextFieldIndex,
    _sectionIndex: nextSectionIndex,
  };
}
export function getDefaultComponents() {
  const components = {};
  components.checkBox = CheckBox;
  components.dateInput = DateInput;
  components.dropdown = Dropdown;
  components.fileInput = FileInput;
  components.input = Input;
  components.textArea = TextArea;
  return components;
}
export function renderField(fieldConfig, registeredComponents, errors, props) {
  const { canShowErrors, shouldShow, dataSources } = props;
  const { helperDrawer } = dataSources;
  const { setOpen, setDrawerBody } = helperDrawer;
  const infoCallback = () => {
    setDrawerBody(fieldConfig.helperContent);
    setOpen((prev) => !prev);
  };
  if (!registeredComponents[fieldConfig.componentName]) {
    return (
      <p>
        Please register component <b>{fieldConfig.componentName}</b> for field
        <b>{fieldConfig.labelText}</b>
      </p>
    );
  }
  const Component = registeredComponents[fieldConfig.componentName];
  const error = canShowErrors ? get(errors, fieldConfig.name) : null;
  return (
    shouldShow && (
      <div className={`field ${typeof error === 'string' ? 'error' : ''} mt-6`}>
        <label className="label">
          {fieldConfig.labelText}
          {fieldConfig.helperContent && (
            <span style={{ position: 'relative', bottom: '8px' }}>
              <Info
                dataTooltip="Click to learn more"
                className="has-tooltip-arrow has-tooltip-right"
                infoCallback={infoCallback}
              />
            </span>
          )}
        </label>
        <div className="control">
          <Component {...props} />
        </div>
        {typeof error === 'string' && (
          <span
            style={error ? { color: '#DE0019' } : null}
            className="is-size-5 mt-1"
          >
            *{error}
          </span>
        )}
      </div>
    )
  );
}
