import OrganizationShowNavMenu from '../../shared/NavMenus/Organizations/OrganizationShowNavMenu';

const routes = [
  {
    path: [
      '/organizations',
      '/organizations/integrations',
      '/organizations/api-settings',
      '/organizations/integrations/:action',
      '/organizations/integrations/:id/:action',
    ],
    component: OrganizationShowNavMenu,
    so: true,
    sl: false,
  },
];

export default routes;
