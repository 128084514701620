import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function HeaderActions(props) {
  const { isSubmitting, edit, toggleEditAndReset } = props;

  const { t } = useTranslation('designProfile');

  return (
    <div className="columns">
      <div className="column">
        {t(`Here you can add instructions for the Design type that you use, or
        manufacture. In this way we make sure that our designers gets a better
        understanding on how your orders have to be executed.`)}
      </div>
      {edit && (
        <div className="column is-4 buttons is-justify-content-end">
          <button
            type="button"
            className="button is-responsive is-danger is-outlined mr-2"
            onClick={toggleEditAndReset}
          >
            <span className="icon is-small">
              <i className="bx bx-x" />
            </span>
            <span>Cancel</span>
          </button>
          <button
            className={`button is-responsive is-primary ${
              isSubmitting && 'is-loading'
            }`}
            type="submit"
            disabled={isSubmitting}
          >
            <span>Save</span>
          </button>
        </div>
      )}

      {!edit && (
        <div className="column is-3 has-text-right">
          <button
            type="button"
            className="button is-primary"
            onClick={toggleEditAndReset}
          >
            <span className="icon">
              <i className="bx bx-edit" />
            </span>
            <span>{t('Edit Preferences')}</span>
          </button>
        </div>
      )}
    </div>
  );
}

HeaderActions.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  toggleEditAndReset: PropTypes.func.isRequired,
};

export default HeaderActions;
