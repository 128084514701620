function calculateTeethElements(objRefs, _currentStage, iprs) {
  if (!objRefs) { return []; }

  const getToothNumber = (mesh) =>
    Number.parseInt(mesh?.name?.match(/\d+/gi), 10);

  const findMeshByToothNumber = (toothNumber) =>
    objRefs.find((item) => getToothNumber(item) === toothNumber);

  const getGeometryArray = (mesh) =>
    mesh?.geometry?.attributes?.position?.array || null;

  const createToothElement = (teeth, vertexes) => ({
    teeth,
    teethVertexes: vertexes,
  });

  return objRefs.reduce((result, currentMesh) => {
    if (currentMesh?.name?.includes('SimpleTeeth')) {
      const toothNumber = getToothNumber(currentMesh);
      const isIPRIncluded = iprs[toothNumber]?.showIPR;
      const currentGeometryArray = getGeometryArray(currentMesh);

      if (!isIPRIncluded) { return result; }

      // Handling for teeth in the upper arch (1-16)
      if (toothNumber < 17) {
        const nextMesh = findMeshByToothNumber(toothNumber === 9 ? 10 : toothNumber + 1);
        const nextGeometryArray = getGeometryArray(nextMesh);

        const teeth = [toothNumber, getToothNumber(nextMesh) || 10];
        const toothElement = createToothElement(
          teeth,
          nextGeometryArray ? [currentGeometryArray, nextGeometryArray] : [currentGeometryArray]
        );

        result.push(toothElement);
      }
      // Handling for teeth in the lower arch (17-32)
      else {
        const previousMesh = findMeshByToothNumber(toothNumber - 1);
        const previousGeometryArray = getGeometryArray(previousMesh);

        const teeth = [getToothNumber(previousMesh), toothNumber];
        const toothElement = createToothElement(
          teeth,
          previousGeometryArray ? [previousGeometryArray, currentGeometryArray] : [currentGeometryArray]
        );

        result.push(toothElement);
      }
    }

    return result;
  }, []);
}

export default calculateTeethElements;
