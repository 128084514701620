import { guideNav } from '../../../config/apollo';
import { newOrderToCache } from '../../../transforms';
import CreateNotification from './createNotification';
import fetchOnStatus from './fetchOnStatus';

class UpdateCache {
  filesToAdd = {};

  filesToAddCount = 0;

  totalFiles = 0;

  addToCacheHandler({
    order,
    total,
    cache,
    query,
    singleClick,
    progressInfo,
    pending,
    isOrders,
    removeSubmitAll,
    toggleOverpanel,
  }) {
    // Helper to update file cache and count
    const addOrderToCache = (state, order) => {
      this.filesToAdd[state] = this.filesToAdd[state]
        ? [...this.filesToAdd[state], newOrderToCache(order)]
        : [newOrderToCache(order)];
      this.filesToAddCount += 1;
    };

    // Helper to update progress info
    const updateProgress = (progressInfo) => {
      const ordersSending = progressInfo();
      progressInfo({
        ...ordersSending,
        casesSent: this.filesToAddCount,
      });
    };

    // Helper to notify and handle post-cache actions
    const handlePostCacheActions = (state, stateArray) => {
      const stateForDisplay = state.replace(/_/g, ' ');
      CreateNotification.createNotification({
        type: 'success',
        message: `${stateArray.length} ${stateArray.length === 1 ? 'case was' : 'cases were'} ${state === 'sent_for_design'
            ? stateForDisplay
            : `sent for "${stateForDisplay}"`
          }`,
      });

      if (isOrders) {
        const isGuided = JSON.parse(localStorage.getItem('isGuided'));
        if (isGuided) {
          guideNav(true);
        }
      }

      if (pending) {
        cache.writeQuery({
          query: pending,
          data: { tempOrdersCount: 0 },
        });
      }

      if (!singleClick) {
        toggleOverpanel(false);
        removeSubmitAll();
      }
    };

    // Helper to update cache with orders
    const updateCache = (cache, query, state, stateArray) => {
      const { queryToFetch, dataKey } = fetchOnStatus(query, state);
      try {
        const data = cache.readQuery({
          query: queryToFetch,
          variables: { status: state },
        });
        const updatedArray = [...data[dataKey], ...stateArray];
        cache.writeQuery({
          query: queryToFetch,
          variables: { status: state },
          data: { [dataKey]: updatedArray },
        });
      } catch (error) {
        console.error(error);
      }
    };

    // Add order to cache and update progress info
    addOrderToCache(order.state, order);
    this.totalFiles = Math.max(total, this.totalFiles);
    updateProgress(progressInfo);

    // Finalize if all files are added or single-click triggered
    if (this.filesToAddCount === this.totalFiles || singleClick) {
      for (const [state, stateArray] of Object.entries(this.filesToAdd)) {
        updateCache(cache, query, state, stateArray);
        handlePostCacheActions(state, stateArray);
      }
      this.resetFiles(progressInfo);
    }
  }

  static filterElementFromCache({ cache, field, id }) {
    cache.modify({
      fields: {
        [field]: (existingFilesRefs, { readField }) =>
          existingFilesRefs.filter(
            (fileRef) => id !== readField('id', fileRef),
          ),
      },
    });
  }

  resetFiles(progressInfo) {
    this.filesToAdd = {};
    this.filesToAddCount = 0;
    this.totalFiles = 0;
    progressInfo({
      totalCases: 0,
      casesSent: 0,
    });
  }
}

export default UpdateCache;