import { useApolloClient, useQuery } from '@apollo/client';
import { useApp } from '@fullcontour/hooks';
import { GET_MESSAGE_UPLOADER_ORDER } from '@fullcontour/shared-api';
import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import {
  FieldContainer,
  FileDropzoneOrderMessage,
  FormGridWrap,
  FormSection,
  LoadingOrError,
  withCurrentUser,
  withFormWrap,
} from '../../../../shared';
import { create, initialValues, schema } from './helpers';

function OrderShowMessageForm({
  initialValues = () => ({}),
  schema = () => ({}),
  getVariables = () => ({}),
  skipQuery = () => ({}),
  match = {},
  orderState,
  orderId,
  ...rest
}) {
  const client = useApolloClient();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { id } = useParams();
  const { isCustomer } = useApp();

  const { loading, error, data } = useQuery(GET_MESSAGE_UPLOADER_ORDER, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  function handleSubmit(values, { resetForm, setSubmitting }) {
    const app = import.meta.env.VITE_APP_NAME;
    const variables = {
      variables: {
        input: {
          input: {
            ...values,
            images: [],
            holdMessage: orderState === 'on_hold' && app === 'designer_portal',
          },
          clientMutationId: uuidv4(),
        },
      },
    };

    create({ variables, client, ...rest });
    resetForm();
    setSubmitting(false);
  }

  function updateUploadProgress(percentage) {
    setUploadPercentage(percentage);
  }

  if (orderState === 'on_hold' && isCustomer) {
    return (
      <div>
        <p>
          Messages disabled during Hold status. Please contact customer support
          for any assistance with this case.
        </p>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues({ orderId })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      <FormGridWrap match={match} customMessage="Message" hideHeader>
        <FormSection title="Send A New Message">
          <FieldContainer name="message">
            <Field
              id="message"
              name="message"
              placeholder="Fill out a message..."
              component="textarea"
              className="textarea"
              rows="6"
            />
          </FieldContainer>
          <FieldContainer required name="imageUrl" hideLabel>
            {loading || error ? (
              <LoadingOrError
                error={error}
                loading={loading}
                title="Uploader"
              />
            ) : (
              <Field
                name="imageUrl"
                component={FileDropzoneOrderMessage}
                required
                labelText="Attachment"
                order={data.order}
                updateUploadProgress={updateUploadProgress}
              />
            )}
          </FieldContainer>
          {uploadPercentage > 0 && (
            <progress
              value={uploadPercentage}
              max="100"
              className={`progress is-small ${
                uploadPercentage === 100 ? 'is-success' : 'is-primary'
              }`}
            >
              {uploadPercentage < 100 ? 'uploading ' : 'uploaded '}
            </progress>
          )}

          {uploadPercentage === 100 && (
            <p style={{ textAlign: 'center' }}>
              attachment uploaded, click send to submit your message.
            </p>
          )}
        </FormSection>
      </FormGridWrap>
    </Formik>
  );
}

OrderShowMessageForm.propTypes = {
  orderState: PropTypes.string.isRequired,
};

export default flowRight(
  withCurrentUser({}),
  withFormWrap({ initialValues, schema }),
)(OrderShowMessageForm);
