import OrdersList from '../../views/Orders/OrdersList/OrdersList';

const routes = [
  {
    path: ['/orders', '/orders/:status'],
    component: OrdersList,
    so: true,
    sl: true,
  },
];

export default routes;
