import UserFormManufacturer from '../../views/Users/UserForms/UserFormManufacturer';
import UserShowInner from '../../views/Users/UserShow/UserShowInner';
import UsersListDataTable from '../../views/Users/UsersList/UsersListDataTable';

const routes = [
  {
    path: '/:root(users)',
    component: UsersListDataTable,
    sl: true,
    so: true,
  },
  {
    path: '/:root(users)/:id{/:action}',
    component: UserFormManufacturer,
    sl: true,
    so: true,
  },
  {
    path: '/:root(users)/:id',
    component: UserShowInner,
    sl: true,
    so: true,
  },
];

export default routes;
