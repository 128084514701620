import { useMutation } from '@apollo/client';
import { DESTROY_CAD_FILE } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { LoadingOrError } from '../../../shared';
import CadFileDestroyForm from './CadFileDestroyForm';

function CadFileDestroy({ cadFileId }) {
  const [destroy, { loading, error }] = useMutation(DESTROY_CAD_FILE);

  if (loading || error) {
    return (
      <LoadingOrError
        error={error}
        loading={loading}
        title="Deleting DME file"
      />
    );
  }

  return <CadFileDestroyForm destroy={destroy} cadFileId={cadFileId} />;
}

CadFileDestroy.propTypes = {
  cadFileId: PropTypes.string.isRequired,
};

export default CadFileDestroy;
