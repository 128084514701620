const navMenuList = [
  {
    name: 'manufacturing',
    icon: 'bxs-truck',
    key: 40,
    items: [
      {
        key: 1,
        to: '/orders/sent_to_manufacturer',
        label: 'sent to manufacturer',
        symbol: 'sent_to_manufacturer',
        labelClass: 'is-danger',
        status: 60,
      },
      {
        key: 2,
        to: '/orders/at_manufacturer',
        label: 'at manufacturer',
        symbol: 'at_manufacturer',
        labelClass: '',
        status: 70,
      },
      {
        key: 3,
        to: '/orders/manufacturing_hold',
        label: 'manufacturing hold',
        symbol: 'manufacturing_hold',
        labelClass: 'is-danger',
        status: 80,
      },
      {
        key: 4,
        to: '/orders/shipped',
        label: 'shipped',
        symbol: 'shipped',
        labelClass: 'is-danger',
        status: 72,
      },
    ],
  },
  {
    name: 'finished',
    icon: 'bx-save',
    key: 50,
    items: [
      {
        key: 3,
        to: '/orders/complete',
        label: 'complete',
        symbol: 'complete',
        labelClass: '',
        status: 95,
      },
    ],
  },
];

export default navMenuList;
