import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  gql,
  makeVar,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import { fetchAuthSession } from 'aws-amplify/auth';
import Log from '../log';

export const sendingOrders = makeVar({
  totalCases: 0,
  casesSent: 0,
});
export const sendingTempOrders = makeVar({
  totalCases: 0,
  casesSent: 0,
});
export const pendingCasesAmount = makeVar(0);
export const guidedFlow = makeVar(false);
export const guideNav = makeVar(false);
export const isHelpCenterBarActive = makeVar(false);
export const getHelpers = makeVar(null);
export const followUpModalActive = makeVar(false);
export const downloadModal = makeVar(false);
export const overpanelCasesData = makeVar({});

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_API_SERVER,
  credentials: 'same-origin',
});

const authLink = setContext(async () => {
  // if you have a cached value, return it immediately
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    return {
      headers: {
        authorization: idToken ? `Bearer ${idToken}` : '',
        'x-admin-portal-version': import.meta.env.VITE_VERSION,
        'x-app-name': import.meta.env.VITE_APP_NAME,
      },
    };
  } catch (_e) {
    return {
      headers: {
        'x-admin-portal-version': import.meta.env.VITE_VERSION,
        'x-app-name': import.meta.env.VITE_APP_NAME,
      },
    };
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { location } = window;
  Log.info(location, 'onError - history -  apolloClient');
  Log.info(graphQLErrors, 'onError - graphqlerrors - apolloClient');
  Log.info(networkError, 'onError - networkerror - apolloClient');

  if (graphQLErrors) {
    for (const { status = 200 } of graphQLErrors) {
      Log.info(status, 'onError - graphqlerrors - status');
      if (status === 401) {
        Log.warn(
          `You've attempted to access ${import.meta.env.VITE_UNAUTHORIZED
          } section`,
        );
        if (location.pathname !== '/signin') {
          location.href = `${location.origin}/signin`;
        }
      }
      if (status === 403) {
        Log.warn(`You've attempted a ${import.meta.env.VITE_FORBIDDEN} action`);
        location.href = `${location.origin}/error-page/403`;
      }
    }
  }

  if (networkError && networkError.statusCode === 401) {
    Log.warn(import.meta.env.VITE_UNAUTHORIZED);
    location.href = `${location.origin}/signin`;
  }

  if (networkError && networkError.statusCode === 403) {
    Log.warn(import.meta.env.VITE_FORBIDDEN);
  }
  /* if (networkError && networkError.statusCode >= 500) {
    Log.warn('SERVER ERROR');
   navigate(`/error-page/${networkError.statusCode}`);
  } */
});

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      adminLocation: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'AdministratorLocation', id: args.id }),
      customerLocation: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'CustomerLocation', id: args.id }),
      designLocation: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'DesignLocation', id: args.id }),
      customerOrganization: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'CustomerOrganization', id: args.id }),
      designOrganization: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'DesignOrganization', id: args.id }),
      manufacturerOrganization: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'ManufacturerOrganization', id: args.id }),
      order: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'Order', id: args.id }),
      orderFile: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'OrderFile', id: args.id }),
      contact: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'Contact', id: args.id }),
    },
  },
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const apolloClient = new ApolloClient({
  link,
  cache,
  resolvers: {},
  connectToDevTools: true,
});

const data = {
  query: gql`
    query adminMessageActive {
      adminMessage(showApp: true) {
        id
        name
        content
      }
      readAdminMessage @client {
        id
      }
    }
  `,
  data: {
    adminMessage: {
      id: '',
      name: '',
      content: {},
      __typename: 'adminMessage',
    },
    readAdminMessage: {
      id: '',
      __typename: 'readAdminMessage',
    },
  },
};

cache.writeQuery(data);

apolloClient.onResetStore(async () => {
  await cache.writeQuery(data);
});

export default apolloClient;
