import PropTypes from 'prop-types';
import HeaderInfoElapsedTime from '../HeaderInfoElapsedTime';
import HeaderInfoSubmitted from '../HeaderInfoSubmitted';

function HeaderOrderTime({ app, order = null, changeableLayout }) {
  return !changeableLayout && app !== 'customer_portal' && order ? (
    <p>
      <small>
        <HeaderInfoElapsedTime createdAt={order.createdAt} />
        <br />
        <HeaderInfoSubmitted createdAt={order.createdAt} />
      </small>
    </p>
  ) : (
    <div />
  );
}

HeaderOrderTime.propTypes = {
  order: PropTypes.object,
  app: PropTypes.string.isRequired,
  changeableLayout: PropTypes.bool.isRequired,
};
export default HeaderOrderTime;
