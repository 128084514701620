function initialValues(props) {
  const {
    data: { integration = {} } = {},
  } = props;

  return {
    id: integration.id || null,
    active:
      integration.active !== null && integration.active !== undefined
        ? integration.active
        : true,
    integrationTypeId: integration.integrationTypeId || '',
    labLocationId: integration.labLocationId || '',
    manufacturerOrganizationId: integration.manufacturerOrganizationId || '',
    credentials: integration.credentials || {
      token: '',
      username: '',
      password: '',
      serverAddress: '',
      workstationId: '',
      initialPath: '',
      clientId: '',
      clientSecret: '',
      callbackUrl: '',
      grant_type: '',
      passiveMode: true,
      sftp: false,
    },
  };
}

export default initialValues;
