import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import getPreferenceValue from './utils/getPreferenceValue';

function PreferenceInactive(props) {
  const {
    foundIndex,
    preference,
    values: { designProfiles },
    labelText,
    designProfileId,
  } = props;
  const [foundPrefIndex, setFoundPrefIndex] = useState(null);

  useEffect(() => {
    const foundPrefIndx =
      foundIndex !== -1
        ? designProfiles[foundIndex].designPreferences.findIndex((p) =>
            preference.values.some((val) => val.value === p.value),
          )
        : -1;

    setFoundPrefIndex(foundPrefIndx);
  }, [designProfiles, foundIndex, preference.values]);

  const opacity = foundIndex !== -1 ? 1.0 : 0.6;

  return (
    <div className="columns">
      <div className="column">
        <label style={{ opacity }}>{labelText}</label>
        <h5 className="title is-5" style={{ marginTop: 5, opacity }}>
          {foundIndex !== -1 && foundPrefIndex !== -1
            ? getPreferenceValue(designProfileId, preference, designProfiles)
            : 'Not Assigned'}
        </h5>
      </div>
    </div>
  );
}

PreferenceInactive.propTypes = {
  foundIndex: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  labelText: PropTypes.string.isRequired,
  preference: PropTypes.object.isRequired,
};

export default PreferenceInactive;
