import { GET_ORDER_FILES } from '@fullcontour/shared-api';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { statusUpdate } from '../components/views/Orders/OrderActions/helpers';

async function fetchFile(url) {
  const response = await fetch(url);
  return response.blob();
}

async function downloadAndZip(caseFolders, intOrderId, designTypeCaseFolders) {
  const zip = new JSZip();
  for (const [key, files] of Object.entries(caseFolders)) {
    for (const { originalFileName, caseFolderId, downloadUrl } of files) {
      const blob = await fetchFile(downloadUrl);
      if (key === 'initial_scans') {
        const subfolder = zip.folder(
          designTypeCaseFolders.find(({ id }) => id === caseFolderId)
            .folderName,
        );
        subfolder.file(originalFileName, blob);
      } else {
        const folder = zip.folder(key);
        folder.file(originalFileName, blob);
      }
    }
  }

  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `${intOrderId}.zip`);
  });
}

export default async function generateSimpleOrdersZips(
  orders,
  client,
  actionTitle,
) {
  const folders = ['initial_scans'];
  await Promise.all(
    orders.map(async ({ id, intOrderId, orderItems }) => {
      const caseFolders = {};
      for (const folderName of folders) {
        caseFolders[folderName] = [];
      }
      const {
        data: { orderFiles },
      } = await client.query({
        query: GET_ORDER_FILES,
        variables: { orderId: id },
        fetchPolicy: 'no-cache',
      });
      for (const {
        downloadUrl,
        originalFileName,
        fileType,
        ...rest
      } of orderFiles) {
        if (folders.includes(fileType)) {
          if (caseFolders[fileType]) {
            caseFolders[fileType].push({
              ...rest,
              downloadUrl,
              originalFileName,
              fileType,
            });
          } else {
            caseFolders[fileType] = [
              { ...rest, downloadUrl, originalFileName, fileType },
            ];
          }
        }
      }
      await downloadAndZip(
        caseFolders,
        intOrderId,
        orderItems[0].designType.caseFolders,
      );
      if (actionTitle === 'Download and Mark In Progress...') {
        statusUpdate(client, 'start_design', id, null, null, 1);
      }
    }),
  );
}
