import { GET_DESIGN_ORGS_MULTI } from '@fullcontour/shared-api';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { CurrentUserContext } from '../../../../shared';
import { MultiSelect } from '../FormFields';
import DataPointWrapper from './DataPointWrapper';

function DataPointsDesignOrganizations(props) {
  const { inputParams, adminLocationIds } = props;
  const { currentUser } = useContext(CurrentUserContext);
  const app = import.meta.env.VITE_APP_NAME;

  let variables = {};

  if (currentUser.scopeToLocation === false && app === 'admin_portal') {
    variables = { adminLocationIds };
  }

  return (
    inputParams.designOrganizations &&
    app === 'admin_portal' &&
    ((currentUser.scopeToLocation === false && adminLocationIds.length > 0) ||
      currentUser.scopeToLocation === true) && (
      <DataPointWrapper>
        <Field
          name="designOrganizationIds"
          required
          component={MultiSelect}
          label="Design Centers"
          query={GET_DESIGN_ORGS_MULTI}
          dataname="designOrganizations"
          variables={variables}
        />
      </DataPointWrapper>
    )
  );
}

DataPointsDesignOrganizations.propTypes = {
  inputParams: PropTypes.object.isRequired,
  adminLocationIds: PropTypes.array.isRequired,
};

export default DataPointsDesignOrganizations;
