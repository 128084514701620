import PropTypes from 'prop-types';
import BoxIcon from '../../Icons/BoxIcon';

function DisplayedData({ displayedData: { data, error, value } }) {
  if (error) {
    return (
      <p style={{ padding: '16px' }}>
        Oops, something wend wrong, try to reload the page
      </p>
    );
  }

  if (data.length === 0) {
    return (
      <p style={{ padding: '16px' }}>
        No results for <b>{value}</b>
      </p>
    );
  }

  return data.map((item) => (
    <li key={item.id}>
      <a href={item.html_url} target="_blank" rel="noreferrer">
        <span>{item.title}</span> <BoxIcon name="bx-link-external bx-xs" />
      </a>
    </li>
  ));
}

DisplayedData.propTypes = {
  displayedData: PropTypes.object.isRequired,
};

export default DisplayedData;
