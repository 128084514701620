import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_LOC_PRICE_LIST } from '@fullcontour/shared-api';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

function useDesignTypeSelection({ data, setGuideCategoryGroups }) {
  const { slug } = useParams();

  const { data: dataP, error: errorP } = useQuery(GET_CUSTOMER_LOC_PRICE_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: { slug },
  });

  const [selectedDesignType, setSelectedDesignType] = useState(null);
  const [designType, setDesignType] = useState(null);

  const designTypeOptions = useMemo(() => {
    if (data && dataP) {
      const designTypesPriceList =
        dataP.customerLocation.priceList.designTypes.reduce((obj, item) => {
          obj[item.id] = true;
          return obj;
        }, {});
      return data.labLocationGuideCategoryGroups
        .filter(({ designTypeId }) => designTypesPriceList[designTypeId])
        .map(({ designTypeName, designTypeId }) => ({
          name: designTypeName,
          value: designTypeId,
          id: designTypeId,
        }));
    }
    return null;
  }, [data, dataP]);

  const handleDesignType = (value) => {
    const dt = designTypeOptions.find((item) => item.value === value);
    setSelectedDesignType(value);
    setDesignType({
      designTypeName: dt.name,
      designTypeId: value,
    });
    setGuideCategoryGroups(
      data.labLocationGuideCategoryGroups.find(
        (item) => item.designTypeId === value,
      ).guideCategoryGroups,
    );
  };

  return {
    handleDesignType,
    designType,
    errorP,
    designTypeOptions,
    selectedDesignType,
  };
}

export default useDesignTypeSelection;
