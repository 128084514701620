import PropTypes from 'prop-types';

function SingleToothSVG({ isEmpty = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 62 60"
      width="28"
      height="28"
      fill="none"
    >
      <title>svg</title>
      <path
        stroke={isEmpty ? '#4A4A4A' : 'rgba(61, 172, 209, .9)'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.8}
        strokeWidth={3}
        d="M42.984 32.76c.118 6.354-5.24 11.595-11.967 11.71-6.727-.098-12.098-5.327-11.997-11.679l-.014-11.695s2.279-2.79 5.132-2.793c2.853-.004 3.092 1.109 6.559 1.105 3.466-.005 3.137-1.397 6.556-1.401 3.418-.004 5.71 3.056 5.71 3.056l.021 11.698Z"
      />
      <path
        stroke={isEmpty ? '#4A4A4A' : 'rgba(61, 172, 209, .9)'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.8}
        strokeWidth={3}
        d="M24 26s6.126 9 14 0"
      />
    </svg>
  );
}

SingleToothSVG.propTypes = {
  isEmpty: PropTypes.bool,
};

export default SingleToothSVG;
