import { useMutation } from '@apollo/client';
import {
  CREATE_GUIDE_CUSTOM_LOCATION,
  GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
} from '@fullcontour/shared-api';
import { useParams } from 'react-router';
import { handleTryCatch } from '../../../..';
import { useGuideCodeCardContext } from '../context/GuideCodeCardContext';

const useCreateNewCode = (value) => {
  const { slug } = useParams();

  const { data } = useGuideCodeCardContext();

  const { category } = data;

  const [createCode, { loading }] = useMutation(CREATE_GUIDE_CUSTOM_LOCATION, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS }],
  });

  const createNewCode = handleTryCatch(
    async () => {
      const isCodeExists = category.guideCodes.some(
        (item) => item.name === value,
      );

      if (isCodeExists) {
        throw { customText: 'Guide code already exists' };
      }
      const variablesCreateCode = {
        input: {
          input: {
            guideCategoryId: category.id,
            name: value,
            ...(slug ? { locationSlug: slug } : {}),
          },
        },
      };
      const {
        data: {
          createGuideCustomCode: {
            guideCustomCode: { id },
          },
        },
      } = await createCode({ variables: variablesCreateCode });

      return id;
    },
    {
      successText: 'Guide Code was successfully created',
      errorText: 'Guide code was not created',
    },
  );

  return { createNewCode, loading };
};

export default useCreateNewCode;
