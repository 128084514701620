import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  BoxIcon,
  CheckboxField,
  CountryDropdown,
  FieldContainer,
  FormSection,
  RegionDropdown,
  ServerErrors,
  withFormWrap,
} from '../../../../shared';
import { create, initialValues, schema, update } from './helpers';

function BillingCreditCardFormModal({
  closeModal,
  action = null,
  modalOpen,
  errorMessages,
  initialValues = () => ({}),
  schema = () => ({}),
  refetch,
  customer,
  type,
  creditCard,
  setErrorMessages,
  clearErrorMessages,
  refetchCustomerLocation,
}) {
  const client = useApolloClient();
  const [focused, setFocused] = useState('');
  async function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };
    if (action === 'New') {
      await create({
        variables,
        closeModal,
        client,
        refetch,
        setErrorMessages,
        clearErrorMessages,
        refetchCustomerLocation,
      });
    } else {
      await update({
        variables,
        closeModal,
        client,
        refetch,
        setErrorMessages,
        clearErrorMessages,
        refetchCustomerLocation,
      });
    }
    setSubmitting(false);
  }
  const resetAndCloseModal = (resetForm) => {
    resetForm();
    closeModal();
  };

  const handleInputFocus = ({ target }) => {
    let focusedField;

    if (target.name === 'cvv') {
      focusedField = 'cvc';
    }

    if (target.name === 'nameOnCard') {
      focusedField = 'name';
    }

    if (target.name === 'expirationDate') {
      focusedField = 'expiry';
    }

    if (target.name === 'cardNumber') {
      focusedField = 'number';
    }

    setFocused(focusedField);
  };

  const { t } = useTranslation('billing');

  return action ? (
    <Formik
      initialValues={initialValues({ customer, type, creditCard })}
      validationSchema={schema({ action })}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleSubmit: onSubmit,
        resetForm,
        isSubmitting,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={onSubmit}>
          <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <BoxIcon name="bx-credit-card bx-sm is-size-3" />
                <span>
                  <h4 className="title is-5 modal-card-title mb-0 ml-4">
                    {t(`${action} Credit Card`)}
                  </h4>
                </span>
              </header>
              <section className="modal-card-body">
                <Cards
                  number={values.cardNumber}
                  name={values.nameOnCard}
                  expiry={values.expirationDate}
                  cvc={values.cvv}
                  focused={focused}
                  issuer={values.brand}
                  callback={({ issuer }) => {
                    setFieldValue('brand', issuer);
                  }}
                />
                <FormSection title="Credit Card Info">
                  <div className="field is-grouped">
                    <div className="control is-expanded">
                      <FieldContainer
                        name="nameOnCard"
                        label="Name on Card"
                        required
                      >
                        <Field
                          id="nameOnCard"
                          name="nameOnCard"
                          required
                          placeholder={t('Name on Card')}
                          autoComplete="cc-name"
                          onFocus={handleInputFocus}
                          className="input"
                        />
                      </FieldContainer>
                    </div>
                    <div className="control is-expanded">
                      <FieldContainer
                        name="cardNumber"
                        required
                        label="Credit Card Number"
                      >
                        <Field
                          id="cardNumber"
                          name="cardNumber"
                          type="tel"
                          pattern="[\d| ]{16,22}"
                          placeholder={t('Credit Card Number')}
                          autoComplete="cc-number"
                          onFocus={handleInputFocus}
                          className="input"
                        />
                      </FieldContainer>
                    </div>
                  </div>
                  <div className="field is-grouped">
                    <div className="control is-expanded">
                      <FieldContainer
                        name="expirationDate"
                        label="Expiration Date - MM/yyyy"
                        required
                      >
                        <Field
                          id="expirationDate"
                          name="expirationDate"
                          type="tel"
                          required
                          autoComplete="cc-exp"
                          pattern="\d\d/\d\d\d\d"
                          placeholder={t('Valid Through')}
                          onFocus={handleInputFocus}
                          className="input"
                        />
                      </FieldContainer>
                    </div>
                    <div className="control is-expanded">
                      <FieldContainer name="cvv" label="CVV" required>
                        <Field
                          id="cvv"
                          name="cvv"
                          type="tel"
                          required
                          pattern="\d{3,4}"
                          placeholder="123"
                          autoComplete="cc-csc"
                          onFocus={handleInputFocus}
                          className="input"
                        />
                      </FieldContainer>
                    </div>
                  </div>
                  <Field
                    name="defaultCard"
                    required
                    component={CheckboxField}
                    labelText="Make Default"
                    headerText="Default Card"
                    size="mini"
                  />
                </FormSection>
                <FormSection title="Billing Address">
                  <FieldContainer
                    name="billingAddress.address"
                    label="Address"
                    required
                  >
                    <Field
                      id="billingAddress.address"
                      className="input"
                      name="billingAddress.address"
                      autoComplete="address-line-1"
                    />
                  </FieldContainer>
                  <FieldContainer
                    name="billingAddress.address2"
                    label="Address 2"
                  >
                    <Field
                      id="billingAddress.address2"
                      className="input"
                      name="billingAddress.address2"
                      autoComplete="address-line-2"
                    />
                  </FieldContainer>
                  <div className="columns">
                    <div className="column">
                      <Field
                        id="billingAddress.country"
                        name="billingAddress.country"
                        required
                        component={CountryDropdown}
                      />
                    </div>
                    <div className="column">
                      <Field
                        id="billingAddress.region"
                        name="billingAddress.region"
                        styles={{ paddingBottom: 10 }}
                        component={RegionDropdown}
                        country={values.billingAddress.country}
                        autoComplete="address-level-1"
                      />
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <FieldContainer name="billingAddress.city" label="City">
                        <Field
                          className="input"
                          id="billingAddress.city"
                          name="billingAddress.city"
                          required
                          autoComplete="address-level-2"
                        />
                      </FieldContainer>
                    </div>
                    <div className="column">
                      <FieldContainer
                        name="billingAddress.postalCode"
                        label="Postal Code"
                      >
                        <Field
                          className="input"
                          id="billingAddress.postalCode"
                          name="billingAddress.postalCode"
                          required
                          autoComplete="postal-code"
                          placeholder="999999"
                        />
                      </FieldContainer>
                    </div>
                  </div>
                </FormSection>
                <ServerErrors errorMessages={errorMessages} />
              </section>
              <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
                <button
                  className="button is-dark"
                  type="button"
                  onClick={() => {
                    resetAndCloseModal(resetForm);
                  }}
                >
                  {t('Close', { ns: 'common' })}
                </button>
                <button
                  className={`button is-primary ${isSubmitting ? 'is-loading' : ''}`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t('Create', { ns: 'common' })}
                </button>
              </footer>
            </div>
          </div>
        </form>
      )}
    </Formik>
  ) : null;
}

BillingCreditCardFormModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  errorMessages: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  BillingCreditCardFormModal,
);
