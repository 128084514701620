import { useThree } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import * as THREE from 'three';

function Scene() {
  const { scene, gl } = useThree();
  const [environment, setEnvironment] = useState(null);

  useEffect(() => {
    if (gl?.capabilities?.isWebGL2) {
      const pmremGenerator = new THREE.PMREMGenerator(gl);
      const envMap = pmremGenerator.fromScene(scene).texture;
      setEnvironment(envMap);
      pmremGenerator.dispose();
    }
  }, [scene, gl]);

  useEffect(() => {
    if (environment) {
      scene.environment = environment;
      // scene.background = new THREE.Color(0xf0f0f0);
    }
  }, [scene, environment]);

  return null;
}

export default Scene;
