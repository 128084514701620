import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { timestampFormatter } from '../../../../transforms';

import './InvoiceDetails.css';

function InvoiceSummary({ invoice, toDownload = false }) {
  return (
    <>
      {invoice.totalDebits > 0 && (
        <div className="columns mt-5">
          <div className="column" style={{ paddingLeft: '1em' }}>
            <b>
              <span>Charge Details</span>
            </b>
          </div>
        </div>
      )}
      {invoice.totalDebits > 0 && (
        <div className="columns mb-6">
          <div className="column" style={{ paddingLeft: '1em' }}>
            <table
              style={{
                fontSize: toDownload ? '10px' : '.75em',
                ...(toDownload ? { width: '660px' } : {}),
              }}
              className="is-bordered is-striped is-fullwidth table"
            >
              <thead>
                <tr>
                  <th
                    style={{
                      paddingLeft: '1em',
                      width: '18%',
                    }}
                  >
                    Charge ID
                  </th>
                  <th style={{ width: '42%' }}>Details</th>
                  <th style={{ width: '25%' }}>Date</th>
                  <th
                    style={{
                      width: '15%',
                      textAlign: 'right',
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoice.totalDebits > 0 &&
                  invoice.debits &&
                  invoice.debits.map((debit) => (
                    <tr key={debit.id}>
                      <td
                        style={{
                          paddingLeft: '1em',
                          textTransform: 'uppercase',
                        }}
                      >
                        {debit.hexDebitNumber}
                      </td>
                      <td>{debit.reason}</td>
                      <td>{timestampFormatter(debit.createdAt, false)}</td>
                      <td style={{ textAlign: 'right' }}>${debit.amount}</td>
                    </tr>
                  ))}
                <tr>
                  <td style={{ paddingLeft: '1em' }}>
                    <b>Charges Total</b>
                  </td>
                  <td> </td>
                  <td> </td>
                  <td style={{ textAlign: 'right' }}>
                    <b>${invoice.totalDebits}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className="columns mt-5">
        <div className="column" style={{ paddingLeft: '1em' }}>
          <b>
            <span>Case Details</span>
          </b>
        </div>
      </div>
      <div className="columns mb-6">
        <div className="column" style={{ paddingLeft: '1em' }}>
          <table
            style={{ fontSize: toDownload ? '10px' : '.75em' }}
            className={`is-bordered is-striped is-fullwidth table ${
              toDownload ? 'downloadPDF' : ''
            }`}
          >
            <thead>
              <tr>
                <th
                  style={{
                    paddingLeft: '1em',
                    width: '10%',
                  }}
                >
                  Case ID
                </th>
                <th style={{ width: '30%' }}>File Name</th>
                <th style={{ width: '5%' }}>Units</th>
                {/* *** Leaving in case we get feedback that this is required ***
                <th>Teeth</th>
                <th>Arches</th> */}
                <th style={{ width: '10%' }}>Design Type</th>
                <th style={{ width: '15%' }}>Turnaround</th>
                <th style={{ width: '10%' }}>Case Date</th>
                <th style={{ textAlign: 'right', width: '10%' }}>Modifers</th>
                <th style={{ textAlign: 'right', width: '10%' }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {invoice.invoiceItems.length > 0 &&
                invoice.invoiceItems.map((item) =>
                  item.invoiceItemCases.map((itemCase) => (
                    <tr key={itemCase.id}>
                      <td style={{ paddingLeft: '1em' }}>
                        {toDownload ? (
                          item.hexOrderCode
                        ) : (
                          <Link
                            to={`/orders/details/${item.hexOrderCode}`}
                            style={{ textTransform: 'uppercase' }}
                            className="has-text-info"
                          >
                            {item.hexOrderCode}
                          </Link>
                        )}
                      </td>
                      <td style={{ maxWidth: 70, wordWrap: 'break-word' }}>
                        {item.order?.intOrderId}
                      </td>
                      <td>{itemCase.totalUnits}</td>
                      {/* *** Leaving in case we get feedback that this is required ***
                      <td>{itemCase.teeth}</td>
                      <td>{itemCase.arches}</td> */}
                      <td>{itemCase.service}</td>
                      <td>{itemCase.turnaroundTime}</td>
                      <td>
                        {item.createdAt &&
                          timestampFormatter(item.createdAt, false)}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        ${Number.parseFloat(itemCase.modifierPrice).toFixed(2)}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        $
                        {(
                          Number.parseFloat(itemCase.unitPrice) *
                            Number.parseInt(itemCase.totalUnits, 10) +
                          Number.parseFloat(itemCase.modifierPrice)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  )),
                )}
              <tr>
                <td style={{ paddingLeft: '1em' }}>
                  <b>Cases Total</b>
                </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td style={{ textAlign: 'right' }}>
                  <b>${invoice.totalPrice}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

InvoiceSummary.propTypes = {
  invoice: PropTypes.object.isRequired,
  toDownload: PropTypes.bool,
};

export default memo(InvoiceSummary);
