import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ElementActionsConvertedTreatmentFiles } from '../../../../..';

function ViewerContainer({
  convertedTreatmentReviewFiles,
  treatmentReviewFiles,
  redesignCountObj = null,
  height = '70vh',
  iprs = null,
}) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const filesFromCurrentRedesign = convertedTreatmentReviewFiles
      ?.filter(({ redesignCount }) => redesignCount === redesignCountObj)
      .map((item) => ({
        ...item,
        signedUrl: item.downloadUrl,
      }));
    setFiles(filesFromCurrentRedesign);
  }, [redesignCountObj, convertedTreatmentReviewFiles]);

  return files?.length > 0 ? (
    <div style={{ height: '100%' }}>
      <ElementActionsConvertedTreatmentFiles
        files={treatmentReviewFiles}
        selectedItems={files}
        redesignCount={redesignCountObj}
        containerStyle={{ height, minHeight: '500px' }}
        iprs={iprs}
      />
    </div>
  ) : (
    <div />
  );
}

ViewerContainer.propTypes = {
  redesignCountObj: PropTypes.number,
  convertedTreatmentReviewFiles: PropTypes.array.isRequired,
  treatmentReviewFiles: PropTypes.array.isRequired,
  iprs: PropTypes.object,
  height: PropTypes.string,
};

export default ViewerContainer;
