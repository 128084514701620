import PropTypes from 'prop-types';
import DataPointsCustomerTags from './DataPointsCustomerTags';
import DataPointsDesignTypes from './DataPointsDesignTypes';
import DataPointsTurnaroundTimes from './DataPointsTurnaroundTimes';

function DataPointsSecondary(props) {
  const {
    currentReportType: { inputParams },
  } = props;

  return (
    <>
      <DataPointsCustomerTags inputParams={inputParams} />
      <DataPointsDesignTypes inputParams={inputParams} />
      <DataPointsTurnaroundTimes inputParams={inputParams} />
    </>
  );
}

DataPointsSecondary.propTypes = {
  currentReportType: PropTypes.object.isRequired,
};

export default DataPointsSecondary;
