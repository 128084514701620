import { useApolloClient, useQuery } from '@apollo/client';
import { GET_NOTIFICATION_TYPES } from '@fullcontour/shared-api';
import { FieldArray, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FormGridWrap, LoadingOrError, withFormWrap } from '../../../shared';
import NotificationSettingsList from './NotificationSettingsList';
import { initialValues, schema, update } from './helpers';

function NotificationSettingsForm({
  initialValues = () => ({}),
  schema = () => ({}),
  getVariables = () => ({}),
  skipQuery = () => ({}),
  match = {},
  separateId = null,
  setErrorMessages,
  clearErrorMessages,
}) {
  const client = useApolloClient();
  const { data, loading, error, refetch } = useQuery(GET_NOTIFICATION_TYPES, {
    skip: skipQuery ? skipQuery() : false,
    variables: getVariables ? getVariables() : {},
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error) {
    return (
      <LoadingOrError
        error={error}
        loading={loading}
        title="Notification Settings"
      />
    );
  }
  function handleSubmit(values, { setSubmitting }) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };

    if (separateId && match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }

    if (separateId) {
      variables.variables.input.input.id = undefined;
    }

    if (!match.params.action) {
      update({ variables, client, setErrorMessages, clearErrorMessages });
      setTimeout(() => {
        setSubmitting(false);
        refetch();
      }, 1000);
    }
  }
  return (
    <Formik
      initialValues={initialValues({ data })}
      validationSchema={schema()}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <FormGridWrap match={match} hideHeader>
          <div className="block">
            <h4 className="title is-4">Notification Settings</h4>
          </div>

          <div className="block">
            <table className="is-bordered is-striped is-fullwidth is-hoverable table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Notification</th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="notificationTypeUsers"
                  required
                  render={(arrayHelpers) => (
                    <NotificationSettingsList
                      arrayHelpers={arrayHelpers}
                      data={data}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
                />
              </tbody>
            </table>
          </div>
        </FormGridWrap>
      )}
    </Formik>
  );
}

NotificationSettingsForm.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  getVariables: PropTypes.func,
  skipQuery: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  create: PropTypes.func,
  separateId: PropTypes.string,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  NotificationSettingsForm,
);
