import { useQuery } from '@apollo/client';
import { useApp } from '@fullcontour/hooks';
import {
  GET_CUSTOMER_LOC,
  GET_CUSTOMER_LOC_LOGO,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useContext } from 'react';
import { timestampFormatter } from '../../../../../transforms';
import { CurrentUserContext } from '../../../../shared';
import OrderMessageInner from './OrderMessageInner';
import OrderShowMessageUserImg from './OrderShowMessageUserImg';
import OrderShowMessagesCustomerImagesSection from './OrderShowMessagesCustomerImagesSection';

function OrderShowMessagesCustomerMessage({
  message,
  idx,
  arrayOrigin,
  setPreviewImage,
  setCurrentMessagePhotoModal,
  setIsOpen,
  setPhotoIndex,
  messageImages,
  setMessageImages,
  locationId,
}) {
  const { isManuf } = useApp();
  const { currentUser } = useContext(CurrentUserContext);

  const query = isManuf ? GET_CUSTOMER_LOC_LOGO : GET_CUSTOMER_LOC;

  const { data } = useQuery(query, {
    variables: {
      id: currentUser?.locationId || locationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const systemMessage =
    message.systemMessage || message.orderMessageableType !== 'User';

  const isGroupedMessage =
    idx &&
    !(arrayOrigin[idx - 1].systemMessage !== message.systemMessage) &&
    (new Date(message.createdAt).getTime() -
      new Date(arrayOrigin[idx - 1].createdAt).getTime()) /
      1000 <
      60 * 15;

  if (isGroupedMessage) {
    return (
      <div
        key={message.id}
        style={{
          display: 'flex',
          margin: '0 80px',
          flexDirection: systemMessage ? 'row' : 'row-reverse',
        }}
      >
        <OrderMessageInner
          single
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: systemMessage ? 'flex-start' : 'flex-end',
          }}
          message={message}
          imagesSection={
            <OrderShowMessagesCustomerImagesSection
              message={message}
              setPreviewImage={setPreviewImage}
              setCurrentMessagePhotoModal={setCurrentMessagePhotoModal}
              setIsOpen={setIsOpen}
              setPhotoIndex={setPhotoIndex}
              messageImages={messageImages}
              setMessageImages={setMessageImages}
            />
          }
        />
      </div>
    );
  }

  return (
    <Fragment key={message.id}>
      <div
        className="panel-block"
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ alignSelf: 'center' }}>
          <p className="is-italic has-text-grey-dark is-size-7 has-text-centered">
            {timestampFormatter(message.createdAt)}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: systemMessage ? 'row' : 'row-reverse',
          }}
        >
          <div
            style={{
              width: '48px',
              height: '48px',
              display: 'flex',
              margin: systemMessage ? '0 20px 0 0' : '0 0 0 20px',
            }}
          >
            {data && (
              <OrderShowMessageUserImg
                systemMessage={message.systemMessage}
                messageableType={message.orderMessageableType}
                logoUrl={data.customerLocation.logoUrl}
                alt={data.customerLocation.name}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: systemMessage ? 'flex-start' : 'flex-end',
            }}
          >
            <div>{message.userName}</div>
            <OrderMessageInner
              style={
                !systemMessage
                  ? {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }
                  : {}
              }
              message={message}
              imagesSection={
                <OrderShowMessagesCustomerImagesSection
                  message={message}
                  setPreviewImage={setPreviewImage}
                  setCurrentMessagePhotoModal={setCurrentMessagePhotoModal}
                  setIsOpen={setIsOpen}
                  setPhotoIndex={setPhotoIndex}
                  messageImages={messageImages}
                  setMessageImages={setMessageImages}
                />
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

OrderShowMessagesCustomerMessage.propTypes = {
  message: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  arrayOrigin: PropTypes.array.isRequired,
  setPreviewImage: PropTypes.func.isRequired,
  setCurrentMessagePhotoModal: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setPhotoIndex: PropTypes.func.isRequired,
  messageImages: PropTypes.object.isRequired,
  setMessageImages: PropTypes.func.isRequired,
  locationId: PropTypes.string,
};

export default OrderShowMessagesCustomerMessage;
