import { useQuery } from '@apollo/client';
import { GET_ORDER_MESSAGES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import {
  PatientLinkModal,
  TeethChart,
  TreatmentHistory,
  ViewerContainer,
} from './components';
import './styles.css';
import { useApp } from '@fullcontour/hooks';

function OrthoObjViewerWithTreatment({
  order,
  dentalNotation = null,
  height = '80vh',
  iprs = null,
  redesignCountObj,
  setRedesignCountObj,
  otpFiles = [],
  orderRedesignCount = null,
}) {
  const { isApproval } = useApp();

  const [designUserMessages, setDesignUserMessages] = useState([]);
  const [
    designUserMessagesCurrentRedesign,
    setDesignUserMessagesCurrentRedesign,
  ] = useState([]);

  useQuery(GET_ORDER_MESSAGES, {
    variables: { id: order.hexOrderCode },
    skip: isApproval,
    onCompleted(data) {
      const { orderMessages } = data;
      const newDesignUserMessages = orderMessages.filter(
        (item) => item.orderMessageableType === 'DesignUser',
      );

      setDesignUserMessages(newDesignUserMessages);

      setDesignUserMessagesCurrentRedesign(
        newDesignUserMessages.filter(
          (item) => item.redesignCount === redesignCountObj,
        ),
      );
    },
  });

  const designerMessages = useMemo(
    () =>
      isApproval
        ? order.designUserMessages?.filter(
            (item) => item.redesignCount === redesignCountObj,
          )
        : designUserMessagesCurrentRedesign,
    [designUserMessagesCurrentRedesign, isApproval, redesignCountObj, order],
  );

  const designerMessagesCurrent = useMemo(
    () =>
      [
        ...(isApproval ? order?.designUserMessages || [] : designUserMessages),
      ]?.filter((item) => item.redesignCount === orderRedesignCount),
    [
      designUserMessages,
      isApproval,
      orderRedesignCount,
      order?.designUserMessages,
    ],
  );

  return (
    <>
      <div className="is-flex is-justify-content-right">
        {isApproval && order?.convertedTreatmentReviewFiles?.length > 0 && (
          <PatientLinkModal />
        )}
      </div>
      <div className="treatment-viewer-wrapper">
        <ViewerContainer
          convertedTreatmentReviewFiles={order.convertedTreatmentReviewFiles}
          treatmentReviewFiles={order.treatmentReviewFiles}
          redesignCountObj={redesignCountObj}
          height={height}
          iprs={iprs}
        />
      </div>

      {otpFiles?.length > 0 && (
        <>
          <TeethChart
            order={order}
            treatmentReviewFiles={order.orderFiles}
            redesignCountObj={redesignCountObj}
            dentalNotation={dentalNotation}
            designerMessagesCurrent={designerMessagesCurrent}
          />
          <TreatmentHistory
            otpFiles={otpFiles}
            dataFromUrl={order.dataFromUrl}
            lastRedesign={order.lastRedesign}
            objFiles={order.objFiles}
            orderRedesignCount={orderRedesignCount}
            treatmentReviewFiles={order.treatmentReviewFiles}
            feedbacks={order.feedbacks}
            designerMessages={designerMessages}
            designerMessagesCurrent={designerMessagesCurrent}
            redesignCountObj={redesignCountObj}
            setRedesignCountObj={setRedesignCountObj}
            currentRedesign={orderRedesignCount}
            resetHistory={() => setRedesignCountObj(orderRedesignCount)}
          />
        </>
      )}
    </>
  );
}

OrthoObjViewerWithTreatment.propTypes = {
  order: PropTypes.object.isRequired,
  dentalNotation: PropTypes.string,
  height: PropTypes.string,
  iprs: PropTypes.object,
  redesignCountObj: PropTypes.number.isRequired,
  setRedesignCountObj: PropTypes.func.isRequired,
  otpFiles: PropTypes.array,
  orderRedesignCount: PropTypes.number,
};

export default OrthoObjViewerWithTreatment;
