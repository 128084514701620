import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

function ReviewModalActionsCheckLink({
  order = null,
  text = '',
  isShowing,
  turnOn,
  query,
  linkType,
  setHideElement,
}) {
  const { error, loading, data } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: { id: order?.id ? order.id : null },
    skip: !order,
    onCompleted(response) {
      if (!response[Object.keys(response)[0]]) {
        setHideElement((prev) => ({ ...prev, [linkType]: true }));
      }
    },
  });

  if (error) {
    return <p>Error...</p>;
  }
  if (loading) {
    return null;
  }

  let link;

  if (linkType === 'doctor') {
    const { showApprovalLink } = data;
    link = showApprovalLink;
  }

  if (linkType === 'patient') {
    const { showPatientLink } = data;
    link = showPatientLink;
  }

  return link ? (
    <button
      className="dropdown-item has-text-black-ter is-clickable button is-ghost"
      type="button"
      onClick={turnOn}
      disabled={isShowing}
    >
      {text}
    </button>
  ) : null;
}

ReviewModalActionsCheckLink.propTypes = {
  turnOn: PropTypes.func.isRequired,
  setHideElement: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  order: PropTypes.object,
  query: PropTypes.object.isRequired,
  text: PropTypes.string,
  linkType: PropTypes.string.isRequired,
};

export default ReviewModalActionsCheckLink;
