import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { classesGenerator } from '../../../../transforms';

function TabList({
  children = null,
  isAlign = null,
  isSize = null,
  isBoxed = false,
  isToggle = false,
  isToggleRounded = false,
  isFullwidth = false,
  ...rest
}) {
  const classNames = useMemo(
    () =>
      classesGenerator({
        tabs: true,
        [`is-${isAlign}`]: isAlign,
        [`is-${isSize}`]: isSize,
        'is-boxed': isBoxed,
        'is-toggle': isToggle,
        'is-toggle-rounded': isToggleRounded,
        'is-fullwidth': isFullwidth,
      }),

    [rest],
  );

  return (
    <div className={classNames} {...rest}>
      <ul>{children}</ul>
    </div>
  );
}

TabList.displayName = 'Tabs.TabList';

TabList.propTypes = {
  isAlign: PropTypes.string,
  isSize: PropTypes.string,
  isBoxed: PropTypes.bool,
  isToggle: PropTypes.bool,
  isToggleRounded: PropTypes.bool,
  isFullwidth: PropTypes.bool,
  children: PropTypes.node,
};

export default TabList;
