import CreateNotification from './createNotification';
import throwError from './throwError';

const handleTryCatch =
  (fn, params = {}) =>
    (...props) =>
      fn(...props)
        .then((data) => {
          if (data?.data) {
            throwError(data.data);
          }

          if (params.successText) {
            CreateNotification.createNotification({
              type: 'success',
              message: params.successText,
            });
          }

          return data;
        })
        .catch((err) => {
          if (!err.hide) {
            CreateNotification.createNotification({
              type: err.type || 'error',
              message:
                err.customText ||
                `Something went wrong${params.errorText ? `: ${params.errorText}` : ''
                }`,
            });
          }
        })
        .finally(() => {
          if (params?.callbacks) {
            for (const fnc of params.callbacks) {
              fnc()
            }
          }
        });
export default handleTryCatch;
